import { db } from "../../services/firebase/Config";
import { fetchLabs } from "../../services/firebase/labs/index";
import {
  SET_TRATAMIENTOS,
  UPDATE_TRATAMIENTO_IN_ARRAY,
  ADD_TRATAMIENTO_IN_ARRAY,
  REMOVE_TRATAMIENTO_IN_ARRAY,
} from "./tratamientosTypes";

import { httpRequest, httpSuccess, httpFailure } from "../sesion/sesionActions";

const setTratamientos = (payload) => {
  return {
    type: SET_TRATAMIENTOS,
    payload,
  };
};

export const updateTratamientoInArray = (tratamiento) => {
  return {
    type: UPDATE_TRATAMIENTO_IN_ARRAY,
    payload: tratamiento,
  };
};

const addTratamientoInArray = (tratamiento) => {
  return {
    type: ADD_TRATAMIENTO_IN_ARRAY,
    payload: tratamiento,
  };
};
const removeTratamientoInArray = (tratamiento) => {
  return {
    type: REMOVE_TRATAMIENTO_IN_ARRAY,
    payload: tratamiento,
  };
};

export const saveTratamiento = (tratamiento) => {
  return function (dispatch) {
    dispatch(httpRequest());
    const tratamientoRef = db.collection("tratamientos").doc(tratamiento.id);
    tratamientoRef
      .set(tratamiento)
      .then(() => {
        dispatch(httpSuccess());
        dispatch(updateTratamientoInArray(tratamiento));
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const removeTratamiento = (tratamiento) => {
  return function (dispatch) {
    dispatch(httpRequest());
    const tratamientoRef = db.collection("tratamientos").doc(tratamiento.id);
    tratamientoRef
      .update({ estado: "INACTIVO" })
      .then(() => {
        dispatch(updateTratamientoInArray(tratamiento));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const updateTratamiento = (tratamiento) => {
  return function (dispatch) {
    dispatch(httpRequest());
    const tratamientoRef = db.collection("tratamientos").doc(tratamiento.id);
    tratamientoRef
      .set(tratamiento)
      .then(() => {
        dispatch(updateTratamientoInArray(tratamiento));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const addTratamiento = (tratamiento) => {
  return function (dispatch) {
    dispatch(httpRequest());

    const tratamientoRef = db.collection("tratamientos").doc();
    tratamiento.id = tratamientoRef.id;
    tratamientoRef
      .set(tratamiento)
      .then((doc) => {
        dispatch(httpSuccess());
        dispatch(addTratamientoInArray(tratamiento));
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};
export const addTratamientoYTarifario = (tratamiento) => {
  return function (dispatch) {
    dispatch(httpRequest());
    let batch = db.batch();
    fetchLabs().then((labs) => {
      const tratamientoRef = db.collection("tratamientos").doc();
      batch.set(tratamientoRef, tratamiento);
      tratamientoRef.set(tratamiento);
      labs.forEach((lab) => {
        const tarifariosRef = db.collection("tarifarios").doc();
        batch.set(tarifariosRef, {
          codigo: tratamiento.codLab,
          id: tarifarioRef.id,
          labId: lab.id,
          nombre: `${tratamiento.tratamiento} ${
            tratamiento.tipo ? tratamiento.tipo : ""
          }`,
          precio: tratamiento.precio,
        });
      });

      batch
        .commit()
        .then(() => {
          dispatch(httpSuccess());
          dispatch(addTratamientoInArray(tratamiento));
        })
        .catch((err) => {
          dispatch(httpFailure(err));
        });
    });
  };
};
const tarifarioRef = db.collection("tratamientos").doc();

export const fetchTratamientos = (empresaId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    let tratamientos = [];
    db.collection("tratamientos")
      .orderBy("tratamiento")
      .where("empresaId", "==", empresaId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          tratamientos.push({
            id: doc.id,
            especialidad: doc.data().especialidad,
            precio: doc.data().precio,
            costo: doc.data().costo||doc.data().precio*0.8,
            tiempo: doc.data().tiempo||30,
            tipo: doc.data().tipo,
            tratamiento: doc.data().tratamiento,
            codLab: doc.data().codLab,
            estado: doc.data().estado,
            empresaId: doc.data().empresaId,
            subGrupoId: doc.data().subGrupoId,
            readOnly: doc.data().readOnly?doc.data().readOnly:false,
          });
        });
        dispatch(httpSuccess(tratamientos));
        dispatch(setTratamientos(tratamientos));
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};
