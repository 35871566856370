import styled from "styled-components";
import { dispositivo } from "../../components/Dispositivos";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";

export const UnOrderedList = styled.div`
  list-style-type: none;
  padding: 0;
  margin: 0;

`;

export const Button = styled.button`
  height: 2.25rem;
  font-size: 0.9rem;  
  width: 7rem;
  border-radius: 0.8rem;      
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #0060b0;
  color: #ffffff;
  border: none;
  &:hover{
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.tablet}{
   width: 10rem;
   font-size: 1rem;  
  }  
  @media ${dispositivo.laptop} {      
    width: 12.5rem;
  }
`;

export const Buttons = styled.button`
  height: 2.25rem;
  font-size: 0.9rem;  
  width: 7rem;  
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #ffffff;
  border: none;
  border-bottom: 1px solid #0C8DE3;
  &:hover{
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.tablet}{
   font-size: 1rem;  
  }  
`;
export const SectionTarjeta = styled.section`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 0.5rem;  
  .cardContainer{
    border-radius: 15px;
    padding: 5px 0px;
  }
  @media ${dispositivo.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${dispositivo.laptop} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
export const ContenedorTarjetas = styled(Card)`
  display: block;
  padding: 5px;
  max-width: 260px;
  max-height: 320px;
  width: 100%;
  font-size: 10px;
  margin: 1%;
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;
export const ContenedorCabeceraTarjeta = styled(CardHeader)`
  color: #045184;
  background: #fff;
  padding: 2% !important;
  border-bottom: 1px solid #AAA4A6;
  .MuiTypography-h5{
    font-size: ${props => ((props.title.length < 35) ? "1.5rem !important" : "1rem !important")};
    font-weight: ${props => ((props.title.length < 35) ? "400 !important" : "bold !important")};
    width: 210px !important;
    white-space: ${props => ((props.title.length > 55) ? "nowrap !important" : " normal")};
    text-overflow: ${props => ((props.title.length > 55) ? "ellipsis !important" : " ")};
    overflow: ${props => ((props.title.length > 55) ? "hidden !important" : "")};
  }
  
  
`;
export const ContenedorContenidoTarjeta = styled(CardContent)`
  display: block;
  color: #045184;
  height: 180px;
  font-size:14px;
  padding: 0px 16px !important;
  .divFecha{
    color: #045184;
    text-align: end;
    margin: 3px 0 5px 0;
    font-size: 14px;
  }
  .scrollDiv{
    flex: 1;
    max-height: 100px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 5px;
    }
  
    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
      border-radius: 2px;
    }
  
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #0c8de3;
      border-radius: 2px;
    }
  
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #fff;
    }
  }
`;
export const TarjetaAccion = styled(CardActions)`
  justify-content: center;
`;

export const TablaBodyTextoFinanzaPrecio = styled.div`
  font-size: 14px;
  padding: 1% 2%;
  text-align: center;
  color: #040404;
  .statusDiv{
    margin: 0.5em 0;
    font-size: 16px;
  }
  .statusSpan{
    font-weight: bold;
    font-size: 18px;
    color: #00BB31;
    @media ${dispositivo.tablet} {
      font-size: 20px;
    }
  }
`;