import { db } from "../Config";

export const fetchStats = async (empresaId, year) => {
  let stats = [];
  await db
    .collection("stsMensual")
    .where("empresaId", "==", empresaId)
    .where("year", "==", year)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        stats.push(doc.data());
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
  return stats;
};

export const fetchMonthStats = async (empresaId, year, month) => {
  let stats = {};
  await db
    .collection("stsMensual")
    .where("empresaId", "==", empresaId)
    .where("year", "==", year)
    .where("month", "==", month)
    .get()
    .then((querySnapshot) => {
      
      querySnapshot.forEach((doc) => {
        stats = doc.data()
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
  return stats;
};

