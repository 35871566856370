import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { dispositivo } from "../../components/Dispositivos";

export const MainContainer = styled.div`
  width: 100%;
  text-align: center;
  background: #f8f8f8;
`;

export const ContainerTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;

export const ContainerTitleText = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;

export const ContainerBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 4%;
  background: #fff;
  box-shadow: 2px 2px #88888877;
  border-radius: 14px;
`;

export const ContainerLeft = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: right;
`;

export const ButtonAddPay = styled(Button)`
  && {
    background-color: #0c8de3;
    border: none;
    color: white;
    text-align: center;
    font-size: 14px;
    margin-bottom: 2%;
    padding-bottom: 0.8%;
    border-radius: 4px;
    transition: 0.3s;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    @media ${dispositivo.ta} {
      font-size: 12px;
    }
    @media ${dispositivo.mobileL} {
      font-size: 10px;
    }
  }
`;

export const Tabla = styled.table`
  width: 100%;
`;

export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const TablaHeaderTexto = styled.th`
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;

export const TablaBody = styled.tbody`
  background: #fff;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  border: 2px solid #ccc;
  padding-bottom: 16px;
`;

export const TablaBodyTexto = styled.th`
  font-size: 0.688rem;
  color:  #045184;
  padding-top: 8px;
  padding-bottom: 8px;
  .pagarLink{
    text-decoration: none;
    color: #0C8DE3;
    font-weight: bold;
    &:hover{
      text-decoration: none;
      color: #045184;
    }
  }
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;

export const StyledInput = styled.input`
  width: 30%;
  color: #707070;
  vertical-align: middle;
  text-align: center;
  &:focus {
    outline: none;
  }
`;

export const ContenedorTabla = styled.div`
  @media ${dispositivo.tablet} {
    flex: 1;
    max-height: 370px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 20px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #fff;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #fff;
    }
  }
`;
