import styled from "styled-components";
import { Link } from "react-router-dom";
import { dispositivo } from "../../components/Dispositivos";

export const MainContainer = styled.div`
  width: 100%;
  padding-left: 3%;
  padding-right: 3%;
  margin-bottom: 15%;
  background: #f8f8f8;
  justify-content: center;
  text-align: center;
`;

export const ContainerTabla = styled.div`
  background: #fff;
  border-radius: 14px;
  padding: 4%;
  margin-top: 3%;
  display: block;
  box-shadow: 2px 2px #88888877;
`;

export const ButtonAbonar = styled.button`
  height: 2.25rem;
  border-radius: 1em;      
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  font-size: 0.9rem;
  background-color: #0060b0;
  color: #ffffff;
  font-size: 0.9rem;
  border: none;
  width: 7rem;
  &:hover{
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.mobileS}{
    font-size: 0.9rem;
    width: 9rem;
  } 
  @media ${dispositivo.tablet}{
    width: 10rem;
  }  
  @media ${dispositivo.laptop} {      
    width: 12.5rem;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  border: 2px solid #0c8de3;
`;

export const StyledForm = styled.form`
  width: 90%;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;

`;

export const Tabla = styled.table`
  width: 100%;
  justify-content: center;
  text-align: center;
`;

export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 12px;
  justify-content: center;
  text-align: center;
`;

export const TablaBody = styled.tbody`
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 12px;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
`;

export const TablaBodyTexto = styled.th`
  font-size: 12px;
  color: #040404;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  .styleMonto{
    font-size: 1.125rem;
    @media ${dispositivo.tablet}{
      font-size: 1.5rem;
    }  
    @media ${dispositivo.laptop} {      
      font-size: 1.875rem;
    }
  }
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const TablaBodyTextoSoles = styled.th`
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  color: #045184;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
 
`;


export const SelectStyle = styled.select`
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  line-height: 2.5;
  font-size: 1rem;
  height: 2.5rem;
  width:100%;
  text-align: end;
  &::placeholder {
  color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
`;

export const StyledInput = styled.input`
  height: 2rem;
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  line-height: 2.5;
  font-size: 1rem;
  width: 100%;
  text-align: end;
  padding-right: 1em;
  
  &::placeholder {
    color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
`;
export const ContainerBtn = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: end;
  .linkP{
    padding-top: 0.8em;
  }
`;

export const LinkCancelar = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  font-size: 0.9rem;  
  width: 7rem;
  border-radius: 0.8rem;      
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #ffffff;
  border: 2px solid #0060b0;
  color: #0060b0;
  text-decoration: none;
  padding: 0.6em 0;
  &:hover {
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
    border: none;
    text-decoration: none;
  }
  @media ${dispositivo.mobileS}{
    font-size: 0.9rem;
    width: 9rem;
  } 
  @media ${dispositivo.tablet}{
    width: 10rem;
  }  
  @media ${dispositivo.laptop} {      
    width: 12.5rem;
  }
`;

export const StyledLabel = styled.label`
  vertical-align: middle;
  padding: 0px;
  margin: 0px;
  font-weight: bold;
`;

export const StyledOption = styled.option`
  color: #707070;
  font-size: 14px;
`;

export const StyledOptgroup = styled.optgroup`
  color: #707070;
  font-weight: bold;
  font-size: 12px;
`;
