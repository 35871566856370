import React from "react";
import IncisivoSelect from "../Dientes/IncisivoSelect";
import { useHistory } from "react-router-dom";
import Diente from "../Diente";
import { Modal } from "../Modal/index";
import { useToasts } from "react-toast-notifications";
import { Puente } from "../Puente";
import BuscarTratamientos from "../BuscarTratamientos/index";
import {
  newPrestation,
  guardarOdontoInicialTrx,
} from "../../redux/prestation/prestationActions";
import {
  Denticion,
  aplicarPrestacion,
  DefaultConfig,
  CARAS,
  NUCLEO,
  EXODONCIA,
  IMPLANTE,
  aplicarCaras,
} from "../ConfigDiente";

import { useDispatch, useSelector } from "react-redux";
import {
  MainContainer,
  ContainerBody,
  ContainerTitle,
  ContainerTitleText,
  ContenedorCorona,
  Buttons,
  LinkCancelar,
  ButtonPagar,
  ContenedorDiente,
  ContenedorDientes,
} from "./styles";

const Caras = ({ itemsOdontograma, config, faces, onSelectedFace }) => {
  let itemOdonto = aplicarCaras(itemsOdontograma[0], config, faces);
  const clase = Denticion.dientes.find(
    (item) => item.diente == itemOdonto.diente
  )?.lado;
  return (
    <>
      <ContenedorDientes>
        <ContenedorDiente>
          <Diente numero={itemOdonto.diente} config={itemOdonto} />
        </ContenedorDiente>
      </ContenedorDientes>
      <ContenedorCorona>
        <div>
          <span className="span-font">Selección de caras:</span>
        </div>
        <div className={clase}>
          <IncisivoSelect
            width="10rm"
            height="10rm"
            faces={faces}
            color={config.color}
            onSelectedFace={onSelectedFace}
          />
        </div>
      </ContenedorCorona>
    </>
  );
};

const General = ({ itemsOdontograma, config, faces }) => {
  let itemsOdonto;
  const { addToast } = useToasts();
  try {
    itemsOdonto = itemsOdontograma.map((item) =>
      aplicarPrestacion({
        itemOdonto: item,
        config,
        caras: faces,
        esPilar: true,
      })
    );
  } catch (err) {
    addToast(err.message, { appearance: "error" });
  }

  return (
    <>
      <ContenedorDientes>
        {itemsOdonto.map((it, index) => (
            <div key={index}>
              <ContenedorDiente>
                <Diente numero={it.diente} config={it}></Diente>
              </ContenedorDiente>
              <div>{it.diente}</div>
            </div>
          
        ))}
      </ContenedorDientes>
    </>
  );
};

export const OdontoInicialModal = (props) => {
  const prestacion = useSelector((state) => state.prestacion);
  const paciente = useSelector((state) => state.paciente);
  const sesion = useSelector((state) => state.sesion);
  const [dientes, setDientes] = React.useState(props.dientes);
  const [faces, setFaces] = React.useState(prestacion.caras);
  const [pilares, setPilares] = React.useState([]);
  const dispatch = useDispatch();
  const [itemsOdontograma, setItemsOdontograma] = React.useState(
    new DefaultConfig()
  );
  const odontograma = useSelector((state) => state.odontograma);
  const empresa = useSelector((state) => state.empresa);
  const [tratamiento, setTratamiento] = React.useState();
  const subGrupos = useSelector((state) => state.subGrupos);
  const PUENTE = "PUENTE";
  const CORONA = "CORONA";
  const TOTAL_SUPERIOR = "TOTAL_SUPERIOR";
  const TOTAL_INFERIOR = "TOTAL_INFERIOR";
  const SUPERIOR = "SUPERIOR";
  const INFERIOR = "INFERIOR";
  const getConfig = (subGrupoId) => {
    let config = {
      grupo: "",
      protesisTipo: "",
      envLab: "",
      afectaOdonto: "",
      multiplesDientes: "",
      color: "",
      id: "",
      coronaTipo: "",
    };

    let miConfig = subGrupos.find((it) => it.id === subGrupoId);

    return miConfig ? miConfig : config;
  };

  React.useEffect(() => {
    dispatch(newPrestation());
  }, []);

  React.useEffect(() => {
    setDientes(props.dientes);
    setItemsOdontograma(props.dientes.map((d) => new DefaultConfig(d)));
  }, [props.dientes]);

  const { addToast } = useToasts();

  const handleSelectedItem = (item) => {
    try {
      let miConfig = subGrupos.find((it) => it.id === item.subGrupoId);
      item.config = miConfig;
      validaTratamiento(item);
      validaSeleccionContigua(item);
      validaSeleccion(item);
      autocompletar(item);
      setTratamiento({ ...item, config: miConfig });

      if (miConfig.grupo == CORONA) setFaces([1, 2, 3, 4, 5]);
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const autocompletar = (item) => {
    let { grupo, protesisTipo } = getConfig(item.subGrupoId);
    if (grupo == CORONA) {
      if (protesisTipo == TOTAL_SUPERIOR)
        setDientes([
          11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 23, 24, 25, 26, 27, 28,
        ]);
      if (protesisTipo == TOTAL_INFERIOR)
        setDientes([
          31, 32, 33, 34, 35, 36, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48,
        ]);
    }
  };

  const validaTratamiento = (item) => {
    if (item.config.grupo === CARAS)
      if (dientes.length > 1)
        throw new Error("El tratamiento solo permite un diente a la vez");
  };

  const ordenaDientes = (dientes) => {
    function orden(a, b) {
      return a.pos - b.pos;
    }
    function existe(dt) {
      return dientes.some((d) => dt.diente == d);
    }
    let dientesFiltrados = Denticion.dientes.filter((dt) => existe(dt));

    let dientesOrdenados = dientesFiltrados.sort(orden);
    return dientesOrdenados;
  };

  const validaSeleccion = (item) => {
    const DIENTES_SUPERIORES = [
      11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 23, 24, 25, 26, 27, 28,
    ];
    const DIENTES_INFERIORES = [
      31, 32, 33, 34, 35, 36, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48,
    ];
    if (item.config.grupo == CORONA) {
      if (item.config.protesisTipo == SUPERIOR)
        if (dientes.some((diente) => DIENTES_INFERIORES.includes(diente)))
          throw new Error("Seleccione solo dientes superiores");

      if (item.config.protesisTipo == INFERIOR)
        if (dientes.some((diente) => DIENTES_SUPERIORES.includes(diente)))
          throw new Error("Seleccione solo dientes inferiores");
    }
  };
  const validaSeleccionContigua = (item) => {
    if (item.config.coronaTipo === PUENTE) {
      const dientesOrdenados = ordenaDientes(dientes);
      let pos = dientesOrdenados[0].pos;
      dientesOrdenados.forEach((it) => {
        if (it.pos !== pos) throw new Error("Seleccione dientes colindantes");
        pos++;
      });
    }
  };

  const handleCloseModal = (e) => {
    setFaces([]);
    setPilares([]);
    setDientes([]);
    setTratamiento();
    props.handleCloseModal();
  };

  const handleCheck = (e) => {
    let diente = e.target.id;
    try {
      if (e.target.checked) {
        setPilares([...pilares, diente]);
      } else {
        let filtro = pilares.filter((d) => d != diente);

        setPilares(filtro);
      }
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const esPilar = (id) => {
    return pilares.some((d) => d == id);
  };

  const handleSelectedFaces = (e) => {
    const property = e.target.id;
    let myfaces = [...faces];
    let idx = faces.findIndex((it) => it == property);
    if (idx > -1) {
      myfaces.splice(idx, 1);
    } else myfaces.push(property);
    setFaces(myfaces);
  };

  const handleSave = () => {
    try {
      valida();
      let myOdontograma = Object.assign({}, odontograma);
      myOdontograma.pacienteId = paciente.id;
      myOdontograma.especialistaId = sesion.usuario.email;
      myOdontograma.empresaId = sesion.usuario.empresaId;
      dispatch(
        guardarOdontoInicialTrx(
          tratamiento,
          myOdontograma,
          faces,
          pilares,
          dientes,
          sesion.fecha
        )
      );
      handleCloseModal();
      addToast("Se guardaron los cambios satisfactoriamente", {
        appearance: "success",
      });
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const valida = () => {
    dientes.forEach((diente) => {
      let itemOdonto = odontograma.items.find((it) => it.diente == diente);
      if (itemOdonto) {
        if (itemOdonto.estado === EXODONCIA || itemOdonto.estado === IMPLANTE) {
          if (
            tratamiento.config.grupo == NUCLEO ||
            tratamiento.config.grupo == CARAS
          )
            throw new Error("Diente con " + itemOdonto.estado);
        }
      }
    });
  };

  if (sesion.loading) {
    return <div>Procesando...</div>;
  } else if (sesion.error) {
    return <div>error </div>;
  } else
    return (
      <Modal isOpen={props.isOpen} onClose={handleCloseModal}>
        <React.Fragment>
          <MainContainer>
            <ContainerTitle>
              <ContainerTitleText>
                Aplicar Prestación :
                {tratamiento ? tratamiento.tratamiento : null}
              </ContainerTitleText>
              <Buttons>
                <LinkCancelar className="btn" onClick={handleCloseModal}>
                  Cancelar
                </LinkCancelar>

                <ButtonPagar className="btn" onClick={handleSave}>
                  Guardar
                </ButtonPagar>
              </Buttons>
              <BuscarTratamientos
                onSelected={handleSelectedItem}
                grupo={props.grupo}
              />
            </ContainerTitle>
            {tratamiento && (
              <ContainerBody>
                <div className="container-dientes-general">
                  {(tratamiento.config.coronaTipo !== "PUENTE") &
                    (tratamiento.config.grupo !== "CARAS") && (
                    <General
                      itemsOdontograma={itemsOdontograma}
                      config={getConfig(tratamiento.subGrupoId)}
                      faces={faces}
                    />
                  )}
                </div>

                <div className="container-dientes">
                  {tratamiento.config.grupo == "CARAS" && (
                    <Caras
                      itemsOdontograma={itemsOdontograma}
                      config={getConfig(tratamiento.subGrupoId)}
                      faces={faces}
                      onSelectedFace={handleSelectedFaces}
                    ></Caras>
                  )}

                  {tratamiento.config.coronaTipo == "PUENTE" && (
                    <Puente
                      itemsOdontograma={itemsOdontograma}
                      pilares={pilares}
                      config={getConfig(tratamiento.subGrupoId)}
                      onCheck={handleCheck}
                      getValue={esPilar}
                    />
                  )}
                </div>
              </ContainerBody>
            )}
          </MainContainer>
        </React.Fragment>
      </Modal>
    );
};
