import {
  NEW_PACIENTE,
  SET_PACIENTE,
  ADD_PATOLOGIA,
  REMOVE_PATOLOGIA,
  UPDATE_PATOLOGIA,
} from "./pacienteTypes";

const initialState = {
  id: "",
  tipDoc: "DNI",
  numDoc: "",
  apellidos: "",
  nombres: "",
  fecNac: "",
  telefono: "",
  direccion: "",
  correo: "",
  patologias: [],
  estado: "S",
  empresaId: "",
  token: ""
};

export const pacienteReducers = (state = initialState, action) => {
  switch (action.type) {
    case NEW_PACIENTE:
      return initialState;
    case SET_PACIENTE:
      return action.payload;
    case ADD_PATOLOGIA:
      return {
        ...state,
        patologias: [...state.patologias, action.payload],
      };
    case REMOVE_PATOLOGIA:
      return {
        ...state,
        patologias: state.patologias.filter(
          (it) =>
            it.llave !== action.payload.llave && it.tipo === action.payload.tipo
        ),
      };
    case UPDATE_PATOLOGIA:
      let idx = state.patologias.findIndex(
        (it) =>
          it.llave == action.payload.llave && it.tipo == action.payload.tipo
      );
      let values = [...state.patologias];
      if (idx > -1) values[idx] = action.payload;
      return { ...state, patologias: values };
    default:
      return state;
  }
};
