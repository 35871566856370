import { SET_LISTAS } from "./listasTypes";

const initialState = {
  bancos: [],
  monederos: [],
};
export const listasReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_LISTAS:
      return action.payload;
    default:
      return state;
  }
};
