import styled from "styled-components";
import { dispositivo } from "../../../../components/Dispositivos";
import Button from "@material-ui/core/Button";

export const ContainerBody = styled.div`
  width: 95%;
  height: 100%;
  padding: 1%;
  margin: 3%;
  background: #fff;
  box-shadow: 2px 2px #88888877;
  border-radius: 14px;
`;
export const CardModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
`;

export const Tabla = styled.table`
  width: 98%;
`;

export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const TablaHeaderTexto = styled.th`
  font-size: 0.75rem;
  font-weight: bold;
  padding-left: 0.5em;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;

export const ButtonAddBenefit = styled(Button)`
  && {
    height: 2.25rem;
    margin-top: 1rem;
    border-radius: 1em;      
    text-transform: uppercase;
    cursor: pointer;
    font-width: bold;
    font-size: 0.9rem;
    background-color: #0060b0;
    color: #ffffff;
    font-size: 0.9rem;
    border: none;
    margin-bottom: 1em;
    &:hover{
      color:#000000;
      background-color: #0c8de390;
      font-width: bold;
    }
    @media ${dispositivo.mobileS}{
      font-size: 0.9rem;
      padding: 0.1em;
      width: 8rem;
    } 
    @media ${dispositivo.tablet}{
      width: 10rem;
    } 
    
  }
`;
export const ContainerTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  text-align: center;
`;

export const ContainerTitleText = styled.h2`
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center;
  padding: 1.5%;
  background-color: #0c8de3;
  color: white;
  border-radius: 10px;
  @media ${dispositivo.mobileL} {      
    font-size: 1.25rem;
  }
`;
export const TablaBody = styled.tbody`
  font-size: 0.6rem;
  font-weight: normal;
  color:  #040404;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;

export const TablaBodyTexto = styled.td`
  font-size: 0.6rem;
  color:  #045184;
  padding: 0.313em 0  0.313em 0.5em;
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;

export const TablaBodyTextoDiente = styled.td`
  font-size: 0.6rem;
  color:  #045184;
  padding: 0.313em 0  0.313em 0.5em;
  display: flex;
  justify-content: space-between;
  max-width: 80px;
  width: 95%;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 2px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0c8de3;
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
  @media ${dispositivo.laptopL} {
    max-width: 200px;
  }
`;

export const ScrollDiv = styled.div`
  flex: 1;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0c8de3;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
`;