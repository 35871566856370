import React from "react";
import {
  DienteContenedor,
  ContenedorAuxiliar,
  DienteContenedorNumero,
  DienteContenedorCorona,
  DienteContenedorRaiz,
  Img,
} from "../Styles/dientes.css";

export const DienteInferior = ({ item, onSelectedTooth, checked }) => {
  const { diente, nombre, patologia } = item;
  const imgCorona = require(`../../assets/svg/incisivo.svg`);
  const imgDiente = require(`../../assets/svg/${nombre}.svg`);

  return (
    <DienteContenedor>
      <ContenedorAuxiliar>
        <DienteContenedorNumero>
          <input
            type="checkbox"
            id={diente}
            name="dientes"
            onChange={(e) => onSelectedTooth(e)}
            checked={checked}
          />
        </DienteContenedorNumero>
        <DienteContenedorCorona>{diente}</DienteContenedorCorona>
      </ContenedorAuxiliar>
      <DienteContenedorRaiz>
        <Img src={imgDiente} />
      </DienteContenedorRaiz>
    </DienteContenedor>
  );
};
