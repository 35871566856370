import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setPrestation } from "../../../redux/prestation/prestationActions";
//../../redux/prestation/prestationActions
import { TopBarPaciente } from "../../../components/TopBarPaciente";
import { ReviertePagoModal } from "../Modales/ReviertePagoModal";
import { NoContinuarPrestacionModal } from "../Modales/NoContinuarPrestacionModal";
import { DesactivarPrestacionModal } from "../Modales/DesactivarPrestacionModal";
import { ModificarPrecioModal } from "../Modales/ModificarPrecioModal";

import {
  MainContainer,
  ContainerBody,
  ContainerLeft,
  ButtonAddPay,
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  ContenedorTabla,
  TablaBodyTexto,
  SelectStyle,
  FlowContainer,
  TablaBodyTextoDiente,
} from "./styles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import DeleteIcon from "@material-ui/icons/Delete";
const Fila = (props) => {
  const { item } = props;

  const printSelectedTooth = (teeth) => {
    let items = [];
    if (teeth) {
      teeth.forEach((item) => {
        items.push(<span key={item} id={item}>{`${item},`}</span>);
      });
    }

    return items;
  };

  return (
    <tr key={item.id}>
      <TablaBodyTexto data-label="Prestacion">
        {item.tratamiento}
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Diente">
        <TablaBodyTextoDiente>
          {printSelectedTooth(item.dientes)}
        </TablaBodyTextoDiente>
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Precio">
        {item.precio}
        <IconButton
          aria-label=""
          onClick={() => props.onModificarPrecio(item)}
          size="small"
        >
          <Tooltip TransitionComponent={Zoom} placement="top" title="Modificar">
            <EditIcon style={{ color: "#0060B0", fontSize: "20px  " }} />
          </Tooltip>
        </IconButton>
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Pagado">
        {Math.round(item.pagado * 100) / 100}
        {item.pagado > 0 && (
          <IconButton aria-label="" onClick={() => props.onReviertePago(item)}>
            <Tooltip
              TransitionComponent={Zoom}
              placement="top"
              title="Revertir"
            >
              <MonetizationOnIcon style={{ color: "#2cb742", fontSize: "20px  " }} />
            </Tooltip>
          </IconButton>
        )}
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Estado">{item.estado}</TablaBodyTexto>
      <TablaBodyTexto data-label="Accion">
        {item.estado === "ACTIVO" && (
          <IconButton
            className="button-icon"
            aria-label=""
            onClick={() => props.onNoContinuar(item)}
          >
            <Tooltip
              TransitionComponent={Zoom}
              placement="top"
              title="No Continuar"
            >
              <PlayArrowIcon style={{ color: "red" }} />
            </Tooltip>
          </IconButton>
        )}
        {item.estado === "ACTIVO" && (
          <IconButton
            className="button-icon"
            aria-label=""
            onClick={() => props.onDesactivar(item)}
          >
            <Tooltip
              TransitionComponent={Zoom}
              placement="top"
              title="Desactivar"
            >
              <DeleteIcon style={{ color: "red" }} />
            </Tooltip>
          </IconButton>
        )}
      </TablaBodyTexto>
    </tr>
  );
};

export const PrestacionesTable = (props) => {
  return (
    <div>
      <Tabla>
        <TablaHeader>
          <tr>
            <TablaHeaderTexto scope="col" width={"30%"}>
              Prestacion
            </TablaHeaderTexto>
            <TablaHeaderTexto scope="col" width={"15%"}>
              Dientes
            </TablaHeaderTexto>
            <TablaHeaderTexto scope="col" width={"15%"}>
              Precio
            </TablaHeaderTexto>
            <TablaHeaderTexto scope="col" width={"15%"}>
              Pagado
            </TablaHeaderTexto>
            <TablaHeaderTexto scope="col" width={"15%"}>
              Estado
            </TablaHeaderTexto>
            <TablaHeaderTexto scope="col" width={"10%"}>
              Acción
            </TablaHeaderTexto>
          </tr>
        </TablaHeader>
        <TablaBody>
          {props.items.map((item) => {
            return (
              <Fila
                key={item.id}
                item={item}
                onReviertePago={props.onReviertePago}
                onNoContinuar={props.onNoContinuar}
                onDesactivar={props.onDesactivar}
                onModificarPrecio={props.onModificarPrecio}
              />
            );
          })}
        </TablaBody>
      </Tabla>
    </div>
  );
};

const usePagos = () => {
  const prestaciones = useSelector((state) => state.prestaciones);
  const [filteredItems, setFilteredItems] = React.useState(prestaciones);
  const [estado, setEstado] = React.useState("PENDIENTE");
  React.useMemo(() => {
    let result = [];
    if (estado) {
      result = prestaciones.items.filter(
        (prestacion) =>
          prestacion.estfin === estado &&
          (prestacion.estado === "ACTIVO" || prestacion.estado === "TERMINADO")
      );
    }

    setFilteredItems(result);
  }, [estado, prestaciones]);

  return [filteredItems, estado, setEstado];
};
export const XPrestaciones = () => {
  const [filteredItems, estado, setEstado] = usePagos();
  const [modalRevierteIsOpen, setModalReviertePagosIsOpen] =
    React.useState(false);

  const [modalModificarPrecioIsOpen, setModalModificarPrecioIsOpen] =
    React.useState(false);

  const [modalNoContinuarIsOpen, setModalNoContinuarIsOpen] =
    React.useState(false);

  const [modalDesactivarIsOpen, setModalDesactivarIsOpen] =
    React.useState(false);

  const dispatch = useDispatch();

  const handleReviertePago = (item) => {
    dispatch(setPrestation(item));
    setModalReviertePagosIsOpen(true);
  };

  const handleNoContinuar = (item) => {
    dispatch(setPrestation(item));
    setModalNoContinuarIsOpen(true);
  };

  const handleDesactivar = (item) => {
    dispatch(setPrestation(item));
    setModalDesactivarIsOpen(true);
  };

  const handleModificarPrecio = (item) => {
    dispatch(setPrestation(item));
    setModalModificarPrecioIsOpen(true);
  };

  const handleCloseModal = (e) => {
    setModalReviertePagosIsOpen(false);
    setModalNoContinuarIsOpen(false);
    setModalDesactivarIsOpen(false);
    setModalModificarPrecioIsOpen(false);
  };

  return (
    <MainContainer>
      <TopBarPaciente />
      <ContainerBody>
        <FlowContainer>
          <SelectStyle
            value={estado}
            onChange={(e) => setEstado(e.target.value)}
          >
            <option value="PENDIENTE">PENDIENTE</option>
            <option value="CANCELADO">CANCELADO</option>
          </SelectStyle>
        </FlowContainer>

        <ContenedorTabla>
          <PrestacionesTable
            items={filteredItems}
            onReviertePago={handleReviertePago}
            onNoContinuar={handleNoContinuar}
            onDesactivar={handleDesactivar}
            onModificarPrecio={handleModificarPrecio}
          />
        </ContenedorTabla>
      </ContainerBody>
      <ReviertePagoModal
        isOpen={modalRevierteIsOpen}
        handleCloseModal={handleCloseModal}
      />
      <NoContinuarPrestacionModal
        isOpen={modalNoContinuarIsOpen}
        handleCloseModal={handleCloseModal}
      />
      <DesactivarPrestacionModal
        isOpen={modalDesactivarIsOpen}
        handleCloseModal={handleCloseModal}
      />

      <ModificarPrecioModal
        isOpen={modalModificarPrecioIsOpen}
        handleCloseModal={handleCloseModal}
      />
    </MainContainer>
  );
};
