import styled from "styled-components";

export const ResponsiveButtonWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 25px;
  cursor: pointer;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .response-button__icon {
    color: white;
    border-radius: 10px;
    padding: 5px;
  }

  .show-on-hover {
    position: absolute;
    left: 50%;
    top: 105%;
    max-width: 0;
    color: white;
    font-weight: bolder;
    border-radius: 8px;
    height: 100%;
    overflow: hidden;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
    z-index: 100;
    font-size: 14px; 

    &__cta {
      padding: 2px 5px;
      margin: 0 5px;
      color: white;
      font-weight: regular;
    }
  }

  &:hover .show-on-hover {
    max-width: 200px;
    transition: all 0.5s ease;
    padding: 0 10px;

  }
`;
