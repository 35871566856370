import styled from "styled-components";
import { dispositivo } from "../../../components/Dispositivos";

export const MainContainer = styled.div`
  width: 100%;
  text-align: center;
  background: #f8f8f8;
`;
export const ContainerTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;
export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 2% 0;
  label{
    font-weight: bold;
    text-align: left;
    color: rgb(4, 4, 4);
  }
  @media ${dispositivo.tablet} {
    flex-direction: row;
  }
 
`;
export const ContainerTitleText = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;

export const ContainerBody = styled.div`
  width: 98%;
  height: 100%;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: #fff;
  box-shadow: 2px 2px #88888877;
  border-radius: 14px;
  @media ${dispositivo.mobileL} {
    padding: 3%;
  }
  @media ${dispositivo.tablet} {
    padding: 4%;
  }
`;

export const Tabla = styled.table`
  width: 100%;
`;

export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const TablaHeaderTexto = styled.th`
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;

export const TablaBody = styled.tbody`
  background: #fff;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  border: 2px solid #ccc;
  padding-bottom: 16px;
`;

export const TablaBodyTexto = styled.th`
  font-size: 0.688rem;
  color: #045184;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;

export const ContenedorTabla = styled.div`
  @media ${dispositivo.tablet} {
    flex: 1;
    // max-height: 370px;
    // overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 20px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #0c8de3;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #fff;
    }
  }
`;

export const StyledInput = styled.input`
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  line-height: 2.5;
  font-size: 0.75rem;
  width: 12.5rem;
  heigth: 2rem;
  padding-left: 0.5em;
  margin-left: 0.8em;
  &::placeholder {
  color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.mobileL} {
    font-size: 1rem;
    width: 10rem;
  }
`;
export const StyledButton = styled.button`
  height: 2.25rem;
  font-size: 0.9rem;  
  width: 100%;
  border-radius: 0.8rem;      
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #0060b0;
  color: #ffffff;
  border: none;
  &:hover{
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.mobileM}{
    width: 7rem; 
  }
  @media ${dispositivo.tablet}{
    width: 10rem;
    font-size: 1rem;  
  }  
  @media ${dispositivo.laptop} {      
    width: 12.5rem;
  }
  
`;
export const ContainerBtn = styled.div`
  display: flex;
  justify-content: end;
  padding-bottom: 1em;`;