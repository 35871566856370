import React, { useEffect, useMemo, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setPrestation } from "../../redux/prestation/prestationActions";
import { payMultiplePrestationsTrx } from "../../redux/prestation/prestationActions";
import { TopBarPaciente } from "../../components/TopBarPaciente";
import IconButton from "@material-ui/core/IconButton";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

import {
  MainContainer,
  ContainerBody,
  ContainerLeft,
  ButtonAddPay,
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  ContenedorTabla,
  TablaBodyTexto,
} from "./styles";

import { useToasts } from "react-toast-notifications";

const Fila = ({ item, index, onCheckedItem }) => {
  const dispatch = useDispatch();
  const [estado, setEstado] = React.useState("P");
  const history = useHistory();
  const handlePayment = (item) => {
    dispatch(setPrestation(item));
    history.push("/paciente/pagos/" + item.id);
  };

  const getFecha = (fecha) => {
    const a = fecha.getFullYear();
    const m = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const d = fecha.getDate().toString().padStart(2, "0");
    return `${a}-${m}-${d}`;
  };
  
  const printSelectedTooth = (dientes) => {
    if (dientes.length>0){
      return dientes.join();
    }
    else return ''
  };

  return (
    <tr key={item.id}>
      <TablaBodyTexto data-label="Prestacion"><IconButton aria-label="" style={{ padding: '5px' }} onClick={() => handlePayment(item)}>
          <Tooltip TransitionComponent={Zoom} placement="top" title="Iniciar">
            <MonetizationOnIcon style={{ color: "#2cb742" }} />
          </Tooltip>
        </IconButton>{`${item.tratamiento} ${printSelectedTooth(item.dientes)}`}</TablaBodyTexto>
      <TablaBodyTexto data-label="Estado">{item.estado}</TablaBodyTexto>
      <TablaBodyTexto data-label="Fecha">{typeof(item.fechaFin)=='object'?getFecha(item.fechaFin):item.fechaFin}</TablaBodyTexto>
      <TablaBodyTexto data-label="Precio">{item.precio}</TablaBodyTexto>
      <TablaBodyTexto data-label="Pagado">
              {Math.round(item.pagado * 100) / 100}
      
        
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Saldo">
        {Math.round((item.precio - item.pagado) * 100) / 100}
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Accion">
      <input
          name="estado"
          type="checkbox"
          checked={item.checked}
          
          onChange={(e) => onCheckedItem(e, index)}
        />
        <label>{item.orden}</label>
      </TablaBodyTexto>
      
    </tr>
  );
};

export const PrestacionesTable = (props) => {
  return (
    <div>
      <Tabla>
        <TablaHeader>
          <tr>
            <TablaHeaderTexto scope="col" style={{ width: "30%" }}>Prestación</TablaHeaderTexto>
            <TablaHeaderTexto scope="col" style={{ width: "15%" }}>Estado</TablaHeaderTexto>
            <TablaHeaderTexto scope="col" style={{ width: "15%" }}>Fecha</TablaHeaderTexto>
            <TablaHeaderTexto scope="col" style={{ width: "10%" }}>Precio</TablaHeaderTexto>
            <TablaHeaderTexto scope="col" style={{ width: "10%" }}>Pagado</TablaHeaderTexto>
            <TablaHeaderTexto scope="col" style={{ width: "10%" }}>Saldo</TablaHeaderTexto>
            <TablaHeaderTexto scope="col" style={{ width: "10%" }}>Acción</TablaHeaderTexto>
            
          </tr>
        </TablaHeader>
        <TablaBody>
          {props.items.map((item, index) => {
            return (
              <Fila
                key={index}
                item={item}
                index = {index}
                onCheckedItem={props.onCheckedItem}
              />
            );
          })}
        </TablaBody>
      </Tabla>
    </div>
  );
};



const usePagos = (prestaciones) => {
  const activas = useMemo(()=>prestaciones.items.filter(
    (prestacion) =>
      prestacion.estfin === "PENDIENTE" &&
      (prestacion.estado === "ACTIVO" || prestacion.estado === "TERMINADO")
  ),[prestaciones]).map((item, index)=> ({...item, checked: false, idx: index, pagadoOriginal: item.pagado, pagadoMov:0}))
  
  const [prestacionesActivas, setPrestacionesActivas] = useState(activas);
    
  
  return {prestacionesActivas, setPrestacionesActivas}
};

export const Pagos = () => {
  const paciente = useSelector((state) => state.paciente);
  const prestaciones = useSelector((state) => state.prestaciones);
  const cuenta = useSelector((state) => state.cuenta);
  const [disponible, setDisponible]= useState(cuenta.saldo);
  const {prestacionesActivas, setPrestacionesActivas} = usePagos(prestaciones);
  // const history = useHistory();
  const { addToast } = useToasts();
  const [countSelected, setCountSelected]= useState(0)
  const sesion = useSelector((state) => state.sesion);
  const dispatch = useDispatch();
  // const handleAbonar = () => {
  //   history.push("/paciente/abonos");
  // };
  const handleCheckedItem =(e,idx)=>{
   const localPrestacionesActivas = [...prestacionesActivas];
    localPrestacionesActivas[idx].checked = e.target.checked 
    setPrestacionesActivas(localPrestacionesActivas);
    let itemSaldo =localPrestacionesActivas[idx].precio - localPrestacionesActivas[idx].pagado;
    if(e.target.checked){
      pagarItem(itemSaldo, idx,disponible  )
    }else{
      restarPago(localPrestacionesActivas[idx].pagadoOriginal,localPrestacionesActivas[idx].pagado,idx);
    }

  }
  useEffect(()=>{
    const activas = prestaciones.items.filter(
      (prestacion) =>
        prestacion.estfin === "PENDIENTE" &&
        (prestacion.estado === "ACTIVO" || prestacion.estado === "TERMINADO")
    ).sort((a,b)=>{return b.estado.localeCompare(a.estado)||b.fechaFin - a.fechaFin}).map((item, index)=> ({...item, checked: false, idx: index, pagadoOriginal: item.pagado, orden:undefined, pagadoMov:0}))
    const simuladas = simulaPagoAutomatico(activas, cuenta.saldo);
    setPrestacionesActivas(simuladas)
  },[cuenta,prestaciones])

  const simulaPagoAutomatico = (prestaciones, disponible)=>{
    let saldoDisponible = disponible;
    let contador = 0;
    let monto = 0;
    const terminadas = prestaciones.filter(
      (prestacion) =>
      prestacion.estado === "TERMINADO"
    )
    for(var i =0; i< terminadas.length; i++){
      const itemSaldo = terminadas[i].precio - terminadas[i].pagado;;
      if (saldoDisponible >= itemSaldo){
        terminadas[i].pagado += itemSaldo;
        terminadas[i].pagadoMov = itemSaldo;
        terminadas[i].checked = true;
        saldoDisponible = saldoDisponible - itemSaldo;
        terminadas[i].orden = ++contador;
      } else if (saldoDisponible < itemSaldo && saldoDisponible > 0)
      {
        terminadas[i].pagado += saldoDisponible
        terminadas[i].pagadoMov = saldoDisponible
        saldoDisponible = 0;
        terminadas[i].orden = ++contador;
        terminadas[i].checked = true;
      }
      else{
        break;
      }
      
    }
    setCountSelected(contador);
      setDisponible(saldoDisponible);
      return prestaciones
    
  }
  const getNewOrder=(prestacionesActivas)=>{
    const ItemsSelected = prestacionesActivas.filter((it)=> it.checked).sort((a,b)=> a.orden-b.orden)
    ItemsSelected.map((it, idx)=> it.orden = idx+1)
    
    
    return prestacionesActivas

  }

  const pagarItem = (itemSaldo, idx, disponible)=>{
    const localPrestacionesActivas = [...prestacionesActivas];
    let saldoDisponible = disponible;
    if (saldoDisponible >= itemSaldo){
      localPrestacionesActivas[idx].pagado += itemSaldo;
      localPrestacionesActivas[idx].pagadoMov = itemSaldo;
      saldoDisponible = saldoDisponible - itemSaldo;
      setCountSelected((prev)=>prev+1)
      localPrestacionesActivas[idx].orden = countSelected+1
    } else if (saldoDisponible < itemSaldo && saldoDisponible > 0)
    {
      localPrestacionesActivas[idx].pagado += saldoDisponible
      localPrestacionesActivas[idx].pagadoMov = saldoDisponible;
      saldoDisponible = 0;
      localPrestacionesActivas[idx].orden = countSelected+1
      setCountSelected((prev)=>prev+1)
    } else {
      localPrestacionesActivas[idx].checked = false;
      addToast("Saldo insuficiente en el disponible", { appearance: "error" });  

    }
    setDisponible(saldoDisponible)
    setPrestacionesActivas(localPrestacionesActivas);
  }
  
  const restarPago = (pagadoOriginal, pagado, idx)=>{
    const localPrestacionesActivas = [...prestacionesActivas];
    let saldo = pagado - pagadoOriginal;
    setDisponible((prev)=>prev + saldo)
    localPrestacionesActivas[idx].pagado = pagadoOriginal; 
    localPrestacionesActivas[idx].pagadoMov = 0; 
    localPrestacionesActivas[idx].orden = undefined
    const newOrder=getNewOrder(localPrestacionesActivas);
    setPrestacionesActivas(newOrder);
    setCountSelected((prev)=>prev-1)
    
  }
  

  function handlePagar(){
    const prestacionesPagar=prestacionesActivas.filter((it)=> it.checked).sort((a,b)=> a.orden-b.orden);
    if (prestacionesPagar.length > 0)
    {
    const montoPago = cuenta.saldo - disponible; 
    const movimiento = {
      monto: Number(montoPago),
      moneda: "SOLES",
      fecha: new Date(),
      usuario: {email:sesion.usuario.email, nombres: sesion.usuario.nombres,id: sesion.usuario.id},
      usuarioId: sesion.usuario.id,
      prestaciones: prestacionesPagar.map((pr)=> ({id:pr.id, costo:pr.costo?pr.costo:0, pagado:pr.pagado, precio:pr.precio, tratamiento:pr.tratamiento, dientes:pr.dientes, estado:pr.estado, estFin: pr.estfin, fechaFin: pr.fechaFin,  pagadoMov: pr.pagadoMov})),
      prestacionId: prestacionesActivas.map((pr)=>pr.id),
      prestacionNombre: prestacionesActivas.map((pr)=>`${pr.tratamiento} ${pr.tipo ? pr.tipo : ""
    }`),
      pacienteId: paciente.id,
      pacienteNombres: `${paciente.apellidos} ${paciente.nombres}`,
      tipMov: "PAGO_PRESTA",
      cuentaId: cuenta.id,
      empresaId: sesion.usuario.empresaId,
    };
    //console.log('mov', movimiento)
       dispatch(payMultiplePrestationsTrx(prestacionesPagar,montoPago,movimiento, cuenta));
       addToast("Pago satisfactorio", { appearance: "success" }); 
    }
      else
      addToast("Selecciona prestaciones a pagar", { appearance: "error" });  

  }

  return (
    <MainContainer>
      <TopBarPaciente />
      <ContainerBody>
        <ContainerLeft>
          
          <div style={{display:'flex', justifyContent: 'end'}}>
          <div>
          <table>
            <tr style={{borderBottom:'solid 1px #CCC'}}><td style={{textAlign:'left',fontWeight: 'bold', color: '#0c8de3'}}>Pago: </td><td>{cuenta.saldo - disponible}</td></tr>
            <tr><td style={{textAlign:'left',fontWeight: 'bold', color: '#0c8de3', paddingRight:'3px'}}>Saldo Disponible: </td><td>{disponible}</td></tr>
          </table>
          </div>
          <ButtonAddPay onClick={handlePagar} className="btn">
            Pagar
          </ButtonAddPay>
          </div>
          <div className="cardnav__right"></div>
        </ContainerLeft>

        <ContenedorTabla>
          <PrestacionesTable items={prestacionesActivas} onCheckedItem={handleCheckedItem} />
        </ContenedorTabla>
      </ContainerBody>
    </MainContainer>
  );
};
