import styled from "styled-components";
import { dispositivo } from "../../components/Dispositivos";

export const DivCharts = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  //flex-direction: column;
  @media ${dispositivo.tablet} {
    display: flex;
  }
`;
export const Contenedor = styled.div`
  display: grid;
  justify-items: center;
  background-color: #9b9b9b;
  grid-template-columns: 1fr;
  gap: 1rem;
  place-content: center;
  padding: 15px;
  @media ${dispositivo.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
export const Titulo = styled.h1`
  display: flex;
  font-size: 1rem;
  justify-content: center;
  @media ${dispositivo.tablet} {
    font-size: 1.5rem;
  }
`;

export const Contenido = styled.div`
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;

  @media ${dispositivo.tablet} {
    font-size: 1.5rem;
  }
`;
export const Item = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 15px;
`;
