import React from "react";

const IncisivoOdontograma = (props) => {
  const defaultColor = "white";

  const getColor = (cara) => {
    let color = defaultColor;
    if (props.config.caras) {
      let idx = props.config.caras.findIndex((it) => it.cara == cara);
      if (idx > -1) color = props.config.caras[idx].color;
    }
    return color;
  };

  const getStrokeColor = (cara) => {
    let color = "black";
    if (props.config.caras) {
      let idx = props.config.caras.findIndex((it) => it.cara == cara);
      if (idx > -1) color = props.config.caras[idx].strokeColor;
    }
    return color;
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="90%"
      //height="80%"
      viewBox="0 0 300 300"
      preserveAspectRatio="xMidYMid meet"
    >
      <g id="incisor-group">
        <rect
          x="75"
          y="75"
          stroke={getStrokeColor("1")}
          id="1"
          style={{ strokeWidth: "5px" }}
          width="150"
          height="150"
          fill={getColor("1")}
        />
        <polygon
          stroke={getStrokeColor("2")}
          id="2"
          style={{ strokeWidth: "5px" }}
          points="0 0 300 0 225 112.5 75 112.5"
          fill={getColor("2")}
        />
        <polygon
          stroke={getStrokeColor("3")}
          id="3"
          style={{ strokeWidth: "5px" }}
          points="300 0 300 300 225 187.5 226 112.5"
          fill={getColor("3")}
        />
        <polygon
          stroke={getStrokeColor("4")}
          id="4"
          style={{ strokeWidth: "5px" }}
          points="300 300 0 300 75 187.5 225 187.5"
          fill={getColor("4")}
        />
        <polygon
          stroke={getStrokeColor("5")}
          id="5"
          style={{ strokeWidth: "5px" }}
          points="0 300 0 0 75 112.5 75 187.5"
          fill={getColor("5")}
        />
      </g>
    </svg>
  );
};

export default IncisivoOdontograma;
