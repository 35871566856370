import React from "react";
import { PrestacionSeleccionModal } from "./Modales/PrestacionSeleccionModal/index";
import { PagoPrestacionModal } from "./Modales/PagoPrestacionModal/index";
import { NuevaNotaClinicaModal } from "./Modales/NuevaNotaClinicaModal/index";
import { TerminarPrestacionModal } from "./Modales/TerminarPrestacionModal/index";
import { TerminarPrestacionOdontoModal } from "./Modales/TerminarPrestacionOdontoModal/index";
import { ConfirmacionSiNoModal } from "../../components/ConfirmacionSiNoModal/index";
import { useDispatch, useSelector } from "react-redux";
import { newToothSelection } from "../../redux/denticion/denticionActions";
import { newPrestation } from "../../redux/prestation/prestationActions";
import { fetchPrestations } from "../../redux/prestations/prestationsActions";
import AddIcon from "@material-ui/icons/Add";
import {
  removePrestacionSesion,
  savePrestacionesSesionTrx,
} from "../../redux/prestacionesSesion/prestacionesSesionActions";
import { addItemToCart } from "../../redux/itemsOrdenCart/itemsOrdenCartActions";
import { TopBarPaciente } from "../../components/TopBarPaciente";

import {
  setPrestation,
  setCaras,
  actualizarNotas,
} from "../../redux/prestation/prestationActions";
import { useHistory, Link } from "react-router-dom";
import { fetchPrestacionesSesion } from "../../redux/prestacionesSesion/prestacionesSesionActions";
import { setEstadoCompletado } from "../../redux/sesion/sesionActions";
import IconButton from "@material-ui/core/IconButton";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import DeleteIcon from "@material-ui/icons/Delete";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import MailIcon from "@material-ui/icons/Mail";
import PostAddIcon from "@material-ui/icons/PostAdd";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import { Laboratorios } from "../../Entidades/Laboratorios";
import { getOrden } from "../../redux/orden/ordenActions";
import Whatsapp from "../../img/whatsapp.png";
import { getDownloadUrl } from "../../services/firebase/ordenes";
import {
  MainContainer,
  ContainerTitle,
  ContainerTitleText,
  ContainerBody,
  ContainerLeft,
  ButtonAddBenefit,
  ContenedorTarjeta,
  SectionTarjeta,
  Tabla,
  TablaFinanzas,
  TablaHeader,
  TablaHeaderTexto,
  TablaBodyTextoSaldo,
  TablaBody,
  ScrollDiv,
  TablaBodyTextoFinanzaPrecio,
  TablaBodyTextoFinanzaPagado,
  TablaBodyTexto,
  ContenedorCabeceraTarjeta,
  ContenedorContenidoTarjeta,
  PlayStyled,
  PayStyled,
  NoteStyled,
  DeleteStyled,
  TablaBodyFinanzas,
  TablaBodyTextoFinanzas,
  TarjetaAccion,
  Icono,
  DivTarjeta,
  TdFinanzaPrecio,
  TrFinanza,
  TablaHeaderTextos,
} from "./styles";
import { useToasts } from "react-toast-notifications";
import CardActions from "@material-ui/core/CardActions";


export const Tarjeta = ({
  item,
  onTerminar,
  onPagar,
  onQuitar,
  onAddNotaClinica,
  onAddToOrden,
}) => {
  const { tratamiento, tipo, dientes, precio, pagado, notasClinicas } = item;
  const titulo = `${tratamiento} ${tipo ? tipo : ""} - ${dientes}`;
  const [isShown, setIsShown] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <ContenedorTarjeta>
      <ContenedorCabeceraTarjeta
        title={<div className="titulo-font">{titulo} </div>}
        subheader={<Laboratorio item={item}></Laboratorio>}
      ></ContenedorCabeceraTarjeta>
      <ContenedorContenidoTarjeta>
        <Finanzas precio={precio} pagado={pagado} />
        <Notas items={notasClinicas}></Notas>
      </ContenedorContenidoTarjeta>

      <TarjetaAccion disableSpacing>
        <IconButton
          aria-label=""
          onClick={() => onTerminar(item)}
          isShown={isShown}
        >
          <Tooltip
            TransitionComponent={Zoom}
            placement="top"
            title="Terminar prestación"
          >
            <PlayArrowIcon style={{ color: "#0089ff" }} />
          </Tooltip>
        </IconButton>

        <IconButton
          aria-label=""
          onClick={() => onPagar(item)}
          isShown={isShown}
        >
          <Tooltip TransitionComponent={Zoom} placement="top" title="Pagar">
            <MonetizationOnIcon style={{ color: "#2cb742" }} />
          </Tooltip>
        </IconButton>

        <IconButton
          aria-label=""
          onClick={() => onAddNotaClinica(item)}
          isShown={isShown}
        >
          <Tooltip
            TransitionComponent={Zoom}
            placement="top"
            title="Nota Clínica"
          >
            <PostAddIcon style={{ color: "#fed843" }} />
          </Tooltip>
        </IconButton>

        <IconButton
          aria-label=""
          onClick={() => onQuitar(item)}
          isShown={isShown}
        >
          <Tooltip TransitionComponent={Zoom} placement="top" title="Eliminar">
            <DeleteIcon style={{ color: "red" }} />
          </Tooltip>
        </IconButton>
      </TarjetaAccion>
    </ContenedorTarjeta>
  );
};

export const Finanzas = ({ precio, pagado }) => (
  <TablaFinanzas>
    <TablaBodyFinanzas>
      
        <TdFinanzaPrecio>
          <TablaBodyTextoFinanzaPrecio>
            Precio: S/ {Number(Math.round(precio * 100) / 100).toFixed(2)}
          </TablaBodyTextoFinanzaPrecio>
        </TdFinanzaPrecio>
        <TdFinanzaPrecio>
          <TablaBodyTextoFinanzaPagado>
            Pagado: S/ {Number(Math.round(pagado * 100) / 100).toFixed(2)}
          </TablaBodyTextoFinanzaPagado>
        </TdFinanzaPrecio>
        <TdFinanzaPrecio>
          <TablaBodyTextoSaldo>
            Saldo: S/{" "}
            {Number(Math.round((precio - pagado) * 100) / 100).toFixed(2)}
          </TablaBodyTextoSaldo>
        </TdFinanzaPrecio>
      
    </TablaBodyFinanzas>
  </TablaFinanzas>
);
export const Laboratorio = ({ item }) => {
  return (
    <div>
      {(item.lab.estado == "ENVIADO" || item.lab.estado == "TERMINADO") && (
        <strong>{`${item.lab.razonSocial}: ${item.lab.numCor}-${item.lab.estado}`}</strong>
      )}
    </div>
  );
};
export const Notas = ({ items }) => {
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const toString = (fecha) => {
    let f = fecha;

    f = new Date(fecha);
    const a = f.getFullYear();
    const m = (f.getMonth() + 1).toString().padStart(2, "0");
    const d = f.getDate().toString().padStart(2, "0");
    return `${a}-${m}-${d}`;
  };
  return (
    <ScrollDiv>
      <Tabla>
        <TablaHeader>
          <tr>
            <TablaHeaderTextos scope="col">Fecha</TablaHeaderTextos>
            <TablaHeaderTexto scope="col">Nota</TablaHeaderTexto>
          </tr>
        </TablaHeader>
        <TablaBody>
          {items.map(({ fecha, nota }) => (
            <tr>
              <TablaBodyTexto>{toString(fecha)}</TablaBodyTexto>
              <TablaBodyTexto
                style={
                  nota.length >= 50
                    ? { textAlign: "justify", padding: "0 5px" }
                    : { textAlign: "center", padding: "0 5px" }
                }
              >
                {nota}
              </TablaBodyTexto>
            </tr>
          ))}
        </TablaBody>
      </Tabla>
    </ScrollDiv>
  );
};
export const Prestaciones = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  const prestacion = useSelector((state) => state.prestacion);
  const { afectaOdonto } = prestacion.config;
  const handleCloseModal = (e) => {
    setModalIsOpen(false);
    setModalPagoIsOpen(false);
    setModalNotaIsOpen(false);
    setModalTerminarIsOpen(false);
    setModalTerminarOdontoIsOpen(false);
    setModalEnvioLabIsOpen(false);
    dispatch(setCaras([]));
  };
  const prestaciones = useSelector((state) => state.prestaciones);
  const prestacionesActivas = prestaciones.items.filter(
    (prestacion) => prestacion.estado === "ACTIVO"
  );
  const paciente = useSelector((state) => state.paciente);
  const sesion = useSelector((state) => state.sesion);
  const [modalPagoIsOpen, setModalPagoIsOpen] = React.useState(false);
  const [modalNotaIsOpen, setModalNotaIsOpen] = React.useState(false);
  const [modalTerminarIsOpen, setModalTerminarIsOpen] = React.useState(false);
  const [modalEnvioLabIsOpen, setModalEnvioLabIsOpen] = React.useState(false);
  const { addToast } = useToasts();
  const [modalTerminarOdontoIsOpen, setModalTerminarOdontoIsOpen] =
    React.useState(false);

  const handleQuitar = (item) => {
    const toString = (fecha) => {
      let f = fecha;

      f = new Date(fecha);
      const a = f.getFullYear();
      const m = (f.getMonth() + 1).toString().padStart(2, "0");
      const d = f.getDate().toString().padStart(2, "0");
      return `${a}-${m}-${d}`;
    };
    //dispatch(removePrestacionSesion(item));
    item.notasClinicas = item.notasClinicas.filter(
      (nota) =>
        toString(nota.fecha) !== sesion.fecha || nota.nota === "Iniciada"
    );

    dispatch(actualizarNotas(item)); //
    dispatch(setPrestation(item)); //
  };

  const handlePayment = (item) => {
    setModalPagoIsOpen(true);
    dispatch(setPrestation(item));
  };

  const handleNotasClinicas = (item) => {
    setModalNotaIsOpen(true);
    dispatch(setPrestation(item));
  };

  const handleConfirmaAddPrestacionToCart = (item) => {
    dispatch(setPrestation(item));
    setModalEnvioLabIsOpen(true);
  };

  
  const handleTerminar = (item) => {
    const { grupo, afectaOdonto } = item.config;
    dispatch(setPrestation(item));
    if (grupo == "CORONA" || grupo == "EXODONCIA")
      dispatch(setCaras([1, 2, 3, 4, 5]));
    if (afectaOdonto) setModalTerminarOdontoIsOpen(true);
    else setModalTerminarIsOpen(true);
  };

  
  const miPrestacion = {
    id: "",
    especialidad: "",
    tratamiento: "",
    material: "",
    precio: null,
    precioConvenio: null,
    dientes: [],
  };

  const [nombre, setNombre] = React.useState("");
  const handleOpenModal = (e) => {
    dispatch(newToothSelection());
    setModalIsOpen(true);
    dispatch(newPrestation(miPrestacion));
  };

  if (sesion.loading) {
    return <div>Procesando</div>;
  } else if (sesion.error) {
    return <div>{`Ocurrio error ${sesion.error}`} </div>;
  } else
    return (
      <React.Fragment>
        <TopBarPaciente />
        <MainContainer>
          <ContainerBody>
            
            <SectionTarjeta>
              <DivTarjeta className="row">
                {prestacionesActivas.map((item) => (
                  <Tarjeta
                    item={item}
                    onTerminar={handleTerminar}
                    onPagar={handlePayment}
                    onQuitar={handleQuitar}
                    onAddNotaClinica={handleNotasClinicas}
                    onAddToOrden={handleConfirmaAddPrestacionToCart}
                  ></Tarjeta>
                ))}
              </DivTarjeta>
            </SectionTarjeta>
          </ContainerBody>
          <TerminarPrestacionModal
            isOpen={modalTerminarIsOpen}
            handleCloseModal={handleCloseModal}
          />
          {afectaOdonto && (
            <TerminarPrestacionOdontoModal
              isOpen={modalTerminarOdontoIsOpen}
              handleCloseModal={handleCloseModal}
            />
          )}
          <PagoPrestacionModal
            isOpen={modalPagoIsOpen}
            handleCloseModal={handleCloseModal}
          />
          <NuevaNotaClinicaModal
            isOpen={modalNotaIsOpen}
            handleCloseModal={handleCloseModal}
          />

                  </MainContainer>
      </React.Fragment>
    );
};
export default Prestaciones;
