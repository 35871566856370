import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { dispositivo } from "../../components/Dispositivos";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { IconButton } from "@material-ui/core";

export const MainContainer = styled.div`
  width: 100%;
  text-align: center;
  background: #f8f8f8;
`;

export const ContainerTitle = styled.div`
  color: #000;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-top: 2%;
  display: flex;
  justify-content: space-around;
`;

export const ContainerTitleText = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #ffffff;
  background: #0c8de3;
  border: 2px solid #0c8de3;
  width: 100%;
`;

export const Titulo = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 5px;
  margin-top: 5px;
`;

export const Subtitulo = styled.h3`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 5px;
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
`;

export const Finanzas = styled.div`
  display: block;
`;

export const ContenedorTarjeta = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  width: 80%;
  font-size: 10px;
  margin: 1%;
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const ContenedorCabeceraTarjeta = styled(CardHeader)`
  color: #040404;
  background: #fff;
  padding: 1em 2% 0 !important;
  font-size: 16px !important;
  max-width: 200px;
  word-break: break-all;
  .titulo-font{
    font-size: 16px !important;x-width: 255px;
    font-weight: bold;
    @media ${dispositivo.mobileL} {
      font-size: 18px !important;
    }
    @media ${dispositivo.laptop} {
      font-size: 20px !important;
    }
  }
  @media ${dispositivo.mobileM} {
    max-width: 255px;
  }
  @media ${dispositivo.mobileL} {
    max-width: 510px;
  }
  @media ${dispositivo.laptop} {
    max-width: 545px;
  }
`;

export const ContenedorContenidoTarjeta = styled(CardContent)`
  display: block;
`;

export const ContainerBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 4%;
  box-shadow: 2px 2px #88888877;
  border-radius: 14px;
  justify-content: center;
`;

export const SectionTarjeta = styled.section`
  justify-content: center;
  margin-top: 1rem;
  @media ${dispositivo.tablet} {
    flex: 1;
    }
`;

export const DivTarjeta = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0px;
  width: 100%;
`;
export const ContainerLeft = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: right;
`;

export const PlayStyled = styled(IconButton)`
  && {
    color: #0089ff !important;
    padding: 1%;
    display: ${(props) => (props.isShown ? "flex" : "none")};
  }
`;

export const PayStyled = styled(IconButton)`
  && {
    color: #2cb742;
    padding: 1%;
    display: ${(props) => (props.isShown ? "flex" : "none")};
  }
`;

export const NoteStyled = styled(IconButton)`
  && {
    color: #fed843;
    padding: 1%;
    display: ${(props) => (props.isShown ? "flex" : "none")};
  }
`;

export const DeleteStyled = styled(IconButton)`
  && {
    color: red;
    padding: 1%;
    display: ${(props) => (props.isShown ? "flex" : "none")};
  }
`;
export const ButtonAddBenefit = styled(Button)`
  && {
    height: 2.25rem;
    margin-top: 1rem;
    border-radius: 1em;      
    text-transform: uppercase;
    cursor: pointer;
    font-width: bold;
    font-size: 0.9rem;
    background-color: #0060b0;
    color: #ffffff;
    font-size: 0.9rem;
    border: none;
    &:hover{
      color:#000000;
      background-color: #0c8de390;
      font-width: bold;
    }
    @media ${dispositivo.mobileS}{
      font-size: 0.9rem;
      padding: 0.1em;
      width: 9rem;
    } 
    @media ${dispositivo.tablet}{
      width: 10rem;
    } 
  }
`;

export const Tabla = styled.table`
  margin: 3%;
  margin-left: 5%;
  width: 92%;

  @media ${dispositivo.tablet} {
    width: 90%;
  }
`;

export const TablaFinanzas = styled.table`
  margin: 1em;
  width: 100%;
  margin-left: 0%;
  
`;

export const TrFinanza = styled.table`
display: flex;
align-items: center;
justify-content: center;
`;

export const TdFinanzaPrecio = styled.td`
  width: 70px;
  @media ${dispositivo.mobileL} {
    width: 75px;
  }
  @media ${dispositivo.tablet} {
    width: 130px;
  }
  @media ${dispositivo.laptop} {
    width: 170px;
  }
`;

export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 3%;
  padding-bottom: 3%;
  border: 1px solid #0c8de3;
`;

export const TablaHeaderTexto = styled.th`
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  padding-top: 1%;
  padding-bottom: 1%;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;
export const TablaHeaderTextos = styled(TablaHeaderTexto)`
    width: 33%;
  
  @media ${dispositivo.laptop} {
    width: 20%;
  }
`;
export const TablaBody = styled.tbody`
  background: #fff;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  border: 2px solid #ccc;
  padding-bottom: 16px;
`;

export const TablaBodyTexto = styled.th`
  font-size: 12px;
  font-weight: normal;
  color: #040404;
  padding-top: 1%;
  padding-bottom: 1%;
  border: 1px solid #707070;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const TablaBodyFinanzas = styled.tbody`
  background: #fff;
  padding-left: 2%;
  padding-right: 0%;
  text-align: left;
`;

export const ScrollDiv = styled.div`
  flex: 1;
  max-height: 300px;
  
  overflow-x: hidden;
  overflow-y: auto;
  @media ${dispositivo.mobileL} {
    min-width: 300px;
  }
  @media ${dispositivo.tablet} {
    min-width: 415px;
  }
  @media ${dispositivo.laptop} {
    min-width: 620px;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0c8de3;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
`;

export const TablaBodyTextoFinanzas = styled.th`
  background: #fff;
  color: #022B4E;
`;

export const TablaBodyTextoFinanzaPrecio = styled.div`
  font-size: 12px;
  font-weight: normal;
  padding-left: 2%;
  padding-right: 2%;
  border: 3px solid #0c8de3;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 10px;
  text-align: center;
  margin-right: 5%;
  color: #022B4E;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const TablaBodyTextoFinanzaPagado = styled.div`
  font-size: 12px;
  font-weight: normal;
  padding-left: 2%;
  padding-right: 2%;
  border: 3px solid #2cb742;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 10px;
  text-align: center;
  margin-left: 5%;
  margin-right: 5%;
  color: #022B4E;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const TablaBodyTextoSaldo = styled.div`
  font-weight: normal;
  font-size: 12px;
  padding-left: 2%;
  padding-right: 2%;
  border: 3px solid red;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 10px;
  text-align: center;
  margin-left: 5%;
  color: #022B4E;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const TarjetaAccion = styled(CardActions)`
  justify-content: center;
`;

export const Icono = styled.div`
  margin: 0%;
  cursor: pointer;
`;
