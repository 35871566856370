import {
  GET_NOTACLINICA,
  SET_NOTACLINICA,
  SAVE_NOTACLINICA_REQUEST,
  SAVE_NOTACLINICA_SUCCESS,
  SAVE_NOTACLINICA_FAILURE,
} from "./notaClinicaTypes";

const notaClinica = {
  nota: "",
  fecha: "",
  usuarioId: "",
};

export const notaClinicaReducers = (state = notaClinica, action) => {
  switch (action.type) {
    case SET_NOTACLINICA:
      return { ...state, nota: action.payload };
    case SAVE_NOTACLINICA_SUCCESS:
      return {
        ...state,
        id: action.payload,
      };
    default:
      return state;
  }
};
