import React from "react";
import IncisivoSelect from "../../../../components/Dientes/IncisivoSelect";

import { useHistory } from "react-router-dom";
import Diente from "../../../../components/Diente";
import { Denticion } from "../../../../components/ConfigDiente";
import { Modal } from "../../../../components/Modal/index";
import { useToasts } from "react-toast-notifications";
import { Puente } from "../../../../components/Puente";
import { terminarPrestacionOdontoTrx } from "../../../../redux/prestation/prestationActions";
import {
  aplicarCaras,
  aplicarPrestacion,
  DefaultConfig,
  CARAS,
  NUCLEO,
  CORONA,
  EXODONCIA,
  IMPLANTE,
} from "../../../../components/ConfigDiente";

import { useDispatch, useSelector } from "react-redux";
import {
  MainContainer,
  ContainerBody,
  ContainerTitle,
  ContainerTitleText,
  ContenedorCorona,
  Buttons,
  LinkCancelar,
  ButtonPagar,
  ContenedorDiente,
  ContenedorDientes,
} from "./styles";
import { getTratamientoById } from "../../../../services/firebase/tratamientos";

const Caras = ({ itemsOdontograma, config, faces, onSelectedFace }) => {
  
  if (itemsOdontograma){

  }
  const itemOdonto = aplicarCaras(itemsOdontograma[0], config, faces);
  
  const clase = Denticion.dientes.find(
    (item) => item.diente == itemOdonto.diente
  )?.lado;
  return (
    <>
      <ContenedorDientes>
        <ContenedorDiente>
          <Diente numero={itemOdonto.diente} config={itemOdonto} />
        </ContenedorDiente>
      </ContenedorDientes>
      <ContenedorCorona>
        <div>
          <span className="span-font">Selección de caras:</span>
        </div>
        <div className={clase}>
          <IncisivoSelect
            width="20rm"
            height="20rm"
            faces={faces}
            color={config.color}
            onSelectedFace={onSelectedFace}
          />
        </div>
      </ContenedorCorona>
    </>
  );
};

const General = ({ itemsOdontograma, config, faces }) => {
  let itemsOdonto;
  const { addToast } = useToasts();
  try {
    itemsOdonto = itemsOdontograma.map((item) =>
      aplicarPrestacion({
        itemOdonto: item,
        config,
        caras: faces,
        esPilar: true,
      })
    );
  } catch (err) {
    addToast(err.message, { appearance: "error" });
  }

  return (
    <>
      <ContenedorDientes>
        {itemsOdonto.map((it) => (
          <>
            <div>
              <ContenedorDiente>
                <Diente numero={it.diente} config={it}></Diente>
              </ContenedorDiente>
              <div>{it.diente}</div>
            </div>
          </>
        ))}
      </ContenedorDientes>
    </>
  );
};

export const TerminarPrestacionOdontoModal = (props) => {
  const prestacion = useSelector((state) => state.prestacion);
  const { grupo, afectaOdonto, coronaTipo, estado } = prestacion.config;
  const tratamientos = useSelector((state) => state.tratamientos)
  const paciente = useSelector((state) => state.paciente);
  const sesion = useSelector((state) => state.sesion);
  const [faces, setFaces] = React.useState(prestacion.caras);
  const [pilares, setPilares] = React.useState([]);
  const gastosFijos = useSelector((state) => state.gastosFijos);
  const dispatch = useDispatch();
  const odontograma = useSelector((state) => state.odontograma);
  let itemsOdontograma = new DefaultConfig();
  if (prestacion.dientes)
    itemsOdontograma = prestacion.dientes.map((d) => new DefaultConfig(d));

  const history = useHistory();
  const { addToast } = useToasts();

  const handleCloseModal = (e) => {
    setFaces([]);
    setPilares([]);
    props.handleCloseModal();
  };

  React.useEffect(() => {
    setFaces([]);
  }, [prestacion]);

  const handleCheck = (e) => {
    let diente = e.target.id;
    try {
      if (e.target.checked) {
        setPilares([...pilares, diente]);
      } else {
        let filtro = pilares.filter((d) => d != diente);

        setPilares(filtro);
      }
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const esPilar = (id) => {
    return pilares.some((d) => d == id);
  };

  const handleSelectedFaces = (e) => {
    const property = e.target.id;
    let myfaces = [...faces];
    let idx = faces.findIndex((it) => it == property);
    if (idx > -1) {
      myfaces.splice(idx, 1);
    } else myfaces.push(property);
    setFaces(myfaces);
  };

  const agregaNotaFinPrestacion = (prestacion) => {
    const nota = {
      fecha: Date.now(),
      nota: "Terminada",
      especialista: sesion.usuario.email,
    };
    prestacion.notasClinicas = [nota, ...prestacion.notasClinicas];
  };
  const handleSave = async () => {
    try {
      validaTerminar(prestacion);
      let costo = 0
      let tiempo = 30
      if (!prestacion.costo){
        let tratamiento = await getTratamientoById(prestacion.tratamientoId)
        costo = tratamiento.costo
        tiempo = tratamiento.tiempo
      }else
      {
        costo = prestacion.costo
        tiempo = prestacion.tiempo
      }
      if (costo===0 || !costo){
        throw new Error("Aún no ha determinado el costo de la prestación")
      }
      const costoMinuto = gastosFijos.costoMinuto?gastosFijos.costoMinuto:0
      prestacion.costo = costoMinuto * tiempo + costo
      prestacion.fechaFin = new Date();
      if (prestacion.config.grupo == "CORONA")
        prestacion.caras = [1, 2, 3, 4, 5];
      else prestacion.caras = faces;

      prestacion.pilares = pilares;
      agregaNotaFinPrestacion(prestacion);

      let myOdontograma = Object.assign({}, odontograma);
      myOdontograma.pacienteId = paciente.id;
      myOdontograma.especialistaId = sesion.usuario.email;
      myOdontograma.empresaId = sesion.usuario.empresaId;
      dispatch(terminarPrestacionOdontoTrx(prestacion, myOdontograma));
      setFaces([]);

      history.push("/paciente/odontograma");
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const validaTerminar = (prestacion) => {
    const { grupo } = prestacion.config;
    let itemOdonto = odontograma.items.find(
      (it) => it.diente == prestacion.diente
    );
    if (itemOdonto) {
      if (itemOdonto.estado === EXODONCIA || itemOdonto.estado === IMPLANTE) {
        if (grupo == NUCLEO || grupo == CARAS)
          throw new Error(
            "No puede aplicar este tratamiento encima de una EXONDONCIA o IMPLANTE"
          );
      }
    }

    if (grupo == CORONA)
      if (coronaTipo == "PUENTE" && pilares.length === 0)
        throw new Error("Seleccione pilares");
    if (grupo === CARAS && faces.length === 0) {
      throw new Error("Seleccione al menos una cara del diente");
    }
  };

  if (sesion.loading) {
    return <div>Procesando...</div>;
  } else if (sesion.error) {
    return <div>error </div>;
  } else
    return (
      <Modal isOpen={props.isOpen} onClose={handleCloseModal}>
        <React.Fragment>
          <ContainerTitle>
            <ContainerTitleText>
              {`Terminar Prestación: ${prestacion.tratamiento} [${
                prestacion?.dientes?.length > 0
                  ? prestacion?.dientes?.join()
                  : ""
              }]`}
            </ContainerTitleText>
          </ContainerTitle>
          <MainContainer>
            <ContainerBody>
              <div className="container-dientes-general">
                {coronaTipo !== "PUENTE" &&
                  coronaTipo !== "PROTESIS_REMOVIBLE" &&
                  grupo !== "CARAS" && (
                    <General
                      itemsOdontograma={itemsOdontograma}
                      config={prestacion.config}
                      faces={faces}
                    />
                  )}
              </div>
              <div className="container-dientes">
                {grupo === "CARAS" && (
                  <Caras
                    itemsOdontograma={itemsOdontograma}
                    config={prestacion.config}
                    faces={faces}
                    onSelectedFace={handleSelectedFaces}
                  ></Caras>
                )}

                {(coronaTipo === "PUENTE" ||
                  coronaTipo === "PROTESIS_REMOVIBLE") && (
                  <Puente
                    itemsOdontograma={itemsOdontograma}
                    pilares={pilares}
                    config={prestacion.config}
                    onCheck={handleCheck}
                    getValue={esPilar}
                  />
                )}
              </div>
            </ContainerBody>
            <Buttons>
              <LinkCancelar className="btn" onClick={handleCloseModal}>
                Cancelar
              </LinkCancelar>
              <ButtonPagar className="btn" onClick={handleSave}>
                Guardar
              </ButtonPagar>
            </Buttons>
          </MainContainer>
        </React.Fragment>
      </Modal>
    );
};
