import React, { useState, useEffect } from "react";

export const Checkbox = (props) => {
  const { llave, checked } = props.item;
  const handleChange = (e) => {
    props.onChange(e.target.value);
  };

  return (
    <React.Fragment>
      <label>
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          value={llave}
        />
        {llave}
      </label>
    </React.Fragment>
  );
};
