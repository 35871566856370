import React from "react";
import Diente from "../Diente";
import { DefaultConfig, Denticion } from "../ConfigDiente";
import { ContenedorDientes, ContenedorDiente, DivPuente } from "./styles";
import { aplicarCorona } from "../ConfigDiente";

export const Puente = ({
  itemsOdontograma,
  pilares,
  config,
  onCheck,
  getValue,
}) => {
  let itemsOdonto = itemsOdontograma.map((it) =>
    aplicarCorona(
      new DefaultConfig(it.diente),
      config,
      [1, 2, 3, 4, 5],
      pilares.some((p) => p == it.diente)
    )
  );
  return (
    <>
      <DivPuente>
        <h5>Seleccione Pilares</h5>
        <ContenedorDientes>
          {itemsOdonto.map((item) => (
            <ContenedorDiente key={item.diente}>
              <Diente numero={item.diente} config={item} />
              <label className="label-puente">{item.diente}</label>
              <input
                type="checkbox"
                id={item.diente}
                name={item.diente}
                onChange={onCheck}
                value={getValue(item.diente)}
              />
            </ContenedorDiente>
          ))}
        </ContenedorDientes>
      </DivPuente>
    </>
  );
};
