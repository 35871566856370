import React from "react";
import styles from "./styles";
import { LogoWaterMarkWrapper } from "./styles";

const LogoWaterMark = () => {
  return (
    <LogoWaterMarkWrapper>
      <center>
        <img
          src={require("../../img/sysdent-header-logo.png")}
          alt="sysdent logo"
        />
      </center>

    </LogoWaterMarkWrapper>
  );
};

export default LogoWaterMark;
