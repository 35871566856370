export const GET_ODONTOGRAMA_REQUEST = "GET_ODONTOGRAMA_REQUEST";
export const GET_ODONTOGRAMA_SUCCESS = "GET_ODONTOGRAMA_SUCCESS";
export const GET_ODONTOGRAMA_FAILURE = "GET_ODONTOGRAMA_FAILURE";

export const SAVE_ODONTOGRAMA_REQUEST = "SAVE_ODONTOGRAMA_REQUEST";
export const SAVE_ODONTOGRAMA_SUCCESS = "SAVE_ODONTOGRAMA_SUCCESS";
export const SAVE_ODONTOGRAMA_FAILURE = "SAVE_ODONTOGRAMA_FAILURE";
export const SET_ODONTOGRAMA = "SET_ODONTOGRAMA";
export const NEW_ODONTOGRAMA = "NEW_ODONTOGRAMA";
export const UPDATE_DIENTE_EN_ODONTOGRAMA = "UPDATE_DIENTE_EN_ODONTOGRAMA";
export const ADD_DIENTE_ODONTOGRAMA = "ADD_DIENTE_ODONTOGRAMA";
