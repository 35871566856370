import { db } from "../../services/firebase/Config";

import { SET_RECETA, GUARDAR_RECETA } from "./recetaTypes";

import { httpRequest, httpSuccess, httpFailure } from "../sesion/sesionActions";

export const setReceta = (receta) => {
  return {
    type: SET_RECETA,
    payload: receta,
  };
};

export const guardarReceta = (receta) => {
  return function (dispatch) {
    let recetasRef = undefined;
    if (receta.id) {
      recetasRef = db.collection("recetas").doc(receta.id);
    } else {
      recetasRef = db.collection("recetas").doc();
    }
    dispatch(httpRequest());
    receta.id = recetasRef.id;
    recetasRef
      .set(receta)
      .then(() => {
        dispatch(httpSuccess());
        //dispatch(setReceta(receta));
      })
      .catch((error) => {
        dispatch(httpFailure(error));
      });
  };
};
