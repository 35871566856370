import { SELECT_TOOTH, NEW_TOOTH_SELECTION } from "./denticionTypes";

export const selectTooth = (payload) => {
  return {
    type: SELECT_TOOTH,
    payload,
  };
};

export const newToothSelection = () => {
  return {
    type: NEW_TOOTH_SELECTION,
  };
};
