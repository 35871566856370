import { db } from "../Config";

// función asincrona de fetch ordenes
export const fetchListas = (empresaId) => {
  return new Promise((resolve, reject) => {
    let listasRef = db.collection("listasEmpresa");
    let listas = {};
    listasRef
      //.where("empresaId", "==", empresaId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          listas = {
            bancos: doc.data().bancos,
            monederos: doc.data().monederos,
          };
        });
        resolve(listas);
      })
      .catch((err) => reject(err));
  });
};
