import { db } from "../Config";

export const fetchMetas = (empresaId) => {
  let metasRef = db.collection("metas")
  return new Promise((resolve, reject) => {
    let metas = {empresaId: empresaId, prestaciones: [], id:""};
    metasRef
      .where("empresaId","==",empresaId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          metas = {
            id : doc.data().id,
            empresaId : doc.data().empresaId,
            prestaciones :  doc.data().prestaciones

          }
        })
        
        resolve(metas)
        
      })
      .catch((err) => reject(err));
  });
};

export const saveMetas = (metas) => {
  return new Promise((resolve, reject) => {
    let metaRef;
    if (metas.id)
    metaRef = db.collection("metas").doc(metas.id);
    else
    metaRef = db.collection("metas").doc();

    metas.id = metaRef.id;
    metaRef
      .set(metas)
      .then((docRef) => {
        resolve(metas);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const updateMetas = (metas) => {
  return new Promise((resolve, reject) => {
    const metaRef = db.collection("metas").doc(metas.id);

    metaRef
      .set(metas)
      .then((docRef) => {
        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
