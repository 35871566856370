import styled from "styled-components";
import { dispositivo } from "../../components/Dispositivos";

export const DivCharts = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  //flex-direction: column;
  @media ${dispositivo.tablet} {
    display: flex;
  }
`;
export const Contenedor = styled.div`
  display: grid;
  grid-template-columns: 1fr ;
  grid-auto-rows: minmax(100px, auto);
  gap: 15px;
  place-content: center;
  padding: 15px;
  margin: 0 2rem;
  @media ${dispositivo.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;
export const Titulo = styled.h1`
  display: flex;
  font-size: 22px;
  justify-content: center;
  text-align: center;
  margin-bottom: 0;
`;

export const Contenido = styled.div`
  display: flex;
  font-size: 24px;
  justify-content: center;
  align-items: center;

  @media ${dispositivo.tablet} {
    font-size: 2.5rem;
  }
`;
export const Item = styled.div`
  background-color: #ffffff;
  padding: 15px;
  color: #0060B0;
  // border: 10px solid #4DA2DE;
  border: 10px solid #3297A9;
  border-radius: 10px;
`;
export const Items = styled.div`
  padding: 5px;
  color: #0060B0;
  margin: 0 0 1rem 0;
  border-radius: 5px;
`;

export const ContenedorBienvenida = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.625em 0.625em  0 0.625em ;
  text-align: center;
  
  .titulo-bienvenida{
    font-size: 2rem;
    color: #0060B0;
    @media ${dispositivo.laptop} {
      font-size: 2.5rem;
    }
  }
  .subtitulo-bienvenida{
    font-size: 1rem;
    color: #029139;
    @media ${dispositivo.laptop} {
      font-size: 1.75rem;
    }
  }
  .subtitulo-reporte{
    font-size: 1rem;
    // color:  #3297A9;
    color:  #4DA2DE;
    @media ${dispositivo.laptop} {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }
`;
