import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { dispositivo } from "../../components/Dispositivos";

export const MainContainer = styled.div`
margin-top: 1.5rem;
  width: 90%;
  background: #fff;
  box-shadow: 2px 2px #88888877;
  border-radius: 14px;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 2% 0;
  margin-left: 4%;
  @media ${dispositivo.tablet} {
    padding: 2%;
    margin-left: 6%;
  }
  flex: 1;
  // max-height: 600px;
  // overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0c8de3;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }

`;

export const ContainerTitle = styled.div`
  color: #000;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-top: 2%;
  display: flex;
  justify-content: space-around;
`;

export const ContainerTitleText = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #ffffff;
  background: #0c8de3;
  border: 2px solid #0c8de3;
  width: 100%;
  margin-bottom: 4%;
`;

export const ContainerBody = styled.div`
  
`;
export const Tabla = styled.table`
  width: 100%;
`;

export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const TablaHeaderTexto = styled.th`
  font-size: 10px;
  font-weight: normal;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 12px;
  }

  
  
  @media ${dispositivo.tablet} {
    font-size: 14px;
  }
`;

export const TablaHeaderUsuario = styled.th`
  font-size: 10px;
  font-weight: normal;
  color: #fff;
  display:none;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 12px;
  }

  
  
  @media ${dispositivo.tablet} {
    font-size: 14px;
  }
`;
export const Fila = styled.tr`
  background-color: ${(props) => (props.idx % 2 == 0 ? "#69bdf5" : "#fff")};
  font-size: 0.625rem;
  @media ${dispositivo.mobileL} {
    font-size: 0.75rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }

  `;
export const TablaBody = styled.tbody`
  background: #fff;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  border: 2px solid #ccc;
  padding-bottom: 16px;
  .tablaBodyTextoDiente{
    font-size: 0.6rem;
    color:  #045184;
    padding: 0.313em 0  0.313em 0.5em;
    display: flex;
    justify-content: space-between;
    max-width: 80px;
    width: 95%;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
  
    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
      border-radius: 2px;
    }
  
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #0c8de3;
      border-radius: 2px;
    }
  
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #fff;
    }
    @media ${dispositivo.mobileL} {
      font-size: 0.875rem;
    }
    @media ${dispositivo.tablet} {
      font-size: 1rem;
    }
    @media ${dispositivo.laptopL} {
      max-width: 200px;
    }
  }
`;

export const TablaBodyTexto = styled.th`
  font-size: 12px;
  font-weight: normal;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const StyledInput = styled.input`
  width: 30%;
  color: #707070;
  vertical-align: middle;
  text-align: center;
  &:focus {
    outline: none;
  }
`;

export const StyledButton = styled(Button)`
  && {
    background-color: #0c8de3;
    border: none;
    color: white;
    outline: none;
    text-align: center;
    font-size: 10px;
    margin-bottom: 2%;
    margin: 0 0.75em;
    padding-bottom: 0.8%;
    padding-top: 2%;
    border-radius: 4px;
    transition: 0.3s;
    display: inline-block;
    text-decoration: none;
    :active {
      transform: translateY(4px);
    }
    @media ${dispositivo.mobileL}{
      margin: 0 .2em;
    }
    @media ${dispositivo.mobileL} {
      font-size: 12px;
    }
    @media ${dispositivo.tablet} {
      font-size: 14px;
    }
  }
`;

export const TablaBodyTextoDiente = styled.div`
  font-size: 0.6rem;
  color:  #212529;
  padding: 0.313em 0  0.313em 0.5em;
  // display: flex;
  justify-content: space-between;
  max-width: 80px;
  width: 95%;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 2px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0c8de3;
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
    max-width: 130px;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
    max-width: 180px;
  }
  @media ${dispositivo.laptopL} {
    max-width: 225px;
  }
`;