import {
  SET_STSORDENESRX,
  UPDATE_STSORDENRX_IN_ARRAY,
  ADD_STSORDENRX_IN_ARRAY,
} from "./stsOrdenesRxTypes";
import { httpRequest, httpSuccess, httpFailure } from "../sesion/sesionActions";

import { fetchStsOrdenesRxEmpresaId } from "../../services/firebase/stsOrdenesRx";

export const setStsOrdenesRx = (ordenes) => {
  return {
    type: SET_STSORDENESRX,
    payload: ordenes,
  };
};

export const updateStsOrdenRxInArray = (orden) => {
  return {
    type: UPDATE_STSORDENRX_IN_ARRAY,
    payload: orden,
  };
};

export const addStsOrdenInArray = (orden) => {
  return {
    type: ADD_STSORDENRX_IN_ARRAY,
    payload: orden,
  };
};

export const getStsOrdenesRx = (empresaId, year, month) => {
  return function (dispatch) {
    dispatch(httpRequest());
    fetchStsOrdenesRxEmpresaId(empresaId, year, month)
      .then((stsOrdenesRx) => {
        dispatch(setStsOrdenesRx(stsOrdenesRx));
        dispatch(httpSuccess());
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};
