import styled from "styled-components";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { dispositivo } from "../../components/Dispositivos";



export const TableWrapper = styled.div`
  thead {
    background: #0c8de3;
    
    tr {
      font-size: 10px;
      font-weight: normal;
      color: #fff;
      td {
        vertical-align: middle;
      }  
      @media ${dispositivo.mobileL} {
        font-size: 14px;
        
      }
  }
}
tbody{
    font-size: 10px;
    font-weight: normal;
}
  
  tfoot {
    background: #0c8de3;
    tr {
      font-size: 10px;
      font-weight: normal;
      color: #fff;
    }
    
  }

`

export const MainContainer = styled.div`
  width: 100%;
  text-align: center;
  background: #f8f8f8;
`;

export const ContainerTitle = styled.div`
  color: #000;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-top: 2%;
  display: flex;
  justify-content: space-around;
`;

export const ContainerTitleText = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #ffffff;
  background: #0c8de3;
  border: 2px solid #0c8de3;
  width: 100%;
`;

export const Titulo = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 5px;
  margin-top: 5px;
`;

export const Subtitulo = styled.h3`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 5px;
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
`;

export const Finanzas = styled.div`
  display: block;
`;

export const ContenedorTarjeta = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  width: 80%;
  font-size: 10px;
  margin: 1%;
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const ContenedorCabeceraTarjeta = styled(CardHeader)`
  color: #040404;
  background: #fff;
  padding: 1em 2% 0 !important;
  font-size: 16px !important;
  max-width: 200px;
  word-break: break-all;
  .titulo-font{
    font-size: 16px !important;x-width: 255px;
    font-weight: bold;
    @media ${dispositivo.mobileL} {
      font-size: 18px !important;
    }
    @media ${dispositivo.laptop} {
      font-size: 20px !important;
    }
  }
  @media ${dispositivo.mobileM} {
    max-width: 255px;
  }
  @media ${dispositivo.mobileL} {
    max-width: 510px;
  }
  @media ${dispositivo.laptop} {
    max-width: 545px;
  }
`;

export const ContenedorContenidoTarjeta = styled(CardContent)`
  display: block;
`;

export const ContainerBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 4%;
  box-shadow: 2px 2px #88888877;
  border-radius: 14px;
  justify-content: center;
`;

export const SectionTarjeta = styled.section`
  justify-content: center;
  margin-top: 1rem;
  @media ${dispositivo.tablet} {
    flex: 1;
    }
`;
