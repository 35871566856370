import React,{useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../../../components/Modal/index";
import { actualizarNotas, setPrestation } from "../../../../redux/prestation/prestationActions";
import { useToasts } from "react-toast-notifications";

import {
  TextArea,
  ContainerTitle,
  ContainerTitleText,
  TablaBody,
  TablaHeaderTextoItems,
  TablaHeaderTextoValues,
  Tabla,
  LinkCancelar,
  ButtonPagar,
  ContainerBody,
  Buttons,
} from "./styles";


function selectText(nodeId) {
  const node = document.getElementById(nodeId);
  node.setSelectionRange(0, node.value.length);
  if (document.body.createTextRange) {
      const range = document.body.createTextRange();
      range.moveToElementText(node);
      range.select();
  } else if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(node);
      selection.removeAllRanges();
      selection.addRange(range);
  } else {
      console.warn("Could not select text in node: Unsupported browser.");
  }
}
const getFecha = (now) => {
 
  let tipo = typeof now;
  if (tipo == 'number'){
    let fecha = new Date(now)
    return formatFecha(fecha)
  
    
  }else return now
  
};

function formatFecha(fecha){
  const a = fecha.getFullYear();
  const m = (fecha.getMonth() + 1).toString().padStart(2, "0");
  const d = fecha.getDate().toString().padStart(2, "0");
  return `${a}-${m}-${d}`;
}
export const NuevaNotaClinicaModal = (props,ref) => {
  const prestacion = useSelector((state) => state.prestacion);
  const sesion = useSelector((state) => state.sesion);
  const dispatch = useDispatch();
  const inputRef = useRef();
  
  let notaHoy = prestacion?.notasClinicas?.find(
    (it) => getFecha(it.fecha) == sesion.fecha
  );
  if(!notaHoy)
  notaHoy = {
    fecha: Date.now(),
    especialista: sesion.usuario.email,
    nota: "",
  }
 
 
  const { addToast } = useToasts();

  const handleChange = (e) => {
    
    let newP = {...prestacion}
      const idx = newP.notasClinicas.findIndex(
        (it) => getFecha(it.fecha) === sesion.fecha
      );
      if (idx>-1)
      {
        newP.notasClinicas[idx].nota = e.target.value;

      }else{
        let newNota = {
          fecha: Date.now(),
          especialista: sesion.usuario.email,
          nota: e.target.value,
        }
        newP.notasClinicas.unshift(newNota)

      }
      
    
      dispatch(setPrestation(newP));
    
  };
  
  React.useEffect(() => {
    
    if (props.isOpen){
      selectText('notaclinica');
      inputRef.current.focus();
    }
    
  }, [props.isOpen]);

  const handleSave = (e) => {
    
    try {
      dispatch(actualizarNotas(prestacion));
      addToast("Nota agregada", { appearance: "success" });
      props.handleCloseModal();
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.handleCloseModal}>
      <ContainerTitle>
        <ContainerTitleText>
          {notaHoy ? "Edita nota clínica" : "Nueva nota clínica"}
        </ContainerTitleText>
      </ContainerTitle>
      <ContainerBody>
        <Tabla>
          <TablaBody>
            <tr>
              <TablaHeaderTextoItems>
                <strong>Fecha: </strong>
              </TablaHeaderTextoItems>
              <TablaHeaderTextoValues data-label="Fecha">
                <span>{sesion.fecha}</span>
              </TablaHeaderTextoValues>
            </tr>

            <tr>
              <TablaHeaderTextoItems>
                <strong>Especialista: </strong>
              </TablaHeaderTextoItems>
              <TablaHeaderTextoValues data-label="Especialista">
                <span>{sesion.usuario.email}</span>
              </TablaHeaderTextoValues>
            </tr>
          </TablaBody>
        </Tabla>
        <TextArea
         id="notaclinica"
          ref={inputRef}
          name="nota"
          rows="3"
          cols="50"
          placeholder="Descripcion de la Nota"
          autoComplete="off"
          value={notaHoy.nota}
          onChange={handleChange}
        ></TextArea>
      </ContainerBody>
      <Buttons>
        <LinkCancelar onClick={props.handleCloseModal}>
          Cancelar
        </LinkCancelar>
        <ButtonPagar onClick={handleSave}>Guardar</ButtonPagar>
      </Buttons>
    </Modal>
  );
};
