import { db } from "../../services/firebase/Config";

import {
  SET_ODONTOGRAMA,
  NEW_ODONTOGRAMA,
  SAVE_ODONTOGRAMA_REQUEST,
  SAVE_ODONTOGRAMA_SUCCESS,
  SAVE_ODONTOGRAMA_FAILURE,
  GET_ODONTOGRAMA_REQUEST,
  GET_ODONTOGRAMA_SUCCESS,
  GET_ODONTOGRAMA_FAILURE,
  UPDATE_DIENTE_EN_ODONTOGRAMA,
  ADD_DIENTE_ODONTOGRAMA,
} from "./odontogramaTypes";

import {
  setEstadoCompletado,
  httpRequest,
  httpSuccess,
  httpFailure,
} from "../sesion/sesionActions";

export const newOdontograma = () => {
  return {
    type: NEW_ODONTOGRAMA,
  };
};
export const updateDienteEnOdontograma = (diente) => {
  return {
    type: UPDATE_DIENTE_EN_ODONTOGRAMA,
    payload: diente,
  };
};

export const setOdontograma = (odontograma) => {
  return {
    type: SET_ODONTOGRAMA,
    payload: odontograma,
  };
};

export const addDienteOdontograma = (item) => {
  return {
    type: ADD_DIENTE_ODONTOGRAMA,
    payload: item,
  };
};

export const saveOdontograma = (odontograma) => {
  return function (dispatch) {
    dispatch(httpRequest());
    db.collection("odontogramas")
      .doc(odontograma.id)
      .set(odontograma)
      .then(() => {
        dispatch(setOdontograma(odontograma));
        dispatch(httpSuccess(odontograma));
      })
      .catch((error) => {
        dispatch(httpFailure(error));
      });
  };
};

export const getOdontograma = (pacienteId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    let odontograma = {
      id: "",
      pacienteId: "",
      tipo: "INICIAL",
      clase: "",
      detalleId: "",
      items: [],
    };
    db.collection("odontogramas")
      .where("pacienteId", "==", pacienteId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          odontograma = doc.data();
          odontograma.id = doc.id;
        });
        dispatch(httpSuccess());
        dispatch(setOdontograma(odontograma));
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};
