import {
  FETCH_NOTAS_CLINICAS_REQUEST,
  FETCH_NOTAS_CLINICAS_SUCCESS,
  FETCH_NOTAS_CLINICAS_FAILURE,
  ADD_NOTA_CLINICA_TO_ARRAY,
  REMOVE_NOTA_CLINICA,
} from "./notasClinicasTypes";

const initialState = { loading: false, error: "", notas: [] };
export const notasClinicasReducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTAS_CLINICAS_REQUEST:
      return { ...state, loading: true };
    case FETCH_NOTAS_CLINICAS_SUCCESS:
      return { loading: false, error: "", notas: action.payload };
    case FETCH_NOTAS_CLINICAS_FAILURE:
      return { loading: false, error: action.payload, notas: [] };
    case ADD_NOTA_CLINICA_TO_ARRAY:
      return { ...state, notas: [action.payload, ...state.notas] };
    case REMOVE_NOTA_CLINICA:
      return {
        ...state,
        notas: state.notas.filter((nota) => nota.id !== nota.payload),
      };
    default:
      return state;
  }
};
