import { SET_ACCESOS } from "./accesosTypes";

const initialState = {
  perfil: "",
  empresaId: "",
  lateral: [],
  superior: [],
  fecha: "",
};

export const accesosReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCESOS:
      return action.payload;
    default:
      return state;
  }
};
