import React from "react";
import { useHistory } from "react-router-dom";
import { ConfirmacionSiNoModal } from "../../components/ConfirmacionSiNoModal/index";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCuenta, abonarCuentaTrx } from "../../redux/cuenta/cuentaActions";
import { getListas } from "../../redux/listasEmpresa/listasActions";
import { TopBarPaciente } from "../../components/TopBarPaciente";
import {
  MainContainer,
  BodyContainer,
  LinkCancelar,
  StyledForm,
  StyledOption,
  ButtonAbonar,
  Tabla,
  TablaBody,
  TablaBodyTexto,
  SelectStyle,
  StyledInput,
  StyledLabel,
  ContainerBtn,
  TablaBodyTextoSoles,
  ContainerTabla,
} from "./styles";
import { useToasts } from "react-toast-notifications";

export const AbonoCuenta = (props) => {
  const history = useHistory();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const paciente = useSelector((state) => state.paciente);
  const cuenta = useSelector((state) => state.cuenta);
  const sesion = useSelector((state) => state.sesion);
  const listas = useSelector((state) => state.listas);

  const [formAbono, setFormAbono] = React.useState({
    cuentaId: cuenta.id,
    estado: "VIGENTE",
    fecha: new Date(),
    medio: "EFECTIVO",
    moneda: "SOLES",
    monto: "",
    costo: 0,
    pacienteId: paciente.id,
    pacienteNombres: `${paciente.apellidos} ${paciente.nombres}`,
    usuario: {email:sesion.usuario.email, nombres: sesion.usuario.nombres, id: sesion.usuario.id},
    usuarioId: sesion.usuario.id,
    tipMov: "ABONO",
    empresaId: sesion.usuario.empresaId,
  });
  const { id, saldo } = cuenta;
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const handleCloseModal = (e) => {
    setModalIsOpen(false);
  };

  const handleOpenModal = (e) => {
    e.preventDefault();
    // TODO: Insertar la transaccion de movimiento, cambiar el estado a pagado, actualizar el
    const myForm = document.getElementById("abonocuenta");
    try {
      myForm.checkValidity();
      myForm.reportValidity();
      validar();
      if (myForm.checkValidity() && myForm.reportValidity()) {
        setModalIsOpen(true);
        //history.push("/prestaciones");
      }
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const handlePayment = (e) => {
    try {
      e.preventDefault();
      validar();
      let abono = { ...formAbono, monto: Number(formAbono.monto) };
      setModalIsOpen(false);
      dispatch(abonarCuentaTrx(abono));
      addToast("Abono satisfactorio", { appearance: "success" });
      history.push("/paciente/pagos");
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const validar = () => {
    let numerosExp = /^\d+([.]\d{1,2})?$/;
    if (!numerosExp.test(formAbono.monto)) {
      throw new Error("Ingrese un monto válido");
    }
    if (formAbono.monto <= 0) throw new Error("Monto debe ser mayor a cero");
    if (cuenta.saldo == undefined)
      throw new Error(
        "Cuenta inválida, comuníquese con el administrador del sistema"
      );
  };
  const handleChange = (e) => {
    setFormAbono({ ...formAbono, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    dispatch(getListas(sesion.usuario.empresaId));
  }, []);

  if (sesion.loading) return <div>...procesando</div>;
  if (sesion.error) return <div>{`Ocurrió un error ${sesion.error}`}</div>;
  return (
    <React.Fragment>
      <TopBarPaciente />
      <MainContainer>
        <ContainerTabla>
          <BodyContainer>
            <StyledForm id="abonocuenta" onSubmit={handlePayment}>
              <Tabla>
                <TablaBody>
                  <tr>
                    <TablaBodyTexto>
                      <StyledLabel for="medio">Tipo de Abono: </StyledLabel>
                    </TablaBodyTexto>
                    <TablaBodyTexto>
                      <SelectStyle
                        name="medio"
                        id="medio"
                        onChange={handleChange}
                        value={formAbono.medio}
                      >
                        <StyledOption value="EFECTIVO">EFECTIVO</StyledOption>
                        <StyledOption value="BANCO">BANCO</StyledOption>
                        <StyledOption value="MONEDERO">
                          APP. ELECTRONICA
                        </StyledOption>
                      </SelectStyle>
                    </TablaBodyTexto>
                  </tr>
                  {formAbono.medio == "MONEDERO" && (
                    <tr>
                      <TablaBodyTexto>
                        <StyledLabel for="monedero">Monedero</StyledLabel>
                      </TablaBodyTexto>
                      <TablaBodyTexto>
                        <SelectStyle
                          name="monedero"
                          id="monedero"
                          onChange={handleChange}
                          value={formAbono.monedero}
                          required
                        >
                          <option value="" selected disabled hidden>
                            Seleccione monedero
                          </option>
                          {listas.monederos.map((item, index) => (
                            <StyledOption key={index} value={item}>{item}</StyledOption>
                          ))}
                        </SelectStyle>
                      </TablaBodyTexto>
                    </tr>
                  )}
                  {formAbono.medio == "BANCO" && (
                    <tr>
                      <TablaBodyTexto>
                        <StyledLabel for="banco">Banco:</StyledLabel>
                      </TablaBodyTexto>
                      <TablaBodyTexto>
                        <SelectStyle
                          name="banco"
                          id="banco"
                          onChange={handleChange}
                          value={formAbono.banco}
                          required
                        >
                          <option value="" selected disabled hidden>
                            Seleccione Banco
                          </option>
                          {listas.bancos.map((item, index) => (
                            <StyledOption key={index} value={item}>{item}</StyledOption>
                          ))}
                        </SelectStyle>
                      </TablaBodyTexto>
                    </tr>
                  )}
                  {formAbono.medio == "BANCO" && (
                    <tr>
                      <TablaBodyTexto>
                        <span>Num. Ope.</span>
                      </TablaBodyTexto>
                      <TablaBodyTexto>
                        <StyledInput
                          type="text"
                          required
                          name="numOpe"
                          value={formAbono.numOpe}
                          onChange={handleChange}
                        ></StyledInput>
                      </TablaBodyTexto>
                    </tr>
                  )}
                  <tr>
                    <TablaBodyTexto>
                      <span>Moneda: </span>
                    </TablaBodyTexto>
                    <TablaBodyTextoSoles >
                      <span >SOLES</span>
                    </TablaBodyTextoSoles>
                  </tr>

                  <tr>
                    <TablaBodyTexto>
                      <span>Monto: </span>
                    </TablaBodyTexto>
                    <TablaBodyTexto>
                      <StyledInput
                        type="text"
                        required
                        name="monto"
                        className="styleMonto"
                        value={formAbono.monto}
                        onChange={handleChange}
                      ></StyledInput>
                    </TablaBodyTexto>
                  </tr>
                </TablaBody>
              </Tabla>
            </StyledForm>
          </BodyContainer>
          <ContainerBtn>
            <LinkCancelar to="pagos" className="btn linkP">
              Cancelar
            </LinkCancelar>
            <ButtonAbonar className="btn" onClick={handleOpenModal}>
              Abonar
            </ButtonAbonar>
          </ContainerBtn>
          <ConfirmacionSiNoModal
            mensaje="Esta seguro de realizar esta operacion?"
            isOpen={modalIsOpen}
            handleCloseModal={handleCloseModal}
            onClickYes={handlePayment}
          />

        </ContainerTabla>

      </MainContainer>
    </React.Fragment>

  );
};
