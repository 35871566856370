import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPrestation,
  setCaras,
} from "../../redux/prestation/prestationActions";
const Incisivo = (props) => {
  let constrastColor = "blue";
  const defaultColor = "white";

  const faces = props.faces;
  constrastColor = props.color ? props.color : constrastColor;
  const getColor = (valor) => {
    let idx = faces.findIndex((it) => it == valor);
    return idx > -1 ? constrastColor : defaultColor;
  };

  const getSelectedFaces = () => {
    let items = [];
    if (faces) {
      Object.keys(faces).forEach((pro) => {
        if (faces[pro]) {
          items.push(pro);
        }
      });
    }

    return items;
  };

  const handleClick = (e) => {
    props.onSelectedFace(e);
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 726.78 726.78">
      <title>incisivo select</title>
      <g id="Capa_1" data-name="Capa 1">
        <g id="incisor-group">
          <rect
            id="1"
            x="238.39"
            y="238.39"
            width="250"
            height="250"
            fill={getColor("1")}
            onClick={handleClick}
            stroke="#000"
            stroke-width="5"
          />
          <polygon
            id="2"
            points="113.39 113.39 613.39 113.39 488.39 300.89 238.39 300.89 113.39 113.39"
            fill={getColor("2")}
            onClick={handleClick}
            stroke="#000"
            stroke-width="5"
          />
          <polygon
            id="3"
            points="613.39 113.39 613.39 613.39 488.39 425.89 490.06 300.89 613.39 113.39"
            fill={getColor("3")}
            onClick={handleClick}
            stroke="#000"
            stroke-width="5"
          />
          <polygon
            id="4"
            points="613.39 613.39 113.39 613.39 238.39 425.89 488.39 425.89 613.39 613.39"
            fill={getColor("4")}
            onClick={handleClick}
            stroke="#000"
            stroke-width="5"
          />
          <polygon
            id="5"
            points="113.39 613.39 113.39 113.39 238.39 300.89 238.39 425.89 113.39 613.39"
            fill={getColor("5")}
            onClick={handleClick}
            stroke="#000"
            stroke-width="5"
          />
        </g>
      </g>
      <g id="Raiza">
        <path
          id="9"
          d="M34.6,500h435l-83.87,95c-9,10.22-21.18,15.94-33.85,15.94H168.48a44.56,44.56,0,0,1-30.76-12.74Z"
          transform="translate(113.39 113.39)"
          fill={getColor("9")}
          onClick={handleClick}
          stroke="#000"
          stroke-width="5"
        />
        <path
          id="7"
          d="M0,28.6v426l-94.95-82.14c-10.22-8.83-15.94-20.74-15.94-33.14V159.71c0-11,4.5-21.65,12.74-30.13Z"
          transform="translate(113.39 113.39)"
          fill={getColor("7")}
          onClick={handleClick}
          stroke="#000"
          stroke-width="5"
        />
        <path
          id="8"
          d="M469.58,0H34.6l83.87-94.95c9-10.22,21.19-15.94,33.85-15.94H335.7a44.56,44.56,0,0,1,30.76,12.74Z"
          transform="translate(113.39 113.39)"
          fill={getColor("8")}
          onClick={handleClick}
          stroke="#000"
          stroke-width="5"
        />
        <path
          id="6"
          d="M500,454.58V28.6l95,82.14c10.22,8.84,15.94,20.74,15.94,33.15V323.47c0,11-4.5,21.66-12.74,30.13Z"
          transform="translate(113.39 113.39)"
          fill={getColor("6")}
          onClick={handleClick}
          stroke="#000"
          stroke-width="5"
        />
      </g>
    </svg>
  );
};

export default Incisivo;
