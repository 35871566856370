import React from "react";

const CaninoInf = (props) => {
  const defaultColor = "#ffeca2";

  const configuracion = props.config;

  const getColor = (cara) => {
    let color = defaultColor;
    if (props.config.caras) {
      let idx = props.config.caras.findIndex((it) => it.cara == cara);
      if (idx > -1) color = props.config.caras[idx].color;
    }
    return color;
  };

  return (
    <svg
      id="Canino_inf"
      data-name="Canino inf"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 201.5 489.99"
    >
      <title>canino_inf</title>
      <g id="Implante">
        <path
          d="M172.85,166c-4.69-4.72-34-2.9-34-2.9l-60.61-.34s-48.8-2.58-54.83,4c-2.82,3-3.22,5-3.22,14.3,0,13.19,2.41,17,10.85,17,3,0,6.57.63,7.91,1.57s8.31,13.82,15.28,28.75l12.86,27.17v12a75.12,75.12,0,0,0-9.09,3.9c-2.9,2.2-4.15,5.82-4.77,13-1,11.62,1.66,16.81,8.71,16.81a27.8,27.8,0,0,0,5.28-.92V311.5a39.68,39.68,0,0,0-5.47,2.7c-2.66,2.2-3.8,5.81-4.37,13-.94,11.62,1.52,16.8,8,16.8a10.74,10.74,0,0,0,1.87-.23v18.72a8.94,8.94,0,0,0-1.45,1.15c-3.21,3.3-3.89,6-3.89,14.13,0,8.57,1.63,13.16,5.34,14.5v19.09c-4.83,4-5.34,8-5.34,15.11,0,8.58,1.69,13.26,5.34,14.54v8.61a2.67,2.67,0,0,0,.58,1.7,1.82,1.82,0,0,0,.11.46c.58,1.67,1.15,19.64,9,29,7.14,6.32,21.7,8.22,24.62,8.22s14.24,1.22,24.66-10.17c7.32-8,7.08-21.73,7.5-26,.13-.71.26-1.55.38-2.49a6.32,6.32,0,0,0,1.39-4v-32a12.44,12.44,0,0,0,1.88-1.15c2.37-2.2,3.39-5.82,3.9-13,.79-10.63-1.06-16-5.78-16.71V365.21c5.27-4.18,6-8.3,6-15.47,0-9.19-1.89-13.83-6-14.76V318.75a30.94,30.94,0,0,0,5.53-3.1c4.55-4.08,5.12-5.81,5.12-14.61,0-10.21-3.23-15.23-9.67-14.92a5.69,5.69,0,0,0-1,.19v-9c4.69-1.94,8-3.52,9.22-4.45,5-4.09,5.6-5.81,5.6-14.61,0-10.21-3.53-15.24-10.58-14.92a25.43,25.43,0,0,0-3.85.94c6.87-16.14,17.83-39.6,20.8-43.61,1.34-1.88,4-2.67,8.58-2.67,9.51,0,11.79-3.3,11.79-17.12C177,170.67,176.73,169.73,172.85,166Z"
          transform="translate(0)"
          fill={configuracion.implante.fill}
          stroke={configuracion.implante.strokeColor}
        />
      </g>
      <path
        id="Raiz"
        d="M182.62,151.1l-170.55-8S38.53,397.89,76.2,475.2c5.74,11.8,34.05,13.8,39.48,11.4,13-5.85,7.76-44.1,7.76-56.6C123.44,358.72,182.62,151.1,182.62,151.1Z"
        transform="translate(0)"
        fill={configuracion.raiz.fill}
        stroke={configuracion.raiz.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
      <path
        id="Nucleo"
        d="M97.16,199.79h0c6.87,0,37.8,1,36.29,6.83l-25.3,257.89c1.52,5.87-4,10.69-11,10.69h0c-6.88,0-12.51-4.82-12.51-10.69l-39-271.59C45.64,187.07,90.28,199.79,97.16,199.79Z"
        transform="translate(0)"
        fill={configuracion.nucleo.fill}
        stroke={configuracion.nucleo.stroke}
      />
      <path
        id="6"
        d="M122.22,248c.11,16,24.58,23.17,25.56,7,.62-10.3,8.41-42.24,15.75-63.62,4.42-12.87,9.46-20.89,8-18.94-12.81,16.78-43.22,39.56-57.72,40.95C106.78,214.06,122.17,241,122.22,248Z"
        transform="translate(0)"
        fill={getColor("6")}
      />
      <path
        id="7"
        d="M58.56,237.25c-.11,16-21.63,22.07-27.56,7-5.75-14.62-10.81-77.75-10.81-77.75s25.29,32,42.31,37.25C69.19,205.81,58.61,230.25,58.56,237.25Z"
        transform="translate(0)"
        fill={getColor("7")}
      />
      <path
        id="8"
        d="M153.18,226.2C138.07,267.72,66.06,274.13,35,229c-7.06-10.25-9.81-54.54-9.81-54.54C64.81,223,130,242.75,167.77,173.87,173.57,163.29,157.47,214.4,153.18,226.2Z"
        transform="translate(0)"
        fill={getColor("8")}
      />
      <path
        id="9"
        d="M155.45,211.38c-15.58,30.08-89.86,34.72-121.9,2C26.27,206,23.43,173.9,23.43,173.9c40.87,35.16,108.11,49.47,147.07-.42C176.48,165.81,159.88,202.83,155.45,211.38Z"
        transform="translate(0)"
        fill={getColor("9")}
      />
      <path
        id="Corona"
        d="M2.52,106.4C1.12,68.2,59,2.5,94.42,2.5c29,0,87,43.1,101.54,85.1a55.64,55.64,0,0,1-.89,38.65c-15.85,40.19-54.71,84.14-94.73,84.14C53.52,210.4,4.22,154.1,2.52,106.4Z"
        transform="translate(0)"
        fill={configuracion.corona.fill}
        stroke={configuracion.corona.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
    </svg>
  );
};
export default CaninoInf;
