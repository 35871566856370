import React from "react";
import Extraccion from "./Extraccion";
import { Grafico } from "./Grafico";
import { Icon } from "./Icon";
import "./style.css";
const IncisivoLateralInf = (props) => {
  const constrastColor = "blue";
  const defaultColor = "white";
  const configuracion = props.config;
  const getColor = (cara) => {
    const defaultColor = "#ffeca2";

    let color = defaultColor;
    if (props.config.caras) {
      let idx = props.config.caras.findIndex((it) => it.cara == cara);
      if (idx > -1) color = props.config.caras[idx].color;
    }
    return color;
  };
  return (
    <svg
      id="Incisor_inf"
      data-name="Incisor inf"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 159.21 402.37"
    >
      <title>incisivo_lateral_inf</title>
      <g id="Implante">
        <path
          d="M131.42,121.62,109.62,120l-49.25.47-32.62.7,1.49,31-1.49,36.05s19.36,7.29,21.66,11.84c1.17,2.3,0,10.31,0,10.31a78,78,0,0,0-9,3.39c-2.85,1.88-4.07,5-4.68,11.18-1,10,1.63,14.43,8.56,14.43a31.16,31.16,0,0,0,5.18-.79v9.57a42,42,0,0,0-5.38,2.32c-2.61,1.89-3.72,5-4.28,11.19-.93,10,1.49,14.42,7.82,14.42a12.63,12.63,0,0,0,1.84-.2V292a8.26,8.26,0,0,0-1.42,1c-3.16,2.83-3.82,5.12-3.82,12.13,0,7.35,1.59,11.29,5.24,12.44V333.9c-4.75,3.42-5.24,6.85-5.24,13,0,7.37,1.65,11.38,5.24,12.48v7.39a2.14,2.14,0,0,0,.57,1.46,1.82,1.82,0,0,0,.11.4c.57,1.43,1.13,16.84,8.86,24.86,7,5.43,21.32,7.06,24.18,7.06s14,1.05,24.24-8.73c7.19-6.87,7-18.64,7.37-22.33.13-.62.25-1.34.38-2.15a5.11,5.11,0,0,0,1.36-3.46V336.4a12.57,12.57,0,0,0,1.84-1c2.34-1.89,3.34-5,3.84-11.19.77-9.12-1-13.71-5.68-14.34V294.23c5.18-3.58,5.85-7.12,5.85-13.28,0-7.88-1.85-11.86-5.85-12.66V254.37A32.47,32.47,0,0,0,122,251.7c4.47-3.5,5-5,5-12.53,0-8.76-3.17-13.08-9.5-12.81a5.65,5.65,0,0,0-1,.17v-7.72a57.18,57.18,0,0,0,9.06-3.82c4.89-3.5,5.5-5,5.5-12.53,0-8.77-3.46-13.08-10.39-12.81a27.19,27.19,0,0,0-3.79.81c6.75-13.85,14.49-18,14.49-18Z"
          transform="translate(0 0)"
          fill={configuracion.implante.fill}
          stroke={configuracion.implante.strokeColor}
        />
      </g>
      <path
        id="Raiz"
        d="M138.56,115.08,20.9,116.46,61.25,364.25l6.86,28.41c2.3,9.53,13.56,9.65,16,.17L93,375.25Z"
        transform="translate(0 0)"
        fill={configuracion.raiz.fill}
        stroke={configuracion.raiz.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
      <path
        id="Nucleo"
        d="M76.3,170h0c4.87,0,20.15-5.2,19-.71L84,369.38c-1.25,4.95-3.6,11.29-8.5,11.29h0c-4.91,0-6.42-6.5-8.07-11.24h0l.1.28v-.43L63.13,169.9C63.17,165.43,71.45,170,76.3,170Z"
        transform="translate(0 0)"
        fill={configuracion.nucleo.fill}
        stroke={configuracion.nucleo.strokeColor}
      />
      <path
        id="6"
        d="M104.45,211.67l11.43,2.09,1.41-9,11.25-61.42c0-6.6-6.18-6-12.78-6h-11.4a12,12,0,0,0-12,12V199C92.36,205.66,97.45,210.58,104.45,211.67Z"
        transform="translate(0 0)"
        fill={getColor("6")}
      />
      <path
        id="7"
        d="M53.83,211.67l-11.42,2.09-1.41-9L29.75,143.33c0-6.6,6.18-6,12.78-6h11.4a12,12,0,0,1,12,12V199C65.93,205.66,60.83,210.58,53.83,211.67Z"
        transform="translate(0 0)"
        fill={getColor("7")}
      />
      <path
        id="8"
        d="M112.48,193.74c-18.52,4.71-53.82,9.79-68.15,0-2.93-2-5.34-11-6.41-13.11l-5.59-32.3c0-7,5.4-12.75,12-12.75h68.15c6.6,0,14.65,6,14.65,13l-5.71,32.74C121.42,188.35,118.89,192.11,112.48,193.74Z"
        transform="translate(0 0)"
        fill={getColor("8")}
      />
      <path
        id="9"
        d="M111.84,192.15c-18.51,4.43-53.81,9.21-68.15,0-2.92-1.88-5.33-10.33-6.41-12.34l-5.59-30.4a12,12,0,0,1,12-12h68.15c6.6,0,14.66,5.66,14.66,12.26l-5.72,30.81C120.78,187.08,118.26,190.61,111.84,192.15Z"
        transform="translate(0 0)"
        fill={getColor("9")}
      />
      <path
        id="Corona"
        d="M12.46,88.37C-20.08-18.13,33.55,4.52,80.36,4.52s98-8.12,66.76,83.75c-16.71,49.07-20,85.77-66.76,85.77S26.42,134.07,12.46,88.37Z"
        transform="translate(0 0)"
        fill={configuracion.corona.fill}
        stroke={configuracion.corona.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
    </svg>
  );
};

export default IncisivoLateralInf;
