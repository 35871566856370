import { auth, db } from '../../firebase/Config';
import { fetchUsuario } from '../usuarios';

export const obtenerDatosEmpresa = async (empresaId) => {
    try {
        const empresaSnapshot = await db.collection('empresas').doc(empresaId).get();

        if (empresaSnapshot.exists) {
            return empresaSnapshot.data();
        } else {
            console.log('No se encontraron datos en la colección empresas.');
            return null;
        }
    } catch (error) {
        console.error('Error al obtener datos de la empresa desde Firebase:', error);
        throw error;
    }
};

export const obtenerDatosAccesos = async (empresaId) => {
    try {
        const snapshot = await db.collection('accesos').where('empresaId', '==', empresaId).get();

        if (!snapshot.empty) {
            return snapshot.docs.map(doc => ({
                id: doc.id,
                perfiles: doc.data().perfil,
            }));
        } else {
            console.log('No se encontraron datos en la colección accesos.');
            return [];
        }
    } catch (error) {
        console.error('Error al obtener datos de accesos desde Firebase:', error);
        throw error;
    }
};

export const obtenerDatosUsuarios = async (empresaId) => {
    try {
        const snapshot = await db.collection('usuarios').where('empresaId', '==', empresaId).get();

        if (!snapshot.empty) {
            return snapshot.docs.map((doc) => doc.data());
        } else {
            console.log('No se encontraron datos en la colección usuarios.');
            return [];
        }
    } catch (error) {
        console.error('Error al obtener datos de usuarios desde Firebase:', error);
        throw error;
    }
};

export const deleteUsuario = async (usuario) => {
    try {

        //await db.collection('usuarios').doc(usuario.id).delete();
        await db.collection('usuarios').doc(usuario.id).set({empresaId: ""});
        

    } catch (error) {
        console.error('Error al eliminar usuario desde Firebase:', error);
        throw error;
    }
}


export const updateUsuario = async (usuario) => {
    try {
        console.log('usuario',usuario)
        await db.collection('usuarios').doc(usuario.id).set(usuario);
        
    } catch (error) {
        console.error('Error al eliminar usuario desde Firebase:', error);
        throw error;
    }
}

export const createUsuario = async (usuario) => {
    try {
        // verifica si existe el usuario
        let usr = await fetchUsuario(usuario.email)
        console.log('usr',usr)
        if (usr.id)
            if (usr.empresaId)
                throw new Error("El usuario ya existe")
            else
            {
                usr.empresaId = usuario.empresaId
                //await db.collection('usuarios').doc().set(usuario);
                updateUsuario(usr)
            }
        else {
            let userCredential
            try{

                userCredential = await auth.createUserWithEmailAndPassword(usuario.email, "123456");
                usr = {
                    ...usuario,
                    uid : userCredential.user.uid    
                    // Any other data you want to store about the user
                }
            }catch(ex){
                usr = {
                    ...usuario,
                    ...usr    
                    // Any other data you want to store about the user
                }
                console.log('cuenta ya existe')
            }
            const usuarioRef = db.collection('usuarios').doc();
        usr.id = usuarioRef.id
        await usuarioRef.set(usr);
        
        }
        return usr;

    } catch (error) {
        console.error('Error al crear usuario:', error);
        throw error;
    }
}

