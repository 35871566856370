import { db } from "../Config";

export const fetchAbonos = async (pacienteId) => {
  let movimientos = [];
  await db
    .collection("movimientos")
    .where("tipMov", "in", ["ABONO", "DEVOLUCION"])
    .where("pacienteId", "==", pacienteId)
    .orderBy("fecha", "desc")
    .limit(30)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        movimientos.push({
          id: doc.id,
          fecha: doc.data().fecha.toDate(),
          medio: doc.data().medio,
          monto: doc.data().monto,
          banco: doc.data().banco,
          numOpe: doc.data().numOpe,
          monedero: doc.data().monedero,
          usuario: doc.data().usuario,
          tipMov: doc.data().tipMov,
        });
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
  return movimientos;
};

export const fetchPagos = async (pacienteId) => {
  let movimientos = [];
  await db
    .collection("movimientos")
    .where("tipMov", "==", "PAGO_PRESTA")
    .where("pacienteId", "==", pacienteId)
    .orderBy("fecha", "desc")
    .limit(30)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        movimientos.push({
          id: doc.id,
          fecha: doc.data().fecha.toDate(),
          monto: doc.data().monto,
          prestacionNombre: doc.data().prestacionNombre,
          prestaciones: doc.data().prestaciones,
          usuario: doc.data().usuario,
        });
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
  return movimientos;
};

export const fetchAbonosMes = async (empresaId, fecIni) => {
  let movimientos = [];
  await db
    .collection("movimientos")
    .where("tipMov", "in", ["ABONO", "DEVOLUCION"])
    .where("empresaId", "==", empresaId)
    .where("fecha", ">=", fecIni)
    .where("estado", "==", "VIGENTE")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        movimientos.push({
          id: doc.id,
          fecha: doc.data().fecha.toDate(),
          medio: doc.data().medio,
          monto: doc.data().monto,
          banco: doc.data().banco,
          numOpe: doc.data().numOpe,
          monedero: doc.data().monedero,
          usuario: doc.data().usuario,
        });
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
  return movimientos;
};

export const fetchAbonosEmpresa = async (empresaId, fecIni, fecFin) => {
  let movimientos = [];
  await db
    .collection("movimientos")
    .where("tipMov", "in", ["ABONO", "DEVOLUCION"])
    .where("empresaId", "==", empresaId)
    .where("fecha", ">=", fecIni)
    .where("fecha", "<=", fecFin)
    .orderBy("fecha", "desc")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        movimientos.push({
          id: doc.id,
          fecha: doc.data().fecha.toDate(),
          medio: doc.data().medio,
          monto: doc.data().monto,
          banco: doc.data().banco,
          numOpe: doc.data().numOpe,
          monedero: doc.data().monedero,
          usuario: doc.data().usuario,
          tipMov: doc.data().tipMov,
          pacienteNombres: doc.data().pacienteNombres,
        });
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
  return movimientos;
};

export const getTotalAbonos = async (empresaId, fecha) => {
  let montos = [];
  let total = 0;
  let monto;
  await db
    .collection("movimientos")
    .where("empresaId", "==", empresaId)
    .where("tipMov", "in", ["ABONO", "DEVOLUCION"])
    .where("fecha", ">", fecha)
    .where("estado", "==", "VIGENTE")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        monto = doc.data().monto;
        monto = doc.data().tipMov == "ABONO"?monto:-monto;
        montos.push(monto);
      });
      if (montos.length > 0) total = montos.reduce((t, i) => t + i, 0);
    })

    .catch((err) => {
      throw new Error(err);
    });
  return total;
};
