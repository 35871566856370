import {
  FETCH_PACIENTES_REQUEST,
  FETCH_PACIENTES_SUCCESS,
  FETCH_PACIENTES_FAILURE,
  UPDATE_PACIENTE_IN_ARRAY,
  ADD_PACIENTE_IN_ARRAY,
} from "./pacientesTypes";

const initialState = {
  loading: false,
  error: null,
  items: [],
};

export const pacientesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PACIENTES_REQUEST:
      return { ...state, loading: true };
    case FETCH_PACIENTES_SUCCESS:
      return { loading: false, error: "", items: action.payload };
    case FETCH_PACIENTES_FAILURE:
      return { loading: false, error: action.payload, items: [] };
    case ADD_PACIENTE_IN_ARRAY:
      return { ...state, items: [...state.items, action.payload] };
    case UPDATE_PACIENTE_IN_ARRAY:
      const index = state.items.findIndex(
        (paciente) => paciente.id === action.payload.id
      );
      const newArray = [...state.items];
      newArray[index] = action.payload;
      return {
        ...state,
        items: newArray,
      };

    default:
      return state;
  }
};
