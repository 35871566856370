import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage.js";
export const PrivateRoute = ({ component: Component, ...rest }) => {
  //const user = auth.currentUser;
  const [ currentUser ]  = useLocalStorage('usuario');
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page

    <Route
      {...rest}
      render={(props) =>
        currentUser ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};
