import { db } from "../Config";

export const fetchDeudores = async (empresaId) => {
  let deudores = [];
  await db
    .collection("cuentas")
    .where("adeudado", ">", 0)
    .where("empresaId","==", empresaId)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        deudores.push({
          pacienteId: doc.data().pacienteId,
          adeudado: doc.data().adeudado,
          abonado: doc.data().saldo,
          paciente: doc.data().paciente
        });
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
  return deudores;
};
