import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  ContainerNav,
  NavMenu,
  NavMenuItem,
} from "./styles";

const MenuItem = ({ item }) => {
  return (
    <NavMenuItem>
      <NavLink
        exact
        to={item.url}
        className="nav-links"
        activeClassName="is-active"
      >
        {item.nombre}
      </NavLink>
    </NavMenuItem>
  );
};
export const TopBarPaciente = () => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const accesos = useSelector((state) => state.accesos);
  const [state, setState] = useState(false);
  const { isActive } = state;
  const sesion = useSelector((state) => state.sesion);
  //const opciones = getMenus(sesion.usuario.perfil, "TOP");
  return (
    <ContainerNav>
      <NavMenu
        id="customized-menu"
        className="container-scroll invisible-scrollbar"
      >
        {accesos.superior.map((item, index) => (
          <MenuItem key={index} item={item} />
        ))}
      </NavMenu>
    </ContainerNav>
  );
};
