import React from "react";

const CaninoSup = (props) => {
  const configuracion = props.config;

  const getColor = (cara) => {
    const defaultColor = "#ffeca2";
    let color = defaultColor;
    if (props.config.caras) {
      let idx = props.config.caras.findIndex((it) => it.cara === cara);
      if (idx > -1) color = props.config.caras[idx].color;
    }
    return color;
  };
  return (
    <svg
      id="Canino_sup"
      data-name="Canino sup"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 204.61 488.24"
    >
      <title>canino_sup</title>
      <path
        id="Implante"
        d="M27.89,324.14,69.41,327l61.24-.55,53.27-3.2L188,306.61l.34-15.42s-8-.63-9.67-1.57c-1.63-.78-10.15-13.82-18.67-28.75l-15.72-27.18v-12a98.22,98.22,0,0,0,11.18-3.95c3.55-2.2,5.07-5.81,5.83-13,1.27-11.62-2-16.81-10.64-16.81a41.24,41.24,0,0,0-6.45.92V177.63a53.75,53.75,0,0,0,6.69-2.7c3.24-2.2,4.63-5.81,5.33-13,1.16-11.63-1.86-16.81-9.73-16.81a16,16,0,0,0-2.29.23V126.58a10.27,10.27,0,0,0,1.77-1.14c3.93-3.3,4.75-6,4.75-14.14,0-8.57-2-13.16-6.52-14.5V77.71c5.91-4,6.52-8,6.52-15.11,0-8.59-2.06-13.27-6.52-14.55V39.44a2.41,2.41,0,0,0-.71-1.7,3.5,3.5,0,0,0-.14-.46c-.71-1.67-1.41-19.64-11-29C123.61,2,105.82,0,102.26,0S84.85-1.15,72.12,10.24c-8.94,8-8.65,21.73-9.17,26-.16.72-.31,1.56-.47,2.5a5.73,5.73,0,0,0-1.69,4v32a15.92,15.92,0,0,0-2.3,1.15c-2.9,2.2-4.14,5.81-4.76,13-1,10.63,1.29,16,7.06,16.72v18.24c-6.44,4.17-7.28,8.3-7.28,15.47,0,9.19,2.3,13.83,7.28,14.76v16.23A40.42,40.42,0,0,0,54,173.5c-5.56,4.08-6.25,5.81-6.25,14.61,0,10.21,3.94,15.24,11.81,14.92a8.1,8.1,0,0,0,1.2-.19v9c-5.73,1.94-9.8,3.51-11.27,4.45-6.08,4.08-6.84,5.81-6.84,14.61,0,10.21,4.31,15.24,12.93,14.93a36.2,36.2,0,0,0,4.71-1c-8.4,16.22-21.79,39.67-25.43,43.67-1.64,1.89-10.48,2.67-10.48,2.67L25,309.83Z"
        transform="translate(0 0)"
        fill={configuracion.implante.fill}
        stroke={configuracion.implante.strokeColor}
      />
      <path
        id="Raiz"
        d="M24.38,333.32H186.21S155.38,244.41,152.8,164.5C145.13,131.6,142.74,42.73,119.13,11,113.88,4,90.86,4.7,87.49,6.5,66.76,17.6,52,168.5,47.69,184.85,38.76,218.62,24.38,333.32,24.38,333.32Z"
        transform="translate(0 0)"
        fill={configuracion.raiz.fill}
        stroke={configuracion.raiz.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
      <path
        id="Nucleo"
        d="M103.4,308.38h0c-5,0-23.7,0-22.6-6.36L95.5,23.73c-1.1-6.35,2.9-11.57,7.9-11.57h0c5,0,9,5.12,9,11.44v.13L123.6,300.5C123.6,306.73,108.4,308.38,103.4,308.38Z"
        transform="translate(0 0)"
        fill={configuracion.nucleo.fill}
        stroke={configuracion.nucleo.strokeColor}
      />
      <path
        id="6"
        d="M114.2,280.28c2.71,13.71,60,57.09,59.72,43-.24-10.88-5.33-33.13-9.87-54.28-5.28-24.52-10.11-47.44-10.11-47.44H120.09S109.5,256.41,114.2,280.28Z"
        transform="translate(0 0)"
        fill={getColor("6")}
      />
      <path
        id="7"
        d="M86.43,280.28C83.66,294,30,338.84,30.33,324.75c.24-10.88,6.38-31.94,6.51-54.92a452,452,0,0,0,7.63-48.27H80.4S91.25,256.41,86.43,280.28Z"
        transform="translate(0 0)"
        fill={getColor("7")}
      />
      <path
        id="8"
        d="M177.73,324.75l-145-2.15,9.44-61.54c1.19-7.75,10.24-9.61,19.63-13.75,19.19-8.48,59.58-9.94,80.93-1,9,3.77,17.82,5.16,19.52,12.46Z"
        transform="translate(0 0)"
        fill={getColor("8")}
      />
      <path
        id="9"
        d="M179,324.75,30.8,322.81l9.64-55.53c1.22-7,10.48-8.67,20.08-12.41,19.61-7.66,60.9-9,82.72-.87,9.18,3.41,18.22,4.66,20,11.25Z"
        transform="translate(0 0)"
        fill={getColor("9")}
      />
      <path
        id="Corona"
        d="M202.08,381.73c.75,20.38-14.05,47.36-34.87,68.3-15.35,15.44-29.55,37.42-57,35.6-23.35-1.55-51.23-17.3-70-31.6C12,432.5-1.59,408,3.58,381.73c8.6-43.8,53.9-104,100.7-104S200.38,334,202.08,381.73Z"
        transform="translate(0 0)"
        fill={configuracion.corona.fill}
        stroke={configuracion.corona.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
    </svg>
  );
};
export default CaninoSup;
