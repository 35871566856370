import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
// import { auth, db } from "../../services/firebase/Config";
// import EventDetails from "../../components/CalendarioGoogle/EventDetails";
import CitaItem from "../../components/CitasDia";



export const CalendarioGoogle = () => {
  // const [usuario] = useLocalStorage('usuario')
  // const pacientesRef = db.collection("pacientes");
  // const user = auth.currentUser;
  // const [doctorName, setDoctorName] = useState("");
  // const [isLoading, setIsLoading] = useState(true);
  // const [showModal, setShowModal] = useState(false);
  // const [showSuccessModal, setShowSuccessModal] = useState(false);
  // const [selectedDate, setSelectedDate] = useState("");
  // const [startTime, setStartTime] = useState(0);
  // const [endTime, setEndTime] = useState(0);
  // const [events, setEvents] = useState([]);
  // const [showEventDetails, setShowEventDetails] = useState(false);
  // const [selectedEvent, setSelectedEvent] = useState(null);
  // const [listaPacientes, setListaPacientes] = useState([]);
  // const [datosCargados, setDatosCargados] = useState(false);
  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [showModalNotfi, setShowModalNoti] = useState(false);
  // const [notificationMessage, setNotificationMessage] = useState('');

  // const [showDayAppointments, setShowDayAppointments] = useState(false);
  // const [showCalendar, setShowCalendar] = useState(true);


  const [usuariofinal, setUsuario] = useState({
    token: "",
    empresaId: "",
  });

  // useEffect(() => {

  //   if (user) {
  //     if (user.displayName) {
  //       setDoctorName(user.displayName);
  //     }
  //   }

  //   exportloadCitasData()
  //     .then((eventos) => {
  //       setEvents(eventos);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.error("Error al cargar los eventos:", err);
  //     });

  //   const loadPacientesData = () => {
  //     pacientesRef
  //       .where("empresaId", "==", usuario.empresaId)
  //       .get()
  //       .then((snapshot) => {
  //         const listaPacientes = [];
  //         snapshot.forEach((doc) => {
  //           const paciente = doc.data();
  //           listaPacientes.push({
  //             id: doc.id,
  //             nombres: paciente.nombres,
  //             apellidos: paciente.apellidos,
  //             numDoc: paciente.numDoc,

  //           });
  //         });

  //         setListaPacientes(listaPacientes);
  //         console.log("lista pacientes", listaPacientes);
  //       });
  //   };

  //   if (!datosCargados) {
  //     exportloadCitasData();
  //     loadPacientesData();
  //     setDatosCargados(true);
  //   }

  // }, [datosCargados, usuario.empresaId, usuariofinal]);

  // const handleClose = () => {
  //   setShowModal(false);
  //   setSelectedDate("");
  //   setStartTime(0);
  //   setEndTime(0);
  // };

  // const handleShow = () => setShowModal(true);

  // const handleDateClick = (info) => {
  //   setSelectedDate(info.dateStr);
  //   handleShow();
  // };

  // const handleEventClick = (info) => {
  //   setSelectedEvent(info.event);
  //   setShowEventDetails(true);
  // };

  // const handleShowSuccessModal = () => {
  //   setShowSuccessModal(true);
  // };

  // const handleSaveEvent = () => {
  //   const selectedPacienteNombre = document.getElementById("formPaciente").value;

  //   const selectedPaciente = listaPacientes.find(
  //     (paciente) => paciente.nombres === selectedPacienteNombre
  //   );

  //   if (!selectedPaciente) {
  //     console.error("Paciente no encontrado");
  //     return;
  //   }

  //   const startSeconds = startTime;
  //   const endSeconds = endTime;

  //   const newStartTime = new Date(selectedDate + 'T00:00:00').getTime() + (startSeconds * 1000);
  //   const newEndTime = new Date(selectedDate + 'T00:00:00').getTime() + (endSeconds * 1000);


  //   const isOverlapping = events.some((event) => {
  //     const eventStartTime = new Date(event.start).getTime();
  //     const eventEndTime = new Date(event.end).getTime();

  //     return (
  //       (newStartTime >= eventStartTime && newStartTime < eventEndTime) ||
  //       (newEndTime > eventStartTime && newEndTime <= eventEndTime) ||
  //       (newStartTime <= eventStartTime && newEndTime >= eventEndTime)
  //     );
  //   });


  //   if (isOverlapping) {
  //     setNotificationMessage("La nueva cita se solapa con una cita existente.");
  //     setShowModalNoti(true);
  //   } else {
  //     setNotificationMessage("Cita guardada con éxito.");
  //     setShowModal(true);
  //   }


  //   const formData = {
  //     paciente: selectedPacienteNombre,
  //     fecha: selectedDate,
  //     horaInicio: startTime,
  //     horaFin: endTime,
  //     telefono: phoneNumber,
  //     descripcion: document.getElementById("formDescripcion").value,
  //   };

  //   handleSaveCita(formData, listaPacientes, doctorName)
  //     .then((citaID) => {
  //       console.log("ID de la cita actualizado con éxito");
  //       handleClose();
  //       handleShowSuccessModal();
  //       exportloadCitasData()
  //         .then((eventos) => {
  //           setEvents(eventos);
  //           setIsLoading(false);
  //         });
  //     })
  //     .catch((error) => {
  //       console.error("Error al guardar la cita:", error);
  //       handleClose();
  //     });
  // };

  // const handleDeleteEvent = (event) => {
  //   if (!event) {
  //     console.error("No se ha seleccionado un evento válido para eliminar.");
  //     return;
  //   }

  //   const citaId = event.extendedProps.citaId;

  //   if (!citaId) {
  //     console.error("No se encontró el ID de la cita.");
  //     return;
  //   }

  //   handleDeleteCita(citaId)
  //     .then(() => {
  //       handleCloseDetails();
  //       exportloadCitasData()
  //         .then((eventos) => {
  //           setEvents(eventos);
  //           setIsLoading(false);
  //         })
  //     })
  //     .catch((error) => {
  //       console.error("Error al eliminar la cita:", error);
  //     });
  // };

  // const handleCloseDetails = () => {
  //   setShowEventDetails(false);
  //   setSelectedEvent(null);
  //   exportloadCitasData()
  //     .then((eventos) => {
  //       setEvents(eventos);
  //       setIsLoading(false);
  //     })
  // };
  // const handleCloseModal = () => {
  //   setShowModalNoti(false);
  // };

  return (
    <>
      <div className={styles.calendarContainer}>

        <CitaItem />


        {/* {showDayAppointments ? (
          <CitaItem />
        ) : (
          <>
            <Calendar
              handleDateClick={handleDateClick}
              handleEventClick={handleEventClick}
              events={events}
            />
            <AppointmentForm
              showModal={showModal}
              handleClose={handleClose}
              handleShow={handleShow}
              handleSaveEvent={handleSaveEvent}
              listaPacientes={listaPacientes}
              doctorName={doctorName}
              selectedDate={selectedDate}
              startTime={startTime}
              setStartTime={setStartTime}
              endTime={endTime}
              setEndTime={setEndTime}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
            />
          </>
        )} */}
        {/* <NotificationModal
          show={showModalNotfi}
          onHide={handleCloseModal}
          message={notificationMessage}
        /> */}

        {/* {selectedEvent && (
          <EventDetails
            event={selectedEvent}
            onClose={handleCloseDetails}
            onDelete={handleDeleteEvent}
          />
        )} */}
      </div>
    </>
  );

};