import React, { useEffect, useState } from "react";

// import { auth } from "../services/firebase/Config";
// import Spinner from "react-bootstrap/Spinner";
import { useLocalStorage } from "../hooks/useLocalStorage";
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  //const [currentUser, setCurrentUser] = useState(null);
  const [currentUser] = useLocalStorage('usuario');

  // useEffect(() => {
  //   auth.onAuthStateChanged((user) => {
  //     setCurrentUser(user);
  //   });
  //   setPending(false);
  // }, []);

  // if (pending) {
  //   return (
  //     <>
  //       <Spinner animation="border" role="status">
  //         <span className="visually-hidden"></span>
  //       </Spinner>
  //     </>
  //   );
  // }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
