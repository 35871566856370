import React from "react";
import { Modal } from "../../../../components/Modal/index";
import { useToasts } from "react-toast-notifications";

import { modificarPrecioTrx } from "../../../../redux/prestation/prestationActions";

import { useDispatch, useSelector } from "react-redux";
import Spinner from 'react-bootstrap/Spinner'
import {
  MainContainer,
  ContainerTitle,
  ContainerTitleText,
  Buttons,
  LinkCancelar,
  ButtonPagar,
  StyledInput,
  ContainerContent,
} from "./styles";

export const ModificarPrecioModal = (props) => {
  const prestacion = useSelector((state) => state.prestacion);
  const sesion = useSelector((state) => state.sesion);
  const dispatch = useDispatch();
  const cuenta = useSelector((state) => state.cuenta);
  const [precio, setPrecio] = React.useState();
  const { addToast } = useToasts();

  const handleCloseModal = (e) => {
    props.handleCloseModal();
  };

  const handleSave = () => {
    try {
      validar(prestacion);
      dispatch(modificarPrecioTrx(prestacion, cuenta, precio));
      addToast("Operación Satisfactoria", { appearance: "success" });
      setPrecio();
      props.handleCloseModal();
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const validar = (prestacion) => {
    if (precio < prestacion.pagado)
      throw new Error("Precio debe ser mayor o igual a lo pagado");
    if (precio <= 0) throw new Error("Precio debe ser mayor a cero");
  };

  if (sesion.loading) {
    return <Spinner animation="border" role="status">
      <span className="visually-hidden"></span>
    </Spinner>;
  } else if (sesion.error) {
    return <div>error </div>;
  } else
    return (
      <Modal isOpen={props.isOpen} onClose={handleCloseModal}>
        <React.Fragment>
          <ContainerTitle>
            <ContainerTitleText>
              {`Modificar Precio: ${prestacion.tratamiento} [${prestacion?.dientes?.length > 0
                ? prestacion?.dientes?.join()
                : ""
                }]`}
            </ContainerTitleText>
          </ContainerTitle>

          <MainContainer>
            <ContainerContent>
              <div className="d-flex justify-content-center">
                <label>
                  Precio Actual:

                </label> <span><bold style={{ fontSize: "18px", color: "#045184", fontWeight: "bold" }}>{prestacion.precio}</bold></span>
              </div>
              <div className="text-end">
                <label>
                  Nuevo Precio:</label>
                <StyledInput
                  type="text"
                  name="precio"
                  onChange={(e) => setPrecio(e.target.value)}
                  value={precio}
                  autoComplete="off"
                ></StyledInput>
              </div>
            </ContainerContent>


            <Buttons>
              <LinkCancelar className="btn" onClick={handleCloseModal}>
                Cancelar
              </LinkCancelar>
              <ButtonPagar className="btn" onClick={handleSave}>
                Guardar
              </ButtonPagar>
            </Buttons>
          </MainContainer>
        </React.Fragment>
      </Modal>
    );
};
