import { db, auth } from "../Config";

export const fetchUsuario = async (email) => {
  let usuario = {};
  await db
    .collection("usuarios")
    .where("email", "==", email)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        usuario = doc.data()
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
  return usuario;
};

export const fetchUser = async (email) => {
  return new Promise((resolve, reject) => {
    let usuario = {};
    db.collection("usuarios")
      .where("email", "==", email)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          usuario = doc.data();
        });
        resolve(usuario);
      })
      .catch((err) => {
        console.log('falta permiso', err)
        reject(err);
      });
  });
};

export const saveUser = (usuario) => {
  return new Promise((resolve, reject) => {
    var usuariosRef = db.collection("usuarios").doc(usuario.id);
    usuariosRef
      .set(usuario)
      .then(() => {
        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const creaUsuario = (email, cargo, nombres, perfil, empresaId, uid) => {
  var usuario = {
    cargo: cargo,
    email: email,
    empresaId: empresaId,
    fecha: new Date(),
    nombres: nombres,
    perfil: perfil,
  };

  var usuariosRef = db.collection("usuarios").doc(uid);
  usuariosRef
    .add(usuario)
    .then(() => {})
    .catch((err) => {
      console.log("error al guardar" + err);
    });
};
