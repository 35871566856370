import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { guardarReceta } from "../../redux/receta/recetaActions";
import { ItemReceta, Receta as RecetaObj } from "../../Entidades/Recetas";
import { traerRecetas } from "../../redux/recetas/recetasActions";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import {
  ContainerBody,
  ContainerLeft,
  ContainerTitle,
  ContainerTitleText,
  ContainerForm,
  MainContainer,
  StyledButton,
  StyledInputIndex,
  StyledInput,
  StyledLink,
  StyledGuardar,
  StyledInputButton,
  DivForm,
  FlexContainer,
} from "./styles";
import Spinner from "react-bootstrap/Spinner";
import { useHistory, Link, Redirect } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import PostAddIcon from "@material-ui/icons/PostAdd";
import { useToasts } from "react-toast-notifications";

export const Receta = () => {
  const itemReceta = {
    medicina: "",
    concentracion: "",
    formato: "",
    cantidad: "",
    dosisYFrecuencia: "",
    duracion: "",
  };
  const [inputFields, setInputFields] = React.useState([itemReceta]);
  const paciente = useSelector((state) => state.paciente);
  const sesion = useSelector((state) => state.sesion);
  const usuario = useSelector((state) => state.usuario);
  const empresa = useSelector((state) => state.empresa);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const handleAddItem = () => {
    setInputFields([...inputFields, itemReceta]);
  };

  const handleRemoveItem = (index) => {
    const items = [...inputFields];
    items.splice(index, 1);
    setInputFields(items);
  };
  const history = useHistory();
  const handleChange = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const receta = {
      pacienteId: paciente.id,
      pacienteNombres: `${paciente.nombres} ${paciente.apellidos}`,
      fecha: new Date(),
      especialista: usuario.data.email,
      especialistaNombres: usuario.data.nombres,
      especialistaCop: usuario.data.cop ? usuario.data.cop : "",
      especialistaId: usuario.data.id,
      items: inputFields,
      empresaId: empresa.id,
      empresaLogo: empresa.logoUrl,
      empresaRazonSocial: empresa.razonSocial,
      empresaTelefono: empresa.telefono,
      empresaRuc: empresa.ruc,
    };
    try {
      dispatch(guardarReceta(receta));
      addToast("Receta guardada satisfactoriamente", { appearance: "success" });
      history.push("recetas");
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  if (sesion.loading || sesion.loading === undefined)
    return (
      <MainContainer>
        <Spinner animation="border" role="status">
          <span className="visually-hidden"></span>
        </Spinner>
      </MainContainer>
    );
  if (sesion.error) return <div>{`ocurrio un error ${sesion.error} `}</div>;

  return (
    <MainContainer>
      <ContainerTitle>
        <ContainerTitleText>Receta Odontol&oacute;gica</ContainerTitleText>
      </ContainerTitle>

      <ContainerBody>
        <FlexContainer>
          <ContainerTitleText>
            {`Paciente: ${paciente.apellidos} ${paciente.nombres}`}
          </ContainerTitleText>
          <ContainerTitleText>{`Fecha: ${sesion.fecha}`}</ContainerTitleText>
        </FlexContainer>
        <ContainerLeft>
          <IconButton
            onClick={handleAddItem}
            style={{
              color: "#0060b0",
              paddingBottom: "0",
            }}
            size="large"
          >
            <PostAddIcon style={{ width: "1.8em", height: " 1.8em" }} />
          </IconButton>
        </ContainerLeft>
        <DivForm onSubmit={handleSubmit}>
          {inputFields.map((inputField, index) => (
            <ContainerForm key={index}>
              <StyledInput
                type="text"
                name="medicina"
                placeholder="medicina"
                autoComplete="off"
                value={inputField.medicina}
                onChange={(event) => handleChange(index, event)}
              ></StyledInput>
              <StyledInput
                type="text"
                name="concentracion"
                placeholder="concentración"
                autoComplete="off"
                value={inputField.concentracion}
                onChange={(event) => handleChange(index, event)}
              ></StyledInput>
              <StyledInput
                type="text"
                name="formato"
                placeholder="presentación"
                autoComplete="off"
                value={inputField.formato}
                onChange={(event) => handleChange(index, event)}
              ></StyledInput>
              <StyledInput
                type="text"
                name="cantidad"
                placeholder="cantidad"
                autoComplete="off"
                value={inputField.cantidad}
                onChange={(event) => handleChange(index, event)}
              ></StyledInput>
              <StyledInput
                type="text"
                name="dosisYFrecuencia"
                placeholder="dosis y frecuencia"
                autoComplete="off"
                value={inputField.dosisYFrecuencia}
                onChange={(event) => handleChange(index, event)}
              ></StyledInput>
              <StyledInput
                type="text"
                name="duracion"
                placeholder="duración"
                autoComplete="off"
                value={inputField.duracion}
                onChange={(event) => handleChange(index, event)}
              ></StyledInput>
              <IconButton aria-label="" onClick={() => handleRemoveItem(index)}>
                <Tooltip
                  TransitionComponent={Zoom}
                  placement="top"
                  title="Eliminar"
                >
                  <DeleteIcon style={{ color: "red" }} />
                </Tooltip>
              </IconButton>
            </ContainerForm>
          ))}
          <FlexContainer>
            <ContainerTitleText>
              {`C.D. ${sesion.usuario.nombres}`}
            </ContainerTitleText>
            <ContainerTitleText>{`C.O.P. ${usuario.data.cop}`}</ContainerTitleText>
          </FlexContainer>
          <ContainerLeft className="buttons">
            <StyledLink to="recetas">CANCELAR</StyledLink>
            <StyledInputButton type="submit" value="GUARDAR" />
          </ContainerLeft>
        </DivForm>
      </ContainerBody>
    </MainContainer>
  );
};
