import styled from "styled-components";
import { dispositivo } from "../../../components/Dispositivos";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

export const TablaNota = styled.table`
  justify-content: center;
  margin-left: 1%;
  margin-bottom: 1%;
  @media ${dispositivo.mobileL} {
    margin-left: 3%;
    margin-bottom: 2%;
  }
  @media ${dispositivo.tablet} {
    margin-left: 5%;
    margin-bottom: 3%;
  }
`;

export const LabelNota = styled.td`
  text-align: left;
`;

export const FlowContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  gap:1rem;
  @media ${dispositivo.tablet} {
    width: 60%;
  }
`;
export const Select = styled.select`
  max-width: 200px;
`;
export const ContainerBtn = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  justify-content: end;
  .linkP{
    padding-top: 0.8em;
  }
`;
export const AddButton = styled(Button)`
  && {
    height: 2.25rem;
    // width: 100%;
    border-radius: 1em;      
    text-transform: uppercase;
    cursor: pointer;
    font-width: bold;
    font-size: 0.9rem;
    background-color: #ffffff;
    border: 2px solid #0060b0;
    color: #0060b0;
    &:hover {
      color:#000000;
      background-color: #0c8de390;
      font-width: bold;
      border: none;
    }  
  
    @media ${dispositivo.mobileS}{
      font-size: 0.9rem;
      padding: 0.1em;
      // width: 9rem;
    } 
    @media ${dispositivo.tablet}{
      // width: 10rem;
    }  
    @media ${dispositivo.laptop} {      
      // width: 12.5rem;
    }
  }
`;

export const ButtonActionNota = styled(Button)`
  && {
    background-color: #0c8de3;
    border: none;
    color: white;
    text-align: center;
    font-size: 10px;
    margin: 2%;
    padding-bottom: 0.8%;
    border-radius: 4px;
    transition: 0.3s;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    @media ${dispositivo.mobileL} {
      font-size: 12px;
    }
    @media ${dispositivo.tablet} {
      font-size: 14px;
    }
  }
`;

export const ContainerNuevaNota = styled.div`
  justify-content: center;
`;

export const ContainerNuevaNotaTitulo = styled.div`
  text-align: center;
  color: #707070;
  background: #fff;
  padding: 1% !important;
`;

export const TextArea = styled.textarea`
  width: 99%;
`;

///////////
export const MainContainer = styled.div`
  height: 100%;
  justify-content: center;
  text-align: center;
`;

export const ContainerTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  text-align: center;
`;

export const ContainerTitleText = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 1.5%;
  background-color: #0c8de3;
  color: white;
  border-radius: 10px;
`;

export const ButtonPagar = styled.button`
height: 2.25rem;
margin-top: 1rem;
border-radius: 1em;      
text-transform: uppercase;
cursor: pointer;
font-width: bold;
font-size: 0.9rem;
background-color: #0060b0;
color: #ffffff;
font-size: 0.9rem;
border: none;
&:hover{
  color:#000000;
  background-color: #0c8de390;
  font-width: bold;
}
@media ${dispositivo.mobileS}{
  font-size: 0.9rem;
  padding: 0.1em;
  width: 9rem;
} 
@media ${dispositivo.tablet}{
  width: 10rem;
}  
@media ${dispositivo.laptop} {      
  width: 12.5rem;
}
`;
export const LinkCancelar = styled.button`
  height: 2.25rem;
  margin-top: 1rem;
  width: 100%;
  border-radius: 1em;      
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  font-size: 0.9rem;
  background-color: #ffffff;
  border: 2px solid #0060b0;
  color: #0060b0;
  &:hover {
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
    border: none;
  }  

  @media ${dispositivo.mobileS}{
    font-size: 0.9rem;
    padding: 0.1em;
    width: 9rem;
  } 
  @media ${dispositivo.tablet}{
    width: 10rem;
  }  
  @media ${dispositivo.laptop} {      
    width: 12.5rem;
  }
`;
export const ButtonInsideContainer = styled.div`
  position: relative;
  margin-bottom: 10px;
  border: 1px solid #000;
`;
export const InputButton = styled.input`
  height: 25px;
  width: 100%;
  padding-left: 1em;
  border-radius: 4px;
  border: none;
  outline: none;
`;

export const ButtonInside = styled.button`
  //position: absolute;
  right: 0px;
  top: 4px;
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  outline: none;
  text-align: center;
  font-weight: bold;
  padding: 2px;
  &:hover {
    cursor: pointer;
  }
`;

export const ItemContainer = styled.div`
  display: grid;  
  grid-template-columns: 1fr 2fr 24px;
  align-items: center;
  justify-content: center;    
  gap: 0.5rem;
  padding: 2% 0 0;
  @media ${dispositivo.tablet} {
    grid-template-columns: 1fr 2fr 24px;
  }
`;
export const ItemContainerEnviar = styled.div`
  display: grid;  
  grid-template-columns: 1fr 1fr 100px;
  align-items: center;
  justify-content: center;    
  gap: 0.5rem;
  padding: 2% 0 0;
  .divEnviar{
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
}
  }
  @media ${dispositivo.tablet} {
    grid-template-columns: 1fr 2fr 115px;
  }
`;
export const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  justify-content: center;
  text-align: center;
`;

export const StyledForm = styled.form`
  width: 90%;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const Tabla = styled.table`
  width: 100%;
  justify-content: center;
  text-align: center;
`;

export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
  justify-content: center;
  text-align: center;
`;
export const TablaHeaderTexto = styled.th`
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;

export const TablaHeaderTextoItems = styled.th`
  font-size: 12px;
  font-weight: normal;
  color: #040404;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  justify-content: center;
  text-align: left;
  width: 50%;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;
export const TablaHeaderTextoValues = styled.th`
  font-size: 12px;  
  font-weight: normal;
  color: #707070;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  justify-content: center;
  text-align: right;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;
export const TablaHeaderTextoButtons = styled.th`
  font-size: 12px;
  font-weight: normal;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: center;
  text-align: center;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const TablaHeaderTextoNumber = styled.th`
  font-size: 12px;  
  font-weight: normal;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: right;
  text-align: right;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;


export const TablaBody = styled.tbody`
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
  vertical-align: middle;
`;
export const TablaBody2 = styled(TablaBody)`
  border: 2px solid #ccc;
`;

export const TablaBodyTexto = styled.th`
  font-size: 12px;
  color: #474747;
  padding: 8px;
  vertical-align: middle;
  text-align: left;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const SelectStyle = styled.select`
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  line-height: 2.5;
  font-size: 0.75rem;
  height: 2.5rem;
  width: 12.5rem;
  text-align: end;
  &::placeholder {
  color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }

  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;

export const StyledInput = styled.input`
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  margin: 0 0 0.8em;
  line-height: 2.5;
  font-size: 0.75rem;
  width: 100%;
  heigth: 2rem;
  padding-left: 0.5em;
  &::placeholder {
  color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.mobileL} {
    font-size: 1rem;
  }
`;
export const StyledInput2 = styled(StyledInput)`
width: 12.5rem;
`;
export const StyledInputNumber = styled(StyledInput)`
  text-align: end;
  padding-right: 1em;
  width: 12.5rem;
`;

export const TablaButtons = styled.table`
  width: 90%;
  justify-content: center;
  text-align: center;
  @media ${dispositivo.tablet} {
    width: 100%;
  }
`;

export const ContainerBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 2%;
  background: #fff;
  display: block;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid #0c8de3;
`;

export const Textarea = styled.textarea`
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  margin: 0 0 1em;
  padding-left: 1em;
  line-height: 2.5;  
  font-size: 0.75rem;
  height: 125px;
  width: 100%;
  &::placeholder {
    color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
  `;
export const Textarea2 = styled(Textarea)`
  width: 100%;
  `;
export const Dientelab = styled.div`
  font-weight: bolder;
  font-size: 12px;
  color: #040404;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  justify-content: center;
  text-align: left;
  width: 100%;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
  `;
export const StyledInputTabla = styled.input`
  width: 100%;
  color:  #474747;
  vertical-align: middle;
  text-align: center;
  border-color: #dcf2f9;
  &:focus {
    outline: none;
  }
  @media ${dispositivo.mobileM}{
    width: 80%;
  }
`;
export const TextareaTabla = styled.textarea`
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  margin: 0 0 1em;
  padding-left: 1em;
  line-height: 2.5;  
  font-size: 0.75rem;
  height: 100px;
  width: 100%;
  &::placeholder {
    color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
  `;