import React from "react";

const PreMolarInf = (props) => {
  const configuracion = props.config;

  const defaultColor = "#ffeca2";

  const getColor = (cara) => {
    let color = defaultColor;
    if (props.config.caras) {
      let idx = props.config.caras.findIndex((it) => it.cara == cara);
      if (idx > -1) color = props.config.caras[idx].color;
    }
    return color;
  };

  return (
    <svg
      id="Premolar_inf"
      data-name="Premolar inf"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 183.36 426.96"
    >
      <title>premolar_inf</title>
      <g id="Implante">
        <path
          d="M163.72,146.33l-30.3-1.62L65,145.18l-45.35.7,2.07,31-2.07,36s26.92,7.28,30.11,11.83c1.62,2.29,0,10.29,0,10.29-6.74,1.64-11.29,2.87-12.49,3.39-4,1.88-5.67,5-6.52,11.17C29.3,259.53,33,264,42.62,264a58,58,0,0,0,7.21-.79v9.57a70.22,70.22,0,0,0-7.48,2.31c-3.63,1.89-5.18,5-6,11.18-1.29,10,2.08,14.4,10.88,14.4a24.3,24.3,0,0,0,2.56-.2v16a14.93,14.93,0,0,0-2,1c-4.39,2.83-5.31,5.12-5.31,12.12,0,7.35,2.21,11.28,7.29,12.43v16.36c-6.61,3.42-7.29,6.84-7.29,12.95,0,7.36,2.3,11.37,7.29,12.47v7.38a1.87,1.87,0,0,0,.78,1.45c.05.14.1.27.16.4.8,1.43,1.58,16.83,12.31,24.84,9.75,5.42,29.64,7.05,33.62,7.05s19.45,1,33.68-8.72c10-6.86,9.67-18.62,10.26-22.31.17-.61.34-1.34.51-2.14a4.41,4.41,0,0,0,1.9-3.46V360.87a21,21,0,0,0,2.57-1c3.24-1.89,4.63-5,5.32-11.18,1.08-9.11-1.44-13.7-7.89-14.32V318.76c7.2-3.58,8.13-7.12,8.13-13.27,0-7.87-2.57-11.85-8.13-12.64V278.93a51.63,51.63,0,0,0,7.55-2.66c6.22-3.5,7-5,7-12.52,0-8.75-4.4-13.06-13.21-12.79a11.79,11.79,0,0,0-1.33.17v-7.71c6.4-1.67,11-3,12.59-3.82,6.8-3.5,7.65-5,7.65-12.52,0-8.75-4.82-13.06-14.45-12.79a51.78,51.78,0,0,0-5.26.81c9.38-13.84,20.14-18,20.14-18Z"
          transform="translate(-0.04)"
          fill={configuracion.implante.fill}
          stroke={configuracion.implante.strokeColor}
        />
      </g>
      <path
        id="Raiz"
        d="M153.22,142.1H31.3a7.6,7.6,0,0,0-7,10.65C32.58,171.64,48.74,213,52.79,255c5.11,53,26.66,162.3,26.66,162.3,1.8,7.35,9.11,9.08,13.35,5a8.37,8.37,0,0,1,6.52-2.3c2.93.23,7.1-2.87,11.12-18,8-30.29,15.75-100.7,18.2-118.72,6.08-44.68,24.51-107.41,31.85-131.36a7.6,7.6,0,0,0-7.27-9.82Z"
        transform="translate(-0.04)"
        fill={configuracion.raiz.fill}
        stroke={configuracion.raiz.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
      <path
        id="Nucleo"
        d="M88,189.5h0c5,0,23.7,0,22.6,4.75L100.17,402.67c1.1,4.75-5,9.16-10,9.16L88,411c-1.81-.22-2.31-2.75-3-8h0L67.76,195.4C67.76,190.65,83,189.5,88,189.5Z"
        transform="translate(-0.04)"
        fill={configuracion.nucleo.fill}
        stroke={configuracion.nucleo.strokeColor}
      />
      <path
        id="6"
        d="M113.67,239.5c-.3,10.87,16.07,8.42,16.72-.33l22.5-81-34.52,4.35S114.06,225.11,113.67,239.5Z"
        transform="translate(-0.04)"
        fill={getColor("6")}
      />
      <path
        id="7"
        d="M65.83,247.67c0,3.15-9.15.65-9.16-2.17-.1-17.66-18-71.16-18-72.65,0-2,22.65,2.34,22.65,2.34S65.83,233.27,65.83,247.67Z"
        transform="translate(-0.04)"
        fill={getColor("7")}
      />
      <path
        id="8"
        d="M138.52,200.87c-14.11,17.9-81.5,23.44-90.69,2.57-6.43-14.6-16.53-58.73-16.53-58.73l124.2,1.58S146.33,190.94,138.52,200.87Z"
        transform="translate(-0.04)"
        fill={getColor("8")}
      />
      <path
        id="9"
        d="M138.52,198.26c-14.11,17.9-81.5,23.44-90.69,2.57C41.4,186.23,31.3,142.1,31.3,142.1l124.2,1.58S146.33,188.33,138.52,198.26Z"
        transform="translate(-0.04)"
        fill={getColor("9")}
      />
      <path
        id="Corona"
        d="M4,96.6C-5.5,59.6,32.7,2.5,81.8,2.5c56.2,0,106.5,49.2,98.2,93s-47.5,104-92.5,104S16.12,144,4,96.6Z"
        transform="translate(-0.04)"
        fill={configuracion.corona.fill}
        stroke={configuracion.corona.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
    </svg>
  );
};
export default PreMolarInf;
