import React from "react";
import { useHistory } from "react-router-dom";
import { ConfirmacionSiNoModal } from "../../components/ConfirmacionSiNoModal/index";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrdenesAdeudadas } from "../../services/firebase/ordenes";
import { getListas } from "../../redux/listasEmpresa/listasActions";

import {
  MainContainer,
  ContainerTitle,
  LinkCancelar,
  StyledForm,
  StyledOption,
  ButtonAbonar,
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  TablaBodyTexto,
  SelectStyle,
  StyledInput,
  StyledLabel,
  ContainerTitleText,
  ContainerBtn,
  TrBody,
} from "./styles";
import { useToasts } from "react-toast-notifications";
import { pagarLab } from "../../redux/labs/labsActions";

const Fila = ({ item }) => {
  return (
    <tr key={item.id}>
      <TablaBodyTexto data-label="FecEnvio">{item.fecEnv}</TablaBodyTexto>
      <TablaBodyTexto data-label="Prestacion">{item.numCor}</TablaBodyTexto>
      <TablaBodyTexto data-label="Cliente">
        {item.pacienteNombres}
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Deuda">
        {item.precio - item.pagado}
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Estado">{item.estado}</TablaBodyTexto>
    </tr>
  );
};

export const OrdenesTable = (props) => {
  return (
    <Tabla>
      <TablaHeader>
        <tr>
          <TablaHeaderTexto scope="col">Fec. Envio</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Orden</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Cliente</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Deuda</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Estado</TablaHeaderTexto>
        </tr>
      </TablaHeader>
      <TablaBody>
        {props.items.map((item) => {
          return <Fila key={item.id} item={item} />;
        })}
      </TablaBody>
    </Tabla>
  );
};

const useDeuda = (ordenes) => {
  if (!ordenes || ordenes.length === 0) return 0;

  let deuda = ordenes
    .map((orden) => orden.precio - orden.pagado)
    .reduce((total, saldo) => total + saldo, 0);
  return deuda;
};
export const PagoLab = ({ location }) => {
  const history = useHistory();
  const sesion = useSelector((state) => state.sesion);
  const lab = location.state.data;
  const [form, setForm] = React.useState({ medio: "EFECTIVO", monto: 0 });
  const listas = useSelector((state) => state.listas);

  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const handleCloseModal = (e) => {
    setModalIsOpen(false);
  };
  const [estado, setEstado] = React.useState({
    loading: true,
    data: [],
    error: "",
  });
  const deuda = useDeuda(estado.data);

  React.useEffect(() => {
    setEstado({ ...estado, loading: true });
    dispatch(getListas(sesion.usuario.empresaId));
    fetchOrdenesAdeudadas(lab.id, sesion.usuario.empresaId)
      .then((ordenes) => {
        setEstado({ ...estado, loading: false, data: ordenes });
      })
      .catch((err) => setEstado({ ...estado, loading: false, error: err }));
  }, []);

  const handleOpenModal = (e) => {
    e.preventDefault();
    // TODO: Insertar la transaccion de movimiento, cambiar el estado a pagado, actualizar el
    const myForm = document.getElementById("pagolab");
    try {
      myForm.checkValidity();
      myForm.reportValidity();
      validar();
      if (myForm.checkValidity() && myForm.reportValidity()) {
        setModalIsOpen(true);
        //history.push("/prestaciones");
      }
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const handlePayment = (e) => {
    try {
      e.preventDefault();
      validar();
      let pago = {
        fecOpe: sesion.fecha,
        labId: lab.id,
        medio: form.medio,
        monto: Number(form.monto),
        usuario: sesion.usuario.email,
        moneda: "SOLES",
        empresaId: sesion.usuario.empresaId,
      };
      if (form.medio === "BANCO")
        pago = { ...pago, numOpe: form.numOpe, banco: form.banco };
      if (form.medio === "MONEDERO")
        pago = { ...pago, monedero: form.monedero };

      setModalIsOpen(false);
      dispatch(pagarLab(pago, estado.data));
      actualizaEstadoOrdenes(pago.monto);
      addToast("Operacion satisfactoria", { appearance: "success" });
      history.push("/admin/laboratorios/deudas");
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const actualizaEstadoOrdenes = (montoPago) => {
    let ordenes = estado.data;
    let estFin = "CANCELADO";
    let saldoTotal = montoPago;
    let continuar = ordenes.length > 0 ? true : false;
    let i = 0;
    while (continuar) {
      let orden = ordenes[i];
      let saldo = Number(orden.precio) - Number(orden.pagado);
      if (saldoTotal < saldo) {
        saldo = saldoTotal;
        estFin = "PENDIENTE";
      }
      saldoTotal -= saldo;
      orden.pagado = orden.pagado + saldo;
      orden.estFin = estFin;
      if (saldoTotal === 0 || i === ordenes.length - 1) continuar = false;
      i++;
    }
    setEstado({ ...estado, data: ordenes });
  };

  const validar = () => {
    let numerosExp = /^\d+([.]\d{1,2})?$/;
    if (!numerosExp.test(form.monto)) {
      throw new Error("Ingrese un monto válido");
    }
    let monto = Number(form.monto);
    if (monto <= 0) throw new Error("Monto inválido");
    if (monto > deuda) throw new Error("Monto no debe ser mayor a la deuda");
  };
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <MainContainer>
      <ContainerTitle>
        <ContainerTitleText>Pago a Laboratorio</ContainerTitleText>
      </ContainerTitle>
      <div>
        <StyledForm id="pagolab" onSubmit={handlePayment}>
          <Tabla>
            <TablaBody>
              <TrBody>
                <TablaBodyTexto>
                  <StyledLabel for="laboratorio">Laboratorio </StyledLabel>
                </TablaBodyTexto>
                <TablaBodyTexto>
                  <span className="lab-title">{lab.razonSocial}</span>
                </TablaBodyTexto>
              </TrBody>
              <TrBody>
                <TablaBodyTexto>
                  <StyledLabel for="medio">Tipo de Pago: </StyledLabel>
                </TablaBodyTexto>
                <TablaBodyTexto>
                  <SelectStyle
                    name="medio"
                    id="medio"
                    onChange={handleChange}
                    value={form.medio}
                  >
                    <StyledOption value="EFECTIVO">EFECTIVO</StyledOption>
                    <StyledOption value="BANCO">BANCO</StyledOption>
                    <StyledOption value="MONEDERO">
                      APP. ELECTRONICA
                    </StyledOption>
                  </SelectStyle>
                </TablaBodyTexto>
              </TrBody>
              {form.medio === "MONEDERO" && (
                <TrBody>
                  <TablaBodyTexto>
                    <StyledLabel for="monedero">Monedero</StyledLabel>
                  </TablaBodyTexto>
                  <TablaBodyTexto>
                    <SelectStyle
                      name="monedero"
                      id="monedero"
                      onChange={handleChange}
                      value={form.monedero}
                      required
                    >
                      <option value="" selected disabled hidden>
                        Seleccione monedero
                      </option>
                      {listas.monederos.map((item) => (
                        <StyledOption value={item}>{item}</StyledOption>
                      ))}
                    </SelectStyle>
                  </TablaBodyTexto>
                </TrBody>
              )}
              {form.medio === "BANCO" && (
                <TrBody>
                  <TablaBodyTexto>
                    <StyledLabel for="banco">Banco:</StyledLabel>
                  </TablaBodyTexto>
                  <TablaBodyTexto>
                    <SelectStyle
                      name="banco"
                      id="banco"
                      onChange={handleChange}
                      value={form.banco}
                      required
                    >
                      <option value="" selected disabled hidden>
                        Seleccione Banco
                      </option>
                      {listas.bancos.map((item) => (
                        <StyledOption value={item}>{item}</StyledOption>
                      ))}
                    </SelectStyle>
                  </TablaBodyTexto>
                </TrBody>
              )}
              {form.medio === "BANCO" && (
                <TrBody>
                  <TablaBodyTexto>
                    <span>Num. Ope.</span>
                  </TablaBodyTexto>
                  <TablaBodyTexto>
                    <StyledInput
                      type="text"
                      required
                      name="numOpe"
                      value={form.numOpe}
                      onChange={handleChange}
                    ></StyledInput>
                  </TablaBodyTexto>
                </TrBody>
              )}
              <TrBody>
                <TablaBodyTexto>
                  <StyledLabel>Moneda: </StyledLabel>
                </TablaBodyTexto>
                <TablaBodyTexto>
                  <span>SOLES</span>
                </TablaBodyTexto>
              </TrBody>

              <TrBody>
                <TablaBodyTexto>
                  <StyledLabel>Deuta Total: </StyledLabel>
                </TablaBodyTexto>
                <TablaBodyTexto>
                  <span>{deuda}</span>
                </TablaBodyTexto>
              </TrBody>

              <TrBody>
                <TablaBodyTexto>
                  <StyledLabel>Monto a pagar: </StyledLabel>
                </TablaBodyTexto>
                <TablaBodyTexto>
                  <StyledInput
                    type="text"
                    required
                    name="monto"
                    value={form.monto}
                    onChange={handleChange}
                  ></StyledInput>
                </TablaBodyTexto>
              </TrBody>
            </TablaBody>
          </Tabla>
        </StyledForm>
        <ContainerBtn>
          <LinkCancelar to="deudas" className="btn ">
            Cancelar
          </LinkCancelar>

          <ButtonAbonar className="btn" onClick={handleOpenModal}>
            Pagar
          </ButtonAbonar>
        </ContainerBtn>
        <div>
          <OrdenesTable items={estado.data} />
        </div>
      </div>
      <ConfirmacionSiNoModal
        mensaje="Esta seguro de realizar esta operacion?"
        isOpen={modalIsOpen}
        handleCloseModal={handleCloseModal}
        onClickYes={handlePayment}
      />
    </MainContainer>
  );
};
