import { SET_CENTROSRX, UPDATE_CENTROSRX_IN_ARRAY } from "./centrosTypes";
import { fetchCentrosRx } from "../../services/firebase/centrosRx";
import { httpRequest, httpSuccess, httpFailure } from "../sesion/sesionActions";

export const setCentrosRx = (centrosRx) => {
  return {
    type: SET_CENTROSRX,
    payload: centrosRx,
  };
};
export const updateCentroRxInArray = (lab) => {
  return {
    type: UPDATE_CENTROSRX_IN_ARRAY,
    payload: lab,
  };
};
export const getCentrosRx = () => {
  return function (dispatch) {
    dispatch(httpRequest());
    fetchCentrosRx()
      .then((labs) => {
        dispatch(httpSuccess());
        dispatch(setCentrosRx(labs));
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};
