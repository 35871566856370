import { db } from "../../services/firebase/Config";
import {
  SET_ORDEN,
  NEW_ORDEN,
  ADD_ITEM,
  REMOVE_ITEM,
  UPDATE_ITEM,
} from "./ordenRxTypes";
import { httpRequest, httpSuccess, httpFailure } from "../sesion/sesionActions";
import {
  updateOrdenInArray,
  addOrdenInArray,
} from "../ordenesRx/ordenesRxActions";

export const setOrden = (orden) => {
  return {
    type: SET_ORDEN,
    payload: orden,
  };
};
export const newOrden = () => {
  return {
    type: NEW_ORDEN,
  };
};

export const addItem = (item) => {
  return {
    type: ADD_ITEM,
    payload: item,
  };
};

export const removeItem = (item) => {
  return {
    type: REMOVE_ITEM,
    payload: item,
  };
};

export const updateItem = (item) => {
  return {
    type: UPDATE_ITEM,
    payload: item,
  };
};

var ordenConverter = {
  toFirestore: function (orden) {
    return {
      id: orden.id,
      estado: orden.estado,
      fecEnv: orden.fecEnv,
      fecTer: orden.fecTer,
      centroRxId: orden.centroRxId,
      centroRxNombre: orden.centroRxNombre,
      pacienteId: orden.pacienteId,
      paciente: orden.paciente,
      empresaId: orden.empresaId,
      items: orden.items,
      empresa: orden.empresa,
      comentarios: orden.comentarios,
    };
  },
  fromFirestore: function (snapshot, options) {
    const data = snapshot.data(options);
    return {
      id: data().id,
      estado: data().estado,
      fecEnv: data().fecEnv.toDate(),
      fecTer: data().fecTer,
      centroRxId: data().centroRxId,
      centroRxNombre: data().centroRxNombre,
      pacienteId: data().pacienteId,
      paciente: data().paciente,
      empresaId: data().empresaId,
      items: data().items,
      empresa: data().empresa,
      comentarios: data().comentarios,
    };
  },
  toDate(fecha) {
    if (typeof fecha === "string") return new Date(fecha);
  },
  toCadena(fecha) {
    if (typeof fecha === "object") {
      const a = fecha.getFullYear();
      const m = (fecha.getMonth() + 1).toString().padStart(2, "0");
      const d = fecha.getDate().toString().padStart(2, "0");
      return `${a}-${m}-${d}`;
    }
  },
};

export const actualizaOrdenRx = (orden) => {
  return function (dispatch) {
    let ordenRef = db.collection("ordenesRx").doc(orden.id);
    dispatch(httpRequest());
    ordenRef
      .withConverter(ordenConverter)
      .set(orden)
      .then(() => {
        dispatch(httpSuccess());
        dispatch(setOrden(orden));
        dispatch(updateOrdenInArray(orden));
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const nuevaOrdenRx = (orden) => {
  return function (dispatch) {
    var batch = db.batch();
    let ordenRef = db.collection("ordenesRx").doc();
    dispatch(httpRequest());
    orden.id = ordenRef.id;
    batch.set(ordenRef, orden);

    batch
      .commit()
      .then(() => {
        dispatch(addOrdenInArray(orden));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const sendOrden = (orden) => {
  return function (dispatch) {
    const SOLICITADO = "SOLICITADO";
    orden.estado = SOLICITADO;
    let ordenRef = db.collection("ordenesRx").doc(orden.id);
    dispatch(httpRequest());
    ordenRef
      .withConverter(ordenConverter)
      .set(orden)
      .then(() => {
        dispatch(updateOrdenInArray(orden));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const anulaOrden = (orden) => {
  return function (dispatch) {
    const ANULADO = "ANULADO";
    orden.estado = ANULADO;
    let ordenRef = db.collection("ordenesRx").doc(orden.id);
    dispatch(httpRequest());

    ordenRef
      .withConverter(ordenConverter)
      .set(orden)
      .then(() => {
        dispatch(updateOrdenInArray(orden));
        dispatch(setOrden(orden));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};
