import { db } from "../../services/firebase/Config";
import firebase from "firebase/app";
import { setCuenta } from "../cuenta/cuentaActions";
import {
  updateDienteEnOdontograma,
  setOdontograma,
} from "../odontograma/odontogramaActions";
import { getPrestacion as fetchPrestacion } from "../../services/firebase/prestaciones/index";
import {
  DefaultConfig,
  aplicarCaras,
  aplicarCorona,
  aplicarImplante,
  aplicarExodoncia,
  aplicarNucleo,
} from "../../components/ConfigDiente.js";

import {
  ADD_NOTA_CLINICA,
  REMOVE_NOTA_CLINICA,
  UPDATE_NOTA_CLINICA,
  GET_PRESTATION,
  SET_PRESTATION,
  NEW_PRESTATION,
  SAVE_PRESTATION_REQUEST,
  ADD_PRESTATION_SUCCESS,
  UPDATE_PRESTATION_SUCCESS,
  SAVE_PRESTATION_FAILURE,
  SET_CARAS,
  PAGAR_PRESTACION,
  REVERTIR_PAGO,
} from "./prestationTypes";

import {
  updatePrestationInArray,
  removePrestationInArray,
} from "../prestations/prestationsActions";

import { httpRequest, httpSuccess, httpFailure } from "../sesion/sesionActions";

export const getPrestation = (payload) => {
  return {
    type: GET_PRESTATION,
    payload,
  };
};
export const setPrestation = (payload) => {
  return {
    type: SET_PRESTATION,
    payload,
  };
};

export const addNotaClinica = (notaClinica) => {
  return {
    type: ADD_NOTA_CLINICA,
    payload: notaClinica,
  };
};

export const updateNotaClinica = (item) => {
  return {
    type: UPDATE_NOTA_CLINICA,
    payload: item,
  };
};

export const removeNotaClinica = (idx) => {
  return {
    type: REMOVE_NOTA_CLINICA,
    payload: idx,
  };
};

export const setCaras = (payload) => {
  return {
    type: SET_CARAS,
    payload,
  };
};

export const newPrestation = () => {
  return {
    type: NEW_PRESTATION,
  };
};

const savePrestationRequest = () => {
  return {
    type: SAVE_PRESTATION_REQUEST,
  };
};

const pagarPrestacion = (montoPago) => {
  return {
    type: PAGAR_PRESTACION,
    payload: montoPago,
  };
};

const revertirPago = (montoPago) => {
  return {
    type: REVERTIR_PAGO,
    payload: montoPago,
  };
};

const addPrestationSuccess = (prestationId) => {
  return {
    type: ADD_PRESTATION_SUCCESS,
    payload: prestationId,
  };
};

const savePrestationFailure = (error) => {
  return {
    type: SAVE_PRESTATION_FAILURE,
    payload: error,
  };
};

export const addPrestation = (prestacion) => {
  return function (dispatch) {
    dispatch(httpRequest());
    db.collection("prestaciones")
      .add(prestacion)
      .then((docRef) => {
        dispatch(httpSuccess());
        dispatch(addPrestationSuccess(docRef.id));
      })
      .catch((err) => {
        dispatch(httpFailure(err));
        dispatch(savePrestationFailure(err));
      });
  };
};

export const addTratamiento = (tratamiento) => {
  return function (dispatch) {
    dispatch(savePrestationRequest());
    db.collection("tratamientos").add(tratamiento);
  };
};

export const getPrestacion = (prestacionId) => {
  return function (dispatch) {
    fetchPrestacion(prestacionId)
      .then((prestacion) => {
        dispatch(setPrestation(prestacion));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};
export const updatePrestation = (prestacion) => {
  return function (dispatch) {
    dispatch(savePrestationRequest());
    dispatch(httpRequest());
    db.collection("prestaciones")
      .doc(prestacion.id)
      .update(prestacion)
      .then(() => {
        dispatch(setPrestation(prestacion));
        dispatch(updatePrestationInArray(prestacion));
        dispatch(httpSuccess());
      })
      .catch((error) => {
        dispatch(httpFailure(error));
      });
  };
};

export const actualizarNotas = (prestacion) => {
  return function (dispatch) {
    dispatch(httpRequest());
    let prestacionRef = db.collection("prestaciones").doc(prestacion.id);

    prestacionRef
      .update(prestacion)
      .then(() => {
        dispatch(setPrestation(prestacion));
        dispatch(updatePrestationInArray(prestacion));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const actualizarLaboratorio = (prestacion) => {
  return function (dispatch) {
    dispatch(httpRequest());
    let prestacionRef = db.collection("prestaciones").doc(prestacion.id);
    prestacionRef
      .update({
        lab: prestacion.lab,
      })
      .then(() => {
        dispatch(setPrestation(prestacion));
        dispatch(updatePrestationInArray(prestacion));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};
export const enviarLaboratorio = (prestacion, labId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    let prestacionRef = db.collection("prestaciones").doc(prestacion.id);
    let laboratorioRef = db.collection("laboratorios").doc(labId);
    prestacionRef
      .update({ lab: prestacion.lab })
      .then(() => {
        dispatch(setPrestation(prestacion));
        dispatch(updatePrestationInArray(prestacion));
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });

    laboratorioRef
      .update({
        deuda: firebase.firestore.FieldValue.increment(
          Number(prestacion.lab.precio)
        ),
      })
      .then(() => {
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const modificarPrecioTrx = (presta, cuenta, nuevoPrecio) => {
  return function (dispatch) {
    let prestacion = Object.assign({}, presta);
    let diferenciaPrecio = Number(nuevoPrecio) - Number(presta.precio);
    dispatch(httpRequest());
    let estFin = "PENDIENTE";
    if (Number(nuevoPrecio) === Number(prestacion.pagado)) {
      estFin = "CANCELADO";
    }
    db.runTransaction((transaction) => {
      let prestacionRef = db.collection("prestaciones").doc(prestacion.id);
      let cuentaRef = db.collection("cuentas").doc(cuenta.id);
      return transaction
        .get(cuentaRef)
        .then((doc) => {
          transaction.update(prestacionRef, {
            precio: nuevoPrecio,
            estfin: estFin,
          });
          let nuevaDeuda = 0;
          prestacion.precio = nuevoPrecio;
          prestacion.estfin = estFin;
          if (doc.exists) {
            nuevaDeuda = doc.data().adeudado + diferenciaPrecio;
            transaction.update(cuentaRef, {
              adeudado: nuevaDeuda,
            });
            cuenta.adeudado = nuevaDeuda;
          }
          return { prestacion, cuenta };
        })
        .then(({ prestacion, cuenta }) => {
          dispatch(setPrestation(prestacion));
          dispatch(updatePrestationInArray(prestacion));
          dispatch(setCuenta(cuenta));
          dispatch(httpSuccess());
        })
        .catch((err) => {
          dispatch(httpFailure(err));
        });
    });
  };
};

export const desactivarPrestacionTrx = (presta, cuenta) => {
  return function (dispatch) {
    let prestacion = Object.assign({}, presta);
    dispatch(httpRequest());
    db.runTransaction((transaction) => {
      let prestacionRef = db.collection("prestaciones").doc(prestacion.id);
      let cuentaRef = db.collection("cuentas").doc(cuenta.id);
      return transaction
        .get(cuentaRef)
        .then((doc) => {
          prestacion.estado = "PENDIENTE";
          transaction.update(prestacionRef, {
            estado: prestacion.estado,
            precio: prestacion.precio,
            notasClinicas: [],
            nota: prestacion.nota,
          });
          let nuevaDeuda = 0;

          if (doc.exists) {
            nuevaDeuda = doc.data().adeudado - Number(prestacion.precio);
            transaction.update(cuentaRef, {
              adeudado: nuevaDeuda,
            });
            cuenta.adeudado = nuevaDeuda;
          }
          return { prestacion, cuenta };
        })
        .then(({ prestacion, cuenta }) => {
          dispatch(setPrestation(prestacion));
          dispatch(updatePrestationInArray(prestacion));
          dispatch(setCuenta(cuenta));
          dispatch(httpSuccess());
        })
        .catch((err) => {
          dispatch(httpFailure(err));
        });
    });
  };
};

export const iniciarPrestacionTrx = (presta, cuenta) => {
  return function (dispatch) {
    let prestacion = Object.assign({}, presta);
    dispatch(httpRequest());
    db.runTransaction((transaction) => {
      let prestacionRef = db.collection("prestaciones").doc(prestacion.id);
      let cuentaRef = db.collection("cuentas").doc(cuenta.id);
      return transaction
        .get(cuentaRef)
        .then((doc) => {
          prestacion.estado = "ACTIVO";
          transaction.update(prestacionRef, {
            estado: prestacion.estado,
            precio: prestacion.precio,
            notasClinicas: prestacion.notasClinicas,
            nota: prestacion.nota,
          });
          let nuevaDeuda = 0;

          if (doc.exists) {
            nuevaDeuda = doc.data().adeudado + Number(prestacion.precio);
            transaction.update(cuentaRef, {
              adeudado: nuevaDeuda,
            });
            cuenta.adeudado = nuevaDeuda;
          }
          return { prestacion, cuenta };
        })
        .then(({ prestacion, cuenta }) => {
          dispatch(setPrestation(prestacion));
          dispatch(updatePrestationInArray(prestacion));
          dispatch(setCuenta(cuenta));
          dispatch(httpSuccess());
        })
        .catch((err) => {
          dispatch(httpFailure(err));
        });
    });
  };
};

export const removePrestacionActivaTrx = (presta, cuenta) => {
  return function (dispatch) {
    let prestacion = Object.assign({}, presta);
    dispatch(httpRequest());
    db.runTransaction((transaction) => {
      let prestacionRef = db.collection("prestaciones").doc(prestacion.id);
      let cuentaRef = db.collection("cuentas").doc(cuenta.id);
      return transaction
        .get(cuentaRef)
        .then((doc) => {
          prestacion.estado = "ELIMINADO";
          transaction.update(prestacionRef, {
            estado: prestacion.estado,
          });
          let nuevaDeuda = 0;

          if (doc.exists) {
            nuevaDeuda = doc.data().adeudado - Number(prestacion.precio);
            transaction.update(cuentaRef, {
              adeudado: nuevaDeuda,
            });
            cuenta.adeudado = nuevaDeuda;
          }
          return { prestacion, cuenta };
        })
        .then(({ prestacion, cuenta }) => {
          dispatch(setPrestation(prestacion));
          dispatch(removePrestationInArray(prestacion));
          dispatch(setCuenta(cuenta));
          dispatch(httpSuccess());
        })
        .catch((err) => {
          dispatch(httpFailure());
        });
    });
  };
};
export const removePrestacionTrx = (presta) => {
  return function (dispatch) {
    let prestacion = Object.assign({}, presta);
    dispatch(httpRequest());
    let prestacionRef = db.collection("prestaciones").doc(prestacion.id);
    prestacion.estado = "ELIMINADO";
    prestacionRef
      .update(prestacion)
      .then(() => {
        dispatch(removePrestationInArray(prestacion));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const payPrestacionTrx = (prestacion, cuenta, movimiento) => {
  return function (dispatch) {
    var batch = db.batch();
    dispatch(httpRequest());
    let prestacionRef = db.collection("prestaciones").doc(prestacion.id);
    let cuentaRef = db.collection("cuentas").doc(cuenta.id);
    let movimientosRef = db.collection("movimientos").doc();

    prestacion.pagado = Number(prestacion.pagado) + Number(movimiento.monto);
    if (Number(prestacion.pagado) === Number(prestacion.precio)) {
      prestacion.estfin = "CANCELADO";
    }
    batch.update(prestacionRef, {
      pagado: firebase.firestore.FieldValue.increment(Number(movimiento.monto)),
      estfin: prestacion.estfin,
    });
    cuenta.saldo =
      Math.round((Number(cuenta.saldo) - Number(movimiento.monto)) * 100) / 100;
    cuenta.adeudado =
      Math.round((Number(cuenta.adeudado) - Number(movimiento.monto)) * 100) / 100;

    batch.update(cuentaRef, {
      adeudado: firebase.firestore.FieldValue.increment(
        Math.round(-Number(movimiento.monto) * 100) / 100
      ),
      saldo: firebase.firestore.FieldValue.increment(
        Math.round(-Number(movimiento.monto) * 100) / 100
      ),
    });

    batch.set(movimientosRef, movimiento);

    batch
      .commit()
      .then(() => {
        dispatch(pagarPrestacion(movimiento.monto));
        dispatch(updatePrestationInArray(prestacion));
        dispatch(setCuenta(cuenta));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const payMultiplePrestationsTrx = (prestaciones, monto, movimiento, cuenta) => {
  return function (dispatch) {
    var batch = db.batch();
    dispatch(httpRequest());
    let cuentaRef = db.collection("cuentas").doc(cuenta.id);
    let movimientosRef = db.collection("movimientos").doc();
    const PrestacionesOrdenadas = prestaciones.sort((a,b)=> a.orden - b.orden);
    PrestacionesOrdenadas.forEach((pr)=>{
      let prestacionRef = db.collection("prestaciones").doc(pr.id);
    
      if (Number(pr.pagado) === Number(pr.precio)) {
        pr.estfin = "CANCELADO";
      }
      batch.update(prestacionRef, {
        pagado: Number(pr.pagado),
        estfin: pr.estfin,
      });
      
      dispatch(updatePrestationInArray(pr));
        

    })
    cuenta.saldo =
      Math.round((Number(cuenta.saldo) - Number(monto)) * 100) / 100;

    cuenta.adeudado =
      Math.round((Number(cuenta.adeudado) - Number(monto)) * 100) / 100;
    if (cuenta.adeudado < 0)
      cuenta.adeudado = 0
    
    batch.update(cuentaRef, {
      adeudado: firebase.firestore.FieldValue.increment(
        Math.round(-Number(monto) * 100) / 100
      ),
      saldo: firebase.firestore.FieldValue.increment(
        Math.round(-Number(monto) * 100) / 100
      ),
    });
    movimiento.id = movimientosRef.id
    
   batch.set(movimientosRef, movimiento);
   
    batch
      .commit()
      .then(() => {
        dispatch(setCuenta(cuenta));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const revertPrestacionTrx = (prestacion, cuenta, pago) => {
  return function (dispatch) {
    var batch = db.batch();
    dispatch(httpRequest());
    let prestacionRef = db.collection("prestaciones").doc(prestacion.id);
    let cuentaRef = db.collection("cuentas").doc(cuenta.id);
    let movimientosRef = db.collection("movimientos").doc();

    prestacion.pagado = Number(prestacion.pagado) - Number(pago.monto);
    if (prestacion.estfin === "CANCELADO") {
      prestacion.estfin = "PENDIENTE";
    }
    batch.update(prestacionRef, {
      pagado: firebase.firestore.FieldValue.increment(-Number(pago.monto)),
      estfin: prestacion.estfin,
    });
    cuenta.saldo =
      Math.round((Number(cuenta.saldo) + Number(pago.monto)) * 100) / 100;
    cuenta.adeudado =
      Math.round((Number(cuenta.adeudado) + Number(pago.monto)) * 100) / 100;

    batch.update(cuentaRef, {
      adeudado: firebase.firestore.FieldValue.increment(
        Math.round(Number(pago.monto) * 100) / 100
      ),
      saldo: firebase.firestore.FieldValue.increment(
        Math.round(Number(pago.monto) * 100) / 100
      ),
    });

    batch.set(movimientosRef, pago);

    batch
      .commit()
      .then(() => {
        dispatch(revertirPago(pago.monto));
        dispatch(updatePrestationInArray(prestacion));
        dispatch(setCuenta(cuenta));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const terminarPrestacionTrx = (prestacion) => {
  return function (dispatch, getState) {
    var batch = db.batch();
    dispatch(httpRequest());
    //Cambia de estado a la prestacion en la coleccion prestaciones
    var prestacionDb = db.collection("prestaciones").doc(prestacion.id);
    let nuevoEstado = "TERMINADO";
    batch.update(prestacionDb, {
      estado: nuevoEstado,
      fechaFin: prestacion.fechaFin,
      notasClinicas: prestacion.notasClinicas,
    });
    prestacion.estado = nuevoEstado;
    dispatch(updatePrestationInArray(prestacion));

    batch
      .commit()
      .then(() => {
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const terminarPrestacionOdontoTrx = (prestacion, odontograma) => {
  return function (dispatch, getState) {
    var batch = db.batch();
    dispatch(httpRequest());
    //Cambia de estado a la prestacion en la coleccion prestaciones
    var prestacionDb = db.collection("prestaciones").doc(prestacion.id);
    let nuevoEstado = "TERMINADO";
    batch.update(prestacionDb, {
      estado: nuevoEstado,
      fechaFin: prestacion.fechaFin,
      notasClinicas: prestacion.notasClinicas,
    });
    prestacion.estado = nuevoEstado;
    //Por cada diente se actualiza en el odontograma
    var odontogramaDb;
    if (odontograma.id) {
      odontogramaDb = db.collection("odontogramas").doc(odontograma.id);
    } else {
      odontogramaDb = db.collection("odontogramas").doc();
      odontograma.id = odontogramaDb.id;
    }

    prestacion.dientes.forEach((d) => {
      //extraemos el resumen actual del diente
      let idx = odontograma.items.findIndex((it) => it.diente === d);
      let itemOdonto = {};
      if (idx > -1) {
        itemOdonto = odontograma.items[idx];
      } else {
        itemOdonto = new DefaultConfig(d);
        itemOdonto = Object.assign({}, itemOdonto);
      }
      let esPilar = prestacion.pilares
        ? prestacion.pilares.some((p) => p === d)
        : false;
      const { grupo } = prestacion.config;
      switch (grupo) {
        case "CARAS":
          itemOdonto = aplicarCaras(
            itemOdonto,
            prestacion.config,
            prestacion.caras
          );
          break;
        case "CORONA":
          itemOdonto = aplicarCorona(
            itemOdonto,
            prestacion.config,
            prestacion.caras,
            esPilar
          );
          break;
        case "IMPLANTE":
          itemOdonto = aplicarImplante(itemOdonto, prestacion.config);
          break;
        case "NUCLEO":
          itemOdonto = aplicarNucleo(itemOdonto, prestacion.config);
          break;

        case "EXODONCIA":
          itemOdonto = aplicarExodoncia(itemOdonto, prestacion.config);
          break;

        default:
          throw new Error("No esta configurado");
      }
      if (grupo === "CORONA") {
        if (prestacion.caras.length === 0)
          throw new Error("Error en la aplicación de corona");
      }
      odontograma.tipo = "ACTIVO";
      // actualiza en el odontogramaLocal
      if (idx > -1) {
        odontograma.items[idx] = itemOdonto;
      } else {
        odontograma.items.push(itemOdonto);
      }
      dispatch(setOdontograma(odontograma));
      dispatch(updateDienteEnOdontograma(itemOdonto));
    });

    dispatch(updatePrestationInArray(prestacion));
    batch.set(odontogramaDb, odontograma);

    batch
      .commit()
      .then(() => {
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const noContinuarPrestacionOdontoTrx = (prestacion) => {
  return function (dispatch, getState) {
    var batch = db.batch();
    dispatch(httpRequest());
    //Cambia de estado a la prestacion en la coleccion prestaciones
    var prestacionDb = db.collection("prestaciones").doc(prestacion.id);
    let nuevoEstado = "TERMINADO";
    batch.update(prestacionDb, {
      estado: nuevoEstado,
      fechaFin: prestacion.fechaFin,
      notasClinicas: prestacion.notasClinicas,
    });
    prestacion.estado = nuevoEstado;
    dispatch(updatePrestationInArray(prestacion));
    batch
      .commit()
      .then(() => {
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const guardarOdontoInicialTrx = (
  tratamiento,
  odontograma,
  caras,
  pilares,
  dientes,
  fecha
) => {
  return function (dispatch, getState) {
    var batch = db.batch();
    dispatch(httpRequest());
    var prestacionDb = db.collection("prestaciones").doc();
    let prestacion = {
      id: prestacionDb.id,
      pacienteId: odontograma.pacienteId,
      especialidad: tratamiento.especialidad,
      tratamiento: tratamiento.tratamiento,
      tipo: tratamiento.tipo,
      precio: 0,
      pagado: 0,
      dientes: dientes,
      caras: caras,
      notasClinicas: [
        {
          fecha: fecha,
          nota: "Odonto. Inicial.",
          especialista: odontograma.especialistaId,
        },
      ],
      fechaInicio: new Date(),
      fechaFin: new Date(),
      estado: "TERMINADO",
      estfin: "CANCELADO",
      config: {},
      lab: {},
      tratamientoId: tratamiento.id,
      empresaId: odontograma.empresaId,
      nota: "ODONTO. INICIAL.",
    };
    //Cambia de estado a la prestacion en la coleccion prestaciones
    //Por cada diente se actualiza en el odontograma
    var odontogramaDb;
    if (odontograma.id) {
      odontogramaDb = db.collection("odontogramas").doc(odontograma.id);
    } else {
      odontogramaDb = db.collection("odontogramas").doc();
      odontograma.id = odontogramaDb.id;
    }

    dientes.forEach((d) => {
      //extraemos el resumen actual del diente
      let idx = odontograma.items.findIndex((it) => it.diente === d);
      let itemOdonto = {};
      if (idx > -1) {
        itemOdonto = odontograma.items[idx];
      } else {
        itemOdonto = new DefaultConfig(d);
        itemOdonto = Object.assign({}, itemOdonto);
      }
      let esPilar = pilares ? pilares.some((p) => p === d) : false;
      const { grupo } = tratamiento.config;
      switch (grupo) {
        case "CARAS":
          itemOdonto = aplicarCaras(itemOdonto, tratamiento.config, caras);
          break;
        case "CORONA":
          itemOdonto = aplicarCorona(
            itemOdonto,
            tratamiento.config,
            caras,
            esPilar
          );
          break;
        case "IMPLANTE":
          itemOdonto = aplicarImplante(itemOdonto, tratamiento.config);
          break;
        case "NUCLEO":
          itemOdonto = aplicarNucleo(itemOdonto, tratamiento.config);
          break;

        case "EXODONCIA":
          itemOdonto = aplicarExodoncia(itemOdonto, tratamiento.config);
          break;

        default:
          throw new Error("No esta configurado");
      }
      if (grupo === "CORONA") {
        if (caras.length === 0)
          throw new Error("Error en la aplicación de corona");
      }
      odontograma.tipo = "INICIAL";
      // actualiza en el odontogramaLocal
      if (idx > -1) {
        odontograma.items[idx] = itemOdonto;
      } else {
        odontograma.items.push(itemOdonto);
      }

      dispatch(setOdontograma(odontograma));
      dispatch(updateDienteEnOdontograma(itemOdonto));
    });

    batch.set(prestacionDb, prestacion);
    batch.set(odontogramaDb, odontograma);

    batch
      .commit()
      .then(() => {
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};
