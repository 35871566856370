import { SET_LABS, UPDATE_LAB_IN_ARRAY } from "./labsTypes";

export const labsReducers = (state = [], action) => {
  switch (action.type) {
    case SET_LABS:
      return action.payload;
    case UPDATE_LAB_IN_ARRAY:
      const index = state.findIndex((lab) => lab.id === action.payload.id);
      const newArray = [...state];
      newArray[index] = action.payload;
      return newArray;
    default:
      return state;
  }
};
