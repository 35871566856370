import styled from "styled-components";
import { dispositivo } from "../Dispositivos";
import IconButton from "@material-ui/core/IconButton";

export const ModalBody = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalContainer = styled.div`
  position: relative;
  top: 4rem;
  background-color: #ffffff;
  padding: 0%;
  width: 90%;
  display: block;
  @media ${dispositivo.tablet}{
    width: 60%;
  }
`;
export const ModalButtonContainer = styled.div`
  text-align: right;
`;
export const ModalButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  background-color: #f5f5f5;
  padding: 1% !important;
`;

export const ModalChildrenContainer = styled.div`
  padding: 2%;
`;