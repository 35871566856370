import React from "react";

const PreMolarSup = (props) => {
  const configuracion = props.config;

  const defaultColor = "#ffeca2";

  const getColor = (cara) => {
    let color = defaultColor;
    if (props.config.caras) {
      let idx = props.config.caras.findIndex((it) => it.cara === cara);
      if (idx > -1) color = props.config.caras[idx].color;
    }
    return color;
  };

  return (
    <svg
      id="Premolar_sup"
      data-name="Premolar sup"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 183.36 424.98"
    >
      <title>premolar_sup</title>
      <g id="Implante">
        <path
          d="M19.6,281.45,49.9,283l68.45-.47,45.35-.7-2.07-31,2.07-36s-26.92-7.29-30.11-11.84c-1.62-2.3,0-10.31,0-10.31,6.74-1.64,11.29-2.87,12.49-3.39,4-1.88,5.67-5,6.52-11.18,1.42-10-2.27-14.43-11.9-14.43a60.2,60.2,0,0,0-7.21.79v-9.57a70.28,70.28,0,0,0,7.48-2.32c3.63-1.89,5.18-5,6-11.19,1.29-10-2.08-14.42-10.88-14.42a24.3,24.3,0,0,0-2.56.2V111.1a12.45,12.45,0,0,0,2-1c4.39-2.83,5.31-5.12,5.31-12.13,0-7.35-2.21-11.29-7.29-12.44V69.17c6.61-3.42,7.29-6.85,7.29-13,0-7.37-2.3-11.38-7.29-12.48V36.34a1.89,1.89,0,0,0-.78-1.46l-.16-.4C131.81,33,131,17.64,120.3,9.62c-9.81-5.43-29.7-7.09-33.68-7.09s-19.45-1-33.68,8.76c-10,6.87-9.67,18.64-10.26,22.33-.17.62-.34,1.34-.51,2.15a4.41,4.41,0,0,0-1.9,3.46V66.68a21,21,0,0,0-2.57,1c-3.24,1.89-4.63,5-5.32,11.19C31.3,88,33.82,92.53,40.27,93.19v15.65c-7.2,3.58-8.13,7.12-8.13,13.28,0,7.88,2.57,11.86,8.13,12.66V148.7a53.31,53.31,0,0,0-7.55,2.67c-6.22,3.5-7,5-7,12.53,0,8.76,4.4,13.08,13.21,12.81a11.79,11.79,0,0,0,1.33-.17v7.72c-6.4,1.67-11,3-12.59,3.82-6.8,3.5-7.65,5-7.65,12.54,0,8.76,4.82,13.07,14.45,12.8a51.78,51.78,0,0,0,5.26-.81c-9.38,13.85-20.14,18-20.14,18Z"
          transform="translate(0 -2.5)"
          fill={configuracion.implante.fill}
          stroke={configuracion.implante.strokeColor}
        />
      </g>
      <path
        id="Raiz"
        d="M19.63,287.36h144.1S144.19,176.9,135.49,137c-2.19-10-4.93-63.08-8.54-83.16-6.78-37.75-20.42-43.33-20.42-43.33-7.81-3.31-18.5-3.8-23,2.58,0,0-23.4,21.71-21.07,90,.9,26.7-1.5,61.66-5.08,76.09C44.33,216,19.63,287.36,19.63,287.36Z"
        transform="translate(0 -2.5)"
        fill={configuracion.raiz.fill}
        stroke={configuracion.raiz.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
      <path
        id="Nucleo"
        d="M95.4,238h0c-5,0-23.7,0-22.6-4.76L87.5,24.7C86.4,19.94,90.4,16,95.4,16h0a8.8,8.8,0,0,1,9,8.58v.09L115.6,232C115.6,236.74,100.4,238,95.4,238Z"
        transform="translate(0 -2.5)"
        fill={configuracion.nucleo.fill}
        stroke={configuracion.nucleo.strokeColor}
      />
      <path
        id="6"
        d="M156,268.93,119.5,265,118,203s22.32-6.31,25-1.94C150.77,213.74,156,268.93,156,268.93Z"
        transform="translate(0 -2.5)"
        fill={getColor("6")}
      />
      <path
        id="7"
        d="M57.19,270.15c-7.5,7-25-5.15-25-5.15s15.39-54.59,22.14-62c2.25-2.48,10.27,0,10.27,0a5.6,5.6,0,0,1,5.59,5.6S68.41,259.62,57.19,270.15Z"
        transform="translate(0 -2.5)"
        fill={getColor("7")}
      />
      <path
        id="8"
        d="M150.25,267l-109.08-3.4s6.45-51.77,17.71-62.82c14.25-14,55.93-16.72,71.06-3.85C142.44,207.59,150.25,267,150.25,267Z"
        transform="translate(0 -2.5)"
        fill={getColor("8")}
      />
      <path
        id="9"
        d="M154,264.33l-121-2S46.07,215,57.33,205c14.25-12.71,66.34-12.28,81.76-2.2C151.59,212.45,154,264.33,154,264.33Z"
        transform="translate(0 -2.5)"
        fill={getColor("9")}
      />
      <path
        id="Corona"
        d="M179.4,330.88c9.5,37-28.7,94.1-77.8,94.1-56.2,0-106.5-49.2-98.2-93S50.9,228,95.9,228,167.2,283.53,179.4,330.88Z"
        transform="translate(0 -2.5)"
        fill={configuracion.corona.fill}
        stroke={configuracion.corona.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
    </svg>
  );
};
export default PreMolarSup;
