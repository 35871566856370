import React from "react";

import { MainContainer, ContainerBody } from "./styles";
import Spinner from "react-bootstrap/Spinner";
import {
  fetchPagos,
  fetchAbonosEmpresa,
} from "../../../services/firebase/movimientos/index";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  TablaBodyTexto,
  ContainerTitle,
  ContainerTitleText,
  FlexContainer,
  StyledInput,
  StyledButton,
  ContainerBtn,
} from "./styles";
const getFecha = (fecha) => {
  const a = fecha.getFullYear();
  const m = (fecha.getMonth() + 1).toString().padStart(2, "0");
  const d = fecha.getDate().toString().padStart(2, "0");
  return `${a}-${m}-${d}`;
};

export const AbonosRpt = () => {
  const empresa = useSelector((state) => state.empresa);
  const [estado, setEstado] = React.useState({
    loading: false,
    data: [],
    err: "",
  });
  const getFecha = (fecha) => {
    const a = fecha.getFullYear();
    const m = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const d = fecha.getDate().toString().padStart(2, "0");
    return `${a}-${m}-${d}`;
  };

  const [fecIni, setFecIni] = React.useState(getFecha(new Date()));
  const [fecFin, setFecFin] = React.useState(getFecha(new Date()));
  /*
   *
   * Se resta menos uno, debido a que el proceso ese ejecuta en la madrugada,
   * con el fin de poder cortar el dia anterior al 01 de cada mes.
   */

  let url = `${process.env.REACT_APP_API_URL}/getFile/api/reportes/empresa/abonos/${empresa.id}`;

  const hayDatos = (filtro) => {
    return estado.data.filter((item) => item.medio === filtro).length > 0;
  };

  const handleClick = async () => {
    let fecIni1 = new Date(fecIni + "T00:00");
    let fecFin1 = new Date(fecFin + "T00:00");

    fecFin1.setHours(23);
    fecFin1.setMinutes(59);

    try {
      setEstado({ ...estado, loading: true });
      let data = await fetchAbonosEmpresa(empresa.id, fecIni1, fecFin1);
      console.log('v1 abonos', data)
      setEstado({ ...estado, data: data, loading: false });
    } catch (err) {
      setEstado({ ...estado, err: err.message, loading: false });
    }
  };

  if (estado.loading)
    return (
      <MainContainer>
        <Spinner animation="border" role="status">
          <span className="visually-hidden"></span>
        </Spinner>
      </MainContainer>
    );
  if (estado.err) return <div>{estado.err}</div>;
  return (
    <>
      <MainContainer>
        <ContainerTitle>
          <ContainerTitleText>Abonos/Devoluciones.</ContainerTitleText>
        </ContainerTitle>
        <ContainerBody>
          <FlexContainer>
            <label>
              Inicio
              <StyledInput
                type="date"
                name="fecNac"
                value={fecIni}
                onChange={(e) => setFecIni(e.target.value)}
              />
            </label>

            <label>
              Fin
              <StyledInput
                type="date"
                name="fecNac"
                value={fecFin}
                onChange={(e) => setFecFin(e.target.value)}
              />
            </label>

          </FlexContainer>
          <ContainerBtn>
            <StyledButton onClick={handleClick}>Reporte</StyledButton>
          </ContainerBtn>


          <div className="py-2">
            {hayDatos("EFECTIVO") && (
              <Tabla>
                <TablaHeader>
                  <tr>
                    <TablaHeaderTexto scope="col">Fecha</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Monto</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Medio</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Paciente</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Usuario</TablaHeaderTexto>
                  </tr>
                </TablaHeader>
                <TablaBody>
                  {estado.data
                    .filter((item) => item.medio === "EFECTIVO")
                    .map((item) => (
                      <tr>
                        <TablaBodyTexto data-label="Fecha">
                          {getFecha(item.fecha)}
                        </TablaBodyTexto>
                        <TablaBodyTexto data-label="Monto">
                          {item.tipMov === "ABONO" ? item.monto : -item.monto}
                        </TablaBodyTexto>
                        <TablaBodyTexto data-label="Medio">
                          EFECTIVO
                        </TablaBodyTexto>
                        <TablaBodyTexto data-label="Paciente">
                          {item.pacienteNombres}
                        </TablaBodyTexto>
                        <TablaBodyTexto data-label="Usuario">
                          {item.usuario?.nombres}
                        </TablaBodyTexto>
                      </tr>
                    ))}
                  <tr>
                    <TablaBodyTexto data-label="Fecha">Total</TablaBodyTexto>
                    <TablaBodyTexto data-label="Monto">
                      {estado.data
                        .filter((item) => item.medio === "EFECTIVO")
                        .map((item) =>
                          item.tipMov === "ABONO" ? item.monto : -item.monto
                        )
                        .reduce((total, i) => total + i, 0)}
                    </TablaBodyTexto>
                  </tr>
                </TablaBody>
              </Tabla>
            )}
          </div>
          <div className="py-2">
            {hayDatos("BANCO") && (
              <Tabla>
                <TablaHeader>
                  <tr>
                    <TablaHeaderTexto scope="col">Fecha</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Monto</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Banco</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Num. Ope</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Paciente</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Usuario</TablaHeaderTexto>
                  </tr>
                </TablaHeader>
                <TablaBody>
                  {estado.data
                    .filter((item) => item.medio === "BANCO")
                    .map((item) => (
                      <tr>
                        <TablaBodyTexto data-label="Fecha">
                          {getFecha(item.fecha)}
                        </TablaBodyTexto>
                        <TablaBodyTexto data-label="Monto">
                          {item.tipMov === "ABONO" ? item.monto : -item.monto}
                        </TablaBodyTexto>
                        <TablaBodyTexto data-label="Banco">
                          {item.banco}
                        </TablaBodyTexto>
                        <TablaBodyTexto data-label="Num Ope">
                          {item.numope}
                        </TablaBodyTexto>
                        <TablaBodyTexto data-label="Paciente">
                          {item.pacienteNombres}
                        </TablaBodyTexto>
                        <TablaBodyTexto data-label="Usuario">
                          {item.usuario?.nombres}
                        </TablaBodyTexto>
                      </tr>
                    ))}
                  <tr>
                    <TablaBodyTexto data-label="Fecha">Total</TablaBodyTexto>
                    <TablaBodyTexto data-label="Monto">
                      {estado.data
                        .filter((item) => item.medio === "BANCO")
                        .map((item) =>
                          item.tipMov === "ABONO" ? item.monto : -item.monto
                        )
                        .reduce((total, i) => total + i, 0)}
                    </TablaBodyTexto>
                  </tr>
                </TablaBody>
              </Tabla>
            )}
          </div>
          <div className="py-2">
            {hayDatos("MONEDERO") && (
              <Tabla>
                <TablaHeader>
                  <tr>
                    <TablaHeaderTexto scope="col">Fecha</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Monto</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Medio</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Paciente</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Usuario</TablaHeaderTexto>
                  </tr>
                </TablaHeader>
                <TablaBody>
                  {estado.data
                    .filter((item) => item.medio === "MONEDERO")
                    .map((item) => (
                      <tr>
                        <TablaBodyTexto data-label="Fecha">
                          {getFecha(item.fecha)}
                        </TablaBodyTexto>
                        <TablaBodyTexto data-label="Monto">
                          {item.tipMov === "ABONO" ? item.monto : -item.monto}
                        </TablaBodyTexto>
                        <TablaBodyTexto data-label="Monedero">
                          {item.monedero}
                        </TablaBodyTexto>
                        <TablaBodyTexto data-label="Paciente">
                          {item.pacienteNombres}
                        </TablaBodyTexto>
                        <TablaBodyTexto data-label="Usuario">
                          {item.usuario?.nombres}
                        </TablaBodyTexto>
                      </tr>
                    ))}
                  <tr>
                    <TablaBodyTexto data-label="Fecha">Total</TablaBodyTexto>
                    <TablaBodyTexto data-label="Monto">
                      {estado.data
                        .filter((item) => item.medio === "MONEDERO")
                        .map((item) =>
                          item.tipMov === "ABONO" ? item.monto : -item.monto
                        )
                        .reduce((total, i) => total + i, 0)}
                    </TablaBodyTexto>
                  </tr>
                </TablaBody>
              </Tabla>
            )}
          </div>
        </ContainerBody>
        {/* <IFrame src={url} frameborder="0" scrolling="no"></IFrame> */}
      </MainContainer>
    </>
  );
};


