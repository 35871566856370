import {  firestorage } from "../Config";


export const uploadFile = async (file) => {
  const storageRef = firestorage.ref();
  let pathFile = `logos/${file.name}`;
  const fileRef = storageRef.child(pathFile);
  await fileRef.put(file);
  const fileUrl = await fileRef.getDownloadURL();
  return fileUrl;
};


export const getUrlFile = async (pathFile) => {
  const fileRef = firestorage.ref(pathFile);
  // Obtiene la URL de descarga
  const fileUrl = await fileRef.getDownloadURL(fileRef);
  return fileUrl;
};