import { db } from "../Config";
import { CPrestacion } from "../../../Entidades/Prestaciones";

export const fetchPrestacionesTerminadas = async (pacienteId) => {
  let prestaciones = [];
  await db
    .collection("prestaciones")
    .where("estado", "in", ["TERMINADO", "ACTIVO", "TERMICAN"])
    .where("pacienteId", "==", pacienteId)
    .orderBy("fechaFin", "desc")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        prestaciones.push({
          id: doc.id,
          tratamiento: doc.data().tratamiento,
          precio: doc.data().precio,
          dientes: doc.data().dientes,
          pagado: doc.data().pagado,
          estado: doc.data().estado,
          caras: doc.data().caras,
          pacienteId: doc.data().pacienteId,
          fechaFin: doc.data().fechaFin,
          notasClinicas: doc.data().notasClinicas,
          tipo: doc.data().tipo,
        });
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
  return prestaciones;
};

export const getPrestacion = (prestacionId) => {
  let prestacion;
  return new Promise((resolve, reject) => {
    let prestacionRef = db.collection("prestaciones").doc(prestacionId);
    prestacionRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          prestacion = {
            id: doc.id,
            tratamiento: doc.data().tratamiento,
            tipo: doc.data().tipo,
            precio: Number(doc.data().precio),
            dientes: doc.data().dientes,
            pagado: Number(doc.data().pagado),
            estado: doc.data().estado,
            estfin: doc.data().estfin,
            caras: doc.data().caras,
            pacienteId: doc.data().pacienteId,
            notasClinicas: doc.data().notasClinicas,
            config: doc.data().config,
            lab: doc.data().lab,
            tratamientoId: doc.data().tratamientoId,
          };
        } else {
          prestacion = new CPrestacion();
        }
        resolve(prestacion);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchPrestacionesTerminadasEmpresa = async (empresaId, fecha) => {
  let prestaciones = [];
  await db
    .collection("prestaciones")
    .where("estado", "in", ["TERMINADO"])
    .where("empresaId", "==", empresaId)
    .where("fechaFin", ">=", fecha)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        prestaciones.push({
          id: doc.id,
          tratamiento: doc.data().tratamiento,
          precio: doc.data().precio,
          dientes: doc.data().dientes,
          pagado: doc.data().pagado,
          estado: doc.data().estado,
          caras: doc.data().caras,
          pacienteId: doc.data().pacienteId,
          fechaFin: doc.data().fechaFin,
          notasClinicas: doc.data().notasClinicas,
        });
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
  return prestaciones.length;
};
