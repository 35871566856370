
import React from "react";
import { NuevaPrestacionModal } from "../Prestaciones/Modales/NuevaPrestacionModal/index";
import { PresupuestoTable } from "../../components/PresupuestoTable/index";
import { TopBarPaciente } from "../../components/TopBarPaciente";
import { useDispatch, useSelector } from "react-redux";
import { newToothSelection } from "../../redux/denticion/denticionActions";
import { newPrestation } from "../../redux/prestation/prestationActions";
import { addPrestationDb } from "../../redux/prestations/prestationsActions";
import AddIcon from "@material-ui/icons/Add";

import {
  MainContainer,
  ContainerBody,
  ContainerLeft,
  ButtonAddBudget,
  ContainerActions,
  ContainerRight,
  ContenedorTabla,
} from "./styles";
const usePresupuesto = () => {
  const prestaciones = useSelector((state) => state.prestaciones);
  let filtradas = prestaciones.items.filter(
    (prestacion) => prestacion.estado === "PENDIENTE"
  );
  return filtradas;
};

export const Presupuesto = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  const handleCloseModal = (e) => {
    setModalIsOpen(false);
  };
  const prestaciones = usePresupuesto();

  const handleOpenModal = (e) => {
    dispatch(newToothSelection());
    setModalIsOpen(true);
  };

  const handleAddToPlan = (prestacion) => {
    dispatch(addPrestationDb(prestacion));
    dispatch(newPrestation());
    dispatch(newToothSelection());
  };

  return (
    <MainContainer>
      <TopBarPaciente />

      <ContainerBody>
        <ContainerActions>
          <ContainerLeft>
            <span>
              <strong>
                {prestaciones.length > 0 &&
                  `Total presupuestado: S/ ${prestaciones
                    .map((item) => Number(item.precio))
                    .reduce((total, num) => total + num, 0)
                    .toFixed(2)}`}
              </strong>
            </span>
          </ContainerLeft>

          <ContainerRight>
            <ButtonAddBudget
              onClick={handleOpenModal}
              variant="contained"
              size="large"
              startIcon={<AddIcon />}
            >
              Nueva Prestación
            </ButtonAddBudget>
          </ContainerRight>
        </ContainerActions>

        <ContenedorTabla>
          <PresupuestoTable items={prestaciones} />
        </ContenedorTabla>
      </ContainerBody>
      <div>
        <NuevaPrestacionModal
          isOpen={modalIsOpen}
          handleCloseModal={handleCloseModal}
          handleAddToPlan={handleAddToPlan}
        />
      </div>
    </MainContainer>
  );
};
export default Presupuesto;
