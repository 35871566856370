import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FormField,
  AutocompleteStyle,
  AutocompleteResultStyle,
  Item,
  ScrollDiv,
} from "./styles";

export function AutocompleteTratamiento({
  useSearchItems,
  onSelected,
  toString,
  
}) {
  //const subGrupos = useSelector((state) => state.subGrupos);
  const dispatch = useDispatch();
  const {
    query,
    setQuery,
    filteredItems,
    clearFilteredItems,
    loading
  } = useSearchItems();
  //const { query, setQuery, filteredItems } = useMyHook(myitems);
  // React.useEffect(() => {
  //   if (grupo) {
  //     setGrupo(grupo);
  //     setAfectaOdonto(true);
  //   }
  // }, []);
  function handleClickItem(item) {
    clearFilteredItems();
    setQuery("");
    onSelected(item);
    //setQuery(`${item.nombres} ${item.apellidos}`);
  }
  return (
    <AutocompleteStyle>
      <FormField
        type="text"
        placeholder="Buscar Tratamiendo"
        onChange={(e) => setQuery(e.target.value)}
        value={query}
      ></FormField>
      <ScrollDiv>
        <AutocompleteResultStyle>
          {filteredItems.map((item) => (
            <Item key={item.id} onClick={() => handleClickItem(item)}>
              {toString(item)}
            </Item>
          ))}
        </AutocompleteResultStyle>
      </ScrollDiv>
    </AutocompleteStyle>
  );
}
