import React from "react";

const MolarInf = (props) => {
  const configuracion = props.config;

  const getColor = (cara) => {
    const defaultColor = "#ffeca2";
    let color = defaultColor;
    if (props.config.caras) {
      let idx = props.config.caras.findIndex((it) => it.cara === cara);
      if (idx > -1) color = props.config.caras[idx].color;
    }
    return color;
  };

  return (
    <svg
      id="Molar_inf"
      data-name="Molar inf"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 253.04 489.93"
    >
      <title>molar_inf</title>
      <path
        id="Implante"
        d="M244.7,136.67l-48.6-2-109.7.6-72.8.9,3.3,39,4,47s35.8,7.5,41,13.3c2.6,2.9,0,13,0,13-10.8,2.1-18.1,3.6-20,4.3-6.4,2.4-9.1,6.3-10.5,14.1C29.1,279.37,35,285,50.5,285c2.2,0,6.2-.4,11.6-1v12c-6.4,1.4-10.6,2.4-11.9,2.9-5.8,2.4-8.3,6.3-9.5,14.1-2.1,12.5,3.3,18,17.4,18a35.17,35.17,0,0,0,4.1-.3V351a18.42,18.42,0,0,0-3.2,1.2c-7,3.6-8.5,6.4-8.5,15.3,0,9.2,3.6,14.2,11.7,15.6v20.6C51.6,408,50.5,412.3,50.5,420c0,9.3,3.7,14.3,11.7,15.7V445a2.39,2.39,0,0,0,1.3,1.8c.1.2.2.3.3.5,1.3,1.8,2.5,21.2,19.7,31.3,15.6,6.8,47.5,8.9,53.9,8.9s31.2,1.3,54-11c16-8.6,15.5-23.5,16.4-28.1.31-.89.58-1.79.8-2.7a5.28,5.28,0,0,0,3-4.4V406.8a26.4,26.4,0,0,0,4.1-1.2c5.2-2.4,7.4-6.3,8.6-14.1,1.7-11.5-2.3-17.2-12.7-18V353.9c11.6-4.5,13-9,13-16.7,0-9.9-4.1-14.9-13-15.9V303.8a118.29,118.29,0,0,0,12-3.3c10-4.4,11.2-6.3,11.2-15.8,0-11-7.1-16.5-21.2-16.1a14.78,14.78,0,0,0-2.1.2V259c10.3-2.1,17.6-3.8,20.2-4.8,10.9-4.4,12.3-6.3,12.3-15.8,0-11-7.7-16.4-23.2-16.1-.9,0-3.8.4-8.4,1a79,79,0,0,1,32.3-22.7Z"
        transform="translate(0 0)"
        fill={configuracion.implante.fill}
        stroke={configuracion.implante.strokeColor}
      />
      <path
        id="Raiz"
        d="M214.8,135.27l-186.2-1.4c-11.3-.1-18.5,13.3-13.3,24.4,10.2,21.5-3,47,5.6,86.8C36,315.27,70,424.47,80.5,456.47c5.2,15.7,21.1,16.1,28.2,21.9,1.4,1.2,5.5,3.2,9.3,6.1,5.5,4.1,12.1,4.7,15.1-3.1a13.58,13.58,0,0,0,.8-3.5c2.5-19.7,18.1-243.7,23.6-219.6,6.6,29-15.5,124.4-9.4,150.7,22.5,97.1,53.5-15.9,85.1-119.4,17.7-58-5.6-72.5-4-134.1C229.5,144.87,224.2,135.37,214.8,135.27Z"
        transform="translate(0 0)"
        fill={configuracion.raiz.fill}
        stroke={configuracion.raiz.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
      <path
        id="Nucleo"
        d="M117,175.85h0c2.49,0,81.12-10.47,93.44-6.85,14.05,4.14,10.6,72.6,9.92,76l-15.67,63s-12.31,98.28-30.34,116.33c-11.52,11.54-21.66-12.66-6.83-84.33,4.17-20.14,6.08-50.14,5.73-61.05-.38-11.66-11-45.38-22.17-42.12-16.28,4.74-21.91,66.91-21.91,66.91L125.8,448.35c1.22,6.08-1.43,22-7.92,25.64,0,0-18.11-7-17.11-11,2.49-10,1.75-8.77,1.75-14.75,0,0-2.23-3.21-2.25-3.25C78.09,408.63,68.64,295.45,68.64,295.45S40.79,218.24,52.33,181C54.13,175.21,111.52,175.85,117,175.85Z"
        transform="translate(0 0)"
        fill={configuracion.nucleo.fill}
        stroke={configuracion.nucleo.strokeColor}
      />
      <g id="6">
        <path
          d="M207.2,251.57l-34.6.9a11.66,11.66,0,0,1-12-11.31h0v-56s40.8,8.1,61.7-29.6c-2,12.67,7.7,68,7.7,68a25.24,25.24,0,0,1-22.21,27.94Z"
          transform="translate(0 0)"
          fill={getColor("6")}
        />
      </g>
      <g id="7">
        <path
          d="M89.76,263.5s-50.89,12.69-57.8,0c-11.68-21.46-9-95.26-6.4-108,15.44,29,67.6,41.1,80.6,39.9a2.11,2.11,0,0,1,2.29,1.89v.21L107,246.9A17.35,17.35,0,0,1,89.76,263.5Z"
          transform="translate(0 0)"
          fill={getColor("7")}
        />
      </g>
      <g id="8">
        <path
          d="M165,256H81.88c-31.11,0-56.24-25.58-56.24-57.28v-54H221.23v54C221.23,230.39,196.13,256,165,256Z"
          transform="translate(0 0)"
          fill={getColor("8")}
        />
      </g>
      <g id="9">
        <path
          d="M157.74,245h-65C54.12,245,23,217.52,23,183.88V144.71H227.5L222.81,188C222.81,221.63,196.35,245,157.74,245Z"
          transform="translate(0 0)"
          fill={getColor("9")}
        />
      </g>
      <path
        id="Corona"
        d="M2.5,89.57c.2-48.9,2.2-89.1,28.3-83,44.2,10,51,1.7,62.3,0,29.7-4.6,39.1,8.5,51.5,7.9,17.4-1,14.5,0,23-4.2,22-11,75.7-23.7,82.4,64.8,2.9,37.5-1.7,122.6-122.4,121.1C34,194.67,2.2,151.37,2.5,89.57Z"
        transform="translate(0 0)"
        fill={configuracion.corona.fill}
        stroke={configuracion.corona.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
    </svg>
  );
};
export default MolarInf;
