import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Zoom from "@material-ui/core/Zoom";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Tooltip from "@material-ui/core/Tooltip";
import MailIcon from "@material-ui/icons/Mail";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import LaunchIcon from "@material-ui/icons/Launch";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../components/Modal/index";
import { useToasts } from "react-toast-notifications";
import { getOrdenesRxPaciente } from "../../redux/ordenesRx/ordenesRxActions";
import { getCentrosRx } from "../../redux/centrosRx/centrosRxActions";
import { ConfirmacionSiNoModal } from "../../components/ConfirmacionSiNoModal/index";
import { TopBarPaciente } from "../../components/TopBarPaciente";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import {
  UnOrderedList,
  Button,
  SectionTarjeta,
  ContenedorTarjetas,
  ContenedorContenidoTarjeta,
  ContenedorCabeceraTarjeta,
  TarjetaAccion,
  TablaBodyTextoFinanzaPrecio,
  Buttons,
} from "./styles";
import {
  setOrden,
  sendOrden,
  anulaOrden,
  newOrden,
} from "../../redux/ordenRx/ordenRxActions";
import {
  MainContainer,
  ContainerTitle,
  ContainerTitleText,
  TablaFinanzas,
  TablaBodyFinanzas,
} from "../../components/Styles/formularios.css";
import { OrdenRx } from "../OrdenRx";
const ESTADOS = {
  SOLICITADO: "SOLICITADO",
  PENDIENTE: "PENDIENTE",
  PROCESO: "PROCESO",
  TRANSITO: "TRANSITO",
  PRUEBAS: "PRUEBAS",
  TERMINADO: "TERMINADO",
  ENVIADO: "ENVIADO",
};

export const Finanzas = ({ precio, pagado }) => (
  <TablaFinanzas>
    <TablaBodyFinanzas>
      <tr>
        <td>
          <TablaBodyTextoFinanzaPrecio>
            Precio: S/ {Number(precio).toFixed(2)}
          </TablaBodyTextoFinanzaPrecio>
        </td>
      </tr>
    </TablaBodyFinanzas>
  </TablaFinanzas>
);

const Tarjeta = ({ orden, onEditar, onEnviar, onAnular, onVer }) => {
  const { fecEnv, pacienteNombres, items, estado, centroRxNombre } = orden;
  function dateToString(fecha) {
    if (typeof fecha === "object") {
      const a = fecha.getFullYear();
      const m = (fecha.getMonth() + 1).toString().padStart(2, "0");
      const d = fecha.getDate().toString().padStart(2, "0");
      return `${a}-${m}-${d}`;
    }
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  let titleCentro = centroRxNombre;
  return (
    <ContenedorTarjetas className="cardContainer">
      <ContenedorCabeceraTarjeta
        idx={titleCentro}
        title={titleCentro}
        action={
          <div>
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <div onClick={handleClose}>
                      {orden.estado == ESTADOS.PENDIENTE && (
                        <Buttons
                          aria-label=""
                          onClick={() => onEditar(orden)}
                          isShown={true}
                        >
                          <EditIcon size="small" style={{ color: "#0089ff" }} />
                          Editar
                        </Buttons>
                      )}
                    </div>
                    <div onClick={handleClose}>
                      {(orden.estado === ESTADOS.ENVIADO ||
                        orden.estado === ESTADOS.PENDIENTE) && (
                        <Buttons
                          aria-label=""
                          onClick={() => onAnular(orden)}
                          isShown={true}
                        >
                          <DeleteIcon
                            size="small"
                            style={{ color: "#ff0000", outline: "none" }}
                          />
                          Eliminar
                        </Buttons>
                      )}
                    </div>
                  </Paper>
                </Fade>
              )}
            </Popper>

            <IconButton
              aria-label="settings"
              onClick={handleClick("bottom-end")}
            >
              <MoreVertIcon />
            </IconButton>
          </div>
        }
      />

      <ContenedorContenidoTarjeta>
        <div className="divFecha">{`${dateToString(fecEnv)}`}</div>
        <TablaBodyTextoFinanzaPrecio>
          <div className="statusDiv">
            <span className="statusSpan">{estado}</span>
          </div>
        </TablaBodyTextoFinanzaPrecio>

        <div>
          <UnOrderedList className="scrollDiv">
            {items.map((item) => (
              <li>{`${item.grupo} - ${item.descripcion}`}</li>
            ))}
          </UnOrderedList>
        </div>
      </ContenedorContenidoTarjeta>

      <TarjetaAccion disableSpacing>
        {orden.estado == ESTADOS.PENDIENTE && (
          <Button aria-label="" onClick={() => onEnviar(orden)} isShown={true}>
            Enviar
          </Button>
        )}
        {orden.estado === ESTADOS.ENVIADO && (
          <IconButton aria-label="" onClick={() => onVer(orden)} isShown={true}>
            <Tooltip TransitionComponent={Zoom} placement="top" title="Ver">
              <VisibilityIcon style={{ color: "green" }} />
            </Tooltip>
          </IconButton>
        )}
      </TarjetaAccion>
    </ContenedorTarjetas>
  );
};

export const OrdenesRx = () => {
  const dispatch = useDispatch();
  const paciente = useSelector((state) => state.paciente);
  const ordenesRx = useSelector((state) => state.ordenesRx);
  const ordenRx = useSelector((state) => state.ordenRx);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalEnviarIsOpen, setEnviarModalIsOpen] = React.useState(false);
  const [modalAnularIsOpen, setAnularModalIsOpen] = React.useState(false);
  const { addToast } = useToasts();
  const [accion, setAccion] = React.useState("NUEVA");
  React.useEffect(() => {
    dispatch(getCentrosRx());
    dispatch(getOrdenesRxPaciente(paciente.id));
  }, []);

  const handleNueva = () => {
    setAccion("NUEVA");
    dispatch(newOrden());
    setModalIsOpen(true);
  };
  const handleEdit = (item) => {
    dispatch(setOrden(item));
    setAccion("EDITAR");
    setModalIsOpen(true);
  };
  const handleEnviar = (item) => {
    dispatch(setOrden(item));
    setAccion("ENVIAR");
    setEnviarModalIsOpen(true);
  };
  const handleConfirmarEnviar = () => {
    try {
      dispatch(sendOrden(ordenRx));
      setEnviarModalIsOpen(false);
      addToast("Grabación satisfactoria", { appearance: "success" });
    } catch (err) {
      addToast(`Ocurrió un error ${err.message}`, { appearance: "error" });
    }
  };

  const handleConfirmarAnular = () => {
    try {
      dispatch(anulaOrden(ordenRx));

      setAnularModalIsOpen(false);
      addToast("Anulación satisfactoria", { appearance: "success" });
    } catch (err) {
      addToast(`Ocurrió un error ${err.message}`, { appearance: "error" });
    }
  };

  const handleAnular = (item) => {
    dispatch(setOrden(item));
    setAccion("ANULAR");
    setAnularModalIsOpen(true);
  };

  const handleVer = (item) => {
    dispatch(setOrden(item));
    setAccion("VER");
    setModalIsOpen(true);
  };

  return (
    <React.Fragment>
      <TopBarPaciente />
      <MainContainer>
        <div>
          <Button onClick={handleNueva} variant="contained" size="large">
            Nueva Orden
          </Button>
        </div>
        <SectionTarjeta>
          {ordenesRx.map((orden) => (
            <Tarjeta
              orden={orden}
              onEditar={handleEdit}
              onEnviar={handleEnviar}
              onAnular={handleAnular}
              onVer={handleVer}
            />
          ))}
        </SectionTarjeta>
        <Modal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
          <OrdenRx onSave={() => setModalIsOpen(false)} accion={accion} />
        </Modal>
        <ConfirmacionSiNoModal
          mensaje="Desea enviar esta orden?"
          isOpen={modalEnviarIsOpen}
          handleCloseModal={() => setEnviarModalIsOpen(false)}
          onClickYes={handleConfirmarEnviar}
        />
        <ConfirmacionSiNoModal
          mensaje="Desea anular esta orden?"
          isOpen={modalAnularIsOpen}
          handleCloseModal={() => setAnularModalIsOpen(false)}
          onClickYes={handleConfirmarAnular}
        />
      </MainContainer>
    </React.Fragment>
  );
};
