import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { iniciarPrestacionTrx } from "../../redux/prestation/prestationActions";
import Spinner from 'react-bootstrap/Spinner';

export const IniciarPrestacion = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const cuenta = useSelector((state) => state.cuenta);
  const prestacion = useSelector((state) => state.prestacion);
  const [montoPago, setMontoPago] = React.useState(prestacion.precio);
  const paciente = useSelector((state) => state.paciente);
  const sesion = useSelector((state) => state.sesion);

  const agregaNotaInicioPrestacion = (prestacion) => {
    const nota = {
      fecha: Date.now(),
      nota: "Iniciada",
      especialista: sesion.usuario.email,
    };
    prestacion.notasClinicas = [nota, ...prestacion.notasClinicas];
  };

  const handleIniciar = (e) => {
    e.preventDefault();
    let presta = Object.assign({}, prestacion);
    presta.precio = Number(montoPago);
    agregaNotaInicioPrestacion(presta);
    dispatch(iniciarPrestacionTrx(presta, cuenta));
    history.push("/paciente/presupuesto");
  };

  const handleChange = (e) => {
    setMontoPago(Number(e.target.value));
  };
  const { id, fecha, tratamiento, especialista, precio, pagado, dientes } =
    prestacion;

  const movimiento = {
    estado: "V",
    fecha: new Date(),
    monto: 0,
    prestacionId: id,
    usuario: "AABRIL",
  };
  if (sesion.loading || sesion.loading == undefined) {
    return <div className="main__container"><Spinner animation="border" role="status">
      <span className="visually-hidden"></span>
    </Spinner></div>;
  } else if (sesion.error) {
    return <div>`Hubo un error ${sesion.error} `</div>;
  } else
    return (
      <div className="main__container">
        <form className="form form--simple" id="pagoprestacion">
          <div className="card__title">
            <h2 className="form__title">Iniciar Prestacion</h2>
          </div>
          <section>
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>Paciente: </strong>
                  </td>
                  <td>
                    <span>{paciente.nombres}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Fecha: </strong>
                  </td>
                  <td>
                    <span>{fecha}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Tratamiento: </strong>
                  </td>
                  <td>
                    <span>{tratamiento} </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Diente: </strong>
                  </td>
                  <td>
                    <span>{dientes}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Especialista: </strong>
                  </td>
                  <td>
                    <span>{especialista}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Precio: </strong>
                  </td>
                  <td>
                    <input
                      type="number"
                      min="0"
                      max="999999999"
                      step="0.01"
                      required
                      value={montoPago}
                      onChange={handleChange}
                    ></input>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Link
                      to="/paciente/presupuesto"
                      className="btn btn-warning"
                    >
                      Cancelar
                    </Link>
                  </td>
                  <td>
                    <button className="btn btn-success" onClick={handleIniciar}>
                      Iniciar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </form>
        {/* <ConfirmacionSiNoModal
        mensaje="Desea realizar esta operación?"
        isOpen={modalIsOpen}
        handleCloseModal={handleCloseModal}
        onPay={handlePayment}
      /> */}
      </div>
    );
};
