import React,{useState, useEffect} from "react";
import { fetchTratamientos } from "../services/firebase/tratamientos";
import { useSelector } from "react-redux";
export const useBuscarTratamientos = () => {
  const [query, setQuery] = React.useState("");
  const empresa = useSelector((state) => state.empresa);
  const [loading, setLoading] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
 
  // const [filteredItems, setFilteredItems] = React.useState(
  //   tratamientos ? tratamientos.filter((tr) => tr.estado) : []
  // );
    // Debounce function
    const debounce = (fn, delay) => {
      let timeoutId;
      return function (...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          fn(...args);
        }, delay);
      };
    };
      // Fetch data from the backend API
  const fetchData = async (query) => {
    try {
      setLoading(true)
      const tratamientos = await fetchTratamientos(empresa.id);
      // console.log('trata', tratamientos) 
      const filtroTratamientos = tratamientos.filter((item) => {
        return `${item.tratamiento} ${item.tipo}`
        .toLocaleLowerCase()
        .includes(query.toLocaleLowerCase());
      })
      setFilteredItems(filtroTratamientos)
      // setPacientes(filtroPacientes);
      setLoading(false)
      
    } catch (error) {
      console.error("Error fetching data:", error);
    } 
  };

    // Update filtered items with a delay (e.g., 300ms) after the query changes
    useEffect(() => {
      const delayedFiltering = debounce(() => {
        if (query.length > 0) {
          fetchData(query);
        } else {
          setFilteredItems([]); // Clear the list if the query is empty
        }
      }, 300); // Adjust the delay as needed (e.g., 300ms)
  
      delayedFiltering();
  
      // Clean up the timeout on component unmount
      return () => clearTimeout(delayedFiltering);
    }, [query]);
  
   
  // React.useMemo(() => {
  //   let result = [];
  //   if (query.length > 0 && tratamientos) {
  //     if (grupo == "NUCLEO") {
  //       result = tratamientos.filter((item) => {
  //         let config = subGrupos.find((it) => it.id === item.subGrupoId);
  //         return (
  //           `${item.tratamiento} ${item.tipo}`
  //             .toLocaleLowerCase()
  //             .includes(query.toLocaleLowerCase()) &&
  //           config.afectaOdonto === afectaOdonto &&
  //           config.grupo !== "CARAS"
  //         );
  //       });
  //     } else if (grupo == "CARAS") {
  //       result = tratamientos.filter((item) => {
  //         let config = subGrupos.find((it) => it.id === item.subGrupoId);
  //         return (
  //           `${item.tratamiento} ${item.tipo}`
  //             .toLocaleLowerCase()
  //             .includes(query.toLocaleLowerCase()) &&
  //           config.afectaOdonto === afectaOdonto &&
  //           config.grupo === grupo
  //         );
  //       });
  //     } else {
  //       result = tratamientos.filter((item) =>
  //         `${item.tratamiento} ${item.tipo}`
  //           .toLocaleLowerCase()
  //           .includes(query.toLocaleLowerCase())
  //       );
  //     }
  //   }

  //   setFilteredItems(result);
  // }, [tratamientos, query, grupo, afectaOdonto]);

  const clearFilteredItems = () => {
    setFilteredItems([]);
  };
  return {
    query,
    setQuery,
    filteredItems,
    clearFilteredItems,
    loading
    
  };
};
