import { SET_METAS, ADD_PRESTACION,REMOVE_PRESTACION } from "./metasTypes";

const initialState = {
  id: "",
  empresaId: "",
  prestaciones: [],
  
};

export const metasReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_METAS:
      return action.payload;
    case ADD_PRESTACION:
      return {...state, prestaciones: [...state.prestaciones, action.payload]};
    case REMOVE_PRESTACION:
      return {...state, prestaciones: state.prestaciones.filter((pr)=> pr.id !== action.payload.id)}
      default:
      return state;
  }
};
