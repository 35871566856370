import {
  ADD_PRESTATION,
  REMOVE_PRESTATION_IN_ARRAY,
  FETCH_PRESTATIONS_REQUEST,
  FETCH_PRESTATIONS_SUCCESS,
  FETCH_PRESTATIONS_FAILURE,
  UPDATE_PRESTATION_IN_ARRAY,
  UPDATE_LAB_IN_PRESTATION_IN_ARRAY,
  SAVE_PRESTATION_REQUEST,
  SAVE_PRESTATION_FAILURE,
  SAVE_PRESTATION_SUCCESS,
  NEW_PRESTATIONS,
} from "./prestationsTypes";

const initialState = {
  loading: false,
  error: null,
  items: [],
};
const prestacionesReducer = (state = initialState, action) => {
  switch (action.type) {
    case NEW_PRESTATIONS:
      return initialState;
    case FETCH_PRESTATIONS_REQUEST:
    case SAVE_PRESTATION_REQUEST:
      return { ...state, loading: true };
    case SAVE_PRESTATION_SUCCESS:
      return { ...state, loading: false };
    case FETCH_PRESTATIONS_SUCCESS:
      return { loading: false, error: "", items: action.payload };
    case SAVE_PRESTATION_FAILURE:
    case FETCH_PRESTATIONS_FAILURE:
      return { loading: false, error: action.payload, items: [] };
    case ADD_PRESTATION:
      return { ...state, items: [action.payload, ...state.items] };
    case REMOVE_PRESTATION_IN_ARRAY:
      return {
        ...state,
        items: state.items.filter(
          (prestation) => prestation.id !== action.payload.id
        ),
      };
    case UPDATE_PRESTATION_IN_ARRAY:
      const index = state.items.findIndex(
        (prestation) => prestation.id === action.payload.id
      );
      const newArray = [...state.items];
      newArray[index] = action.payload;
      return {
        ...state,
        items: newArray,
      };
    case UPDATE_LAB_IN_PRESTATION_IN_ARRAY:
      let idx = state.items.findIndex(
        (prestation) => prestation.id === action.payload.prestacionId
      );
      let nArray = [...state.items];
      if (idx > -1) nArray[idx]["lab"] = action.payload;
      return {
        ...state,
        items: nArray,
      };
    default:
      return state;
  }
};

export default prestacionesReducer;
