import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../../../components/Modal/index";
import BuscarTratamientos from "../../../../components/BuscarTratamientos/index";
import Back from "../../../../img/back.png";

import {
  Denticion,
  INFANTIL,
  ADULTO,
  superiorDerecha,
  inferiorDerecha,
  superiorIzquierda,
  inferiorIzquierda,
  tratamientos,
} from "../../../../components/ConfigDiente";
import {
  DienteContenedorRaiz,
  DienteContenedorCorona,
  DienteContenedor,
  DienteContenedorInferior,
  TratamientoDiv,
  DienteContenedorNumero,
  DenticionSide,
  DenticionContainer,
  DenticionBlock,
  SpanTitulo,
  SpanVariables,
  Container,
  ContainerTitle,
  ContainerTitleText,
  ContenedorAuxiliar,
  ContainerNuevaPestacion,
  ButtonAgregar,
  BackContainer,
  Img,
  SectionContainer,
  Centrado,
  StyledSelect,
  DivInfo,
  ImgIcon,
} from "./styles";
import { CPrestacion, Estado, EstFin } from "../../../../Entidades/Prestaciones";
import { useToasts } from "react-toast-notifications";
import { newPrestation } from "../../../../redux/prestation/prestationActions";
import { fetchSubGruposTra } from "../../../../services/firebase/subGruposTra";

export const NuevaPrestacionModal = (props) => {
  const [tratamiento, setTratamiento] = useState({ config: {} });
  const paciente = useSelector((state) => state.paciente);
  const [ShowDientes, setShowDientes] = useState(false);
  const sesion = useSelector((state) => state.sesion);
  const dispatch = useDispatch();
  const [dientes, setDientes] = useState([]);
  const [subGrupos, setSubGrupos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tipoDenticion, setTipoDenticion] = React.useState(ADULTO);
  const odontograma = useSelector((state) => state.odontograma);
  const getConfig = (subGrupoId) => {
    let config = {
      grupo: "",
      protesisTipo: "",
      envLab: "",
      afectaOdonto: "",
      multiplesDientes: "",
      color: "",
      id: "",
      coronaTipo: "",
    };
    let miConfig = subGrupos.find((it) => it.id === subGrupoId);

    return miConfig ? miConfig : config;
  };

  const {
    grupo,
    protesisTipo,
    envLab,
    afectaOdonto,
    multiplesDientes,
    color,
    id,
    coronaTipo,
  } = getConfig(tratamiento.subGrupoId);
  const CORONA = "CORONA";
  const PUENTE = "PUENTE";
  const PROTESIS_REMOVIBLE = "PROTESIS_REMOVIBLE";
  const TOTAL_SUPERIOR = "TOTAL_SUPERIOR";
  const TOTAL_INFERIOR = "TOTAL_INFERIOR";
  const SUPERIOR = "SUPERIOR";
  const INFERIOR = "INFERIOR";
  const LIBRE = "LIBRE";
  const EXODONCIA = "EXODONCIA";
  const IMPLANTE = "IMPLANTE";
  const NUCLEO = "NUCLEO";
  const CARAS = "CARAS";
  const miDiente = {
    id: "",
    tipo: "",
    lados: [],
  };
  const { addToast } = useToasts();
  

  const fetchData = async () => {
    try {
      setLoading(true)
      const subGrupos = await fetchSubGruposTra();
      setSubGrupos(subGrupos)
      setLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error);
    } 
  };
  React.useEffect(() => {
    fetchData()
    dispatch(newPrestation());
  }, []);

  const handleSelectedTooth = (diente) => {
    try {
      validaSeleccion(diente);
      if (!getValue(diente)) {
        setDientes([...dientes, diente]);
      } else {
        let filtro = dientes.filter((d) => d !== diente);
        setDientes(filtro);
      }
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const validaSeleccion = (diente) => {
    const DIENTES_SUPERIORES = [
      11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 23, 24, 25, 26, 27, 28,
    ];
    const DIENTES_INFERIORES = [
      31, 32, 33, 34, 35, 36, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48,
    ];
    if (grupo == CORONA) {
      if (protesisTipo == SUPERIOR)
        if (!DIENTES_SUPERIORES.some((d) => d == diente))
          throw new Error("Seleccione solo dientes superiores");

      if (protesisTipo == INFERIOR)
        if (!DIENTES_INFERIORES.some((d) => d == diente))
          throw new Error("Seleccione solo dientes inferiores");

      if (protesisTipo == TOTAL_SUPERIOR || protesisTipo == TOTAL_INFERIOR)
        throw new Error("No puede modificar esta seleccion");
    }
    let itemOdonto = odontograma.items.find((it) => it.diente == diente);
    if (itemOdonto) {
      if (itemOdonto.estado === EXODONCIA || itemOdonto.estado === IMPLANTE) {
        if (grupo == NUCLEO || grupo == CARAS)
          throw new Error("Diente con " + itemOdonto.estado);
      }
    }
  };

  const getValue = (id) => {
    return dientes.some((d) => d == id);
  };

  const handleSelectedItem = (item) => {
    try {
      let miConfig = subGrupos.find((it) => it.id === item.subGrupoId);
      item.config = miConfig;
      setTratamiento({ ...item, config: miConfig });
      autocompletar(item);
      setShowDientes(true);
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };
  const ordenaDientes = (dientes) => {
    function orden(a, b) {
      return a.pos - b.pos;
    }
    function existe(dt) {
      return dientes.some((d) => dt.diente == d);
    }
    let dientesFiltrados = Denticion.dientes.filter((dt) => existe(dt));

    let dientesOrdenados = dientesFiltrados.sort(orden);
    return dientesOrdenados;
  };

  const autocompletar = (item) => {
    let { grupo, protesisTipo } = getConfig(item.subGrupoId);
    if (grupo == CORONA) {
      if (protesisTipo == TOTAL_SUPERIOR)
        setDientes([
          11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 23, 24, 25, 26, 27, 28,
        ]);
      if (protesisTipo == TOTAL_INFERIOR)
        setDientes([
          31, 32, 33, 34, 35, 36, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48,
        ]);
    }
  };
  const getVeces = () => {
    let veces = 1;
    if (grupo == CORONA && coronaTipo !== "PROTESIS_REMOVIBLE")
      veces = dientes.length;
    return veces;
  };

  const handleClick = () => {
    // aqui se crea el objeto prestacion y se agregan los dientes seleccionados

    try {
      validar(tratamiento);
      let prestacion = {
        id: "",
        pacienteId: paciente.id,
        especialidad: tratamiento.especialidad,
        tratamiento: tratamiento.tratamiento,
        tipo: tratamiento.tipo,
        precio: tratamiento.precio,
        precioOrigin: tratamiento.precio,
        dscto: 0,
        costo: tratamiento.costo?tratamiento.costo:0,
        tiempo: tratamiento.tiempo?tratamiento.tiempo:30,
        pagado: 0,
        dientes: [],
        caras: [],
        notasClinicas: [],
        fechaInicio: new Date(),
        fechaFin: "",
        estado: Estado.PENDIENTE,
        estfin: EstFin.PENDIENTE,
        config: getConfig(tratamiento.subGrupoId),
        lab: {},
        tratamientoId: tratamiento.id,
        empresaId: sesion.usuario.empresaId,
        nota: "",
      };

      if (multiplesDientes) {
        prestacion.dientes = ordenaDientes(dientes).map((it) => it.diente);
        let veces = getVeces();
        prestacion.precio = Number(tratamiento.precio) * veces;
        if (envLab) {
          prestacion.lab = {
            envLab: envLab,
            codLab: tratamiento.codLab,
          };
        }
        props.handleAddToPlan(prestacion);
      } else {
        if (afectaOdonto) {
          dientes.forEach((diente) => {
            let prestacion = {
              id: "",
              pacienteId: paciente.id,
              especialidad: tratamiento.especialidad,
              tratamiento: tratamiento.tratamiento,
              tipo: tratamiento.tipo,
              precio: tratamiento.precio,
              costo: tratamiento.costo?tratamiento.costo:0,
              precioOrigin:tratamiento.precio?tratamiento.precio:0,
              dscto: 0,
              tiempo: tratamiento.tiempo?tratamiento.tiempo:30,
              pagado: 0,
              dientes: [diente],
              caras: [],
              notasClinicas: [],
              fechaInicio: new Date(),
              fechaFin: "",
              estado: Estado.PENDIENTE,
              estfin: EstFin.PENDIENTE,
              config: getConfig(tratamiento.subGrupoId),
              lab: {},
              tratamientoId: tratamiento.id,
              empresaId: sesion.usuario.empresaId,
              nota: "",
            };
            if (envLab) {
              prestacion.lab = {
                envLab: envLab,
                codLab: tratamiento.codLab,
              };
            }
            props.handleAddToPlan(prestacion);
          });
        } else {
          if (envLab) {
            prestacion.lab = {
              envLab: envLab,
              codLab: tratamiento.codLab,
            };
          }
          props.handleAddToPlan(prestacion);
        }
      }
      props.handleCloseModal();
      setTratamiento({ config: {} });
      setDientes([]);
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };
  const validar = (item) => {
    let config = getConfig(item.subGrupoId);
    const {
      grupo,
      protesisTipo,
      envLab,
      afectaOdonto,
      multiplesDientes,
      color,
      id,
      coronaTipo,
    } = config;

    if (grupo === CORONA) {
      if (coronaTipo == null)
        throw new Error("Grupo corona no esta bien configurado");
      if (coronaTipo == PROTESIS_REMOVIBLE) {
        if (protesisTipo == null)
          throw new Error("Protesis no está bien configurada");
        if (multiplesDientes == false)
          throw new Error("Protesis removible no está bien configurada");
      }

      if (coronaTipo === PUENTE) {
        validaSeleccionContigua();
      }
    }

    if (afectaOdonto && dientes.length === 0) {
      throw new Error("Seleccione un diente");
    }
  };

  const validaSeleccionContigua = () => {
    const dientesOrdenados = ordenaDientes(dientes);
    let pos = dientesOrdenados[0].pos;
    dientesOrdenados.forEach((it) => {
      if (it.pos !== pos) throw new Error("Seleccione dientes colindantes");
      pos++;
    });
  };
  const printSelectedTooth = () => {
    let myItems = [];

    dientes.forEach((item) => {
      myItems.push(<span>{`${item},`}</span>);
    });
    return myItems;
  };

  const limpiar = () => {
    setTratamiento({ config: {} });
    setDientes([]);
    setShowDientes(false);
  };

  const DatosTratamiento = () => {
    return (
      <ContainerNuevaPestacion>
        <BackContainer>
          <figure>
            <ImgIcon onClick={() => limpiar({})} src={Back}></ImgIcon>
          </figure>
        </BackContainer>
        <TratamientoDiv>
          <DivInfo>
            <SpanTitulo>Prestación: </SpanTitulo>
            <SpanVariables>{`${tratamiento.tratamiento} ${tratamiento.tipo} (${tratamiento.precio * getVeces()
              })`}</SpanVariables>
          </DivInfo>
        </TratamientoDiv>
        <TratamientoDiv>
          <DivInfo>
            <SpanTitulo>Dientes:</SpanTitulo>
            {<SpanVariables>{printSelectedTooth()}</SpanVariables>}
          </DivInfo>
        </TratamientoDiv>
        <TratamientoDiv>
          <ButtonAgregar onClick={handleClick}>Agregar</ButtonAgregar>
        </TratamientoDiv>
      </ContainerNuevaPestacion>
    );
  };

  const DienteSuperior = ({ item }) => {
    const { diente, nombre } = item;
    const imgDiente = require(`../../../../assets/svg/${nombre}.svg`);
    return (
      <DienteContenedor>
        <DienteContenedorRaiz onClick={() => handleSelectedTooth(diente)}>
          <Img src={imgDiente} />
        </DienteContenedorRaiz>
        <ContenedorAuxiliar id="contenedoprmar">
          <DienteContenedorCorona onClick={() => handleSelectedTooth(diente)}>
            {diente}
          </DienteContenedorCorona>

          <DienteContenedorNumero>
            <input
              type="checkbox"
              name="dientes"
              onChange={() => handleSelectedTooth(diente)}
              checked={getValue(diente)}
            />
          </DienteContenedorNumero>
        </ContenedorAuxiliar>
      </DienteContenedor>
    );
  };

  const DienteInferior = ({ item }) => {
    const { diente, nombre, patologia } = item;
    const imgDiente = require(`../../../../assets/svg/${nombre}.svg`);

    return (
      <DienteContenedorInferior>
        <ContenedorAuxiliar>
          <DienteContenedorNumero>
            <input
              type="checkbox"
              name="dientes"
              onChange={() => handleSelectedTooth(diente)}
              checked={getValue(diente)}
            />
          </DienteContenedorNumero>
          <DienteContenedorCorona onClick={() => handleSelectedTooth(diente)}>
            {diente}
          </DienteContenedorCorona>
        </ContenedorAuxiliar>
        <DienteContenedorRaiz onClick={() => handleSelectedTooth(diente)}>
          <Img src={imgDiente} />
        </DienteContenedorRaiz>
      </DienteContenedorInferior>
    );
  };

  const generaDientes = (items) => {
    let dientes = [];

    items.forEach((item) => {
      if (item.lado == superiorIzquierda || item.lado == superiorDerecha)
        dientes.push(<DienteSuperior key={item.diente} item={item} />);
      else dientes.push(<DienteInferior key={item.diente} item={item} />);
    });

    return dientes;
  };

  const handleChangeTipoDenticion = (e) => {
    let tipo = e.target.value;
    setTipoDenticion(tipo);
  };
  return (
    <Modal isOpen={props.isOpen} onClose={props.handleCloseModal}>
      <Container>
        <ContainerTitle>
          <ContainerTitleText>Nueva Prestacion</ContainerTitleText>
        </ContainerTitle>
        <SectionContainer>
          <label className="label-styled" for="tipo">
            Tipo Dentición:
          </label>
          <StyledSelect id="tipo" onChange={handleChangeTipoDenticion}>
            <option value={ADULTO}>Adulta</option>
            <option value={INFANTIL}>Infantil</option>
          </StyledSelect>
        </SectionContainer>
        {tratamiento.tratamiento ? (
          <DatosTratamiento />
        ) : (
          <BuscarTratamientos onSelected={handleSelectedItem} />
        )}
        <div>
          <Centrado>
            {ShowDientes ? (
              <DenticionContainer>
                <DenticionBlock>
                  <DenticionSide>
                    {generaDientes(
                      Denticion.getDientes(tipoDenticion, superiorDerecha)
                    )}

                    {generaDientes(
                      Denticion.getDientes(tipoDenticion, superiorIzquierda)
                    )}
                  </DenticionSide>
                </DenticionBlock>

                <DenticionBlock>
                  <DenticionSide>
                    {generaDientes(
                      Denticion.getDientes(tipoDenticion, inferiorDerecha)
                    )}
                    {generaDientes(
                      Denticion.getDientes(tipoDenticion, inferiorIzquierda)
                    )}
                  </DenticionSide>
                </DenticionBlock>
              </DenticionContainer>
            ) : (
              <div> </div>
            )}
          </Centrado>
        </div>
      </Container>
    </Modal>
  );
};
