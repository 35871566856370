import styled from "styled-components";
import { dispositivo } from "../../components/Dispositivos";

export const MainContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
width: 100%;
padding-left: 3%;
padding-right: 3%;
background: #f8f8f8;
justify-content: center;
text-align: center;
`;
export const ContainerTitle = styled.div`
font-size: 12px;
font-weight: normal;
text-align: center;
padding: 2.5%;
`;
export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2%;
  a{
    text-decoration: none;
    color: #000;
    :hover{
      text-decoration: none;
      color: #0060B0;
    }
  }
`;
export const ContainerTitleText = styled.h2`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  @media ${dispositivo.mobileL} {
    font-size: 16px;
  }
  @media ${dispositivo.tablet} {
    font-size: 20px;
  }
`;

export const ContainerBody = styled.div`
  width: 98%;
  height: 100%;
  padding: 2%;
  margin-left: 1%;
  margin-right: 1%;
  background: #fff;
  box-shadow: 2px 2px #88888877;
  border-radius: 14px;
  @media ${dispositivo.mobileL} {
    padding: 3%;
  }
  @media ${dispositivo.tablet} {
    padding: 4%;
  }
`;

export const Tabla = styled.table`
  width: 100%;
}
`;

export const Fila = styled.tr`
  background-color: ${(props) => (props.idx % 2 == 0 ? "#69bdf5" : "#fff")};
  font-size: 0.625rem;
  @media ${dispositivo.mobileL} {
    font-size: 0.75rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }

  `;
export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const TablaHeaderTexto = styled.th`
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;

export const TablaBody = styled.tbody`
  background: #fff;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  border: 2px solid #ccc;
  padding-bottom: 16px;
`;

export const TablaBodyTexto = styled.td`
  font-size: 0.688rem;
  color: #045184;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;

export const ContenedorTabla = styled.div`
  @media ${dispositivo.tablet} {
    flex: 1;
    // max-height: 370px;
    // overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 20px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #0c8de3;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #fff;
    }
  }
`;
