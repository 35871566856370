import React from "react";
import { useHistory, Redirect } from "react-router-dom";
import { ConfirmacionSiNoModal } from "../../components/ConfirmacionSiNoModal/index";
import { updatePrestation } from "../../redux/prestation/prestationActions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { payPrestacionTrx } from "../../redux/prestation/prestationActions";
import {
  MainContainer,
  ContainerTitle,
  ContainerTitleText,
  ButtonAbonar,
  BodyContainer,
  TablaBody,
  TablaHeaderTexto,
  TablaHeaderTextoItems,
  TablaHeaderTextoValues,
  TablaHeaderTextoNumber,
  Tabla,
  StyledForm,
  StyledInput,
  LinkCancelar,
  ButtonPagar,
  Buttons,
  ContainerTabla,
} from "./styles";
import { useToasts } from "react-toast-notifications";

export const PagoPrestacion = () => {
  const history = useHistory();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [montoPago, setMontoPago] = React.useState("");
  const cuenta = useSelector((state) => state.cuenta);
  const prestacion = useSelector((state) => state.prestacion);
  const sesion = useSelector((state) => state.sesion);
  const paciente = useSelector((state) => state.paciente);
  const dispatch = useDispatch();
  const handleCloseModal = (e) => {
    setModalIsOpen(false);
  };
  const { addToast } = useToasts();

  const handleOpenModal = (e) => {
    e.preventDefault();
    const myForm = document.getElementById("pagoprestacion");
    // TODO: Insertar la transaccion de movimiento, cambiar el estado a pagado, actualizar el
    try {
      myForm.checkValidity();
      myForm.reportValidity();
      validar();
      if (myForm.checkValidity() && myForm.reportValidity()) {
        setModalIsOpen(true);
        //history.push("/prestaciones");
      }
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const handlePayment = (e) => {
    e.preventDefault();
    try {
      validar();
      const movimiento = {
        monto: Number(montoPago),
        moneda: "SOLES",
        fecha: new Date(),
        usuario: {email:sesion.usuario.email, nombres: sesion.usuario.nombres},
        prestaciones: [{id:prestacion.id, costo:prestacion.costo?prestacion.costo:0, pagado:prestacion.pagado, precio:prestacion.precio, tratamiento:prestacion.tratamiento, dientes:prestacion.dientes, estado:prestacion.estado, estFin: prestacion.estfin, fechaFin: prestacion.fechaFin,  pagadoMov: montoPago}],
        prestacionId: prestacion.id,
        prestacionNombre: `${prestacion.tratamiento} ${prestacion.tipo ? prestacion.tipo : ""
          }`,
        pacienteId: prestacion.pacienteId,
        pacienteNombres: `${paciente.apellidos} ${paciente.nombres}`,
        tipMov: "PAGO_PRESTA",
        cuentaId: cuenta.id,
        empresaId: sesion.usuario.empresaId,
      };
      dispatch(payPrestacionTrx(prestacion, cuenta, movimiento));
      setModalIsOpen(false);
      addToast("Pago satisfactorio", { appearance: "success" });
      history.push("/paciente/pagos");
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };
  const validar = () => {
    let numerosExp = /^\d+([.]\d{1,2})?$/;
    if (!numerosExp.test(montoPago)) {
      throw new Error("Ingrese un monto válido");
    }

    if (
      Number(prestacion.precio) -
      Number(prestacion.pagado) -
      Number(montoPago) <
      0
    )
      throw new Error("Monto de pago mayor a la deuda");
    if (Number(cuenta.saldo) < Number(montoPago))
      throw new Error("No tiene saldo en la cuenta, realice un abono");
  };
  const handleChange = (e) => {
    setMontoPago(e.target.value);
  };

  const { tratamiento, especialista, precio, pagado } = prestacion;

  if (sesion.loading || sesion.loading == undefined)
    return <div>...procesando</div>;
  else if (sesion.error) return <div>{sesion.error}</div>;
  else
    return (
      <MainContainer>
        <ContainerTabla>
          <ContainerTitle>
            <ContainerTitleText>Pago de Prestación</ContainerTitleText>
          </ContainerTitle>
          <BodyContainer>
            <StyledForm id="pagoprestacion" onSubmit={handlePayment}>
              <Tabla>
                <TablaBody>
                  <tr>
                    <TablaHeaderTextoItems>
                      <span>Tratamiento: </span>
                    </TablaHeaderTextoItems>
                    <TablaHeaderTextoValues>
                      <span>{tratamiento}</span>
                    </TablaHeaderTextoValues>
                  </tr>

                  <tr>
                    <TablaHeaderTextoItems>
                      <span>Precio: </span>
                    </TablaHeaderTextoItems>
                    <TablaHeaderTextoNumber>
                      <span>{`S/ ${precio}`}</span>
                    </TablaHeaderTextoNumber>
                  </tr>

                  <tr>
                    <TablaHeaderTextoItems>
                      <span>Pagado: </span>
                    </TablaHeaderTextoItems>
                    <TablaHeaderTextoNumber>
                      <span>{`S/ ${pagado}`}</span>
                    </TablaHeaderTextoNumber>
                  </tr>

                  <tr>
                    <TablaHeaderTextoItems>
                      <span>Saldo: </span>
                    </TablaHeaderTextoItems>
                    <TablaHeaderTextoNumber>
                      <span className="color-saldo">{`S/ ${Math.round((precio - pagado) * 100) / 100
                        }`}</span>
                    </TablaHeaderTextoNumber>
                  </tr>
                  <tr>
                    <TablaHeaderTextoItems>
                      <span>Moneda: </span>
                    </TablaHeaderTextoItems>
                    <TablaHeaderTexto>
                      <span>SOLES</span>
                    </TablaHeaderTexto>
                  </tr>

                  <tr>
                    <TablaHeaderTextoItems>
                      <span>Monto a pagar: </span>
                    </TablaHeaderTextoItems>
                    <TablaHeaderTexto>
                      <StyledInput
                        type="text"
                        required
                        value={montoPago}
                        onChange={handleChange}
                      ></StyledInput>
                    </TablaHeaderTexto>
                  </tr>
                </TablaBody>
              </Tabla>
            </StyledForm>
          </BodyContainer>
          <Buttons>
            <LinkCancelar to='/paciente/pagos' >Cancelar</LinkCancelar>

            <ButtonPagar onClick={handleOpenModal}>Pagar</ButtonPagar>

          </Buttons>
          <ConfirmacionSiNoModal
            mensaje="¿Esta seguro de realizar esta operacion?"
            isOpen={modalIsOpen}
            handleCloseModal={handleCloseModal}
            onClickYes={handlePayment}
          />
        </ContainerTabla>
      </MainContainer>
    );
};
