import { SET_EMPRESA } from "./empresaTypes";

const initialState = {
  id: "",
  razonSocial: "",
  ruc: false,
  googleCalendar: "",
  telefono: "",
  fecIni: new Date(),
  fecUltPago: new Date(),
  tipoMembresia: "DEMO",
  logoUrl: "",
};

export const empresaReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMPRESA:
      return action.payload;
    default:
      return state;
  }
};
