import styled from "styled-components";

export const AllergiesWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  align-self: center;
  border-radius: 10px;
  padding: 10px;
  background-color: #f44538;
  cursor: pointer;

  .allergies {
    &__img {
      height: 25px;
      width: 25px;
      margin-right: 5px;
    }

    &__title {
      font-weight: bolder;
    }

    &__data {
      display: flex;
      justify-content: center;
      flex-direction: column;
      max-height: 0;
      overflow: hidden;

      &__allergy {
        margin: 0;
      }
    }
  }

  .flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover .allergies__data{
    max-height: 500px;
    transition: all 0.5s ease;
  }
`;
