import React, { useState } from "react";
import {
  MainContainer,
  ContainerTitle,
  ContainerTitleText,
  ContainerBody,
  StyledButton,
  Tabla,
  TablaHeader,
  TablaBody,
  Button,
} from "./styles";
export const Consentimientos = () => {
  return (
    <>
      <MainContainer>
        <ContainerTitle>
          <ContainerTitleText>Consentimientos </ContainerTitleText>
        </ContainerTitle>
        <ContainerBody>
          <ul>
            <li>
              <a
                href="http://www.cop.org.pe/wp-content/uploads/2015/05/Apicectomía-corregida.pdf"
                target="_blank"
              >
                Apicectomía
              </a>
            </li>
            <li>
              <a
                href="http://www.cop.org.pe/wp-content/uploads/2015/05/Caninos-Retenidos.pdf"
                target="_blank"
              >
                Caninos retenidos
              </a>
            </li>

            <li>
              <a
                href="http://www.cop.org.pe/wp-content/uploads/2015/05/Cirugía-3era-molar.pdf"
                target="_blank"
              >
                Cirugía tercera molar
              </a>
            </li>
            <li>
              <a
                href="http://www.cop.org.pe/wp-content/uploads/2015/05/Cirugía-Apical.pdf"
                target="_blank"
              >
                Cirugía apical
              </a>
            </li>
            <li>
              <a
                href="http://www.cop.org.pe/wp-content/uploads/2015/05/Cirugía-Bucal-Menor.pdf"
                target="_blank"
              >
                Cirugía bucal menor
              </a>
            </li>
            <li>
              <a
                href="http://www.cop.org.pe/wp-content/uploads/2015/05/Cirugía-Ortognática.pdf"
                target="_blank"
              >
                Cirugía ortognática
              </a>
            </li>
            <li>
              <a
                href="http://www.cop.org.pe/wp-content/uploads/2015/05/ENDODONCIA.pdf"
                target="_blank"
              >
                Endodoncia
              </a>
            </li>
            <li>
              <a
                href="http://www.cop.org.pe/wp-content/uploads/2015/05/Exodoncia-Simple.pdf"
                target="_blank"
              >
                Exodoncia simple
              </a>
            </li>
            <li>
              <a
                href="http://www.cop.org.pe/wp-content/uploads/2015/05/Implantes.pdf"
                target="_blank"
              >
                Implantes
              </a>
            </li>
            <li>
              <a
                href="http://www.cop.org.pe/wp-content/uploads/2015/05/Normas-tecnicas-del-Odontograma.pdf"
                target="_blank"
              >
                Normas técnicas del odontograma
              </a>
            </li>
            <li>
              <a
                href="http://www.cop.org.pe/wp-content/uploads/2015/05/Operatoria.pdf"
                target="_blank"
              >
                Operatoria
              </a>
            </li>

            <li>
              <a
                href="http://www.cop.org.pe/wp-content/uploads/2015/05/Ortodoncia.pdf"
                target="_blank"
              >
                Ortodoncia
              </a>
            </li>
            <li>
              <a
                href="http://www.cop.org.pe/wp-content/uploads/2015/05/Periodoncia.pdf"
                target="_blank"
              >
                Periodoncia
              </a>
            </li>
            <li>
              <a
                href="http://www.cop.org.pe/wp-content/uploads/2015/05/Prótesis-Fija.pdf"
                target="_blank"
              >
                Prótesis fija
              </a>
            </li>
            <li>
              <a
                href="http://www.cop.org.pe/wp-content/uploads/2015/05/Tercer-Molar.pdf"
                target="_blank"
              >
                Tercer molar
              </a>
            </li>
            <li>
              <a
                href="http://www.cop.org.pe/wp-content/uploads/2015/05/Tratamiento-General.pdf"
                target="_blank"
              >
                Tratamiento general
              </a>
            </li>
          </ul>
        </ContainerBody>
      </MainContainer>
    </>
  );
};
