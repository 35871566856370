import styled from "styled-components";
import { Link } from "react-router-dom";
import { dispositivo } from "../../components/Dispositivos";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";

export const SectionTarjeta = styled.section`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 1rem;
  .cardContainer{
    border-radius: 15px;
    padding: 5px 0px;
  }
  @media ${dispositivo.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const ContenedorTarjetas = styled(Card)`
  display: block;
  padding: 5px;
  max-width: 550px;
  max-height: 420px;
  width: 100%;
  font-size: 10px;
  margin: 1%;
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const ContenedorCabeceraTarjeta = styled(CardHeader)`
  color: #707070;
  background: #fff;
  padding: 2% !important;
  border-bottom: 1px solid #AAA4A6;
`;

export const ContenedorContenidoTarjeta = styled(CardContent)`
  display: block;
  color: #707070;
  height: 265px;
  padding: 0px 16px !important;
  .divFecha{
    display: flex;
    justify-content: space-between;
    margin: 3px 0 5px 0;
    font-size: 14px;
  }
  .pacienteDiv{
    color: #00BB56;
    font-size: 14px;
    margin-bottom: 0.5em;
    @media ${dispositivo.tablet} {
      font-size: 16px;
    }
  }
`;

export const TarjetaAccion = styled(CardActions)`
  justify-content: center;
  padding: 1.125em 0 !important;
`;
export const Icono = styled.div`
  margin: 0%;
  cursor: pointer;
`;

export const MainContainer = styled.div`
  margin-top: 1.5rem;
  width: 100%;
  height: 100%;
  padding-left: 4%;
  padding-right: 4%;
  background: #f8f8f8;
  justify-content: center;
  text-align: center;
`;

export const ContainerTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;

export const ContainerTitleText = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;

export const Button = styled.button`
  height: 2.25rem;
  font-size: 0.9rem;  
  width: 7rem;
  border-radius: 0.8rem;      
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #0060b0;
  color: #ffffff;
  border: none;
  &:hover{
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.tablet}{
   width: 10rem;
   font-size: 1rem;  
  }  
  @media ${dispositivo.laptop} {      
    width: 12.5rem;
  }
`;

export const Buttons = styled.button`
  height: 2.25rem;
  font-size: 0.9rem;  
  width: 7rem;  
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #ffffff;
  border: none;
  border-bottom: 1px solid #0C8DE3;
  &:hover{
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.tablet}{
   font-size: 1rem;  
  }  
`;
export const TablaBodyFinanzas = styled.tbody`
  background: #fff;
  padding-left: 2%;
  padding-right: 0%;
  text-align: left;
`;

export const TablaBodyTextoFinanzas = styled.th`
  background: #fff;
  color: #707070;
`;

export const TablaBodyTextoFinanzaPrecio = styled.div`
  font-size: 14px;
  padding: 1% 2%;
  text-align: center;
  color: #040404;
  .statusDiv{
    margin: 0.5em 0;
    font-size: 16px;
  }
  .statusSpan{
    font-weight: bold;
    font-size: 18px;
    color: #00BB31;
    @media ${dispositivo.tablet} {
      font-size: 20px;
    }
  } 
 .priceSpan{
  font-weight: bold;
  font-size: 16px;
  color: #3297A9;
  @media ${dispositivo.tablet} {
    font-size: 20px;
  }
}
`;

export const TablaBodyTextoFinanzaPagado = styled.div`
  font-size: 12px;
  padding-left: 2%;
  padding-right: 2%;
  border: 3px solid #2cb742;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 10px;
  text-align: center;
  margin-left: 5%;
  margin-right: 5%;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const TablaBodyTextoSaldo = styled.div`
  font-size: 12px;
  padding-left: 2%;
  padding-right: 2%;
  border: 3px solid red;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 10px;
  text-align: center;
  margin-left: 5%;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const TablaFinanzas = styled.table`
  margin: 3%;
  width: 72%;
  margin-left: 0%;
  @media ${dispositivo.mobileL} {
    width: 90%;
  }
  @media ${dispositivo.tablet} {
    width: 100%;
  }
`;
export const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 2%;
  justify-content: center;
  text-align: center;
`;

export const StyledForm = styled.form`
  width: 90%;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const Tabla = styled.table`
  width: 99%;
  border-radius: 12px;
  justify-content: center;
  text-align: center;
`;

export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 12px;
  justify-content: center;
  text-align: center;
`;

export const TablaHeaderTexto = styled.th`
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;

export const TablaBody = styled.tbody`
border: 2px solid #ccc;
`;

export const TablaBodyTexto = styled.th`
font-size: 0.688rem;
color:  #045184;
padding-top: 8px;
padding-bottom: 8px;
@media ${dispositivo.mobileL} {
  font-size: 0.875rem;
}
@media ${dispositivo.tablet} {
  font-size: 1rem;
}
`;

export const SelectStyle = styled.select`
  width: 50%;
  vertical-align: middle;
`;

export const StyledInput = styled.input`
  width: 50%;
  margin-bottom: 10px;
  margin-top: 10px;
  vertical-align: middle;
  &:focus {
    outline: none;
  }
`;

export const LinkCancelar = styled(Button)`
  background-color: #ffffff;
  border: 2px solid #0060b0;
  color: #0060b0;
  &:hover {
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
    border: none;
  }  

`;

export const StyledLabel = styled.label`
  vertical-align: middle;
  padding: 0px;
  margin: 0px;
  font-weight: bold;
`;

export const StyledOption = styled.option`
  color: #707070;
  font-size: 12px;
`;

export const StyledOptgroup = styled.optgroup`
  color: #707070;
  font-weight: bold;
  font-size: 12px;
`;

export const ScrollDiv = styled.div`
  flex: 1;
  max-height: 115px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 0.5em;
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0c8de3;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
`;

export const ContenedorTabla = styled.div`
  flex: 1;
  max-height: 370px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
`;
