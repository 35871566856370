import { db } from "../Config";
import firebase from "firebase/app";

// función asincrona de fetch ordenes
export const pagarTrx = (pago) => {
  return new Promise((resolve, reject) => {
    db.runTransaction(async (trx) => {
      let pagoLabRef = db.collection("pagosLabs").doc();
      let laboratorioRef = db.collection("laboratorios").doc(pago.labId);
      try {
        const labDoc = await trx.get(laboratorioRef);
        let lab = {};
        if (labDoc.exists) {
          lab = labDoc.data();
          lab.deuda = lab.deuda - Number(pago.monto);

          trx.update(laboratorioRef, { deuda: lab.deuda });
        }

        trx.set(pagoLabRef, pago);
        resolve(lab);
        return Promise.resolve(true);
      } catch (err) {
        reject(err);
      }
    });
  });
};

//Usando batch
export const pagar = (pago, ordenes) => {
  return new Promise((resolve, reject) => {
    let batch = db.batch();
    let saldoTotal = pago.monto;
    let pagoLabRef = db.collection("pagosLabs").doc();

    batch.set(pagoLabRef, pago);
    let estFin = "CANCELADO";
    let continuar = ordenes.length > 0 ? true : false;
    let i = 0;
    while (continuar) {
      let orden = ordenes[i];
      let ordenRef = db.collection("ordenes").doc(orden.id);
      let saldo = Number(orden.precio) - Number(orden.pagado);
      if (saldoTotal < saldo) {
        saldo = saldoTotal;
        estFin = "PENDIENTE";
      }
      saldoTotal -= saldo;
      batch.update(ordenRef, {
        pagado: firebase.firestore.FieldValue.increment(Number(saldo)),
        estFin: estFin,
      });

      if (saldoTotal == 0 || i == ordenes.length - 1) continuar = false;
      i++;
    }
    batch
      .commit()
      .then(() => {
        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
