import React from 'react';
import NuevoLogo from "../../img/slider/nuevoLogo.png";

import {
  ImgLogo,
  StyledSelect,
  StyledInput
} from "./styles";
let regionesPeru = [
  {
    CODIGO: "01",
    DEPARTAMENTO: "AMAZONAS",
  },
  {
    CODIGO: "02",
    DEPARTAMENTO: "ANCASH",
  },
  {
    CODIGO: "03",
    DEPARTAMENTO: "APURIMAC",
  },
  {
    CODIGO: "04",
    DEPARTAMENTO: "AREQUIPA",
  },
  {
    CODIGO: "05",
    DEPARTAMENTO: "AYACUCHO",
  },
  {
    CODIGO: "06",
    DEPARTAMENTO: "CAJAMARCA",
  },
  {
    CODIGO: "07",
    DEPARTAMENTO: "CALLAO",
  },
  {
    CODIGO: "08",
    DEPARTAMENTO: "CUSCO",
  },
  {
    CODIGO: "09",
    DEPARTAMENTO: "HUANCAVELICA",
  },
  {
    CODIGO: 10,
    DEPARTAMENTO: "HUANUCO",
  },
  {
    CODIGO: 11,
    DEPARTAMENTO: "ICA",
  },
  {
    CODIGO: 12,
    DEPARTAMENTO: "JUNIN",
  },
  {
    CODIGO: 13,
    DEPARTAMENTO: "LA LIBERTAD",
  },
  {
    CODIGO: 14,
    DEPARTAMENTO: "LAMBAYEQUE",
  },
  {
    CODIGO: 15,
    DEPARTAMENTO: "LIMA",
  },
  {
    CODIGO: 16,
    DEPARTAMENTO: "LORETO",
  },
  {
    CODIGO: 17,
    DEPARTAMENTO: "MADRE DE DIOS",
  },
  {
    CODIGO: 18,
    DEPARTAMENTO: "MOQUEGUA",
  },
  {
    CODIGO: 19,
    DEPARTAMENTO: "PASCO",
  },
  {
    CODIGO: 20,
    DEPARTAMENTO: "PIURA",
  },
  {
    CODIGO: 21,
    DEPARTAMENTO: "PUNO",
  },
  {
    CODIGO: 22,
    DEPARTAMENTO: "SAN MARTIN",
  },
  {
    CODIGO: 23,
    DEPARTAMENTO: "TACNA",
  },
  {
    CODIGO: 24,
    DEPARTAMENTO: "TUMBES",
  },
  {
    CODIGO: 25,
    DEPARTAMENTO: "UCAYALI",
  },
];
export default function DataEnterprise({ dataForm, setDataForm }) {

  return (
    <>
      <fieldset>
        <input
          //ref={register({ required: true })}
          className="form__input"
          placeholder="Razon Social"
          name="razonSocial"
          value={dataForm.razonSocial}
          onChange={(e) => setDataForm({ ...dataForm, razonSocial: e.target.value })}
          required
        />
      </fieldset>
      <fieldset>
        <StyledInput
          //ref={register({ required: true })}
          placeholder="RUC"
          name="ruc"
          value={dataForm.ruc}
          onChange={(e) => setDataForm({ ...dataForm, ruc: e.target.value })}
          required
        />
      </fieldset>
      <fieldset>
        <StyledInput
          //ref={register({ required: true })}
          placeholder="Telefono"
          name="telefono"
          value={dataForm.telefono}
          onChange={(e) => setDataForm({ ...dataForm, telefono: e.target.value })}
          required
        />
      </fieldset>
      <fieldset>
        <StyledSelect
          className="form_input"
          name="plan"
          value={dataForm.plan}
          onChange={(e) => setDataForm({ ...dataForm, plan: e.target.value })}
          required
        >
          <option selected hidden value="">
            Seleccione un plan...
          </option>
          <option value="FREE">Gratis</option>
          <option value="BASIC">Basico</option>
          <option value="SILVER">Plata</option>
          <option value="GOLD">Oro</option>
        </StyledSelect>
      </fieldset>

      <fieldset>
        <StyledSelect
          className="form_input"
          //ref={register({ required: true })}
          name="ubicacion"
          value={dataForm.ubicacion}
          onChange={(e) => setDataForm({ ...dataForm, ubicacion: e.target.value })}
          required
        >
          <option selected hidden value="">
            UBICACION...
          </option>
          {regionesPeru.map((item) => (
            <option value={item.CODIGO}>{item.DEPARTAMENTO}</option>
          ))}
        </StyledSelect>
      </fieldset>


    </>
  );
}