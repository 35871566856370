import React from "react";
import NuevoLogo from "../../img/slider/nuevoLogo.png";

import {
    ImgLogo,
    StyledSelect,
  } from "./styles";
  
export default function SignUp({dataForm, setDataForm}){

    return (
              
                <>
                  <fieldset>
                    <input
                      className="form__input"
                      placeholder="Nombres y Apellidos"
                      name="nombres"
                      value={dataForm.nombres}
                      readOnly
                    />
                  </fieldset>
                  <fieldset>
                    <input
                      className="form__input"
                      placeholder="Correo"
                      name="email"
                      value={dataForm.email}
                      readOnly
                    />
                  </fieldset>
                  <fieldset>
                    <StyledSelect
                      value={dataForm.especialidad}
                      required
                      onChange={(e) => setDataForm({...dataForm, especialidad: e.target.value})}
                    >
                      <option selected hidden value="">
                        Especialidad...
                      </option>
                      <option value="01">Cirugía Bucal y Maxilofacial</option>
                      <option value="02">Endodoncia</option>
                      <option value="03">Medicina y Patología Estomatológica</option>
                      <option value="04">Odontopediatría</option>
                      <option value="05">Odontogeriatría</option>
                      <option value="06">Odontología Restauradora y Estética.</option>
                      <option value="07">Odontología General.</option>
                      <option value="08">Ortodoncia y Ortopedia Maxilar</option>
                      <option value="09">Periodoncia e Implantología</option>
                      <option value="10">Radiología Bucal y Maxilofacial</option>
                      <option value="11">Rehabilitación Oral</option>
                      <option value="12">Salud Pública Estomatológica</option>
                    </StyledSelect>
                  </fieldset>
                  
                </>
            );
         
        
    
}