import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { dispositivo } from "../../components/Dispositivos";

export const MainContainer = styled.div`
  width: 100%;
  text-align: center;
  background: #f8f8f8;
`;

export const ContainerTitle = styled.div`
  color: #000;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding-top: 2%;
`;

export const ContainerTitleText = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 4%;
  color: #ffffff;
  background: #0c8de3;
  border: 2px solid #0c8de3;
  witdh: 100%;
`;

export const ContainerBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 4%;
  background: #fff;
  box-shadow: 2px 2px #88888877;
  border-radius: 14px;
`;

export const ContainerLeft = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: right;
  padding-bottom: 1rem;
`;

export const ButtonAddPay = styled(Button)`
  && {
    height: 2.25rem;
    margin-top: 1rem;
    margin-left: 2rem;
    border-radius: 1em;      
    text-transform: uppercase;
    cursor: pointer;
    font-width: bold;
    font-size: 0.9rem;
    background-color: #0060b0;
    color: #ffffff;
    font-size: 0.9rem;
    border: none;
    &:hover{
      color:#000000;
      background-color: #0c8de390;
      font-width: bold;
    }
    @media ${dispositivo.mobileS}{
      font-size: 0.9rem;
      padding: 0.1em;
      width: 9rem;
    } 
    @media ${dispositivo.tablet}{
      width: 10rem;
    }  
    @media ${dispositivo.laptop} {      
      width: 12.5rem;
    }
  }
`;

export const Tabla = styled.table`
  width: 100%;
`;

export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const TablaHeaderTexto = styled.th`
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;

export const TablaBody = styled.tbody`
  background: #fff;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  border: 2px solid #ccc;
  padding-bottom: 16px;
`;
export const TablaBodyTexto = styled.th`
  font-size: 0.688rem;
  color:  #045184;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;



export const ContenedorTabla = styled.div`
  flex: 1;
  max-height: 370px;
  // overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0c8de3;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
`;
export const TablaBodyTextoDiente = styled.div`
  font-size: 0.6rem;
  color:  #045184;
  padding: 0.313em 0  0.313em 0.5em;
  display: flex;
  max-width: 80px;
  width: 95%;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 2px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0c8de3;
    border-radius: 2px;
  }
 
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
  @media ${dispositivo.laptop} {
    max-width: 180px;
  }
  @media ${dispositivo.laptopL} {
    max-width: 200px;
  }
`;

