import React from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  ContenedorHeader,
  HeaderBar,
  HeaderBarUl,
} from "./styles";
import { auth } from "../../services/firebase/Config";
import { setUsuarioSesion } from "../../redux/sesion/sesionActions";
import { setPacienteSesion } from "../../redux/sesion/sesionActions";
import { newPaciente } from "../../redux/paciente/pacienteActions";
import HeaderProfileItem from "./HeaderProfileItem/HeaderProfileItem";
import Spinner from "react-bootstrap/Spinner";
import { useLocalStorage } from "../../hooks/useLocalStorage";

export const Header = () => {
  const dispatch = useDispatch();
  const [, setUsuario] = useLocalStorage('usuario')
  const empresa = useSelector((state) => state.empresa);
  const sesion = useSelector((state) => state.sesion);

  const getMensaje = () => {
    return `Su periodo de prueba finaliza en ${getDiasPrueba()} dias`;
  };

  const getDiasPrueba = () => {
    let dias = 0;
    if (empresa.tipoMembresia === "DEMO") {
      let fechaTermino = new Date();
      if (empresa.fechaIngreso instanceof Date)
        fechaTermino = empresa.fechaIngreso.getDate() + 15;
      if (fechaTermino > new Date().getDate())
        dias = fechaTermino - new Date().getDate();
    }
    return dias;
  };
  //const logoImg = require(`../../img/logos/${sesion.usuario.empresa.logoNombre}`);
  const handleCloseSesion = () => {
    dispatch(setUsuarioSesion(null));
    dispatch(setPacienteSesion(false));
    dispatch(newPaciente());
    setUsuario()
    auth.signOut();
    // history.push("/");
    window.location.href = "/";
  };
  if (sesion.loading || sesion.loading === undefined) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden"></span>
      </Spinner>
    );
  }
  return (
    sesion.usuario && (
      <header>
        <ContenedorHeader>
          <HeaderBar>
            <HeaderBarUl>
              <HeaderProfileItem
                onCloseSesion={handleCloseSesion}
                mensaje={getMensaje()}
              />
            </HeaderBarUl>
          </HeaderBar>
        </ContenedorHeader>
      </header>
    )
  );
};
