import { db } from "../Config";

export const fetchGastosFijos = (empresaId) => {
  let gastosFijosRef = db.collection("gastosFijos");
  return new Promise((resolve, reject) => {
    let datos = {
      empresaId: "",
      fecha: "",
      total: 0,
      dias: 0,
      horas: 0,
      items: [],
    };
    gastosFijosRef
      .where("empresaId", "==", empresaId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          datos = doc.data();
        });
        resolve(datos);
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const saveGastosFijos = (gastos) => {
  return new Promise((resolve, reject) => {
    const gastosRef = gastos.id
      ? db.collection("gastosFijos").doc(gastos.id)
      : db.collection("gastosFijos").doc();
    gastos.id = gastosRef.id;
    gastosRef
      .set(gastos)
      .then(() => {
        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
