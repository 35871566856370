import { SET_METAS, ADD_PRESTACION, REMOVE_PRESTACION } from "./metasTypes";

import {
  httpRequest,
  httpSuccess,
  httpFailure,
} from "../sesion/sesionActions";

import {
  fetchMetas,
  saveMetas,
} from "../../services/firebase/metas/index";


export const setMetas = (metas) => {
  return {
    type: SET_METAS,
    payload: metas,
  };
};

export const addPrestacion = (prestacion)=>{
  return {
    type: ADD_PRESTACION,
    payload: prestacion
  }
}

export const removePrestacion = (id)=>{
  return {
    type: REMOVE_PRESTACION,
    payload: id
  }
}

export const getMetas = (empresaId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    fetchMetas(empresaId)
      .then((metas) => {
        dispatch(setMetas(metas));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const guardaMetas = (metas) => {
  return function (dispatch) {
    dispatch(httpRequest());
    saveMetas(metas)
      .then((metas) => {
        dispatch(setMetas(metas));
        dispatch(httpSuccess());
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};
