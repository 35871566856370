import { SET_CENTROSRX, UPDATE_CENTROSRX_IN_ARRAY } from "./centrosTypes";

export const centrosRxReducers = (state = [], action) => {
  switch (action.type) {
    case SET_CENTROSRX:
      return action.payload;
    case UPDATE_CENTROSRX_IN_ARRAY:
      const index = state.findIndex((lab) => lab.id === action.payload.id);
      const newArray = [...state];
      newArray[index] = action.payload;
      return newArray;
    default:
      return state;
  }
};
