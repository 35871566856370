import denticionReducer from "./denticion/denticionReducers";
import prestacionesReducer from "./prestations/prestationsReducers";
import { prestacionReducer } from "./prestation/prestationReducers";
import { notaClinicaReducers } from "./nota-clinica/notaClinicaReducers";
import { notasClinicasReducers } from "./notasclinicas/notasClinicasReducers";
import { cuentaReducers } from "./cuenta/cuentaReducers";
import { pacientesReducer } from "./pacientes/pacientesReducers";
import { pacienteReducers } from "./paciente/pacienteReducers";
import { combineReducers } from "redux";
import { sesionReducers } from "./sesion/sesionReducers";
import { prestacionesSesionReducers } from "./prestacionesSesion/prestacionesSesionReducers";
import { odontogramaReducers } from "./odontograma/odontogramaReducers";
import { tratamientosReducers } from "./tratamientos/tratamientosReducers";
import { recetaReducers } from "./receta/recetaReducers";
import { recetasReducers } from "./recetas/recetasReducers";
import { ordenReducers } from "./orden/ordenReducers";
import { ordenesReducers } from "./ordenes/ordenesReducers";
import { ordenRxReducers } from "./ordenRx/ordenRxReducers";
import { ordenesRxReducers } from "./ordenesRx/ordenesRxReducers";
import { empresaReducers } from "./empresa/empresaReducers";
import { gastosFijosReducers } from "./gastosFijos/gastosFijosReducers";
import { labsReducers } from "./labs/labsReducers";
import { centrosRxReducers } from "./centrosRx/centrosRxReducers";
import { listasReducers } from "./listasEmpresa/listasReducers";
import { subGruposReducers } from "./subGrupos/subGruposReducers";
import { itemsOrdenCartReducers } from "./itemsOrdenCart/itemsOrdenCartReducers";
import { stsOrdenesRxReducers } from "./stsOrdenesRx/stsOrdenesRxReducers";
import { accesosReducers } from "./accesos/accesosReducers";
import { usuarioReducers } from "./usuario/usuarioReducers";
import { metasReducers } from "./metas/metasReducers";
const allReducers = combineReducers({
  denticion: denticionReducer,
  // caras: prestacionReducer,
  notaClinica: notaClinicaReducers,
  notasClinicas: notasClinicasReducers,
  prestaciones: prestacionesReducer,
  prestacionesSesion: prestacionesSesionReducers,
  prestacion: prestacionReducer,
  pacientes: pacientesReducer,
  paciente: pacienteReducers,
  cuenta: cuentaReducers,
  sesion: sesionReducers,
  odontograma: odontogramaReducers,
  tratamientos: tratamientosReducers,
  receta: recetaReducers,
  recetas: recetasReducers,
  orden: ordenReducers,
  ordenRx: ordenRxReducers,
  labs: labsReducers,
  centrosRx: centrosRxReducers,
  ordenes: ordenesReducers,
  ordenesRx: ordenesRxReducers,
  listas: listasReducers,
  subGrupos: subGruposReducers,
  itemsOrdenCart: itemsOrdenCartReducers,
  empresa: empresaReducers,
  gastosFijos: gastosFijosReducers,
  stsOrdenesRx: stsOrdenesRxReducers,
  accesos: accesosReducers,
  usuario: usuarioReducers,
  metas: metasReducers,
});

export default allReducers;
