import React from "react";
import IncisivoSelect from "../../../../components/Dientes/IncisivoSelect";
import { Grafico } from "../../../../components/Dientes/Grafico";
import { useHistory } from "react-router-dom";
import Diente from "../../../../components/Diente";
import { Modal } from "../../../../components/Modal/index";
import { useToasts } from "react-toast-notifications";
import { terminarPrestacionTrx } from "../../../../redux/prestation/prestationActions";
import { DefaultConfig } from "../../../../components/ConfigDiente";

import { useDispatch, useSelector } from "react-redux";
import {
  MainContainer,
  ContainerBody,
  ContainerTitle,
  ContainerTitleText,
  TablaBody,
  TablaHeaderTextoItems,
  TablaHeaderTextoValues,
  TablaHeaderTextoNumber,
  Tabla,
  Buttons,
  ButtonPagar,
  ButtonCancelar,
} from "./styles";

const useItemOdontograma = (item) => {
  const prestacion = useSelector((state) => state.prestacion);
  const odontograma = useSelector((state) => state.odontograma);
  //recupera el item del odontograma

  const idx = odontograma.items.findIndex(
    (it) => it.diente == prestacion.dientes[0]
  );
  let itemOdontograma = React.useState(item);
  if (idx > -1) {
    itemOdontograma = odontograma.items[idx];
  } else itemOdontograma = new DefaultConfig(prestacion.dientes[0]);
  return itemOdontograma;
};

export const TerminarPrestacionModal = (props) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const prestacion = useSelector((state) => state.prestacion);
  const paciente = useSelector((state) => state.paciente);
  const sesion = useSelector((state) => state.sesion);
  const faces = prestacion.caras;
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const { grupo, afectaOdonto, color, codtra } = prestacion;
  const handleCloseModal = (e) => {
    setModalIsOpen(false);
  };
  const printSelectedFaces = () => {
    let myItems = [];

    getSelectedFaces().forEach((item) => {
      myItems.push(<span>{`${item},`}</span>);
    });
    return myItems;
  };

  const getSelectedFaces = () => {
    let items = [];
    if (faces) {
      Object.keys(faces).forEach((pro) => {
        if (faces[pro]) {
          items.push(pro);
        }
      });
    }

    return items;
  };

  const agregaNotaFinPrestacion = (prestacion) => {
    const nota = {
      fecha: Date.now(),
      nota: "Finalizada",
      especialista: sesion.usuario.email,
    };
    prestacion.notasClinicas = [nota, ...prestacion.notasClinicas];
  };
  const handleSave = () => {
    try {
      validaTerminar(prestacion);
      let lPrestacion = Object.assign({}, prestacion);
      lPrestacion.fechaFin = new Date();

      agregaNotaFinPrestacion(lPrestacion);
      dispatch(terminarPrestacionTrx(lPrestacion));
      handleCloseModal();
      history.push("/paciente/odontograma");
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const validaTerminar = (prestacion) => {};

  if (sesion.loading) {
    return <div>Procesando...</div>;
  } else if (sesion.error) {
    return <div>error </div>;
  } else
    return (
      <Modal isOpen={props.isOpen} onClose={props.handleCloseModal}>
        <React.Fragment>
          <MainContainer>
            <ContainerTitle>
              <ContainerTitleText>Terminar Prestación</ContainerTitleText>
            </ContainerTitle>
            <ContainerBody>
              <Tabla>
                <TablaBody>
                  <tr>
                    <TablaHeaderTextoItems>
                      <span>Tratamiento: </span>
                    </TablaHeaderTextoItems>
                    <TablaHeaderTextoValues>
                      <span>{prestacion.tratamiento}</span>
                    </TablaHeaderTextoValues>
                  </tr>

                  <tr>
                    <TablaHeaderTextoItems>
                      <span>Estado: </span>
                    </TablaHeaderTextoItems>
                    <TablaHeaderTextoValues>
                      <span>{prestacion.estado}</span>
                    </TablaHeaderTextoValues>
                  </tr>

                  <tr>
                    <TablaHeaderTextoItems>
                      <span>Dientes: </span>
                    </TablaHeaderTextoItems>
                    <TablaHeaderTextoNumber>
                      <span>{prestacion.dientes}</span>
                    </TablaHeaderTextoNumber>
                  </tr>
                </TablaBody>
              </Tabla>
            </ContainerBody>
            <Buttons>
              <ButtonCancelar className="btn" onClick={props.handleCloseModal}>
                Cancelar
              </ButtonCancelar>
              <ButtonPagar className="btn" onClick={handleSave}>
                Guardar
              </ButtonPagar>
            </Buttons>
          </MainContainer>
        </React.Fragment>
      </Modal>
    );
};
