import React from 'react';
import NuevoLogo from "../../img/slider/nuevoLogo.png";
import { Link } from "react-router-dom";
import {
    ImgLogo,
    Btn,
    SpanContainer
  } from "./styles";

export default function SelectAccount({loginWithGoogle}){
    return (
          <>
            <Btn type="button" onClick={loginWithGoogle}>Crear con Google</Btn>
            <hr></hr>
            <SpanContainer>
              <span>Si ya tienes una cuenta ingresa con tus crendenciales </span>
              <Link to="/login" className="changeLink">
                INGRESAR
              </Link>
            </SpanContainer>
          </>
        
      );
}