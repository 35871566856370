import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Navbar from 'react-bootstrap/Navbar'
import { NavLink } from "react-router-dom";
import Nav from 'react-bootstrap/Nav'
import {
  Divheader, UlMenu, IconTitle
} from "./styles";

export const TabMenu = ({ url, urlBase }) => {
  const [isActive, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!isActive);
  };
  const handleToggle2 = () => {
    setActive(!isActive);
  };


  const sesion = useSelector((state) => state.sesion);
  const accesos = useSelector((state) => state.accesos);

  const generaMenu = () => {
    return accesos.lateral?.map((_op, index) => {
      let iconPath = `${_op.iconFile}`;
      return (

        <li key={index}>
          <div onClick={handleToggle2}> <IconTitle className={iconPath}><span>{_op.nombre}</span> <i className="fas fa-caret-down" ></i> </IconTitle></div>
          {_op.items && (
            <UlMenu className={isActive ? " delete-ul" : !isActive ? " show-ul" : ""}>
              {_op.items?.map((it, index) => {
                let iconPath2 = `${it.iconFile}`;
                return (

                  it?.items?.length > 0 ? (
                    <li key={index}>
                      <div onClick={(e) => (it.items ? e.preventDefault() : null)}><i className={iconPath2}><span className="span-subtitle">{it.nombre}</span> <i className="fas fa-caret-down" ></i> </i></div>
                      <ol >

                        <li>
                          {it.items.map((_subitem, index) => {
                            return (
                              <NavLink key={index} to={`${_op.url}${_subitem.url}`} onClick={handleToggle}>
                                {_subitem.nombre}
                              </NavLink>
                            );
                          })}
                        </li>
                      </ol>
                    </li>) :
                    <NavLink key={index} to={`${_op.url}${it.url}`} onClick={handleToggle}>
                      <i className={iconPath} style={{ paddingRight: '5px' }}></i>
                      <span>{it.nombre}</span>
                    </NavLink>

                );
              })}
            </UlMenu>
          )}
        </li>
      );
    });
  };




  return (
    <React.Fragment>
      <Divheader>
        <UlMenu className="nav">
          {generaMenu()}
        </UlMenu>
      </Divheader>


    </React.Fragment>
  );
};
