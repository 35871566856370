import React from "react";

export function useContextMenu() {
  const [x, setX] = React.useState(0);
  const [y, setY] = React.useState(0);
  const [showMenu, setShowMenu] = React.useState(false);
  const [hayDientes, setHayDientes] = React.useState(false);
  const handleContextMenu = (e) => {
    e.preventDefault();
    setX(e.pageX);

    setY(e.pageY);
    if (!hayDientes) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  };

  // const handleClick = () => {
  //   showMenu && setShowMenu(false);
  // };
  React.useEffect(() => {
    //document.addEventListener("click", handleClick);

    document
      .getElementById("dientesContenedor")
      .addEventListener("contextmenu", handleContextMenu);
    return () => {
      //document.removeEventListener("click", handleClick);
      document
        .getElementById("dientesContenedor")
        .removeEventListener("contextmenu", handleContextMenu);
    };
  });

  return { x, y, showMenu, setShowMenu, setHayDientes };
}
