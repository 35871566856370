import React, { useState } from "react";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PhoneForwardedIcon from "@material-ui/icons/PhoneForwarded";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import Tooltip from "@material-ui/core/Tooltip";
import { ResponsiveButtonWrapper } from "./styles";

const ResponsiveButton = (responsiveButtonProps) => {
  const {
    iconName = "",
    data = "",
    fullName = "",
    left = false,
  } = responsiveButtonProps;

  const renderButton = (_iconName, _data, _fullName, _left) => {
    switch (_iconName) {
      case "whatsapp":
        return (
          <>

            <a rel="nofollow" href="#!">
              <WhatsAppIcon
                className="response-button__icon"
                style={{
                  background: "#50CE56",
                }}
              /></a>
            <div className="show-on-hover" style={{ background: "#50CE56" }}>
              <a
                href={`https://wa.me/51${_data}?text=Hola ${_fullName}.`}
                target="_blank"
                className="show-on-hover__cta"
              >
                <span>{_data}</span>
              </a>
            </div>

          </>
        );

      case "phone":
        return (
          <>
            {/* href={`tel:${_data}` */}
            <a rel="nofollow" href="#!">
              <PhoneForwardedIcon
                className="response-button__icon"
                style={{
                  background: "#043E80",
                  borderRadius: "10px",
                  padding: "5px",
                }}
              />
            </a>
            <div
              className="show-on-hover"
              style={{ background: "#043E80" }}
            >
              <a
                href={`tel:${_data}`}
                className="show-on-hover__cta"
              >
                <span>{_data}</span>
              </a>
            </div>

          </>
        );

      case "mail":
        return (
          <>
            {/* href={`mailto:${_data}`} */}
            <a href="#!">
              <MailOutlineIcon
                className="response-button__icon"
                style={{
                  background: "#D64135",
                  borderRadius: "10px",
                  padding: "5px",
                }}
              /> </a>
            <div
              className={
                _left ? "show-on-hover show-on-hover--left" : "show-on-hover"
              }
              style={{ background: "#D64135" }}
            >
              <a
                href={`mailto:${_data}`}
                className="show-on-hover__cta"
              >

                <span>{_data}</span>
              </a>
            </div>

          </>
        );

      case "dni":
        return (
          <>
            <AssignmentIndIcon
              className="response-button__icon"
              style={{
                background: "#043E80",
                borderRadius: "10px",
                padding: "5px",
              }}
            />
            <div
              className={
                _left ? "show-on-hover show-on-hover--left" : "show-on-hover"
              }
              style={{ background: "#043E80" }}
            >
              {_data}
            </div>
          </>
        );

      default:
        return null;
    }
  };

  const renderButtonContainer = (_iconName, _data, _fullName, _left) => {
    return (
      <ResponsiveButtonWrapper>
        {renderButton(_iconName, _data, _fullName, _left)}
      </ResponsiveButtonWrapper>
    );
  };

  return renderButtonContainer(iconName, data, fullName, left);
};

export default ResponsiveButton;
