import { db } from "../Config";

export const fetchTratamientosLab = async () => {
  let tratamientos = [];
  await db
    .collection("tratamientos")
    .orderBy("tratamiento")
    .where("envLab", "==", true)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        tratamientos.push({
          id: doc.id,
          tipo: doc.data().tipo,
          tratamiento: doc.data().tratamiento,
          preciosLabs: doc.data().preciosLabs,
          estado: doc.data().estado,
        });
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
  return tratamientos;
};

export const fetchTratamientos = (empresaId) => {
  return new Promise((resolve, reject) => {
    let tratamientos = [];
    db.collection("tratamientos")
      .where("empresaId", "==", empresaId)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          tratamientos.push(doc.data());
        });
        resolve(tratamientos);
      },(error) => {
        reject(error)
        // Handle errors
      });
      
  });
};


export const getTratamientoById = (tratamientoId) => {
  return new Promise((resolve, reject) => {
    db.collection("tratamientos")
      .doc(tratamientoId)
      .get()
      .then((doc) => {
        resolve(doc.data());
      }).catch((err)=>
      reject(err)
      );
      
  });
};

