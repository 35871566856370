import styled from "styled-components";
import { dispositivo } from "../../components/Dispositivos";

const btn = `
 height: 2.125rem;
 width: 100%;
 border-radius: 1em;      
 text-transform: uppercase;
 cursor: pointer;
 font-width: bold;
 font-size: 0.9rem;
 
 @media ${dispositivo.mobileS}{
   font-size: 0.9rem;
   padding: 0.1em;  
 }
`;

export const RegistroWrapper = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  background: #f8f8f8;
  justify-content: center;
`;
export const ContainerTitle = styled.div`
  color: #000;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding: margin: 2% 2% 0 ;
  margin-top: 10px;
`;

export const ContainerTitleText = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 1%;
  margin-top: 5px;
`;

export const ContainerLeft = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;
`;
export const StyledButton = styled.button`
  height: 2.25rem;
  border-radius: 0.8rem;
  width: 7rem;      
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #0060b0;
  color: #ffffff;
  font-size: 0.75rem;
  border: none;
  &:hover{
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.mobileL} {
    font-size: 1rem;
  }
  @media ${dispositivo.tablet}{
    width: 10rem;
  }  
  @media ${dispositivo.laptop} {      
    width: 12.5rem;
  }
`;
export const StyledLabel = styled.label`
  font-weight: bold;
  text-align: left;
  color: #040404;
  font-size: 0.875rem;
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;
export const DivInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  .button-wrapper{
    display: flex;
    flex-direction: column;
    height: 2.25rem;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
    width: 60%;
    @media ${dispositivo.mobileL} {
      width: 40%;
    }
  }
  .label{
    position: relative;
    z-index: 0;
    display: inline-block;
    border-radius: 10px;
    background-color: #64bdff30;
    color: #0D0808;
    cursor: pointer;
    padding: 8px 0;
    font-size: 0.75rem;
    @media ${dispositivo.tablet} {
      font-size: 1rem;
    }
  }
  .upload-box {
    display: inline-block;
    position: absolute;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
    width: 33%;
}
`;

export const StyledInput = styled.input`
  height: 2.5rem;
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  padding-left: 1em;
  line-height: 2.5;
  font-size: 0.75rem;
  width: 75%;
  &::placeholder {
    color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.mobileL} {
    width: 70%;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;

export const RegistroFormWrapper = styled.section`
  background: #fff;
  border-radius: 8px;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 2% 5% 5% 5% ;
  padding: 1rem;
  @media ${dispositivo.laptop} {
    margin: 2% 15% 5% 15% ;
  }
`;
export const StyledForm = styled.div`
  width: 100%;
  height: 100%;
  padding: 2%;
  background: #fff;
  display: block;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid #0c8de3;
  margin-bottom: 1rem;
`;
export const FooterWrapper = styled.footer`
 background: #EBF0FC;
 left: 400px;
 position: fixed;
 @media (max-width: 1024px) {
   display: none;
 }
 .carouselHeigth{
   height: 100%;
 } 
`;