import React from "react";
import { useState } from "react";
import IncisivoOdontograma from "../../components/Dientes/IncisivoOdontograma";
import MolarSup from "../../components/Dientes/MolarSup";
import MolarInf from "../../components/Dientes/MolarInf";
import MolarDeciduoSup from "../../components/Dientes/MolarDeciduoSup";
import MolarDeciduoInf from "../../components/Dientes/MolarDeciduoInf";
import { TopBarPaciente } from "../../components/TopBarPaciente";
import PreMolarSup from "../../components/Dientes/PreMolarSup";
import PreMolarInf from "../../components/Dientes/PreMolarInf";
import IncisivoCentralSup from "../../components/Dientes/IncisivoCentralSup";
import IncisivoCentralInf from "../../components/Dientes/IncisivoCentralInf";
import IncisivoLateralInf from "../../components/Dientes/IncisivoLateralInf";
import IncisivoLateralSup from "../../components/Dientes/IncisivoLateralSup";
import CaninoInf from "../../components/Dientes/CaninoInf";
import CaninoSup from "../../components/Dientes/CaninoSup";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { OdontoInicialModal } from "../../components/OdontoInicialModal/index";
import {
  Denticion,
  ADULTO,
  INFANTIL,
  superiorDerecha,
  inferiorDerecha,
  superiorIzquierda,
  inferiorIzquierda,
  incisivo_central_sup,
  incisivo_central_inf,
  incisivo_lateral_sup,
  incisivo_lateral_inf,
  canino_sup,
  canino_inf,
  premolar_sup,
  premolar_inf,
  molar_sup,
  molar_inf,
  molar_deciduo_sup,
  molar_deciduo_inf,
  DefaultConfig,
} from "../../components/ConfigDiente";

import { getOdontograma } from "../../redux/odontograma/odontogramaActions";

import {
  DienteContenedorRaiz,
  DienteContenedorRaizInferior,
  DienteContenedorCorona,
  DienteContenedor,
  ContenedorAuxiliar,
  DienteContenedorNumero,
  DenticionSide,
  DenticionContainer,
  DenticionBlock,
  ContainerTitle,
  ContainerTitleText,
  MainContainer,
  ContenedorAuxiliarSuperior,
  ContaineRight,
  StyledSelect,
  StyledButton,
  ContainerButton,
  LinkCancelar,
} from "./styles";
import Collapse from "react-bootstrap/Collapse";
import Card from "react-bootstrap/Card";
import Popover from "react-bootstrap/Popover";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import Button from "react-bootstrap/Button";
import { useContextMenu } from "../../hooks/useContextMenu";

export const OdontogramaInicial = (props) => {
  const [isShown, setIsShown] = React.useState(true);
  const dispatch = useDispatch();
  const paciente = useSelector((state) => state.paciente);
  const cuenta = useSelector((state) => state.cuenta);
  const odontograma = useSelector((state) => state.odontograma);
  const sesion = useSelector((state) => state.sesion);
  const [tipoDenticion, setTipoDenticion] = React.useState(ADULTO);
  const [seleccionados, setSeleccionados] = React.useState(Denticion.dientes);
  const [grupo, setGrupo] = React.useState();
  const { addToast } = useToasts();
  //const [isOpenModal, setIsOpenModal] = React.useState(false);
  React.useEffect(() => {
    if (odontograma.items.length == 0) dispatch(getOdontograma(paciente.id));
  }, []);

  React.useEffect(() => {
    setShowMenu(false);
    setGrupo(null);
    setSeleccionados(seleccionados.map((it) => ({ ...it, selected: false })));
  }, [paciente.id]);

  const { x, y, showMenu, setShowMenu, setHayDientes } = useContextMenu();
  const handleCloseModal = () => {
    setShowMenu(false);
    setGrupo(null);
    setSeleccionados(seleccionados.map((it) => ({ ...it, selected: false })));
  };

  const handleSelected = (item, itemGrupo) => {
    if (!grupo) setGrupo(itemGrupo);
    else if (grupo !== itemGrupo && seleccionados.some((it) => it.selected)) {
      addToast("Seleccione elementos del mismo grupo", { appearance: "error" });
      return;
    }
    const myArr = [...seleccionados];
    const idx = myArr.findIndex((it) => it.diente === item.diente);
    myArr[idx]["selected"] = myArr[idx]["selected"] ? false : true;
    myArr[idx]["grupo"] = itemGrupo;

    setGrupo(itemGrupo);
    setSeleccionados(myArr);
    if (seleccionados.some((it) => it.selected)) setHayDientes(true);
    else setHayDientes(false);
  };

  const getDiente = (nombre, config) => {
    switch (nombre) {
      case incisivo_central_sup:
        return <IncisivoCentralSup config={config} />;
      case incisivo_central_inf:
        return <IncisivoCentralInf config={config} />;
      case incisivo_lateral_sup:
        return <IncisivoLateralSup config={config} />;
      case incisivo_lateral_inf:
        return <IncisivoLateralInf config={config} />;
      case canino_sup:
        return <CaninoSup config={config} />;
      case canino_inf:
        return <CaninoInf config={config} />;
      case premolar_sup:
        return <PreMolarSup config={config} />;
      case premolar_inf:
        return <PreMolarInf config={config} />;
      case molar_sup:
        return <MolarSup config={config} />;
      case molar_inf:
        return <MolarInf config={config} />;
      case molar_deciduo_sup:
        return <MolarDeciduoSup config={config} />;
      case molar_deciduo_inf:
        return <MolarDeciduoInf config={config} />;
      default:
        return <IncisivoLateralSup config={config} />;
    }
  };

  const DienteSuperior = ({ item, config }) => {
    const { diente, nombre, selected, grupo } = item;
    //const imgDiente = require(`./svg/${tipo}.svg`);
    const imgCorona = require(`../../assets/svg/incisivo.svg`);
    return (
      <DienteContenedor>
        <DienteContenedorRaiz
          onClick={() => handleSelected(item, "NUCLEO")}
          selected={selected}
          grupo={grupo}
        >
          {getDiente(nombre, config)}
        </DienteContenedorRaiz>
        <ContenedorAuxiliarSuperior>
          <DienteContenedorCorona
            onClick={() => handleSelected(item, "CARAS")}
            selected={selected}
            grupo={grupo}
          >
            <IncisivoOdontograma config={config} />
          </DienteContenedorCorona>

          <DienteContenedorNumero>{diente}</DienteContenedorNumero>
        </ContenedorAuxiliarSuperior>
      </DienteContenedor>
    );
  };

  const DienteInferior = ({ item, config }) => {
    const { diente, nombre, selected, grupo } = item;
    //const imgCorona = require(`./svg/incisivo.svg`);

    return (
      <DienteContenedor>
        <ContenedorAuxiliar>
          <DienteContenedorNumero>{diente}</DienteContenedorNumero>

          <DienteContenedorCorona
            onClick={() => handleSelected(item, "CARAS")}
            selected={selected}
            grupo={grupo}
          >
            <IncisivoOdontograma config={config} />
          </DienteContenedorCorona>
        </ContenedorAuxiliar>

        <DienteContenedorRaizInferior
          onClick={() => handleSelected(item, "NUCLEO")}
          selected={selected}
          grupo={grupo}
        >
          {getDiente(nombre, config)}
        </DienteContenedorRaizInferior>
      </DienteContenedor>
    );
  };

  const generaDientes = (items) => {
    let dientes = [];
    let idx = -1;
    items.forEach((item) => {
      if (odontograma.items) {
        idx = odontograma.items.findIndex((it) => it.diente == item.diente);
      }
      let config = { caras: [] };
      if (idx > -1) {
        config = odontograma.items[idx];
      } else {
        config = new DefaultConfig(item.diente);
      }
      if (item.lado == superiorIzquierda || item.lado == superiorDerecha) {
        dientes.push(
          <DienteSuperior key={item.diente} item={item} config={config} />
        );
      } else {
        dientes.push(
          <DienteInferior key={item.diente} item={item} config={config} />
        );
      }
    });

    return dientes;
  };
  const handleChangeTipoDenticion = (e) => {
    let tipo = e.target.value;
    setTipoDenticion(tipo);
    if (tipo == "ADULTO") {
      setIsShown(true);
    } else {
      setIsShown(false);
    }
  };

  const paso1 = (
    <Popover id="popover-basic">
      <Popover.Body
        style={{ background: "#43B8AD", color: "#ffffff", fontSize: "14px" }}
      >
        <p>
          1. Clic sobre el(los) diente(s), si el tratamiento se aplica a
          conductos/raices o coronas dentarias.
        </p>
        2. Clic en las caras, si el tratamiento se aplica a caras: como
        obturaciones o incrustaciones.
      </Popover.Body>
    </Popover>
  );
  const paso2 = (
    <Popover id="popover-basic">
      <Popover.Body
        style={{ background: "#43B8AD", color: "#ffffff", fontSize: "14px" }}
      >
        <p>3. Clic derecho sobre la pantalla</p>
        <p>4. Busca y selecciona el tratamiento que deseas aplicar</p>
      </Popover.Body>
    </Popover>
  );

  return (
    <React.Fragment>
      <TopBarPaciente />
      <MainContainer>
        <ContainerTitle>
          <ContainerTitleText>
            Odontograma Inicial{" "}
            <ContaineRight>
              <OverlayTrigger
                delay={{ show: 250, hide: 400 }}
                placement="bottom"
                overlay={paso1}
              >
                <Button
                  variant="outline-info"
                  style={{
                    borderRadius: "50px",
                    padding: "0",
                    height: "24px",
                    width: "24px",
                  }}
                >
                  1
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                delay={{ show: 250, hide: 400 }}
                placement="bottom"
                overlay={paso2}
              >
                <Button
                  variant="outline-info"
                  style={{
                    borderRadius: "50px",
                    padding: "0",
                    height: "24px",
                    width: "24px",
                  }}
                >
                  2
                </Button>
              </OverlayTrigger>
            </ContaineRight>
          </ContainerTitleText>
        </ContainerTitle>
        <DenticionContainer id="dientesContenedor">
          <ContainerButton>
            <div></div>
            <LinkCancelar to="/paciente/odontograma">
              <ArrowBackIcon />
              Regresar
            </LinkCancelar>
          </ContainerButton>
          <ContainerButton>
            <div>
              <label className="label-styled" for="tipo">
                Tipo de dentición:
              </label>
              <StyledSelect id="tipo" onChange={handleChangeTipoDenticion}>
                <option value={ADULTO}>Adulto</option>
                <option value={INFANTIL}>Infantil</option>
              </StyledSelect>
            </div>
          </ContainerButton>
          <DenticionBlock>
            <DenticionSide isShown={isShown}>
              {generaDientes(
                seleccionados.filter(
                  (item) =>
                    item.lado === superiorDerecha && item.tipo === tipoDenticion
                )
              )}
              {generaDientes(
                seleccionados.filter(
                  (item) =>
                    item.lado === superiorIzquierda &&
                    item.tipo == tipoDenticion
                )
              )}
            </DenticionSide>
          </DenticionBlock>

          <DenticionBlock>
            <DenticionSide isShown={isShown}>
              {generaDientes(
                seleccionados.filter(
                  (item) =>
                    item.lado === inferiorDerecha && item.tipo == tipoDenticion
                )
              )}
              {generaDientes(
                seleccionados.filter(
                  (item) =>
                    item.lado === inferiorIzquierda &&
                    item.tipo == tipoDenticion
                )
              )}
            </DenticionSide>
          </DenticionBlock>
        </DenticionContainer>
      </MainContainer>
      <OdontoInicialModal
        isOpen={showMenu}
        handleCloseModal={handleCloseModal}
        grupo={grupo}
        dientes={seleccionados
          .filter((it) => it.selected)
          .map((it) => it.diente)}
      />
    </React.Fragment>
  );
};

export default OdontogramaInicial;
