import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStsOrdenesRx } from "../../redux/stsOrdenesRx/stsOrdenesRxActions";
import { getEmpresas } from "../../services/firebase/empresas/index";
import {
  MainContainer,
  ContainerTitle,
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  ContainerTitleText,
  ContenedorTabla,
  ContainerBody,
  FlexContainer,
  Fila,
  Button,
  ContainerButton,
  ContainerDate,
} from "./styles";
import Spinner from 'react-bootstrap/Spinner';
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import { useToasts } from "react-toast-notifications";

export const CentroRx = ({ centroRx }) => {
  return (
    <div>
      <div className="container-info-consultorio">
        <h4>{centroRx.centroRxNombre}</h4>
        <h4>Total S/ <span>{centroRx.total}</span></h4>
      </div>
      <OrdenesTable items={centroRx.grupos} />
    </div>
  );
};

export const OrdenesTable = ({ items: grupos }) => {
  return (
    <Tabla>
      <TablaHeader>
        <tr>
          <TablaHeaderTexto scope="col">Grupo</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Tipo</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Total S/</TablaHeaderTexto>
        </tr>
      </TablaHeader>
      <TablaBody>
        {grupos.map((grupo, idy) =>
          grupo.items.map((item, idz) => (
            <Fila idx={idy}>
              {idz === 0 && (
                <td
                  data-label="Grupo"
                  rowspan={grupo.filas}
                >{`${grupo.grupo} [${grupo.cantidad}]`}</td>
              )}
              <td data-label="Item">{`${item.descripcion} [${item.cantidad}]`}</td>
              {idz === 0 && (
                <td
                  data-label="Total"
                  rowspan={grupo.filas}
                >{`${grupo.total}`}</td>
              )}
            </Fila>
          ))
        )}
      </TablaBody>
    </Tabla>
  );
};

export const OrdenesRxAtendidasRes = () => {
  const [estado, setEstado] = React.useState({
    loading: true,
    empresas: [],
    empresaId: "",
    ordenes: [],
    error: "",
  });

  const stsOrdenesRx = useSelector((state) => state.stsOrdenesRx);
  const sesion = useSelector((state) => state.sesion);
  const empresa = useSelector((state) => state.empresa);
  const tarifario = useSelector((state) => state.tarifario);
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const ANTERIOR = "ANTERIOR";
  const ACTUAL = "ACTUAL";

  const [periodo, setPeriodo] = React.useState({
    year: "",
    month: "",
    orden: ACTUAL,
  });

  React.useEffect(() => {
    getEmpresas().then((empresas) =>
      setEstado({ ...estado, empresas: empresas })
    );
    let fecha = new Date();
    let year = fecha.getFullYear();
    let month = fecha.getMonth();
    setPeriodo({ year: year, month: month, orden: ANTERIOR });
    dispatch(getStsOrdenesRx(empresa.id, year, month));
  }, [empresa]);

  const handleClick = (orden) => {
    let fecha = new Date();
    let year = fecha.getFullYear();
    let month = fecha.getMonth();
    if (orden === ANTERIOR) {
      if (month === 0) {
        month = 11;
        year--;
      } else month--;
    }

    setPeriodo({
      year: year,
      month: month,
      orden: orden === ANTERIOR ? ACTUAL : ANTERIOR,
    });
    dispatch(getStsOrdenesRx(empresa.id, year, month));
  };

  if (sesion.loading || sesion.loading == undefined)
    return <MainContainer><Spinner animation="border" role="status">
      <span className="visually-hidden"></span>
    </Spinner></MainContainer>;
  else if (sesion.error) return <div>error + {sesion.error}</div>;
  return (
    <MainContainer>
      <ContainerTitle>
        <ContainerTitleText>Ordenes Atendidas</ContainerTitleText>
      </ContainerTitle>

      <ContainerBody>
        <FlexContainer>
          <ContainerButton>
            <Button
              onClick={() => handleClick(periodo.orden)}
              startIcon={<KeyboardReturnIcon />}
            >
              <KeyboardReturnIcon />
              {periodo.orden}
            </Button>
          </ContainerButton>


          <ContainerDate>
            <span>
              <span style={{ color: "#040404" }}>Año:</span>
              {periodo.year}
            </span>
            <span>
              <span style={{ color: "#040404" }}>Mes:</span>
              {periodo.month + 1}
            </span>
          </ContainerDate>
        </FlexContainer>
        <ContenedorTabla>
          <h3>
            Total Comision S/{" "}
            {stsOrdenesRx.length > 0 &&
              stsOrdenesRx
                .map((con) => con.total)
                .reduce((acc, inc) => acc + inc, 0)}
          </h3>
          {stsOrdenesRx.map((centroRx) => (
            <CentroRx centroRx={centroRx} />
          ))}
        </ContenedorTabla>
      </ContainerBody>
    </MainContainer>
  );
};
