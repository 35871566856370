import React,{useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
//import { useBuscarTratamientos } from "../../components/Hooks/useBuscarTratamientos";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import SettingsIcon from "@material-ui/icons/Settings";
import {
  fetchTratamientos,
  updateTratamiento,
  updateTratamientoInArray,
} from "../../redux/tratamientos/tratamientosActions";
import { fetchSubGrupos } from "../../redux/subGrupos/subGruposActions";

import {
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  TablaBodyTexto,
  StyledInput,
  MainContainer,
  ContainerTitle,
  ContainerTitleText,
  ContainerBody,
  StyledLabel,
  ContainerButton,
  ButtonAbonar,
  ContainerTitles,
  InputColor,
  StyledInputTabla
} from "./styles";
import Spinner from "react-bootstrap/Spinner";

export const useBuscarTratamientos = (tratamientos) => {
  const [query, setQuery] = React.useState("");

  const [filteredItems, setFilteredItems] = React.useState(
    tratamientos ? tratamientos : []
  );
  React.useMemo(() => {
    let result = tratamientos;
    if (query.length > 0 && tratamientos) {
      result = tratamientos.filter((item) =>
        `${item.tratamiento} ${item.tipo} ${item.especialidad}`
          .toLocaleLowerCase()
          .includes(query.toLocaleLowerCase())
      );
    }

    setFilteredItems(result);
  }, [tratamientos, query]);

  const clearFilteredItems = () => {
    setFilteredItems([]);
  };
  return { query, setQuery, filteredItems, clearFilteredItems };
};

const Fila = ({ item, subGrupos, onClick }) => {
  
  const subGrupo = subGrupos.find((it) => it.id === item.subGrupoId);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [cambio, setCambio] = React.useState(false);
  const handleOnBlur = (item) => {
    try {
      if (cambio) {
        dispatch(updateTratamiento(item));
        setCambio(false);
        addToast("Tratamiento actualizado", { appearance: "success" });
      }
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const handleChange = (e, item) => {
    try {
      if (e.target.name === "precio") {
        setCambio(true);
        item.precio = Number(e.target.value);
        dispatch(updateTratamientoInArray(item));
      }
      if (e.target.name === "costo") {
        setCambio(true);
        item.costo = Number(e.target.value);
        dispatch(updateTratamientoInArray(item));
      }
      if (e.target.name === "tiempo") {
        setCambio(true);
        item.tiempo = Number(e.target.value);
        dispatch(updateTratamientoInArray(item));
      }
      if (e.target.name === "estado") {
        item.estado = e.target.checked;
        dispatch(updateTratamiento(item));
        addToast("Tratamiento actualizado", { appearance: "success" });
      }
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };
  if (!subGrupo)
  return null
  else
  return (
    <tr>
      <TablaBodyTexto>
        <InputColor
          type="color"
          data-label="color"
          value={subGrupo.color}
          readOnly
        />
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Tratamiento">
        {item.tratamiento}
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Tipo">{item.tipo}</TablaBodyTexto>

      <TablaBodyTexto data-label="Especialidad">
        {item.especialidad}
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Sub.Grupo">{subGrupo.nombre}</TablaBodyTexto>

      <TablaBodyTexto data-label="Precio">
        <StyledInputTabla
          name="precio"
          value={item.precio}
          type="text"
          onBlur={() => handleOnBlur(item)}
          onChange={(e) => handleChange(e, item)}
        />
       </TablaBodyTexto> 
      <TablaBodyTexto data-label="Costo">
        <StyledInputTabla
          name="costo"
          value={item.costo}
          type="text"
          onBlur={() => handleOnBlur(item)}
          onChange={(e) => handleChange(e, item)}
        />
        </TablaBodyTexto>
      <TablaBodyTexto data-label="Tiempo">
        <StyledInputTabla
          name="tiempo"
          value={item.tiempo}
          type="text"
          onBlur={() => handleOnBlur(item)}
          onChange={(e) => handleChange(e, item)}
        />
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Config">
        <IconButton
          className="iconButton"
          aria-label=""
          style={{ padding: '5px' }}
          onClick={() => onClick(item)}
        >
          <Tooltip TransitionComponent={Zoom} placement="top" title="Config">
            <SettingsIcon style={{ color: "blue" }} />
          </Tooltip>
        </IconButton>
      </TablaBodyTexto>
      <TablaBodyTexto data-label="Desactivar">
        <input
          name="estado"
          type="checkbox"
          checked={item.estado}
          onChange={(e) => handleChange(e, item)}
        />
      </TablaBodyTexto>
    </tr>
  );
};

export const Tratamientos = () => {
  const dispatch = useDispatch();
  const sesion = useSelector((state) => state.sesion);
  const history = useHistory();
  const tratamientos = useSelector((state) => state.tratamientos);
  const subGrupos = useSelector((state)=> state.subGrupos)
  const empresa = useSelector((state) => state.empresa);
  const { query, setQuery, filteredItems } =
    useBuscarTratamientos(tratamientos);
  React.useEffect(() => {
    if (tratamientos.length === 0) dispatch(fetchTratamientos(empresa.id));
    if (subGrupos.length === 0) dispatch(fetchSubGrupos());
  }, [empresa.id]);

  React.useEffect(() => {
    dispatch(fetchSubGrupos());
  }, []);


  const { addToast } = useToasts();
  const handleClick = (item) => {
    history.push({ pathname: "tratamientos/config", state: { data: item } });
  };

  if (sesion.loading || sesion.loading === undefined)
    return (
      <MainContainer>
        <Spinner animation="border" role="status">
          <span className="visually-hidden"></span>
        </Spinner>
      </MainContainer>
    );
  else if (sesion.error) return <div>error + {sesion.error}</div>;
  else
    return (
      <MainContainer>
        <ContainerTitle>
          <ContainerTitleText>Tratamientos</ContainerTitleText>
        </ContainerTitle>
        <ContainerButton>
          <ContainerTitles>
            <StyledLabel>Buscar tratamiento</StyledLabel>
            <StyledInput
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </ContainerTitles>
          <ContainerTitle>
            <ButtonAbonar
              onClick={() => {
                history.push("tratamientos/nuevo");
              }}
            >
              Nuevo
            </ButtonAbonar>
          </ContainerTitle>
        </ContainerButton>

        <ContainerBody>
          <Tabla>
            <TablaHeader>
              <tr>
                <TablaHeaderTexto scope="col">Color</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">Tratamiento</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">Tipo</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">Especialidad</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">Sub. Grupo</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">Precio</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">Costo</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">Tiempo(min)</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">Config</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">Estado</TablaHeaderTexto>
              </tr>
            </TablaHeader>
            <TablaBody>
              {filteredItems.map((item) => {
                return <Fila key={item.id} subGrupos={subGrupos} item={item} onClick={handleClick} />;
              })}
            </TablaBody>
          </Tabla>
        </ContainerBody>
      </MainContainer>
    );
};
