import React from "react";
import { useHistory } from "react-router-dom";
import { ConfirmacionSiNoModal } from "../../components/ConfirmacionSiNoModal/index";
import { useDispatch, useSelector } from "react-redux";
import { setOrden } from "../../redux/orden/ordenActions";
import { getOrdenesEmpresa } from "../../redux/ordenes/ordenesActions";
import { getLabs } from "../../redux/labs/labsActions";
import IconButton from "@material-ui/core/IconButton";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import LaunchIcon from "@material-ui/icons/Launch";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { OrdenModalContainer } from "../Laboratorios/OrdenModalContainer";

import {
  MainContainer,
  ContainerTitle,
  BodyContainer,
  LinkCancelar,
  StyledForm,
  StyledOption,
  StyledOptgroup,
  ButtonAbonar,
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  TablaBodyTexto,
  SelectStyle,
  StyledInput,
  StyledLabel,
  ContainerTitleText,
  ContenedorTabla,
  SectionTarjeta,
  ContenedorContenidoTarjeta,
  ContenedorCabeceraTarjeta,
  TarjetaAccion,
  ToolTip,
  TablaBodyFinanzas,
  TablaBodyTextoFinanzas,
  TablaBodyTextoFinanzaPrecio,
  TablaBodyTextoFinanzaPagado,
  TablaBodyTextoSaldo,
  TablaFinanzas,
  ContenedorTarjeta,
  ScrollDiv,
  ContainerTitles,
  ContainerBody,
  ContainerTitleOrden,
} from "./styles";
import Spinner from 'react-bootstrap/Spinner';
import { useToasts } from "react-toast-notifications";
import { ESTADOS } from "../../Entidades/Orden";

const EVENTOS = {
  EDITAR_ORDEN_EN_LAB: "EDITAR_ORDEN_EN_LAB",
  VER_ORDEN: "VER_ORDEN",
  ENVIAR_ORDEN_A_CLI: "ENVIAR_ORDEN_A_CLI",
  INICIAR_ORDEN_EN_LAB: "INICIAR_ORDEN_EN_LAB",
  RECIBIR_ORDEN_EN_CLI: "RECIBIR_ORDEN_EN_CLI",
  TERMINAR_ORDEN: "TERMINAR_ORDEN",
};

export const Prestaciones = ({ items }) => {
  return (
    <ScrollDiv>
      <Tabla>
        <TablaHeader>
          <tr>
            <TablaHeaderTexto scope="col">Nombre</TablaHeaderTexto>
            <TablaHeaderTexto scope="col">Dientes</TablaHeaderTexto>
            <TablaHeaderTexto scope="col">Nota</TablaHeaderTexto>
          </tr>
        </TablaHeader>
        <TablaBody>
          {items.map(({ nombre, dientes, nota }) => (
            <tr>
              <TablaBodyTexto>{nombre}</TablaBodyTexto>
              <TablaBodyTexto>{dientes}</TablaBodyTexto>
              <TablaBodyTexto>{nota}</TablaBodyTexto>
            </tr>
          ))}
        </TablaBody>
      </Tabla>
    </ScrollDiv>
  );
};

const Fila = ({ item, handleClick }) => {
  return (
    <tr key={item.id}>
      <TablaBodyTexto data-label="FecEnvio">{item.fecEnv}</TablaBodyTexto>
      <TablaBodyTexto data-label="Numero">{item.numCor}</TablaBodyTexto>
      <TablaBodyTexto data-label="Estado">{item.estado}</TablaBodyTexto>
      <TablaBodyTexto data-label="Paciente">{item.pacienteNombres}</TablaBodyTexto>
      <TablaBodyTexto data-label="Deuda">{item.precio}</TablaBodyTexto>
      <TablaBodyTexto data-label="Accion">
        {item.estado == ESTADOS.PROCESO && (
          <IconButton
            aria-label=""
            onClick={() => handleClick(item, EVENTOS.TERMINAR_ORDEN)}
            isShown={true}
          >
            <Tooltip
              TransitionComponent={Zoom}
              placement="top"
              title="Terminar Orden"
            >
              <PlayArrowIcon style={{ color: "#0089ff" }} />
            </Tooltip>
          </IconButton>
        )}
        {item.estado == ESTADOS.SOLICITADO && (
          <IconButton
            aria-label=""
            onClick={() => handleClick(item, EVENTOS.VER_ORDEN)}
            isShown={true}
          >
            <Tooltip TransitionComponent={Zoom} placement="top" title="Ver">
              <VisibilityIcon style={{ color: "green" }} />
            </Tooltip>
          </IconButton>
        )}
      </TablaBodyTexto>
    </tr>
  );
};

export const Finanzas = ({ precio, pagado }) => (
  <TablaFinanzas>
    <TablaBodyFinanzas>
      <tr>
        <td>
          <TablaBodyTextoFinanzaPrecio>
            Precio: S/ {Number(precio).toFixed(2)}
          </TablaBodyTextoFinanzaPrecio>
        </td>
        <td>
          <TablaBodyTextoFinanzaPagado>
            Pagado: S/ {Number(pagado).toFixed(2)}
          </TablaBodyTextoFinanzaPagado>
        </td>
        <td>
          <TablaBodyTextoSaldo>
            Saldo: S/ {Number(precio - pagado).toFixed(2)}
          </TablaBodyTextoSaldo>
        </td>
      </tr>
    </TablaBodyFinanzas>
  </TablaFinanzas>
);
const Tarjeta = ({ orden, onRecibir }) => {
  const {
    fecEnv,
    numCor,
    pacienteNombres,
    precio,
    pagado,
    labNombre,
    estado,
    items,
  } = orden;

  return (
    <ContenedorTarjeta>
      <ContenedorCabeceraTarjeta
        title={numCor}
        subheader={
          <TablaBodyTextoFinanzaPrecio>
            <div>{labNombre}</div>
            <div>Precio: S/ {Number(precio).toFixed(2)}</div>
            <div> Estado: {estado}</div>
          </TablaBodyTextoFinanzaPrecio>
        }
      />

      <ContenedorContenidoTarjeta>
        <div>{`${fecEnv} - ${pacienteNombres}`}</div>
        <Prestaciones items={items}></Prestaciones>
      </ContenedorContenidoTarjeta>

      <TarjetaAccion disableSpacing>
        <IconButton
          aria-label=""
          onClick={() => onRecibir(orden)}
          isShown={true}
        >
          <Tooltip
            TransitionComponent={Zoom}
            placement="top"
            title="Enviar prestación"
          >
            <PlayArrowIcon style={{ color: "#0089ff" }} />
          </Tooltip>
        </IconButton>
        <IconButton
          aria-label=""
          onClick={() => onRecibir(orden)}
          isShown={true}
        >
          <Tooltip
            TransitionComponent={Zoom}
            placement="top"
            title="Recibir prestación"
          >
            <PlayArrowIcon style={{ color: "#0089ff" }} />
          </Tooltip>
        </IconButton>
      </TarjetaAccion>
    </ContenedorTarjeta>
  );
};
export const OrdenesTable = ({ items, handleClick }) => {
  return (
    <Tabla>
      <TablaHeader>
        <tr>
          <TablaHeaderTexto scope="col">Fec. Envio</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Numero</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Estado</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Paciente</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Precio</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Accion</TablaHeaderTexto>
        </tr>
      </TablaHeader>
      <TablaBody>
        {items.map((item) => {
          return <Fila key={item.id} item={item} handleClick={handleClick} />;
        })}
      </TablaBody>
    </Tabla>
  );
};

const useOrdenes = (ordenes) => {
  const [labId, setLabId] = React.useState("");
  const [query, setQuery] = React.useState("");
  const [filteredItems, setFilteredItems] = React.useState(ordenes);

  React.useMemo(() => {
    let result = ordenes;

    result = ordenes.filter((orden) => orden.estado == ESTADOS.PROCESO);
    if (labId && ordenes.length > 0) {
      result = ordenes.filter((orden) => {
        return (
          `${orden.prestacionNombre} ${orden.pacienteNombres} ${orden.numCor}`
            .toLocaleLowerCase()
            .includes(query.toLocaleLowerCase()) &&
          orden.labId === labId &&
          orden.estado == ESTADOS.PROCESO
        );
      });
    }

    setFilteredItems(result);
  }, [ordenes, query, labId]);
  return [filteredItems, labId, setLabId, query, setQuery];
};
export const OrdenesLab = () => {
  const [estado, setEstado] = React.useState({
    loading: true,
    labs: [],
    ordenes: [],
    error: "",
  });
  const EVENTOS = {
    EDITAR_ORDEN_EN_CLI: "EDITAR_ORDEN_EN_CLI",
    NUEVA_ORDEN_EN_CLI: "NUEVA_ORDEN_EN_CLI",
    VER_ORDEN: "VER_ORDEN",
    ENVIAR_ORDEN_A_LAB: "ENVIAR_ORDEN_A_LAB",
  };
  const labs = useSelector((state) => state.labs);
  const ordenes = useSelector((state) => state.ordenes);
  const orden = useSelector((state) => state.orden);
  const sesion = useSelector((state) => state.sesion);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [accion, setAccion] = React.useState("");
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [ordenesFiltradas, labId, setLabId, query, setQuery] =
    useOrdenes(ordenes);
  React.useEffect(() => {
    dispatch(getLabs());
    dispatch(getOrdenesEmpresa(sesion.usuario.empresaId));
  }, []);

  const handleOpenModal = (item) => {
    setModalIsOpen(true);
  };

  const handleChange = (e) => {
    setLabId(e.target.value);
  };
  const handleCloseModal = (e) => {
    setModalIsOpen(false);
  };
  const handleClick = (item, evento) => {
    item.fecRec = new Date();

    setAccion(evento);
    dispatch(setOrden(item));
    setModalIsOpen(true);
  };

  if (sesion.loading || sesion.loading == undefined)
    return <MainContainer><Spinner animation="border" role="status">
      <span className="visually-hidden"></span>
    </Spinner></MainContainer>;
  else if (sesion.error) return <div>error + {sesion.error}</div>;
  return (
    <MainContainer>
      <ContainerTitle>
        <ContainerTitleText>Ordenes</ContainerTitleText>
      </ContainerTitle>

      <ContainerBody>
        <ContenedorTabla>
          <ContainerTitles>
            <ContainerTitleOrden>
              <StyledLabel>Buscar orden:</StyledLabel>
              <StyledInput
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </ContainerTitleOrden>
            <SelectStyle
              id="labId"
              name="labId"
              value={labId}
              onChange={handleChange}
              onSelected={handleChange}
              required
            >
              <option value="" selected disabled hidden>
                Escoja un laboratorio
              </option>
              {labs.map((lab) => (
                <option value={lab.id}>{lab.razonSocial}</option>
              ))}
            </SelectStyle>
          </ContainerTitles>
          <OrdenesTable items={ordenesFiltradas} handleClick={handleClick} />
        </ContenedorTabla>
      </ContainerBody>

      <OrdenModalContainer
        isOpen={modalIsOpen}
        handleCloseModal={handleCloseModal}
        accion={accion}
      />
    </MainContainer>
  );
};
