import React, { useState, useEffect } from "react";
import { MyCheck } from "./styles";
import Form from "react-bootstrap/Form";
export const CheckboxText = ({ index, item, onCheckChange, onTextChange }) => {
  const { llave, checked, valor } = item;
  const handleChange = (e, index) => {
    if (e.target.name === "checkbox")
      onCheckChange(index, {
        ...item,
        llave: e.target.value,
        checked: e.target.checked,
        // valor: e.target.checked ? valor : "",
      });
    if (e.target.name === "textbox") {
      onTextChange(index, { ...item, valor: e.target.value });
    }
  };

  return (
    <React.Fragment>
      <label
        style={{
          display: "flex",
          alignItems: "center",
          color: "#040404",
          gap: "1rem",
        }}
      >
        <MyCheck
          name="checkbox"
          type="checkbox"
          checked={checked}
          onChange={(e) => handleChange(e, index)}
          value={llave}
        />
        {llave}
      </label>
      {checked && (
        <Form.Control
          name="textbox"
          type="text"
          value={valor}
          onChange={(e) => handleChange(e, index)}
          className="mb-2"
        />
      )}
    </React.Fragment>
  );
};
