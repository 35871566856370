import { db } from "../../services/firebase/Config";

import {
  ADD_PRESTATION_SESION,
  REMOVE_PRESTACION_SESION,
  UPDATE_PRESTATION_SESION_IN_ARRAY,
  SET_PRESTACIONES_SESION,
  NEW_PRESTACIONES_SESION,
  SAVE_PRESTACIONES_SESION_REQUEST,
  SAVE_PRESTACIONES_SESION_SUCCESS,
  SAVE_PRESTACIONES_SESION_FAILURE,
  FETCH_PRESTACIONES_SESION_REQUEST,
  FETCH_PRESTACIONES_SESION_SUCCESS,
  FETCH_PRESTACIONES_SESION_FAILURE,
} from "./prestacionesSesionTypes";

import { httpRequest, httpSuccess, httpFailure } from "../sesion/sesionActions";
import { setEstadoCompletado } from "../sesion/sesionActions";

export const newPrestacionesSesion = () => {
  return {
    type: NEW_PRESTACIONES_SESION,
  };
};

const fetchPrestacionesSesionSuccess = (payload) => {
  return {
    type: FETCH_PRESTACIONES_SESION_SUCCESS,
    payload,
  };
};

const fetchPrestacionesSesionFailure = (payload) => {
  return {
    type: FETCH_PRESTACIONES_SESION_FAILURE,
    payload,
  };
};

export const addPrestationSesion = (payload) => {
  return {
    type: ADD_PRESTATION_SESION,
    payload,
  };
};

export const updatePrestationSesionInArray = (payload) => {
  return {
    type: UPDATE_PRESTATION_SESION_IN_ARRAY,
    payload,
  };
};
// Remove Prestation
export const removePrestacionSesion = (prestacion) => {
  return {
    type: REMOVE_PRESTACION_SESION,
    payload: prestacion,
  };
};
// Set Prestaciones
export const setPrestacionesSesion = (prestaciones) => {
  return {
    type: SET_PRESTACIONES_SESION,
    payload: prestaciones,
  };
};

const savePrestacionesSesionRequest = () => {
  return {
    type: SAVE_PRESTACIONES_SESION_REQUEST,
  };
};

const savePrestacionesSesionSuccess = () => {
  return {
    type: SAVE_PRESTACIONES_SESION_SUCCESS,
  };
};

const savePrestacionesSesionFailure = (error) => {
  return {
    type: SAVE_PRESTACIONES_SESION_FAILURE,
    payload: error,
  };
};

export const savePrestacionesSesionTrx = (prestacionesSesion) => {
  return function (dispatch) {
    var batch = db.batch();
    dispatch(httpRequest());
    var prestacionDb = prestacionesSesion.id
      ? db.collection("prestacionesSesion").doc(prestacionesSesion.id)
      : db.collection("prestacionesSesion").doc();
    batch.set(prestacionDb, prestacionesSesion);
    prestacionesSesion.id = prestacionDb.id;

    batch
      .commit()
      .then(() => {
        dispatch(setPrestacionesSesion(prestacionesSesion));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const fetchPrestacionesSesion = (pacienteId, fecha) => {
  return function (dispatch) {
    dispatch(httpRequest());
    let prestacionesSesion = {
      fecha: new Date(),
      pacienteId: pacienteId,
      items: [],
    };
    db.collection("prestacionesSesion")
      .where("pacienteId", "==", pacienteId)
      .where("fecha", "==", fecha)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          prestacionesSesion = doc.data();
          prestacionesSesion.id = doc.id;
        });
        dispatch(httpSuccess());
        dispatch(fetchPrestacionesSesionSuccess(prestacionesSesion));
        dispatch(
          setEstadoCompletado({
            prestacionesSesion: true,
          })
        );
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};
