import styled from "styled-components";
import { dispositivo } from "../../components/Dispositivos";

export const MainContainer = styled.div`
  width: 100%;
  text-align: center;
  background: #f8f8f8;
`;

export const ContainerTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  padding: 1em 0 0;
`;
export const ContainerTitles = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media ${dispositivo.mobileL} {
    width: 65%;
  }
`;

export const FlowContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ContainerTitleText = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;

export const ContainerBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 1% 2% 1%;
  background: #fff;
  box-shadow: 2px 2px #88888877;
  border-radius: 14px;
  display: flex;
  justify-content: center;
`;

export const Tabla = styled.table`
  width: 100%;
`;

export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const TablaHeaderTexto = styled.th`
  font-size: 0.5rem;
  font-weight: normal;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 0.75rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.laptop} {
    font-size: 1rem;
  }
`;

export const TablaBody = styled.tbody`
  background: #fff;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  border: 2px solid #ccc;
  padding-bottom: 16px;
`;

export const TablaBodyTexto = styled.td`
  font-size: 0.5rem;
  font-weight: bold;
  color: #045184;
  padding: 8px 2px
  .iconButton {
    padding: 0;
  }
  @media ${dispositivo.mobileL} {
    font-size: 0.75rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.laptop} {
    font-size: 1rem;
  }
`;

export const StyledInput = styled.input`
  height: 2rem;
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0d0808;
  line-height: 2.5;
  font-size: 1rem;
  padding-left: 1em;
  width: 100%;
  &::placeholder {
    color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus {
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.mobileL} {
    width: 50%;
  }
`;

export const StyledInputTabla = styled(StyledInput)`
padding-left: 0;
text-align: center;
margin:0 4px;
height: 1.5rem;
font-size: 0.8rem;
@media ${dispositivo.tablet} {
  font-size: 1rem;
  text-align: end;
  padding-right: 1em;
}

`;
export const InputColor = styled.input`
width:20px;
border-width:0;
`;


export const StyledLabel = styled.label`
  vertical-align: middle;
  padding: 0px;
  margin: 0px;
  font-weight: bold;
  font-size: 0.75rem;
  padding-right: 0.5em;
  color: #040404;
  @media ${dispositivo.mobileL} {
    font-size: 1rem; 
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  padding: 0 4%;
  margin: 0 0 1em;
  @media ${dispositivo.mobileL} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;
export const ButtonAbonar = styled.button`
  height: 2.25rem;
  border-radius: 0.8rem;
  width: 100%;
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #0060b0;
  color: #ffffff;
  font-size: 0.75rem;
  border: none;
  &:hover {
    color: #000000;
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.mobileL} {
    width: 7rem;
    font-size: 1rem;
  }
  @media ${dispositivo.tablet} {
    width: 10rem;
  }
  @media ${dispositivo.laptop} {
    width: 12.5rem;
  }
`;
