import styled from "styled-components";
import { dispositivo } from "../../components/Dispositivos";

export const ContainerNav = styled.div`
 display: flex;
 justify-content: center;
 border-top: 1px solid #0089ff;
 border-bottom: 1px solid #0c8de3;
 @media (max-width: 768px) {
  .container-scroll {
    display: flex;
    justify-content: space-between;
    min-width: 300px;
    width: 95%;
    overflow: auto;
  }
  .invisible-scrollbar::-webkit-scrollbar {
    display: none;
  }
}
`;
export const NavMenu = styled.ul`
display: flex;
justify-content: center;
width: 90%;
margin-bottom: 0;
list-style:none

`;
export const NavMenuItem = styled.li`
// background: pink;
height: 40px;
display: flex;
justify-content: center;
width: 100%;
text-align: center;
.nav-links{
  padding: 0 0.5em;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #0060B0;
  &:hover{
    background: #64bdff;
    color: #000000;
  }
  @media (max-width: 768px) {
    width: 150px;
    font-size: .8rem;
  }
  @media (min-width: 1024px) {
    font-size: .9rem;
  }
  @media (min-width: 1440px) {
    font-size: 1rem;
  }
}
.is-active{
  background: #0c8de3;
  color: #ffffff;
  width: 100%;
  @media (max-width: 768px) {
    width: 150px;
  }
}
`;


/*export const ContainerNav = styled.div`
display: flex;
 justify-content: center;
 border-top: 1px solid #0089ff;
 border-bottom: 1px solid #0c8de3;
overflow: auto;
scroll-snap-type: x mandatory;

`;
export const NavMenu = styled.ul`
display: flex;
min-width: 100%;
scroll-snap-type: start;

`;
export const NavMenuItem = styled.li`
// background: pink;
height: 40px;
display: flex;
justify-content: center;
width: 100%;
text-align: center;
.nav-links{
  padding: 0 0.5em;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #0060B0;
  &:hover{
    background: #64bdff;
    color: #000000;
  }
  @media (max-width: 768px) {
    width: 150px;
    font-size: .8rem;
  }
  @media (min-width: 1024px) {
    font-size: .9rem;
  }
  @media (min-width: 1440px) {
    font-size: 1rem;
  }
}
.is-active{
  background: #0c8de3;
  color: #ffffff;
  width: 100%;
  @media (max-width: 768px) {
    width: 150px;
  }
}
`;*/