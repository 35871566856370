import styled from "styled-components";
import { dispositivo } from "../../../../components/Dispositivos";

export const DienteContenedor = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0px;
  padding: 1px;
  @media ${dispositivo.mobileM} {
    padding: 2px;
  }
  @media ${dispositivo.laptop} {
    padding: 4px;
  }
`;
export const DienteContenedorInferior = styled(DienteContenedor)`
justify-content: flex-start;
`;

export const BackContainer = styled.div`
  width: auto;
  display: flex;
  padding: 3px;

  @media ${dispositivo.tablet} {
    padding: 10px;
  }
`;

export const DienteContenedorRaiz = styled.div`
  width: 15px;
  display: flex;
  align-items: center;
  
  padding: 0 0 4px 0;
  @media ${dispositivo.mobileM} {
    padding: 0;
    width: 17px;
  } 
  @media ${dispositivo.mobileL} {
    width: 20px; 
  }
  @media ${dispositivo.tablet} {
    width: 23px; 
  }
 @media ${dispositivo.laptop} {
   width: 25px;    
  }
`;

export const DienteContenedorCorona = styled.div`
display: flex;
width: 100%;
font-size: 0.625rem;
display: flex;
align-items: center;
justify-content: center;
margin: 0px;
padding-right: 3px;
@media ${dispositivo.mobileL} {
  padding-right: 1px;
}
@media ${dispositivo.tablet} {
  font-size: 0.8rem;
}
@media ${dispositivo.laptop} {
  font-size: 1rem; 
}
`;

export const DienteContenedorNumero = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export const ContenedorAuxiliar = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const Container = styled.div`
  display: block;
  width: 100%;
  padding: 5px;
  position: relative;
  overflow: hidden;
`;

export const ContainerTitle = styled.div`
  text-align: center;
`;

export const ContainerTitleText = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  padding: 1.5%;
  background-color: #0c8de3;
  color: white;
  border-radius: 10px;
  @media ${dispositivo.mobileL} {      
    font-size: 1.25rem;
  }
`;

export const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap:1rem;
  padding-top: 1rem;
  .label-styled{
    font-size: 0.875rem;
    color: #040404;
    font-weight: bold;
    @media ${dispositivo.mobileL} {      
      font-size: 1rem;
    }
  }
`;

export const DenticionContainer = styled.div`
  display: block;
`;

export const DenticionBlock = styled.div`
  display: flex;
  justify-content: center;
  margin:  10px 0;
  // max-width: 90%;
`;

export const DenticionSide = styled.div`
  display: flex;
  //justify-content: space-between;
  width: 100%;
  justify-content: center;
`;

export const Centrado = styled.div`
  display: flex;
  justify-content: center;
`;
export const ContainerNuevaPestacion = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  padding: 1%;
`;

export const ButtonAgregar = styled.button`
  height: 2.25rem;
  font-size: 0.6rem;
  width: 5rem;
  border-radius: 0.8rem;      
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #0060b0;
  color: #ffffff;
  border: none;
  margin: 0 0 0 8px;
  &:hover{
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.tablet}{
    font-size: 0.9rem;  
    width: 7rem; 
  }  
  @media ${dispositivo.laptop} {      
    width: 12.5rem;
  }
`;

export const SpanTitulo = styled.span`
  color: #040404;
  font-weight: bold;
  font-size: 0.8rem;
  @media ${dispositivo.mobileL} {
    font-size: 1rem;
  }

  @media ${dispositivo.tablet} {
   
  }
`;

export const SpanVariables = styled.span`
  color: #022B4E;
  font-weight: normal;
  font-size: .625rem;
  word-break: break-all;
  @media ${dispositivo.mobileL} {
    font-size: 1rem;
  }
`;

export const TratamientoDiv = styled.div`
  color: #707070;
  font-size: 14px;
  
`;

export const Img = styled.img`
  width: 100%;
`;

export const StyledSelect = styled.select`
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  line-height: 2.5;
  font-size: 1rem;
  height: 2rem;
  width: 12.5rem;
  text-align: end;
  &::placeholder {
  color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }

  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const DivInfo = styled.div`
display: flex;
flex-direction: column;
align-items: baseline;


@media ${dispositivo.tablet} {
  flex-direction: row;
  gap: .5rem;
}
`;

export const ImgIcon = styled.img`
width: 90%;
`;