import styled, { css } from "styled-components";
import { dispositivo } from "../../components/Dispositivos";
import { Link } from "react-router-dom";
export const DienteContenedor = styled.div`
  display: block;
  max-width: 150px;
  margin: 1px;
  padding: 0;

  @media ${dispositivo.tablet} {
    width: 30px;
    margin: 2px;
  }

  @media ${dispositivo.laptop} {
    margin: 2px;
    width: 40px;
  }
  @media ${dispositivo.laptopL} {
    margin: 2px;
    width: 40px;
  }
`;

export const StyledSelect = styled.select`
  width: 12.5rem;
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0d0808;
  margin: 0 0 0.8em;
  line-height: 2.5;
  font-size: 1rem;
  text-align: end;
  height: 2em;
  &::placeholder {
    color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus {
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }

  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const DienteContenedorRaiz = styled.div`
  display: flex;
  align-items: flex-end;
  width: 1rem;
  height: 58%;
  padding: 1px;
  width: 100%;
  border-radius: 1px;
  background: ${(props) =>
    props.selected && props.grupo == "NUCLEO" ? "aqua" : "white"};
  ${(props) =>
    props.edicion &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
  @media ${dispositivo.mobileL} {
    height: 55%;
    padding: 5px;
  }
  @media ${dispositivo.tablet} {    
    padding: 3px;
  }
  @media ${dispositivo.laptop} {
    padding: 7px;
    width: 100%;
    border-radius: 5px;
  }
  @media ${dispositivo.laptopL} {    
    padding: 7px;
    width: 100%;
    border-radius: 5px;
  }
`;
export const DienteContenedorRaizInferior = styled(DienteContenedorRaiz)`
  display: flex;
  align-items: flex-start;
`;

export const DienteContenedorCorona = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  width: 100%;
  padding: 1px;
  border-radius: 1px;
  background: ${(props) =>
    props.selected && props.grupo == "CARAS" ? "aqua" : "white"};
  ${(props) =>
    props.edicion &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
    @media ${dispositivo.mobileM} {
      padding: 2px;
    }
  @media ${dispositivo.tablet} {
    padding: 5px;
    border-radius: 5px;
    
  }
`;

export const DienteContenedorNumero = styled.div`
  display: block;
  width: 100%;
  font-size: 0.7rem;
  @media ${dispositivo.tablet} {
    font-size: 0.8rem;
    margin: 0px;
  }

  @media ${dispositivo.laptop} {
    justify-content: center;
    font-size: 1rem;
    margin: 0px;
  }
`;

export const MainContainer = styled.div`
  width: 100%;
  padding-left: 3%;
  padding-right: 3%;
  margin-bottom: 15%;
  background: #f8f8f8;
  justify-content: center;
  text-align: center;
`;

export const ContenedorAuxiliar = styled.div`
  display: block;
  justify-content: center;
  text-align: center;
  margin-top: 2px;
`;

export const ContenedorAuxiliarSuperior = styled.div`
  display: block;
  justify-content: center;
  text-align: center;
  @media ${dispositivo.mobileL} {
    margin-bottom: 4px;
    padding-bottom: 4%;
  }
`;


export const DenticionContainer = styled.div`
  background: #fff;
  border-radius: 14px;
  padding: 4%;
  margin-top: 3%;
  display: block;
  box-shadow: 2px 2px #88888877;
`;

export const DenticionBlock = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
 
  @media ${dispositivo.mobileL} {
    margin: 0 3%;
  }
`;

export const DenticionSide = styled.div`
  display: flex;
  //width: ${(props) => (props.isShown ? "100%" : "52%")};
`;

export const ContaineRight = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  justify-content: space-between;
  .label-styled {
    color: #040404;
    font-weight: bold;
  }
`;
export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8%;
  @media ${dispositivo.tablet} {
    padding-bottom: 2%;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
  }
`;
export const StyledButton = styled.button`
  height: 2.25rem;
  font-size: 0.9rem;
  width: 100%;
  border-radius: 0.8rem;
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #0060b0;
  color: #ffffff;
  border: none;
  &:hover {
    color: #000000;
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.mobileL} {
    height: 2.25rem;
    width: 7rem;
  }
  @media ${dispositivo.tablet} {
    width: 10rem;
    font-size: 1rem;
  }
  @media ${dispositivo.laptop} {
    width: 12.5rem;
  }
`;
export const LinkCancelar = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  font-size: 0.9rem;  
  width: 100%;
  border-radius: 0.8rem;      
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #ffffff;
  border: 2px solid #0060b0;
  color: #0060b0;
  text-decoration: none;
  padding: 0.6em 0;
  &:hover {
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
    border: none;
    text-decoration: none;
  }
  @media ${dispositivo.mobileS}{
    font-size: 0.9rem;
  } 
  @media ${dispositivo.tablet}{
    width: 10rem;
  }  
  @media ${dispositivo.laptop} {      
    width: 12.5rem;
  }
`;

