import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useSelector, useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Card, Button, InputGroup, Form } from "react-bootstrap";
import { Checkbox } from "../../components/Checkbox";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { setPacienteSesion } from "../../redux/sesion/sesionActions";
import { crearCuenta } from "../../redux/cuenta/cuentaActions";
import { CheckboxText } from "../../components/CheckboxText/index";
import { TopBarPaciente } from "../../components/TopBarPaciente";
import { fechaToInpuHtml } from "../../helpers/fechas";
import {
  guardaPaciente,
  addPaciente,
  setPaciente,
  addPatologia,
  removePatologia,
  updatePatologia,
} from "../../redux/paciente/pacienteActions";

import { updatePacienteInArray } from "../../redux/pacientes/pacientesActions";
import { getPacienteByNumdocAndEmpresaId } from "../../services/firebase/pacientes";
import { TokenPac } from "../../components/TokenPac";

import {
  MainContainer,
  ContainerBody,
  FormStyled,
  STabs,
  STab,
  STabPanel,
  STabList,
  Tabla,
  TablaHeader,
  TablaHeaderLabel,
  StyledInput,
  StyledSelect,
  TablaHeaderText,
  ContainerPatologias,
  ContainerPatologia,
  StyledButton,
  LabelStyled,
  CotenedorFechaNac,
  ContainerBtn,
  CardBody,
  StyledInput2,
} from "./style";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useLocation } from 'react-router-dom';
import axios from "axios";

const ALERGIA = "ALERGIA";
const ESTOMATOLOGICA = "ESTOMATOLOGICA";
const MEDICA = "MEDICA";
const usePatologias = (patologiasPaciente) => {
  const [patologias, setPatologias] = React.useState([
    { llave: "Anestesia Local", checked: false, valor: "", tipo: ALERGIA },
    { llave: "Antibióticos", checked: false, valor: "", tipo: ALERGIA },
    { llave: "Antinflamatorios", checked: false, valor: "", tipo: ALERGIA },
    { llave: "Otros", checked: false, valor: "", tipo: ALERGIA },
    {
      llave: "Abcesos y F.D.",
      checked: false,
      valor: "",
      tipo: ESTOMATOLOGICA,
    },
    { llave: "Agenesias Den", checked: false, valor: "", tipo: ESTOMATOLOGICA },
    { llave: "Bruxismo", checked: false, valor: "", tipo: ESTOMATOLOGICA },
    { llave: "Desgaste Den", checked: false, valor: "", tipo: ESTOMATOLOGICA },
    { llave: "Gingivitis", checked: false, valor: "", tipo: ESTOMATOLOGICA },
    { llave: "Halitosis", checked: false, valor: "", tipo: ESTOMATOLOGICA },
    { llave: "Periodontitis", checked: false, valor: "", tipo: ESTOMATOLOGICA },
    { llave: "Piezas", checked: false, valor: "", tipo: ESTOMATOLOGICA },
    { llave: "Prob Oclusión", checked: false, valor: "", tipo: ESTOMATOLOGICA },
    {
      llave: "Sensibilidad Den",
      checked: false,
      valor: "",
      tipo: ESTOMATOLOGICA,
    },
    {
      llave: "Transtornos ATM",
      checked: false,
      valor: "",
      tipo: ESTOMATOLOGICA,
    },
    { llave: "Otros", checked: false, valor: "", tipo: ESTOMATOLOGICA },
    { llave: "Asma", checked: false, valor: "", tipo: MEDICA },
    { llave: "Cancer", checked: false, valor: "", tipo: MEDICA },
    { llave: "Cardiopatias", checked: false, valor: "", tipo: MEDICA },
    { llave: "Diabetes", checked: false, valor: "", tipo: MEDICA },
    { llave: "Gastropatias", checked: false, valor: "", tipo: MEDICA },
    { llave: "Hepatitis", checked: false, valor: "", tipo: MEDICA },
    { llave: "Hepatopatias", checked: false, valor: "", tipo: MEDICA },
    { llave: "Hipertensión", checked: false, valor: "", tipo: MEDICA },
    { llave: "Hipotension", checked: false, valor: "", tipo: MEDICA },
    { llave: "Nefropatias", checked: false, valor: "", tipo: MEDICA },
    { llave: "Tuberculosis", checked: false, valor: "", tipo: MEDICA },
    { llave: "Transt Mentales", checked: false, valor: "", tipo: MEDICA },
    { llave: "Transt Neuralgicos", checked: false, valor: "", tipo: MEDICA },
    { llave: "Transt Psiquiatrico", checked: false, valor: "", tipo: MEDICA },
    { llave: "Transt Hemorragic", checked: false, valor: "", tipo: MEDICA },
    { llave: "VIH SIDA", checked: false, valor: "", tipo: MEDICA },
    { llave: "Otros", checked: false, valor: "", tipo: MEDICA },
  ]);
  const patologiasFiltradas = patologias.map((item) => {
    let ele = patologiasPaciente.find(
      (it) => it.llave === item.llave && it.tipo === item.tipo
    );
    return ele ? ele : item;
  });
  return [patologiasFiltradas, setPatologias];
};

const fechaACadena = (fecha) => {
  let f;
  if (fecha === null || fecha === undefined || fecha === "" || isNaN(fecha))
    f = new Date();
  else f = fecha;
  const a = f.getFullYear();
  const m = (f.getMonth() + 1).toString().padStart(2, "0");
  const d = f.getDate().toString().padStart(2, "0");
  return `${a}-${m}-${d}`;
};

export const Paciente = (props) => {
  const paciente = useSelector((state) => state.paciente);
  const [localPaciente, setLocalPaciente] = useState({ ...paciente });
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const pacientes = useSelector((state) => state.pacientes);
  // const cuenta = useSelector((state) => state.cuenta);
  const dispatch = useDispatch();
  const [patologias, setPatologias] = usePatologias(paciente.patologias);
  const { addToast } = useToasts();
  // const editarPaciente = "Editar Paciente";
  // const nuevoPaciente = "Nuevo Paciente";
  const sesion = useSelector((state) => state.sesion);
  const empresaId = sesion.usuario.empresaId
  // const [startDate, setStartDate] = useState(new Date());
  const [token, setToken] = React.useState("")
  const APP_PACIENTE_URL = `${process.env.REACT_APP_APP_PACIENTE_URL}`
  // const endpoint = 'http://127.0.0.1:5001/sysdent-devs/us-central1'
  const API_URL_BASE = `${process.env.REACT_APP_API_URL}`





  const validar = async () => {
    let emailRegex;
    if (paciente.tipDoc === "DNI" && paciente.numDoc.toString().length != 8)
      throw new Error(`DNI inválido`);
    if (paciente.correo && paciente.correo != "") {
      emailRegex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (!emailRegex.test(paciente.correo)) throw new Error("Correo inválido");
    }
    if (!paciente.fecNac) throw new Error(`Ingrese una fecha válida`);
    let fecNac = new Date(paciente.fecNac);
    let hoy = new Date()

    if (fecNac >= hoy) throw new Error("Fecha de nacimiento inválida");
    if (!paciente.id) // se ejecuta solo si se esta creando un nuevo paciente
    await documentoValido(paciente);
  };
  const documentoValido = async () => {
    const localPaciente = await getPacienteByNumdocAndEmpresaId(paciente.numDoc,empresaId)
    if (localPaciente) throw new Error("Paciente ya existe");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validar();
      paciente["patologias"] = patologias.filter((it) => it.checked);
      paciente.empresaId = sesion.usuario.empresaId
      console.log('pac', paciente)
      if (paciente.id == null || paciente.id === "")
        dispatch(addPaciente(paciente));
      else dispatch(guardaPaciente(paciente));
      dispatch(setPaciente(paciente));
      dispatch(setPacienteSesion(true));
      dispatch(updatePacienteInArray(paciente));
      addToast("Se guardaron los cambios", { appearance: "success" });
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };
  const handleCheckChange = (index, item) => {
    if (item.checked) dispatch(addPatologia(item));
    else dispatch(removePatologia(item));
  };
  const handleTextChange = (index, item) => {
    dispatch(updatePatologia(item));
  };

  const getPatologias = (tipo) => {
    if (patologias == null) return;
    let items = patologias
      .filter((it) => it.tipo == tipo)
      .map((item, index) => (
        <ul key={index} className="list-group">
          <li className="list-group-item">
            <CheckboxText
              index={index}
              item={item}
              onCheckChange={handleCheckChange}
              onTextChange={handleTextChange}
            />
          </li>
        </ul>
      ));

    return <ol>{items}</ol>;
  };

  // const handleChange = (e) => {
  //   let { name, value } = e.target;
  //   if (name == 'fecNac') {
  //     var parts = value.split('-');
  //     var mydate = new Date(parts[0], parts[1] - 1, parts[2]);
  //   }
  //   setLocalPaciente({ ...localPaciente, [name]: mydate });
  //   dispatch(setPaciente({ ...paciente, [name]: mydate }));
  // };
  const handleChange = (e) => {
    let myPac = { ...paciente };
    console.log('fecNac', e.target.value)
    if (e.target.name == "fecNac") {
      var parts = e.target.value.split("-");
      var mydate = new Date(parts[0], parts[1] - 1, parts[2]);
      console.log('fecNacDate', mydate)
      dispatch(setPaciente({ ...myPac, [e.target.name]: mydate }));
    }

    dispatch(setPaciente({ ...myPac, [e.target.name]: e.target.value }));
  };


  function handleGetToken() {
    fetch(`https://us-central1-odontoplus-4db47.cloudfunctions.net/api/v1/pacientes/token?userid=${paciente.id}&empresaid=${paciente.empresaId}`)
      .then(async (response) => {
        const res = await response.json();
        let myPac = { ...paciente };
        dispatch(setPaciente({ ...myPac, token: `${APP_PACIENTE_URL}?token=${res.token}` }));
        //setToken(`${urlBase}?token=${res.token}`);
      })
  }

  const handleChangeFecNac = (date) => {
    let myPac = { ...paciente };
    dispatch(setPaciente({ ...myPac, fecNac: date }));
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelStyledledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  if (sesion.loading || sesion.loading === undefined)
    return <div>...procesando</div>;
  if (sesion.error) return <div>{`Hubo un error ${sesion.error}`}</div>;
  return (
    <MainContainer>
      <TopBarPaciente />
      <ContainerBody>
        <FormStyled className="form form--simple" onSubmit={handleSubmit}>
          <STabs
            selectedTabClassName="is-selected"
            selectedTabPanelClassName="is-selected"
          >
            <STabList>
              <STab>Datos Generales</STab>
              <STab>Patologías</STab>

            </STabList>
            <STabPanel>
              <Card>
                <CardBody>
                  <Tabla>
                    <TablaHeader>
                      <tr>
                        <TablaHeaderLabel>
                          <LabelStyled htmlFor="tipDoc">Tip. Doc.</LabelStyled>
                        </TablaHeaderLabel>
                        <TablaHeaderText>
                          <StyledSelect
                            name="tipDoc"
                            id="tipDoc"
                            className="form__select"
                            value={paciente.tipDoc}
                            onChange={handleChange}
                            required
                          //ref={register}
                          >
                            <option value="DNI">DNI</option>
                            <option value="CE">CE</option>
                            <option value="RUC">RUC</option>
                          </StyledSelect>
                        </TablaHeaderText>
                      </tr>
                      <tr>
                        <TablaHeaderLabel>
                          <LabelStyled
                            className="form__labelStyled"
                            htmlFor="numDoc"
                          >
                            Num. Doc.
                          </LabelStyled>
                        </TablaHeaderLabel>
                        <TablaHeaderText>
                          <StyledInput
                            className="form__field"
                            name="numDoc"
                            value={paciente.numDoc}
                            onChange={handleChange}
                            autoComplete="off"
                            required
                          //ref={register}
                          />
                        </TablaHeaderText>
                      </tr>
                      <tr>
                        <TablaHeaderLabel>
                          <LabelStyled htmlFor="apellidos">
                            Apellidos
                          </LabelStyled>
                        </TablaHeaderLabel>
                        <TablaHeaderText>
                          <StyledInput
                            className="form__field"
                            type="input"
                            name="apellidos"
                            value={paciente.apellidos}
                            onChange={handleChange}
                            autoComplete="off"
                            required
                          />
                        </TablaHeaderText>
                      </tr>
                      <tr>
                        <TablaHeaderLabel>
                          <LabelStyled htmlFor="nombres">Nombres</LabelStyled>
                        </TablaHeaderLabel>
                        <TablaHeaderText>
                          <StyledInput
                            className="form__field"
                            type="text"
                            name="nombres"
                            value={paciente.nombres}
                            onChange={handleChange}
                            autoComplete="off"
                            required
                          />
                        </TablaHeaderText>
                      </tr>

                      <tr>
                        <TablaHeaderLabel>
                          <LabelStyled
                            className="form__labelStyled"
                            htmlFor="fecNac"
                          >
                            Fec. Nac.
                          </LabelStyled>
                        </TablaHeaderLabel>
                        <TablaHeaderText>
                          <CotenedorFechaNac>
                            {/* <CotenedorFechaNac>
                            <SDatePicker
                              selected={paciente.fecNac}

                              onChange={(date) => handleChangeFecNac(date)}
                              dateFormat="dd/MM/yyyy"
                            />
                          </CotenedorFechaNac> */}
                            <StyledInput2
                              type="date"

                              name="fecNac"
                              value={
                                paciente.fecNac
                              }
                              onChange={
                                handleChange
                              }
                            />

                          </CotenedorFechaNac>
                        </TablaHeaderText>
                      </tr>
                      <tr>
                        <TablaHeaderLabel>
                          <LabelStyled
                            className="form__labelStyled"
                            htmlFor="direccion"
                          >
                            Dirección
                          </LabelStyled>
                        </TablaHeaderLabel>
                        <TablaHeaderText>
                          <StyledInput
                            className="form__field"
                            type="text"
                            name="direccion"
                            //ref={register}
                            value={paciente.direccion}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </TablaHeaderText>
                      </tr>
                      <tr>
                        <TablaHeaderLabel>
                          <LabelStyled
                            className="form__labelStyled"
                            htmlFor="telefono"
                          >
                            Teléfono
                          </LabelStyled>
                        </TablaHeaderLabel>
                        <TablaHeaderText>
                          <StyledInput
                            className="form__field"
                            type="tel"
                            autoComplete="off"
                            name="telefono"
                            // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            value={paciente.telefono}
                            // ref={register({
                            //   required: "PASSWORD REQUERIDO",
                            //   minlength: { value: 8, message: "MUY DEBIL" },
                            // })}
                            onChange={handleChange}
                          />
                        </TablaHeaderText>
                      </tr>
                      <tr>
                        <TablaHeaderLabel>
                          <LabelStyled
                            className="form__labelStyled"
                            htmlFor="correo"
                          >
                            Correo
                          </LabelStyled>
                        </TablaHeaderLabel>
                        <TablaHeaderText>
                          <StyledInput
                            className="form__field"
                            type="email"
                            name="correo"
                            value={paciente.correo}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </TablaHeaderText>
                      </tr>

                      <tr>
                        <TablaHeaderLabel>
                          <LabelStyled
                            className="form__labelStyled"
                            htmlFor="invitar"
                          >
                            Invitacion
                          </LabelStyled>
                        </TablaHeaderLabel>
                        <TablaHeaderText>
                          <TokenPac token={paciente.token} onGetToken={handleGetToken} />
                        </TablaHeaderText>
                      </tr>
                    </TablaHeader>
                  </Tabla>
                  <ContainerBtn>
                    <StyledButton type="submit" value="Guardar">
                      Guardar
                    </StyledButton>
                  </ContainerBtn>
                </CardBody>
              </Card>
            </STabPanel>
            <STabPanel>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  Médicas
                </AccordionSummary>
                <AccordionDetails>
                  <ContainerPatologias>
                    <ContainerPatologia>
                      {getPatologias(MEDICA)}
                    </ContainerPatologia>
                  </ContainerPatologias>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography> Alergias/Alertas</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ContainerPatologias>
                    <ContainerPatologia>
                      {getPatologias(ALERGIA)}
                    </ContainerPatologia>
                  </ContainerPatologias>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Estomatológicas</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ContainerPatologias>
                    <ContainerPatologia>
                      {getPatologias(ESTOMATOLOGICA)}
                    </ContainerPatologia>
                  </ContainerPatologias>
                </AccordionDetails>
              </Accordion>
              <ContainerBtn>
                <StyledButton type="submit" value="Guardar">
                  Guardar
                </StyledButton>
              </ContainerBtn>
            </STabPanel>
          </STabs>
        </FormStyled>
      </ContainerBody>
    </MainContainer>
  );
};