import React from "react";
import { Modal } from "./Modal/index";

const ConfirmacionSiNoModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.handleCloseModal}>
      <strong>{props.mensaje}</strong>
      <div>
        <div>
          <button className="btn btn-warning" onClick={props.handleCloseModal}>
            No
          </button>
        </div>
        <div>
          <button className="btn btn-success" onClick={props.onClickYes}>
            Si
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmacionSiNoModal;
