import React from "react";

import {
  ContainerTitle,
  ContainerTitleText,
  TablaBody,
  TablaHeaderTextoItems,
  TablaHeaderTextoValues,
  Tabla,
  LinkCancelar,
  ContainerBody,
  SelectStyle,
  ButtonPagar,
  ItemContainer,
  StyledInput2,
  ContainerBtn,
  StyledInputNumber,
  Textarea,
  Dientelab,
} from "./styles";

export const TerminaOrden = ({
  orden,
  labs,
  onCloseModal,
  refOrden,
  onClick,
  onChangeOrden,
  onChangeItem,
}) => {
  return (
    <form ref={refOrden}>
      <ContainerTitle>
        <ContainerTitleText>Terminar Orden</ContainerTitleText>
      </ContainerTitle>
      <ContainerBody>
        <Tabla>
          <TablaBody>
            <tr>
              <TablaHeaderTextoItems><strong>Laboratorio</strong></TablaHeaderTextoItems>
              <TablaHeaderTextoValues>
                <SelectStyle id="labId" name="labId" value={orden.labId} disabled>
                  {labs.map((lab) => (
                    <option value={lab.id}>{lab.razonSocial}</option>
                  ))}
                </SelectStyle>
              </TablaHeaderTextoValues>
            </tr>
            <tr>
              <TablaHeaderTextoItems>
                <strong>Fec. Envío: </strong>
              </TablaHeaderTextoItems>
              <TablaHeaderTextoValues data-label="envio">
                <StyledInput2
                  type="date"
                  value={orden.fecEnv}
                  name="fecEnv"
                  required
                  disabled
                ></StyledInput2>
              </TablaHeaderTextoValues>
            </tr>
            <tr>
              <TablaHeaderTextoItems>
                <strong>Fecha Prueba: </strong>
              </TablaHeaderTextoItems>
              <TablaHeaderTextoValues data-label="recep">
                <StyledInput2
                  type="date"
                  value={orden.fecRec}
                  name="fecRec"
                  onChange={onChangeOrden}
                  required
                ></StyledInput2>
              </TablaHeaderTextoValues>
            </tr>
            {/* <tr>
              <TablaHeaderTextoItems>
                <strong>Nota Recepcion: </strong>
              </TablaHeaderTextoItems>
              <TablaHeaderTextoValues data-label="recep">
                <input
                  type="text"
                  value={orden.notaRec}
                  name="notaRec"
                  onChange={onChangeOrden}
                  required
                ></input>
              </TablaHeaderTextoValues>
            </tr> */}
          </TablaBody>
        </Tabla>

        {orden.items.map((item, index) => (
          <ItemContainer>
            <Dientelab>{` ${item.nombre} dientes:[${item.dientes}] - S/ ${item.precio}`}</Dientelab>

            {/* <TablaHeaderTextoValues>
              <StyledInputNumber
                type="text"
                name="precio"
                value={item.precio}
                onChange={(e) => onChangeItem(e, index)}
                disabled
              />
            </TablaHeaderTextoValues> */}

            <Textarea
              name="nota"
              rows="3"
              cols="50"
              placeholder="Descripcion de la Nota"
              value={item.nota}
              disabled
            ></Textarea>


            <div>
              {item.estados && (
                <ul>
                  {item.estados.map((estado, indey) => (
                    <li>{estado} </li>
                  ))}
                </ul>
              )}

              <input
                name="terminado"
                type="checkbox"
                checked={item.terminado}
                onChange={(e) => onChangeItem(e, index)}
              ></input>
            </div>
          </ItemContainer>
        ))}
      </ContainerBody>
      <ContainerBtn>
        <LinkCancelar onClick={onCloseModal}>Cancelar</LinkCancelar>
        <ButtonPagar onClick={onClick}>Guardar</ButtonPagar>
      </ContainerBtn>
    </form>
  );
};
