import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { dispositivo } from "../../components/Dispositivos";

export const MainContainer = styled.div`
  width: 100%;
  text-align: center;
  background: #f8f8f8;
`;

export const FlexContainer = styled.div`
  display: flex;
  
  `

  export const FlexItemLeft = styled.div`
    flex:70%;
    margin: 5px;
  `

  export const FlexItemRight = styled.div`
    flex:30%;
    
  `

export const ContainerTitle = styled.div`
  color: #000;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-top: 2%;
  display: flex;
  justify-content: space-around;
`;

export const ContainerTitleText = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #ffffff;
  background: #0c8de3;
  border: 2px solid #0c8de3;
  width: 100%;
`;

export const ContainerPadding = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 2px solid #fff;
  width: 100%;
`;


export const ContainerBody = styled.div`
  width: 95%;
  height: 100%;
  padding: 3%;
  margin: 3%;
  background: #fff;
  box-shadow: 2px 2px #88888877;
  border-radius: 14px;
`;

export const ContainerActions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media ${dispositivo.mobileL} {
    flex-direction: row;
  }
`;
export const ContainerLeft = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 1%;
  padding-bottom: 4%;
  @media ${dispositivo.mobileL} {
    text-align: left;
    padding-bottom: 0%;
  }
`;

export const ContainerRight = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-bottom: 1rem;
  @media ${dispositivo.mobileL} {
    text-align: right;
  }
`;

export const ButtonAddBudget = styled(Button)`
  && {
    height: 2.25rem;
    font-size: 0.9rem;
    width: 15rem;
    border-radius: 0.8rem;
    text-transform: uppercase;
    cursor: pointer;
    font-width: bold;
    background-color: #0060b0;
    color: #ffffff;
    border: none;
    &:hover {
      color: #000000;
      background-color: #0c8de390;
      font-width: bold;
    }
  }
`;

export const ContenedorTabla = styled.div`
  @media ${dispositivo.tablet} {
    flex: 1;
    // max-height: 370px;
    // overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 20px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #0c8de3;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #fff;
    }
  }
`;

export const TableWrapper = styled.div`
  thead {
    background: #0c8de3;
    
    tr {
      font-size: 10px;
      font-weight: normal;
      color: #fff;
      td {
        vertical-align: middle;
      }  
      @media ${dispositivo.mobileL} {
        font-size: 14px;
        
      }
  }
}
tbody{
    font-size: 10px;
    font-weight: normal;
}
  
  tfoot {
    background: #0c8de3;
    tr {
      font-size: 10px;
      font-weight: normal;
      color: #fff;
    }
    
  }

`

export const Tabla = styled.table`
  width: 100%;
  padding: 0;
  margin:0
`;

export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const TablaHeaderTexto = styled.th`
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;

export const TablaBody = styled.tbody`
  background: #fff;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  border: 2px solid #ccc;
  padding-bottom: 16px;
`;

export const TablaBodyTexto = styled.th`
  font-size: 0.688rem;
  color:  #045184;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
  .button-icon{
    padding: 5px 0;
    @media ${dispositivo.tablet} {
      padding: 0 5px;
    }
  }
`;
export const StyledSelect = styled.select`
  display: flex;
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  margin: 0 0 0.8em;
  line-height: 2.5;
  font-size: 0.75rem;
  text-align: end;
  height: 2rem;
  &::placeholder {
  color: #04000060;
    font-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  
  @media ${dispositivo.mobileL} {
    font-size: 1rem;
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  color:  #474747;
  vertical-align: middle;
  text-align: center;
  border-color: #dcf2f9;
  &:focus {
    outline: none;
  }
  @media ${dispositivo.mobileM}{
    width: 80%;
  }
  @media ${dispositivo.mobileL}{
    width: 60%;
  }  
  @media ${dispositivo.tablet} {      
    width: 70%;
  }
  @media ${dispositivo.laptop} {      
    width: 40%;
  }
`;

export const StyledInputNota = styled(StyledInput)`
  width: 90%;
 
`;

export const TablaBodyTextoDiente = styled.div`
  font-size: 0.6rem;
  color:  #045184;
  padding: 0.313em 0  0.313em 0.5em;
  display: flex;
  justify-content: space-between;
  max-width: 80px;
  width: 95%;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 2px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0c8de3;
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
  @media ${dispositivo.laptopL} {
    max-width: 200px;
  }
`;
