import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY || "AIzaSyAprSBZYIYG_Qpr4reb12lt8DcCasp3i4E",
  authDomain: process.env.REACT_APP_AUTH_DOMAIN || "sysdent-devs.firebaseapp.com",
  databaseURL: process.env.REACT_APP_DATABASE_URL || "https://sysdent-devs-default-rtdb.firebaseio.com",
  projectId: process.env.REACT_APP_PROJECT_ID || "sysdent-devs",
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET || "sysdent-devs.appspot.com",
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID || "688456294615",
  appId: process.env.REACT_APP_APP_ID || "1:688456294615:web:18fbaba7c6da26b148d092",
};
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const functions = firebase.functions();

const firestorage = firebase.storage();
const auth = firebase.auth();
export { auth, db, firestorage, functions };
