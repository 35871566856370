
import React from "react";
import { TopBarPaciente } from "../../components/TopBarPaciente";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import BuscarTratamientos from "../../components/BuscarTratamientos/index";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {addPrestacion, removePrestacion, getMetas, guardaMetas,setMetas} from "../../redux/metas/metasActions";
import {
  MainContainer,
  TablaHeaderTexto as td,
  StyledInput,
  ContainerTitle,
  ContainerTitleText,
  ContainerPadding,
  TableWrapper,
  StyledSelect
} from "./styles";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import Spinner from "react-bootstrap/Spinner";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Button from 'react-bootstrap/Button';
import { useEffect } from "react";
import { useState } from "react";
import { fetchMonthStats } from "../../services/firebase/stsMensual/index";

const Fila = ({ item, onDelete, onChangeQuantity,idx }) => {
  
  return (
    <tr
    >
      <td>
        {`${item.tratamiento}${item.tipo ? "|"+item.tipo : ""}`}
      </td>
      <td>
        <span>{item.ventaTot}</span>
      </td>

      <td>
        <span>{item.costoTot}</span>
      </td>
      {/* <td data-label="Sub. Tot.">
        <span>{item.precio - item.costo}</span>
      </td> */}

      <td>
        <StyledInput
          type="number"
          value={item.cantidad}
          name="cantidad"
          min="0"
          max="999999999"
          step="1"
          placeholder="Cantidad.."
          onChange={(e)=>onChangeQuantity(e,idx)}
        ></StyledInput>
      </td>
      
      <td>
        <span>{(item.ganancia)}</span>
      </td>
      

      <td>
     
        
          <IconButton
            className="button-icon"
            disableRipple size="small"
            aria-label=""
            onClick={() => onDelete(item)}
          >
            <Tooltip
              TransitionComponent={Zoom}
              placement="top"
              title="Eliminar"
            >
              <DeleteIcon style={{ color: "red" }} />
            </Tooltip>
          </IconButton>
        
        
      </td>
    </tr>
  );
};

const FilaTotales = ({ totales }) => {
  
  return (
<tfoot>    
            <tr>
              <td>Totales</td>
              <td>{totales.ventaTotal}</td>
              <td>{totales.costoTotal}</td>
              <td></td>
              <td>{totales.gananciaTotal}</td>
            </tr>
        <tr>
          <td colSpan={4}>Gastos Fijos</td>
          <td >{totales.gastosFijos}</td>
        </tr>
        <tr><td colSpan={4}>Utilidad</td>
        <td >{totales.gananciaTotal - totales.gastosFijos}</td></tr>
        </tfoot>
        
  );
};

export const Metas = () => {
  //const [prestaciones, setPrestaciones] = useState([])
  const empresa = useSelector((state) => state.empresa);
  const metas = useSelector((state)=> state.metas)
  const gastosFijos = useSelector((state) => state.gastosFijos);
  const minutosTrabajados = (gastosFijos.horas * 60 * gastosFijos.dias)
  const costoMinuto = minutosTrabajados>0? (Math.round(gastosFijos.total / minutosTrabajados*100)/100):undefined; 
  const dispatch = useDispatch();  
  const { addToast } = useToasts();
  const sesion = useSelector((state) => state.sesion);
  let url = `${process.env.REACT_APP_API_URL}/api/v1/reportes/metas/${empresa.id}`;
  const [currentGoals, setCurrentGoals] = useState([])
  const [loadingGoals, setLoadingGoals] = useState(false)
  const fecha = new Date();
  const [periodo, setPeriodo]= useState(fecha.getMonth());
  const [year, setYear] = useState(fecha.getFullYear())
  const years = [fecha.getFullYear()- 1, fecha.getFullYear()];
  const meses = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  const handleChangeQuantity = (e,idx)=>{
    // const myPr = metas.prestaciones.slice(0)
    const MyMetas = Object.assign({},metas)
    const item = MyMetas.prestaciones[idx];
    console.log('item antes',item)
    item.cantidad = e.target.value
    item.ventaTot = Round(item.precio * item.cantidad)  
    item.costoTot = Round(item.costo * item.cantidad)
    item.ganancia = Round((item.precio - item.costo)*item.cantidad)
    console.log('item dps',item)
    dispatch(setMetas(MyMetas))
  }
  const getTotales = (prestaciones)=>{
    const totales = {
      ventaTotal :0,
      costoTotal :0,
      gananciaTotal:0,
      gastosFijos: 0
    }
  
      if( prestaciones.length > 0 ){
        
        totales.ventaTotal = prestaciones
        .map((item) => Number(item.ventaTot))
        .reduce((total, num) => Round(total + num), 0)

        totales.costoTotal = prestaciones
        .map((item) => Number(item.costoTot))
        .reduce((total, num) => Round(total + num), 0)

        totales.gananciaTotal = prestaciones
        .map((item) => Number(item.ganancia))
        .reduce((total, num) => Round(total + num), 0)
        totales.gastosFijos = gastosFijos.total
      }
      
      return totales;
  }

  const getTotalesReales = (prestaciones)=>{
    
    function getCostoFijo(tiempo){
      return tiempo? (tiempo * costoMinuto):0
    }
    
    const totales = {
      ventaTotal :0,
      costoTotal :0,
      gananciaTotal:0,
      gastosFijos: 0
    }
  if (prestaciones){

    if( prestaciones.length > 0 ){
      
      totales.ventaTotal = prestaciones
      .map((item) => item.precio)
      .reduce((total, num) => Round(total + num), 0)

      totales.costoTotal = prestaciones
      .map((item) => item.costo+getCostoFijo(item.tiempo))
      .reduce((total, num) => Round(total + num), 0)

      totales.gananciaTotal = prestaciones
      .map((item) => item.precio - item.costo)
      .reduce((total, num) => Round(total + num), 0)
      totales.gastosFijos = gastosFijos.total
    }
  }
      
      return totales;
  }
  const totales = getTotales(metas.prestaciones);
  const totalesReales = getTotalesReales(currentGoals)

  useEffect(()=>{
    if (empresa.id){
      dispatch(getMetas(empresa.id))
      
    }
  },[])

  useEffect(()=>{
    if (empresa.id){
      handleGetGoals(year, periodo)
    }
    
  },[year,periodo])
  
  const handleClick=()=>{
    try{
    validar()
      metas.empresaId= empresa.id;
      dispatch(guardaMetas(metas))
      addToast("Operación satisfactoria", { appearance: "success" });
    }
    catch(err){
      addToast(err.message, { appearance: "error" })
    }
  }

  const handleGetGoals = async (year,month) => {
    setLoadingGoals(true);
    let data = await fetchMonthStats(empresa.id, year, month);
    console.log(data);
    setCurrentGoals(data.prestacionesTerminadas)
    setLoadingGoals(false)
  };

  
  const validar = ()=>{
    
      if (metas.prestaciones.length=== 0){
        throw new Error("Establezca sus metas")
      }
      const utilidad = 
      metas.prestaciones
        .map((item) => Number(item.precio-item.costo)*item.cantidad)
        .reduce((total, num) => total + num, 0)-gastosFijos.total
        if (utilidad<0){
          throw new Error("Establezca una utilidad positiva")
        }
        

  }
  function Round(number){
    return Math.round((number)*100)/100
  }
  const handleSelectedItem = (item) => {
    const costoFijo = item.costo + item.tiempo? (item.tiempo * costoMinuto):0
      
    const existe = metas.prestaciones.find((pr)=> pr.id === item.id)
    if (!existe)
    {
      item.cantidad = 1
      item.costo = Round(item.costo + costoFijo)
      item.ventaTot = item.precio
      item.costoTot = item.costo
      item.ganancia = Round(item.precio - item.costo)
      dispatch(addPrestacion(item))
      // dispatch(setMetas({...metas, prestaciones:[...metas.prestaciones, item]}))
    }
  };

  const handleDelete = (item) => {
    dispatch(removePrestacion(item))
  }
 

  const eeffc = (totales)=>
  (<Popover id="popover-basic">
  <Popover.Header as="h3">Estados financieros</Popover.Header>
  <Popover.Body>
    <TableWrapper>
  <Table responsive size="sm">
<thead>
  <tr>
    <td>Descripcion</td>
    <td>Total</td>
  </tr>
</thead>
<tbody>
<tr>
  <td>Ventas</td>
  <td>{totales.ventaTotal}</td>
</tr>
  <tr>
    <td>Gastos Fijos</td>
    <td>{totales.gastosFijos}</td>
  </tr>
  
<tr>
  <td>Gastos Variables</td>
  <td>{totales.costoTotal}</td>
</tr>
<tr>
  <td>Utilidad</td>
  <td>{totales.gananciaTotal - totales.gastosFijos}</td>
</tr>
</tbody>

</Table>
</TableWrapper>
  </Popover.Body>
</Popover>
)
 
const EEFF = ({totales, position}) => (

    
    <OverlayTrigger trigger="click" placement={position} overlay={eeffc(totales)}>
      <Button variant="success">EEFF</Button>
    </OverlayTrigger>
  );
  

  if (sesion.loading || sesion.loading === undefined)
    return (
      <MainContainer>
        <Spinner animation="border" role="status">
          <span className="visually-hidden"></span>
        </Spinner>
      </MainContainer>
    );
  if (sesion.error) return <div>{`Hubo un error ${sesion.error}`}</div>;
  return (
    <MainContainer>
  <TopBarPaciente />
  
  <ContainerTitle>
        <ContainerTitleText>Establece tus Metas Operativas:</ContainerTitleText>
      </ContainerTitle>
        <Tabs
      defaultActiveKey="MetasDeseadas"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="MetasDeseadas" title="Metas Deseadas">
      <Container>
       <Container>
        <Row>
          <Col xs={10}><BuscarTratamientos onSelected={handleSelectedItem} /></Col>
          <Col xs={2}>
          <ContainerPadding>

            <Button variant="primary" onClick={handleClick} size="sm">Guardar</Button>
          </ContainerPadding>
            </Col>
        </Row>
        </Container> 
        <TableWrapper>
<Table responsive bordered hover size="sm">
        <thead>
          <tr>
            <td >
              Tratamiento
            </td>
            <td >
              Precio
            </td>
            <td >
              Costo
            </td>
            <td>
              Cantidad
            </td>
            <td>
              Ganancia
            </td>
            <td>
            <EEFF totales={totales} position="left"/>
            </td>
          </tr>
        </thead>
        <tbody>
          {metas.prestaciones.map((item, idx) => {
            return (
              <Fila
                key={item.id}
                item={item}
                onDelete = {handleDelete}
                onChangeQuantity = {handleChangeQuantity}
                idx = {idx}
              />
            );
          })
          
          }
          
        </tbody>
        <FilaTotales totales={totales}/>
      </Table>
      </TableWrapper>
      </Container>

      </Tab>
     
      
      
      <Tab eventKey="metasObtenidas" title="Metas obtenidas">
        <Container>
          <Row>
            <Col xs="1">
            <StyledSelect
                        aria-label="Año"
                            name="year"
                            id="year"
                            className="form__select"
                            value={year}
                            onChange={(e)=>setYear(Number(e.target.value))}
                            required
                          //ref={register}
                          >
                            {years.map((year)=><option value={year}>{year}</option>)}
                            
                          </StyledSelect>

            <StyledSelect
                          aria-label="Mes"
                            name="periodo"
                            id="periodo"
                            className="form__select"
                            value={periodo}
                            onChange={(e)=>setPeriodo(Number(e.target.value))}
                            required
                          //ref={register}
                          >
                            {meses.map((mes, index)=><option value={index}>{mes}</option>)}
                            
                          </StyledSelect>
              
            </Col>
            <Col xs="10">
           
            {loadingGoals===true && (<span>Loading...</span>)}
              {!loadingGoals===true &&(
              <TableWrapper>
              <Table responsive striped size="sm" >
                <thead>
                  <tr>
                    <td>Tratamiento</td>
                    <td>Precio</td>
                    <td>Costo</td>
                    <td>Cantidad</td>
                    <td>Ganancia</td>
                  </tr>
                </thead>
                <tbody>
                  {currentGoals&&currentGoals.map((fila)=>
                   <tr>
                   <td>{fila.tratamiento}</td>
                   <td>{fila.precio}</td>
                   <td>{fila.costo}</td>
                   <td>{fila.cantidad}</td>
                   <td>{fila.ganancia}</td>
                 </tr>
                  )}
                 
                </tbody>
                <FilaTotales totales = {totalesReales}/>
              </Table>
              </TableWrapper>
              )}
            </Col>
            <Col xs="1">
            <EEFF totales ={totalesReales} position="left"/>
            </Col>
          </Row>
        </Container>
      </Tab>
    </Tabs>
      
    </MainContainer>
  );
};
export default Metas;
