import React from "react";
import Extraccion from "./Extraccion";
import { Grafico } from "./Grafico";
import { Icon } from "./Icon";
import "./style.css";
const IncisivoLateralSup = (props) => {
  const constrastColor = "blue";
  const defaultColor = "white";
  const configuracion = props.config;
  const getColor = (cara) => {
    const defaultColor = "#ffeca2";

    let color = defaultColor;
    if (props.config.caras) {
      let idx = props.config.caras.findIndex((it) => it.cara == cara);
      if (idx > -1) color = props.config.caras[idx].color;
    }
    return color;
  };
  return (
    <svg
      id="Incisor_central"
      data-name="Incisor central"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 190.6 402.25"
    >
      <title>incisivo_lateral555_sup</title>
      <g id="Implante">
        <path
          d="M19.91,281.45,51.64,283l71.68-.47,47.49-.7-2.16-31,2.16-36s-28.19-7.29-31.53-11.84c-1.7-2.3,0-10.31,0-10.31,7.05-1.64,11.82-2.87,13.08-3.39,4.16-1.88,5.94-5,6.82-11.18,1.49-10-2.37-14.43-12.45-14.43a65.94,65.94,0,0,0-7.55.79V154.9a79,79,0,0,0,7.83-2.32c3.79-1.89,5.42-5,6.24-11.19,1.35-10-2.17-14.42-11.4-14.42a26.64,26.64,0,0,0-2.67.2V111.1a14.1,14.1,0,0,0,2.07-1c4.6-2.83,5.56-5.12,5.56-12.13,0-7.35-2.32-11.29-7.63-12.44V69.17c6.91-3.42,7.63-6.85,7.63-13,0-7.37-2.41-11.38-7.63-12.48V36.34a1.88,1.88,0,0,0-.83-1.46,1.16,1.16,0,0,0-.16-.4c-.84-1.43-1.65-16.84-12.89-24.86-10.21-5.43-31-7.09-35.21-7.09s-20.37-1-35.27,8.76C44.35,18.16,44.69,29.93,44.08,33.62c-.18.62-.36,1.34-.54,2.15a4.35,4.35,0,0,0-2,3.46V66.68a21.16,21.16,0,0,0-2.69,1c-3.4,1.89-4.85,5-5.58,11.19-1.1,9.1,1.53,13.66,8.29,14.32v15.65C34,112.42,33,116,33,122.12c0,7.88,2.69,11.86,8.52,12.66V148.7a57.92,57.92,0,0,0-7.91,2.67c-6.51,3.5-7.32,5-7.32,12.53,0,8.76,4.61,13.08,13.83,12.81a10.92,10.92,0,0,0,1.4-.17v7.72c-6.71,1.67-11.48,3-13.19,3.82-7.12,3.5-8,5-8,12.54,0,8.76,5,13.07,15.13,12.8.57,0,2.51-.29,5.51-.81-9.82,13.85-21.09,18-21.09,18Z"
          transform="translate(0 -2.5)"
          fill={configuracion.implante.fill}
          stroke={configuracion.implante.strokeColor}
        />
      </g>
      <path
        id="Raiz"
        d="M20.53,290.28l150.9-1.8S129,119,130,82.92c1.5-51-22-70.44-22-70.44-2.3-9.5-13.6-9.6-16-.2,0,0-33.41,17.5-42.41,170.5C47.63,217.26,20.53,290.28,20.53,290.28Z"
        transform="translate(0 -2.5)"
        fill={configuracion.raiz.fill}
        stroke={configuracion.raiz.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
      <path
        id="Nucleo"
        d="M99.11,234.8h0c-4.9,0-20.1,5.65-19,.78L91.41,19.8c-1.1-4.8,2.8-8.8,7.7-8.8h0a8.69,8.69,0,0,1,8.8,8.57h0v.2h0l4.3,215C112.31,239.58,104,234.8,99.11,234.8Z"
        transform="translate(0 -2.5)"
        fill={configuracion.nucleo.fill}
        stroke={configuracion.nucleo.strokeColor}
      />
      <path
        id="6"
        d="M117.44,254.42c-1.17,7.39,44.16,14.62,42.36,5.94C153.5,230,143,186.7,139.5,175.7a49.37,49.37,0,0,0-2.55-8.92c-2.57-6-12.33-3.44-16.87-.25-3.66,2.58-2.72,5.05-2.83,5.81C115.87,181.77,120.12,237.56,117.44,254.42Z"
        transform="translate(0 -2.5)"
        fill={getColor("6")}
      />
      <path
        id="7"
        d="M77,254.42c1.17,7.39-46.29,13.89-42.35,5.94C49.21,231,58.05,185,56,174c-.16-.89,0-7.06,0-7.06.82-5.69,13.84-3.63,18.37-.44,3.66,2.58,2.73,5.05,2.84,5.81C78.6,181.77,74.35,237.56,77,254.42Z"
        transform="translate(0 -2.5)"
        fill={getColor("7")}
      />
      <path
        id="8"
        d="M156.64,264.33c-25.07-45.91-65.4-41.47-115.75-10.8-1.64,1,17-49.5,17-49.5,3.77-14,8.66-23,19.59-23.39l39.64-1.33c13-.43,24.39,11.64,27.29,28.89C144.41,208.2,163.26,276.46,156.64,264.33Z"
        transform="translate(0 -2.5)"
        fill={getColor("8")}
      />
      <path
        id="9"
        d="M163.27,273.17c-26.1-37.81-73.44-68-128.6-12.81-7.34,7.34,16.08-38.83,16.08-38.83C55,210,64.05,202,76.34,201.75l44.55-1.1a30.71,30.71,0,0,1,30.69,23.84S170.38,283.48,163.27,273.17Z"
        transform="translate(0 -2.5)"
        fill={getColor("9")}
      />
      <path
        id="Corona"
        d="M179.81,315.63c32.5,106.5-37.9,84.8-84.7,84.8s-116,7.1-84.8-84.8c16.7-49.1,38-84.8,84.8-84.8S165.91,269.93,179.81,315.63Z"
        transform="translate(0 -2.5)"
        fill={configuracion.corona.fill}
        stroke={configuracion.corona.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
    </svg>
  );
};

export default IncisivoLateralSup;
