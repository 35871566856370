import React, { useState, useContext, useCallback } from "react";
import { withRouter, Redirect } from "react-router";
import { auth } from "../../services/firebase/Config";
import firebase from "firebase/app";
import { setUsuarioSesion } from "../../redux/sesion/sesionActions";
import {
  setUser,
  httpUserRequest,
  httpUserRequestSuccess,
} from "../../redux/usuario/usuarioActions";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import NuevoLogo from "../../img/slider/nuevoLogo.png";
import EjemploSlider from "../../img/slider/ejemploSlider.png";
import EjemploSlider2 from "../../img/slider/ejemploSlider2.png";
import { fetchUser } from "../../services/firebase/usuarios";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useToasts } from "react-toast-notifications";
import { useForm } from "../../hooks/useForm";
import {
  LoginViewWrapper,
  FooterWrapper,
  LoginView,
  SectionForm,
  ImgLogo,
} from "./styles";
import { AuthContext } from "../../components/Auth.js";
import Carousel from "react-bootstrap/Carousel";

const Login = () => {
  const [, setUsuario] = useLocalStorage('usuario', {})
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const [{ email, password }, handleInputChange] = useForm({
    email: "",
    password: "",
  });
  const { currentUser } = useContext(AuthContext);

  const handleLoginWithEmailAndPassword = (email, password) => {
    setIsLoading(true);
    auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredentials) => {
        setIsLoading(false);
        history.push("/paciente");
      })
      .catch((err) => {
        auth.signOut();
        addToast(err.message, { appearance: "warning" });
        setIsLoading(false);
      });
  };

  const verificaUsuario = useCallback(async (email, accessToken) => {
    dispatch(httpUserRequest());
    console.log('ingresa verifica usuario')
    let usuario = await fetchUser(email);
    console.log('usuarioVERIFICAR', usuario);

    if (usuario.empresaId) {
      usuario.accessToken = accessToken;

      dispatch(httpUserRequestSuccess(usuario));
      dispatch(setUsuarioSesion(usuario));
      console.log('usuario a guardar', usuario);
      setUsuario(usuario);
      history.push("/admin/dashboard/bienvenida");
    } else {
      addToast(
        "No encontramos tu cuenta! Crea una cuenta para que puedas acceder.",
        { appearance: "error" }
      );
    }
  });

  const handleLoginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    auth.signInWithPopup(provider)
      .then(async (login) => {
        const accessToken = login.credential.accessToken;

        verificaUsuario(login.user.email, accessToken);
        console.log('Access Token:', accessToken);
        await auth.getRedirectResult();
        console.log('termino login')
      })
      .catch((err) => {
        auth.signOut();
      });
  };




  const handleSubmit = (e) => {
    e.preventDefault();
    handleLoginWithGoogle();
  };

  const renderLoginForm = () => {
    return !isLoading ? (
      <div className="form__container">
        <div className="img__container">
          <ImgLogo src={NuevoLogo} alt="Sysdent logo" />
          <p>Ingresa a tu cuenta</p>
        </div>
        <form onSubmit={handleSubmit}>
          <button className="form__button" type="submit">
            Ingresar con Google
          </button>
          <hr></hr>
          <div className="form__forgotpassword__container">
            <span>¿Aún no tienes una cuenta? </span>
            <Link to="/register" className="changeLink">
              CREAR CUENTA
            </Link>
          </div>
        </form>
      </div>
    ) : (
      <>
        <CircularProgress color="secondary" />
      </>
    );
  };

  React.useEffect(() => {
    if (currentUser) verificaUsuario(currentUser.email);
  }, [currentUser, verificaUsuario]);
  return (
    <LoginViewWrapper>
      <LoginView>
        <SectionForm>{renderLoginForm()}</SectionForm>
        <FooterWrapper>
          <Carousel variant="dark" className="carouselHeigth">
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={EjemploSlider}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={EjemploSlider2}
                alt="Second slide"
              />
            </Carousel.Item>
          </Carousel>
        </FooterWrapper>
      </LoginView>
    </LoginViewWrapper>
  );
};
export default withRouter(Login);