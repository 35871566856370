import {
  SET_USER,
  HTTP_USER_REQUEST,
  HTTP_USER_REQUEST_FAILURE,
  HTTP_USER_REQUEST_SUCCESS,
} from "./usuarioTypes";
import { fetchUser, saveUser } from "../../services/firebase/usuarios/index";

export const httpUserRequest = () => {
  return {
    type: HTTP_USER_REQUEST,
  };
};

export const httpUserRequestSuccess = (usuario) => {
  return {
    type: HTTP_USER_REQUEST_SUCCESS,
    payload: usuario,
  };
};

export const httpUserRequestFailure = (error) => {
  return {
    type: HTTP_USER_REQUEST_FAILURE,
    payload: error,
  };
};

export const getUser = (email) => {
  return function (dispatch) {
    dispatch(httpUserRequest());
    fetchUser(email)
      .then((user) => {
        dispatch(httpUserRequestSuccess());
        dispatch(setUser(user));
      })
      .catch((err) => dispatch(httpUserRequestFailure(err)));
  };
};

export const setUser = (usuario) => {
  return {
    type: SET_USER,
    payload: usuario,
  };
};

export const saveUsuario = (usuario) => {
  return function (dispatch) {
    dispatch(httpUserRequest());
    saveUser(usuario)
      .then(() => dispatch(httpUserRequestSuccess(usuario)))
      .catch((err) => dispatch(httpUserRequestFailure(err)));
  };
};
