import React from "react";

const Extraccion = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 300 300"
    >
      <defs>
        <path d="M302.11 0L0 300" id="d2h5TFUjVc"></path>
        <path d="M300 300L187.7 187.7L144.67 144.67L0 0" id="b1toZXpsu9"></path>
      </defs>
      <g>
        <g>
          <g>
            <g>
              <use
                xlinkHref="#d2h5TFUjVc"
                opacity="1"
                fill-opacity="0"
                stroke="#000000"
                strokeWidth="10"
                stroke-opacity="1"
              ></use>
            </g>
          </g>
          <g>
            <g>
              <use
                xlinkHref="#b1toZXpsu9"
                opacity="1"
                fill-opacity="0"
                stroke="#000000"
                strokeWidth="10"
                stroke-opacity="1"
              ></use>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Extraccion;
