import styled from "styled-components";
import { dispositivo } from "../../components/Dispositivos";

export const Container = styled.div`
  display: block;
  width: 100%;
  padding: 5px;
  position: relative;
  overflow: hidden;
`;
export const ContainerTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  text-align: center;
`;
export const StyledInput = styled.input`
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0d0808;
  margin: 0 0 0.8em;
  line-height: 2.5;
  font-size: 0.75rem;
  width: 100%;
  height: 2rem;
  padding-left: 0.5em;
  &::placeholder {
    color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus {
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.mobileL} {
    font-size: 1rem;
  }
`;

export const LabelStyled = styled.label`
  text-align: left;
  font-size: 0.875rem;
  color: #040404;
  font-weight: bold;
  @media ${dispositivo.mobileL} {
    font-size: 1rem;
  }
`;

export const ContainerTitleText = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 1.5%;
  background-color: #0c8de3;
  color: white;
  border-radius: 10px;
`;

export const StyledSelect = styled.select`
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0d0808;
  line-height: 2.5;
  font-size: 1rem;
  height: 2.5rem;
  width: 12.5rem;
  text-align: end;
  &::placeholder {
    color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus {
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }

  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;
export const ContainerButton = styled.div`
  display: flex;
  justify-content: end;
  margin: 3% 5%;
`;
export const ContainerSelect = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 3% 2.5%;
`;
export const Button = styled.button`
  height: 2.25rem;
  font-size: 0.9rem;
  width: 7rem;
  border-radius: 0.8rem;
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #0060b0;
  color: #ffffff;
  border: none;
  &:hover {
    color: #000000;
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.tablet} {
    width: 10rem;
    font-size: 1rem;
  }
  @media ${dispositivo.laptop} {
    width: 12.5rem;
  }
`;
export const AccordionContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .accordion {
    width: 95%;
  }

  .item {
    background: #f0ebe1;
    margin-bottom: 5px;
    padding: 10px 20px;
  }

  .element {
    display: flex;
    align-items: center;
    padding: 2px 2px;
    margin: 2px;
    color: #045184;
  }
  .labelCheck {
    margin-bottom: 0;
    font-size: 0.875rem;
    @media ${dispositivo.mobileL} {
      font-size: 1rem;
    }
  }
  .checkBox {
    margin: 0 4px 0;
  }

  .title {
    display: flex;
    justify-content: space-between;
    color: white;
    align-items: center;
    cursor: pointer;
    background: #0c8de3;
    font-size: 0.625rem;
    padding: 0.2em 1em;
    border-bottom: 1px solid #fff;
    @media ${dispositivo.mobileM} {
      font-size: 1rem;
    }
  }
  .iconSpan {
    font-size: 0.875rem;
    @media ${dispositivo.tablet} {
      font-size: 1rem;
    }
  }
  .subtitle {
    color: #040404;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  .content {
    color: #8b7f75;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
    padding: 0 3%;
  }

  .content.show {
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1, 0, 1, 0);
  }
`;

export const FlowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-content: flex-start;
  align-items: flex-start;
  // flex-wrap: wrap;
  margin-left: 0 0.5em 0 0.5em;
  @media ${dispositivo.mobileM} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${dispositivo.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media ${dispositivo.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
export const ScrollDiv = styled.div`
  flex: 1;
  max-height: 300px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0c8de3;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
`;
