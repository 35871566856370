import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { dispositivo } from "../../components/Dispositivos";

export const MainContainer = styled.div`
  width: 100%;
  text-align: center;
  background: #f8f8f8;
  justify-content: center;
`;

export const ContainerTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;

export const ContainerTitleText = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;

export const ContainerBody = styled.div`
  width: 90%;
  height: 90%;
  padding: 4%;
  margin-left: 6%;
  //background-color: ${(props) => (props.edicion ? "#000" : "#fff")};
  text-align: "left";
  box-shadow: 2px 2px #88888877;
  border-radius: 14px;
  align-items: center;
  justify-content: center;
  ul {
    list-style-type: none;
    a{
      text-decoration: none;
      color: #000;
      :hover{
        text-decoration: none;
        color: #0060B0;
      }
    }
  }
`;

export const Tabla = styled.table`
  width: 100%;
`;

export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
  border: 2px solid #0c8de3;
`;

export const TablaHeaderTexto = styled.th`
  font-size: 12px;
  font-family: Segoe UI;
  font-weight: normal;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const TablaBody = styled.tbody`
  background: #fff;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  border: 2px solid #ccc;
  padding-bottom: 16px;
`;

export const TablaBodyTexto = styled.th`
  font-size: 12px;
  font-family: Segoe UI;
  font-weight: normal;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const StyledInput = styled.input`
  width: 30%;
  color: #707070;
  vertical-align: middle;
  text-align: center;
  &:focus {
    outline: none;
  }
`;

export const StyledButton = styled(Button)`
  && {
    height: 2.25rem;
    margin-top: 1rem;
    border-radius: 1em;
    text-transform: uppercase;
    cursor: pointer;
    font-width: bold;
    font-size: 0.9rem;
    background-color: #0060b0;
    color: #ffffff;
    font-size: 0.9rem;
    border: none;
    margin-bottom: 1rem;
    &:hover {
      color: #000000;
      background-color: #0c8de390;
      font-width: bold;
    }
    @media ${dispositivo.mobileS} {
      font-size: 0.9rem;
      padding: 0.1em;
      width: 9rem;
    }
    @media ${dispositivo.tablet} {
      width: 10rem;
    }
    @media ${dispositivo.laptop} {
      width: 12.5rem;
    }
  }
`;
