import React from "react";
import { useSelector } from "react-redux";
import { HeaderProfileItemWrapper, Alert } from "./styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Link } from "react-router-dom";
const HeaderProfileItem = ({ onCloseSesion, mensaje }) => {
  const { usuario } = useSelector((state) => state.sesion);

  return (
    <>
      {/* <Alert>
        <span>{mensaje}</span>
      </Alert> */}

      <HeaderProfileItemWrapper>
        <div className="main-header">
          <img
            className="main-header__img"
            src="https://www.kindpng.com/picc/m/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png"
            alt="user profile"
          />
          <h2 className="main-header__name">{usuario.nombres}</h2>

          <ArrowDropDownIcon style={{ color: "white" }} />

          <div className="main-header__content hide">
            <ul className="main-header__content__list">
              <Link to="/admin/usuario" className="main-header__content__link">
                <li className="main-header__content__list__item" >Mi Perfil</li>
              </Link>
              <li
                className="main-header__content__list__item"
                onClick={() => onCloseSesion()}
              >
                <ExitToAppIcon />
                Cerrar sesión
              </li>
            </ul>
          </div>
        </div>
      </HeaderProfileItemWrapper>
    </>
  );
};

export default HeaderProfileItem;
