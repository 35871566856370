import React from "react";

import { MainContainer, ContainerIFrame, ContainerBody } from "./styles";
import Spinner from "react-bootstrap/Spinner";
import {
  fetchPagos,
  fetchAbonos,
} from "../../services/firebase/movimientos/index";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  Fila,
  ContainerTitle,
  ContainerTitleText,
  FlexContainer,
} from "./styles";
import { Icon } from "@material-ui/core";
const getFecha = (fecha) => {
  if (fecha){
    const a = fecha.getFullYear();
    const m = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const d = fecha.getDate().toString().padStart(2, "0");
    return `${a}-${m}-${d}`;
  }
  else
  return '';
  
};

const FilaComp = ({pago, idx})=>{
  return   (<Fila idx={idx}>
  <td data-label="Fecha">
    {getFecha(pago.fecha)}
  </td>
  <td data-label="Monto">
    {pago.monto}
  </td>
  <td data-label="Usuario">
    {pago.usuario?.nombres}
  </td>
  <td data-label="Prestación">
    {pago.prestacionNombre}
  </td>
  
  <td data-label="Diente">
    
  </td>
  <td data-label="Pagado">
  {pago.monto}
    </td>
</Fila>)
}

const FilaAgrupada = ({pago, idx})=>(pago.prestaciones.map((pr, index)=>
(<Fila idx={idx}>
  {index === 0 && <td rowspan={pago.prestaciones.length} data-label="Fecha">
    {getFecha(pago.fecha)}
  </td>}
  {index === 0 &&<td rowspan={pago.prestaciones.length} data-label="Monto">
    {pago.monto}
  </td>}
  {index === 0 &&<td rowspan={pago.prestaciones.length} data-label="Usuario">
    {pago.usuario?.nombres}
  </td>}
  <td  data-label="Prestación">
    {pr.tratamiento}
  </td>
  <td data-label="Diente">
    {pr.dientes}
  </td>
  <td data-label="Diente">
    {pr.pagadoMov}
  </td>
</Fila>)


))

export const PagosRpt = ({ paciente }) => {
  let url = `${process.env.REACT_APP_API_URL}/api/v1/reportes/pagos/${paciente.id}`;
  
  const [estado, setEstado] = React.useState({
    loading: false,
    data: [],
    err: "",
  });

  React.useEffect(() => {
    async function fetchData() {
      try {
        setEstado({ ...estado, loading: true });
        let data = await fetchPagos(paciente.id);
        console.log('data ', data)
        setEstado({ ...estado, data: data, loading: false });
      } catch (err) {
        setEstado({ ...estado, err: err.message, loading: false });
      }
    }
    if (estado.data.length === 0) fetchData();
  }, []);

  if (estado.loading)
    return (
      <MainContainer>
        <Spinner animation="border" role="status">
          <span className="visually-hidden"></span>
        </Spinner>
      </MainContainer>
    );
  if (estado.err) return <div>{estado.err}</div>;
  return (
    <>
      <MainContainer>
        <ContainerTitle>
          <ContainerTitleText>Pagos</ContainerTitleText>
        </ContainerTitle>
        <ContainerBody>
          <FlexContainer>
            {/* <a href={url} target="_blank" rel="noopener noreferrer">
              Descargar Excel
              <Icon fontSize="small"> <GetAppIcon fontSize="small" /></Icon >
            </a> */}

          </FlexContainer>
          <div>
            <Tabla>
              <TablaHeader>
                <tr>
                  <TablaHeaderTexto scope="col">Fecha</TablaHeaderTexto>
                  <TablaHeaderTexto scope="col">Monto</TablaHeaderTexto>
                  <TablaHeaderTexto scope="col">Usuario</TablaHeaderTexto>
                  <TablaHeaderTexto scope="col">Prestacion</TablaHeaderTexto>
                  <TablaHeaderTexto scope="col">Diente</TablaHeaderTexto>
                  <TablaHeaderTexto scope="col">Pagado</TablaHeaderTexto>
                </tr>
              </TablaHeader>
              <TablaBody>
                {estado.data.map((pago, idx) => (
                 pago.prestaciones?<FilaAgrupada pago={pago} idx={idx}></FilaAgrupada>: <FilaComp pago={pago} idx={idx}></FilaComp>
                ))}
                <tr>
                  <td data-label="Fecha">Total</td>
                  <td data-label="Monto">
                    {estado.data
                      .map((item) => Number(item.monto))
                      .reduce((total, i) => total + i, 0)}
                  </td>
                </tr>
              </TablaBody>
            </Tabla>
          </div>
        </ContainerBody>

      </MainContainer>
    </>
  );
};

export const AbonosRpt = ({ paciente }) => {
  const [estado, setEstado] = React.useState({
    loading: false,
    data: [],
    err: "",
  });
  let url = `${process.env.REACT_APP_API_URL}/api/v1/reportes/abonos/${paciente.id}`;

  React.useEffect(() => {
    async function fetchData() {
      try {
        setEstado({ ...estado, loading: true });
        let data = await fetchAbonos(paciente.id);
        setEstado({ ...estado, data: data, loading: false });
      } catch (err) {
        setEstado({ ...estado, err: err.message, loading: false });
      }
    }
    if (estado.data.length === 0) fetchData();
  }, []);
  const hayDatos = (filtro) => {
    return estado.data.filter((item) => item.medio === filtro).length > 0;
  };

  if (estado.loading)
    return (
      <MainContainer>
        <Spinner animation="border" role="status">
          <span className="visually-hidden"></span>
        </Spinner>
      </MainContainer>
    );
  if (estado.err) return <div>{estado.err}</div>;
  return (
    <>
      <MainContainer>
        <ContainerTitle>
          <ContainerTitleText>Abonos/Devoluciones</ContainerTitleText>
        </ContainerTitle>
        <ContainerBody>
          <FlexContainer>
            {/* <a href={url} target="_blank" rel="noopener noreferrer">
              Descargar Excel
              <Icon fontSize="small"> <GetAppIcon fontSize="small" /></Icon >
            </a> */}


          </FlexContainer>
          <div className="py-2">
            {hayDatos("EFECTIVO") && (
              <Tabla >
                <TablaHeader>
                  <tr>
                    <TablaHeaderTexto scope="col">Fecha</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Monto</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Usuario</TablaHeaderTexto>
                  </tr>
                </TablaHeader>
                <TablaBody>
                  {estado.data
                    .filter((item) => item.medio === "EFECTIVO")
                    .map((item) => (
                      <tr>
                        <td data-label="Fecha">
                          {getFecha(item.fecha)}
                        </td>
                        <td data-label="Monto">
                          {item.tipMov === "ABONO" ? item.monto : -item.monto}
                        </td>
                        <td data-label="Usuario">
                          {item.usuario?.nombres}
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td data-label="Fecha">Total</td>
                    <td data-label="Monto">
                      {estado.data
                        .filter((item) => item.medio === "EFECTIVO")
                        .map((item) =>
                          item.tipMov === "ABONO" ? item.monto : -item.monto
                        )
                        .reduce((total, i) => total + i, 0)}
                    </td>
                  </tr>
                </TablaBody>
              </Tabla>
            )}
          </div>
          <div className="py-2">
            {hayDatos("BANCO") && (
              <Tabla>
                <TablaHeader>
                  <tr>
                    <TablaHeaderTexto scope="col">Fecha</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Monto</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Banco</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Num. Ope</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Usuario</TablaHeaderTexto>
                  </tr>
                </TablaHeader>
                <TablaBody>
                  {estado.data
                    .filter((item) => item.medio === "BANCO")
                    .map((item) => (
                      <tr>
                        <td data-label="Fecha">
                          {getFecha(item.fecha)}
                        </td>
                        <td data-label="Monto">
                          {item.tipMov === "ABONO" ? item.monto : -item.monto}
                        </td>
                        <td data-label="Banco">
                          {item.banco}
                        </td>
                        <td data-label="Num Ope">
                          {item.numope}
                        </td>
                        <td data-label="Usuario">
                          {item.usuario?.nombres}
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td data-label="Fecha">Total</td>
                    <td data-label="Monto">
                      {estado.data
                        .filter((item) => item.medio === "BANCO")
                        .map((item) =>
                          item.tipMov === "ABONO" ? item.monto : -item.monto
                        )
                        .reduce((total, i) => total + i, 0)}
                    </td>
                  </tr>
                </TablaBody>
              </Tabla>
            )}
          </div>
          <div className="py-2">
            {hayDatos("MONEDERO") && (
              <Tabla>
                <TablaHeader>
                  <tr>
                    <TablaHeaderTexto scope="col">Fecha</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Monto</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Monedero</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Usuario</TablaHeaderTexto>
                  </tr>
                </TablaHeader>
                <TablaBody>
                  {estado.data
                    .filter((item) => item.medio === "MONEDERO")
                    .map((item) => (
                      <tr>
                        <td data-label="Fecha">
                          {getFecha(item.fecha)}
                        </td>
                        <td data-label="Monto">
                          {item.tipMov === "ABONO" ? item.monto : -item.monto}
                        </td>
                        <td data-label="Monedero">
                          {item.monedero}
                        </td>
                        <td data-label="Usuario">
                          {item.usuario?.nombres}
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td data-label="Fecha">Total</td>
                    <td data-label="Monto">
                      {estado.data
                        .filter((item) => item.medio === "MONEDERO")
                        .map((item) =>
                          item.tipMov === "ABONO" ? item.monto : -item.monto
                        )
                        .reduce((total, i) => total + i, 0)}
                    </td>
                  </tr>
                </TablaBody>
              </Tabla>
            )}
          </div>
        </ContainerBody>
        {/* <IFrame src={url} frameborder="0" scrolling="no"></IFrame> */}
      </MainContainer>
    </>
  );
};

export const ReportesPaciente = () => {
  const paciente = useSelector((state) => state.paciente);
  let param = useParams();
  let reporte = {};
  switch (param.tipo) {
    case "abonos":
      reporte = <AbonosRpt paciente={paciente} />;
      break;
    case "pagos":
      reporte = <PagosRpt paciente={paciente} />;
      break;
    default:
      reporte = <AbonosRpt paciente={paciente} />;
  }
  return (
    <>
      {reporte}
    </>
  );
};
