import { db, firestorage } from "../Config";
import { COrden } from "../../../Entidades/Orden";
import firebase from "firebase/app";
// función asincrona de fetch ordenes
export const fetchOrdenes = () => {
  return new Promise((resolve, reject) => {
    let ordenesRef = db.collection("ordenes");
    let ordenes = [];
    ordenesRef
      .where("estado", "in", ["TRANSITO", "PROCESO", "SOLICITADO", "PRUEBAS"])
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          ordenes.push({
            id: doc.data().id,
            estado: doc.data().estado,
            fecEnv: doc.data().fecEnv,
            fecRec: doc.data().fecRec,
            labId: doc.data().labId,
            labNombre: doc.data().labNombre,
            pacienteId: doc.data().pacienteId,
            pacienteNombres: doc.data().pacienteNombres,
            precio: Number(doc.data().precio),
            pagado: Number(doc.data().pagado),
            estFin: doc.data().estFin,
            notaRec: doc.data().notaRec,
            numCor: doc.data().numCor,
            empresaId: doc.data().empresaId,
            items: doc.data().items,
            empresa: doc.data().empresa,
            cambioPrecio: doc.data().cambioPrecio,
          });
        });
        resolve(ordenes);
      })
      .catch((err) => reject(err));
  });
};

export const fetchOrdenesEmpresa = (empresaId) => {
  return new Promise((resolve, reject) => {
    let ordenesRef = db.collection("ordenes");
    let ordenes = [];
    ordenesRef
      .where("empresaId", "==", Number(empresaId))
      .where("estado", "in", ["TRANSITO", "PROCESO", "PRUEBAS", "PENDIENTE"])
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          ordenes.push({
            id: doc.data().id,
            estado: doc.data().estado,
            fecEnv: doc.data().fecEnv,
            fecRec: doc.data().fecRec,
            labId: doc.data().labId,
            labNombre: doc.data().labNombre,
            pacienteId: doc.data().pacienteId,
            pacienteNombres: doc.data().pacienteNombres,
            precio: Number(doc.data().precio),
            pagado: Number(doc.data().pagado),
            estFin: doc.data().estFin,
            notaRec: doc.data().notaRec,
            numCor: doc.data().numCor,
            empresaId: doc.data().empresaId,
            items: doc.data().items,
            empresa: doc.data().empresa,
            cambioPrecio: doc.data().cambioPrecio,
          });
        });
        resolve(ordenes);
      })
      .catch((err) => reject(err));
  });
};

export const fetchOrdenesPaciente = (pacienteId) => {
  return new Promise((resolve, reject) => {
    let ordenesRef = db.collection("ordenes");
    let ordenes = [];
    ordenesRef
      .where("pacienteId", "==", pacienteId)
      .where("estado", "in", [
        "TRANSITO",
        "PROCESO",
        "PRUEBAS",
        "PENDIENTE",
        "SOLICITADO",
      ])
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          ordenes.push({
            id: doc.data().id,
            estado: doc.data().estado,
            fecEnv: doc.data().fecEnv,
            fecRec: doc.data().fecRec,
            labId: doc.data().labId,
            labNombre: doc.data().labNombre,
            pacienteId: doc.data().pacienteId,
            pacienteNombres: doc.data().pacienteNombres,
            precio: Number(doc.data().precio),
            pagado: Number(doc.data().pagado),
            estFin: doc.data().estFin,
            notaRec: doc.data().notaRec,
            numCor: doc.data().numCor,
            empresaId: doc.data().empresaId,
            items: doc.data().items,
            empresa: doc.data().empresa,
            cambioPrecio: doc.data().cambioPrecio,
          });
        });
        resolve(ordenes);
      })
      .catch((err) => reject(err));
  });
};

export const fetchOrdenesAdeudadas = (labId, empresaId) => {
  return new Promise((resolve, reject) => {
    let ordenesRef = db.collection("ordenes");
    let ordenes = [];
    ordenesRef
      .where("estFin", "==", "PENDIENTE")
      .where("labId", "==", labId)
      .where("empresaId", "==", empresaId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          ordenes.push({
            id: doc.data().id,
            estado: doc.data().estado,
            fecEnv: doc.data().fecEnv,
            fecRec: doc.data().fecRec,
            labId: doc.data().labId,
            labNombre: doc.data().labNombre,
            pacienteId: doc.data().pacienteId,
            pacienteNombres: doc.data().pacienteNombres,
            precio: Number(doc.data().precio),
            pagado: Number(doc.data().pagado),
            estFin: doc.data().estFin,
            notaRec: doc.data().notaRec,
            numCor: doc.data().numCor,
            empresaId: doc.data().empresaId,
            items: doc.data().items,
            empresa: doc.data().empresa,
            cambioPrecio: doc.data().cambioPrecio,
          });
        });
        resolve(ordenes);
      })
      .catch((err) => reject(err));
  });
};

export const getDeuda = (labId, empresaId) => {
  return new Promise((resolve, reject) => {
    let ordenesRef = db.collection("ordenes");
    let deuda = 0;
    ordenesRef
      .where("labId", "==", labId)
      .where("estFin", "==", "PENDIENTE")
      .where("empresaId", "==", empresaId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let saldo = doc.data().precio - doc.data().pagado;
          deuda = deuda + saldo;
        });
        resolve(deuda);
      })
      .catch((err) => reject(err));
  });
};

export const pagarOrden = (ordenId, monto) => {
  return new Promise((resolve, reject) => {
    let ordenRef = db.collection("ordenes").doc(ordenId);

    ordenRef
      .update({
        pagado: firebase.firestore.FieldValue.increment(Number(monto)),
      })
      .then(() => resolve)
      .catch((err) => reject(err));
  });
};

export const recibirOrdenDb = (orden) => {
  return new Promise((resolve, reject) => {
    let ordenRef = db.collection("ordenes").doc(orden.id);
    ordenRef
      .update({
        fecRec: orden.fecRec,
        notaRec: orden.notaRec,
        estado: orden.estado,
      })
      .then(() => resolve(true))
      .catch((err) => reject(err));
  });
};

export const enviarOrdenDb = (orden) => {
  return new Promise((resolve, reject) => {
    let ordenRef = db.collection("ordenes").doc(orden.id);
    ordenRef
      .update({
        estado: "TRANSITO",
      })
      .then(() => resolve(true))
      .catch((err) => reject(err));
  });
};

export const getOrden = (ordenId) => {
  let orden;
  let ordenRef = db.collection("ordenes").doc(ordenId);
  ordenRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        orden = {
          id: doc.data().id,
          estado: doc.data().estado,
          fecEnv: doc.data().fecEnv,
          fecRec: doc.data().fecRec,
          labId: doc.data().labId,
          labNombre: doc.data().labNombre,
          pacienteId: doc.data().pacienteId,
          pacienteNombres: doc.data().pacienteNombres,
          precio: Number(doc.data().precio),
          pagado: Number(doc.data().pagado),
          estFin: doc.data().estFin,
          notaRec: doc.data().notaRec,
          numCor: doc.data().numCor,
          empresaId: doc.data().empresaId,
          items: doc.data().items,
          empresa: doc.data().empresa,
        };
      } else {
        orden = new COrden();
      }
    })
    .catch((err) => {});
};

var ordenConverter = {
  toFirestore: function (orden) {
    return {
      id: orden.id,
      estado: orden.estado,
      fecEnv: orden.fecEnv,
      fecRec: orden.fecRec,
      labId: orden.labId,
      labNombre: orden.labNombre,
      pacienteId: orden.pacienteId,
      pacienteNombres: orden.pacienteNombres,
      prestacionNombre: orden.prestacionNombre,
      notaEnv: orden.notaEnv,
      precio: Number(orden.precio),
      pagado: Number(orden.pagado),
      estFin: orden.estFin,
      notaRec: orden.notaRec,
      numCor: orden.numCor,
      empresa: orden.empresa,
    };
  },
  fromFirestore: function (snapshot, options) {
    const data = snapshot.data(options);
    return {
      id: data().id,
      estado: data().estado,
      fecEnv: data().fecEnv,
      fecRec: data().fecRec,
      labId: data().labId,
      labNombre: data().labNombre,
      pacienteId: data().pacienteId,
      pacienteNombres: data().pacienteNombres,
      precio: Number(data().precio),
      pagado: Number(data().pagado),
      estFin: data().estFin,
      notaRec: data().notaRec,
      numCor: data().numCor,
      empresaId: data().empresaId,
      items: data().items,
      empresa: data().empresa,
    };
  },
  toDate(fecha) {
    if (typeof fecha === "string") return new Date(fecha);
  },
  toCadena(fecha) {
    if (typeof fecha === "object") {
      const a = fecha.getFullYear();
      const m = (fecha.getMonth() + 1).toString().padStart(2, "0");
      const d = fecha.getDate().toString().padStart(2, "0");
      return `${a}-${m}-${d}`;
    }
  },
};

export const getDownloadUrl = async () => {
  var storageRef = firestorage.ref();
  var insigniaRef = storageRef.child("insignia-colegio.jpeg");
  let url = await insigniaRef.getDownloadURL();
  return url;
};
