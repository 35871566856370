import React from "react";
import { ConfirmacionSiNoModal } from "../../components/ConfirmacionSiNoModal/index";
import { useDispatch, useSelector } from "react-redux";
import { setOrden, newOrden, anulaOrden } from "../../redux/orden/ordenActions";
import { getOrdenesPaciente } from "../../redux/ordenes/ordenesActions";
import { getLabs } from "../../redux/labs/labsActions";
import IconButton from "@material-ui/core/IconButton";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import MailIcon from "@material-ui/icons/Mail";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { OrdenModalContainer } from "../Laboratorios/OrdenModalContainer";
import { TopBarPaciente } from "../../components/TopBarPaciente";
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import {
  MainContainer,
  ContainerTitle,
  Button,
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  TablaBodyTexto,
  ContainerTitleText,
  SectionTarjeta,
  ContenedorContenidoTarjeta,
  ContenedorCabeceraTarjeta,
  TarjetaAccion,
  TablaBodyFinanzas,
  TablaBodyTextoFinanzaPrecio,
  TablaFinanzas,
  ContenedorTarjetas,
  ScrollDiv,
  Buttons,
  LinkCancelar,
} from "./styles";
import Spinner from 'react-bootstrap/Spinner';
import { useToasts } from "react-toast-notifications";

const ESTADOS = {
  SOLICITADO: "SOLICITADO",
  PENDIENTE: "PENDIENTE",
  PROCESO: "PROCESO",
  TRANSITO: "TRANSITO",
  PRUEBAS: "PRUEBAS",
  TERMINADO: "TERMINADO",
};

const Fila = ({ item, handleClick }) => {
  return (
    <tr key={item.id}>
      <td data-label="FecEnvio">{item.fecEnv}</td>
      <td data-label="Numero">{item.numCor}</td>
      <td data-label="Descripcion">{item.prestacionNombre}</td>
      <td data-label="Paciente">{item.pacienteNombres}</td>
      <td data-label="Deuda">{item.precio}</td>
      <td data-label="Accion">
        <button onClick={() => handleClick(item)}>Recibir</button>
      </td>
    </tr>
  );
};

export const Prestaciones = ({ items }) => {
  return (
    <ScrollDiv>
      <Tabla>
        <TablaHeader>
          <tr>
            <TablaHeaderTexto scope="col">Nombre</TablaHeaderTexto>
            <TablaHeaderTexto scope="col">Dientes</TablaHeaderTexto>
            <TablaHeaderTexto scope="col">Nota</TablaHeaderTexto>
          </tr>
        </TablaHeader>
        <TablaBody>
          {items.map(({ nombre, dientes, nota }) => (
            <tr>
              <TablaBodyTexto>{nombre}</TablaBodyTexto>
              <TablaBodyTexto>{dientes}</TablaBodyTexto>
              <TablaBodyTexto>{nota}</TablaBodyTexto>
            </tr>
          ))}
        </TablaBody>
      </Tabla>
    </ScrollDiv>

  );
};

export const Finanzas = ({ precio, pagado }) => (
  <TablaFinanzas>
    <TablaBodyFinanzas>
      <tr>
        <td>
          <TablaBodyTextoFinanzaPrecio>
            Precio: S/ {Number(precio).toFixed(2)}
          </TablaBodyTextoFinanzaPrecio>
        </td>
      </tr>
    </TablaBodyFinanzas>
  </TablaFinanzas>
);
const Tarjeta = ({
  orden,
  onEditar,
  onEnviar,
  onAnular,
  onVer,
  onTerminar,
  onAutorizar,
}) => {
  const {
    fecEnv,
    numCor,
    pacienteNombres,
    precio,
    pagado,
    items,
    estado,
    labNombre,
  } = orden;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  return (
    <ContenedorTarjetas className="cardContainer">
      <ContenedorCabeceraTarjeta
        title={labNombre}
        action={
          <div>
            <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper >
                    <div onClick={handleClose} >
                      {orden.estado == ESTADOS.PENDIENTE && (
                        <Buttons
                          aria-label=""
                          onClick={() => onEditar(orden)}
                          isShown={true}
                        >
                          <EditIcon size="small" style={{ color: "#0089ff" }} />
                          Editar
                        </Buttons>
                      )}

                    </div>
                    <div onClick={handleClose}>
                      {(orden.estado == ESTADOS.PROCESO ||
                        orden.estado == ESTADOS.SOLICITADO ||
                        orden.estado == ESTADOS.PENDIENTE) && (
                          <Buttons
                            aria-label=""
                            onClick={() => onAnular(orden)}
                            isShown={true}
                          >

                            <DeleteIcon size="small" style={{ color: "red" }} />
                            Eliminar
                          </Buttons>
                        )}

                    </div>
                  </Paper>
                </Fade>
              )}
            </Popper>

            <IconButton aria-label="settings" onClick={handleClick('bottom-end')}>
              <MoreVertIcon />
            </IconButton>
          </div>
        } />
      <div>
        {orden.cambioPrecio && (
          <IconButton
            aria-label=""
            onClick={() => onAutorizar(orden)}
            isShown={true}
          >
            <Tooltip
              TransitionComponent={Zoom}
              placement="top"
              title="Autorizar"
            >
              <MailIcon style={{ color: "orange" }} />
            </Tooltip>
          </IconButton>
        )}
      </div>

      <ContenedorContenidoTarjeta>
        <div className="divFecha"><p>{fecEnv}</p><p>{numCor}</p></div>

        <TablaBodyTextoFinanzaPrecio>
          <div className="statusDiv"> <span className="statusSpan">{estado}</span> </div>
          <div > <span className="priceSpan"> S/ {Number(precio).toFixed(2)}</span></div>
        </TablaBodyTextoFinanzaPrecio>
        <div className="pacienteDiv" >{`${pacienteNombres}`}</div>
        <Prestaciones items={items}></Prestaciones>
      </ContenedorContenidoTarjeta>

      <TarjetaAccion disableSpacing>

        {orden.estado == ESTADOS.PENDIENTE && (
          <Button
            aria-label=""
            onClick={() => onEnviar(orden)}
            isShown={true}
          >
            Enviar
          </Button>
        )}
        {orden.estado == ESTADOS.SOLICITADO && (
          <LinkCancelar aria-label="" onClick={() => onVer(orden)} isShown={true}>
            Ver
          </LinkCancelar>
        )}

        {orden.estado == ESTADOS.PROCESO && (
          <Button
            aria-label=""
            onClick={() => onTerminar(orden)}
            isShown={true}
          >Terminar
          </Button>
        )}

      </TarjetaAccion>
    </ContenedorTarjetas>
  );
};
export const OrdenesTable = ({ items, handleClick }) => {
  return (
    <Tabla>
      <TablaHeader>
        <tr>
          <TablaHeaderTexto scope="col">Fec. Envio</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Numero</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Orden</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Paciente</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Precio</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Accion</TablaHeaderTexto>
        </tr>
      </TablaHeader>
      <TablaBody>
        {items.map((item) => {
          return <Fila key={item.id} item={item} handleClick={handleClick} />;
        })}
      </TablaBody>
    </Tabla>
  );
};

const useOrdenes = (ordenes) => {
  const [query, setQuery] = React.useState("");
  const [filteredItems, setFilteredItems] = React.useState(ordenes);

  React.useMemo(() => {
    let result = [];

    if (ordenes.length > 0) {
      result = ordenes.filter((orden) => {
        return (
          `${orden.prestacionNombre} ${orden.pacienteNombres} ${orden.numCor}`
            .toLocaleLowerCase()
            .includes(query.toLocaleLowerCase()) && orden.estado !== "TERMINADO"
        );
      });
    }

    setFilteredItems(result);
  }, [ordenes, query]);
  return [filteredItems, query, setQuery];
};
export const OrdenesPaciente = () => {
  const [estado, setEstado] = React.useState({
    loading: true,
    labs: [],
    ordenes: [],
    error: "",
  });

  const ordenes = useSelector((state) => state.ordenes);
  const orden = useSelector((state) => state.orden);
  const sesion = useSelector((state) => state.sesion);
  const paciente = useSelector((state) => state.paciente);
  const [accion, setAccion] = React.useState("");
  const [editOrdenModalIsOpen, setEditOrdenModalIsOpen] = React.useState(false);
  const [enviaOrdenModalIsOpen, setEnviaOrdenModalIsOpen] =
    React.useState(false);
  const [anulaOrdenModalIsOpen, setAnulaOrdenModalIsOpen] =
    React.useState(false);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [ordenesFiltradas, labId, setLabId, query, setQuery] =
    useOrdenes(ordenes);
  React.useEffect(() => {
    dispatch(getLabs());
    dispatch(getOrdenesPaciente(paciente.id));
  }, []);
  const EVENTOS = {
    EDITAR_ORDEN_EN_CLI: "EDITAR_ORDEN_EN_CLI",
    NUEVA_ORDEN_EN_CLI: "NUEVA_ORDEN_EN_CLI",
    VER_ORDEN: "VER_ORDEN",
    ENVIAR_ORDEN_A_LAB: "ENVIAR_ORDEN_A_LAB",
    TERMINAR_ORDEN: "TERMINAR_ORDEN",
    AUTORIZAR_ORDEN: "AUTORIZAR_ORDEN",
  };
  const handleNewOrden = () => {
    const existePendiente = ordenes.some(
      (orden) => orden.estado == "PENDIENTE"
    );
    if (existePendiente) {
      addToast("Existe una orden pendiente de envío", { appearance: "error" });
      return;
    }
    setAccion(EVENTOS.NUEVA_ORDEN_EN_CLI);
    dispatch(newOrden());
    setEditOrdenModalIsOpen(true);
  };
  const ConfirmaEnviarModal = ({ isOpen, handleCloseModal, onClickYes }) => {
    return (
      <ConfirmacionSiNoModal
        mensaje="Desea enviar esta orden?"
        isOpen={isOpen}
        handleCloseModal={handleCloseModal}
        onClickYes={onClickYes}
      />
    );
  };

  const ConfirmaAnularModal = ({ isOpen, handleCloseModal, onClickYes }) => {
    return (
      <ConfirmacionSiNoModal
        mensaje="Desea anular esta orden?"
        isOpen={isOpen}
        handleCloseModal={handleCloseModal}
        onClickYes={onClickYes}
      />
    );
  };

  const handleChange = (e) => {
    setLabId(e.target.value);
  };
  const handleCloseModal = (e) => {
    setEditOrdenModalIsOpen(false);
    setEnviaOrdenModalIsOpen(false);
    setAnulaOrdenModalIsOpen(false);
  };
  const handleEdit = (item) => {
    dispatch(setOrden(item));
    setAccion(EVENTOS.EDITAR_ORDEN_EN_CLI);
    setEditOrdenModalIsOpen(true);
  };
  const handleConfirmarEnviar = (item) => {
    dispatch(setOrden(item));
    setEnviaOrdenModalIsOpen(true);
  };

  const handleConfirmarAnular = (item) => {
    dispatch(setOrden(item));
    setAnulaOrdenModalIsOpen(true);
  };

  const handleAnular = () => {
    try {
      dispatch(anulaOrden(orden));
      setAnulaOrdenModalIsOpen(false);
      addToast("Orden anulada satisfactoriamente", { appearance: "success" });
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };
  const getFecha = () => {
    const fecha = new Date();
    const a = fecha.getFullYear();
    const m = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const d = fecha.getDate().toString().padStart(2, "0");
    return `${a}-${m}-${d}`;
  };

  const handleEnviar = (item) => {
    item.fecEnv = getFecha();
    dispatch(setOrden(item));
    setAccion(EVENTOS.ENVIAR_ORDEN_A_LAB);
    setEditOrdenModalIsOpen(true);
  };

  const handleVer = (item) => {
    dispatch(setOrden(item));
    setAccion(EVENTOS.VER_ORDEN);
    setEditOrdenModalIsOpen(true);
  };

  const handleTerminar = (item) => {
    dispatch(setOrden(item));
    setAccion(EVENTOS.TERMINAR_ORDEN);
    setEditOrdenModalIsOpen(true);
  };

  const handleAutorizar = (item) => {
    dispatch(setOrden(item));
    setAccion(EVENTOS.AUTORIZAR_ORDEN);
    setEditOrdenModalIsOpen(true);
  };
  if (sesion.loading || sesion.loading == undefined)
    return <MainContainer><Spinner animation="border" role="status">
      <span className="visually-hidden"></span>
    </Spinner></MainContainer>;
  else if (sesion.error) return <div>error + {sesion.error}</div>;
  return (
    <React.Fragment>
      <TopBarPaciente />
      <MainContainer>
        <div>
          <Button onClick={handleNewOrden} variant="contained" size="large">
            Nueva Orden
          </Button>
        </div>

        <SectionTarjeta>
          {ordenesFiltradas.map((orden) => (
            <Tarjeta
              orden={orden}
              onEditar={handleEdit}
              onEnviar={handleEnviar}
              onAnular={handleConfirmarAnular}
              onVer={handleVer}
              onTerminar={handleTerminar}
              onAutorizar={handleAutorizar}
            />
          ))}
        </SectionTarjeta>
        <OrdenModalContainer
          isOpen={editOrdenModalIsOpen}
          handleCloseModal={handleCloseModal}
          accion={accion}
        />
        <ConfirmaEnviarModal
          isOpen={enviaOrdenModalIsOpen}
          handleCloseModal={handleCloseModal}
          onClickYes={handleEnviar}
        />

        <ConfirmaAnularModal
          isOpen={anulaOrdenModalIsOpen}
          handleCloseModal={handleCloseModal}
          onClickYes={handleAnular}
        />
      </MainContainer>
    </React.Fragment>

  );
};
