import React from "react";
import Extraccion from "./Extraccion";
import { Grafico } from "./Grafico";
import { Icon } from "./Icon";
import "./style.css";
const IncisivoCentralInf = (props) => {
  const configuracion = props.config;
  const getColor = (cara) => {
    const defaultColor = "#ffeca2";
    let color = defaultColor;
    if (props.config.caras) {
      let idx = props.config.caras.findIndex((it) => it.cara == cara);
      if (idx > -1) color = props.config.caras[idx].color;
    }
    return color;
  };
  return (
    <svg
      id="Incisivo_inf"
      data-name="Incisivo inf"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140.78 388.58"
    >
      <title>incisivo_central_inf</title>
      <g id="Implante">
        <path
          d="M122.41,109.62,100.61,108l-49.25.47-32.62.7,1.49,31-1.49,36.05s19.36,7.29,21.66,11.84c1.17,2.3,0,10.31,0,10.31a78,78,0,0,0-9,3.39c-2.85,1.88-4.07,5-4.68,11.18-1,10,1.63,14.43,8.56,14.43a31.16,31.16,0,0,0,5.18-.79v9.57a42,42,0,0,0-5.38,2.32c-2.61,1.89-3.72,5-4.28,11.19-.93,10,1.49,14.42,7.82,14.42a12.63,12.63,0,0,0,1.84-.2V280A8.58,8.58,0,0,0,39,281c-3.16,2.83-3.82,5.12-3.82,12.13,0,7.35,1.59,11.29,5.24,12.44V321.9c-4.75,3.42-5.24,6.85-5.24,13,0,7.37,1.65,11.38,5.24,12.48v7.39a2.14,2.14,0,0,0,.57,1.46,1.82,1.82,0,0,0,.11.4c.57,1.43,1.13,16.84,8.86,24.86,7,5.43,21.32,7.06,24.18,7.06s14,1,24.24-8.73c7.19-6.87,7-18.64,7.37-22.33.13-.62.25-1.34.38-2.15a5.11,5.11,0,0,0,1.36-3.46V324.39a11.69,11.69,0,0,0,1.84-1c2.34-1.89,3.34-5,3.84-11.19.77-9.12-1-13.71-5.68-14.34V282.23c5.18-3.58,5.85-7.12,5.85-13.28,0-7.88-1.85-11.86-5.85-12.66V242.37A32.19,32.19,0,0,0,113,239.7c4.47-3.5,5-5,5-12.53,0-8.76-3.17-13.08-9.5-12.81a5.65,5.65,0,0,0-1,.17v-7.72a57.18,57.18,0,0,0,9.06-3.82c4.89-3.5,5.5-5,5.5-12.54,0-8.76-3.46-13.07-10.39-12.8a27.19,27.19,0,0,0-3.79.81c6.75-13.85,14.49-18,14.49-18Z"
          transform="translate(0 0)"
          fill={configuracion.implante.fill}
          stroke={configuracion.implante.strokeColor}
        />
      </g>
      <path
        id="Raiz"
        d="M122.41,101.31,18.65,102.53S47.08,216.42,47.08,255.25c0,33.11,11.69,123.56,11.69,123.56,2.3,9.53,13.56,9.65,16,.17,0,0,19.78-80.93,18.6-108.4C91.45,226.11,122.41,101.31,122.41,101.31Z"
        transform="translate(0 0)"
        fill={configuracion.raiz.fill}
        stroke={configuracion.raiz.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
      <path
        id="Nucleo"
        d="M68.09,142.87h0c3.73,0,15.41-5.48,14.53-.76L74,353.12c.72,3.79-1.29,7.55-4.5,8.41h0a5,5,0,0,1-1.39.13h0c-3.86-.16-6.89-4-6.75-8.54h0L58,142.78C58,138.07,64.36,142.87,68.09,142.87Z"
        transform="translate(0 0)"
        fill={configuracion.nucleo.fill}
        stroke={configuracion.nucleo.strokeColor}
      />
      <path
        id="6"
        d="M88.22,190.91l10-3.91,11.52-56.94H84.05v58a3,3,0,0,0,3,3.06A3.14,3.14,0,0,0,88.22,190.91Z"
        transform="translate(0 0)"
        fill={getColor("6")}
      />
      <path
        id="7"
        d="M52.72,191l-8.6-3.36a3,3,0,0,1-1.84-2.18l-12-51.74c-.37-1.85,2.52-3.61,4.4-3.61H53.81a3,3,0,0,1,3,3v55.13a3,3,0,0,1-3,3A2.93,2.93,0,0,1,52.72,191Z"
        transform="translate(0 0)"
        fill={getColor("7")}
      />
      <path
        id="8"
        d="M38.49,131.89h70.06a35.78,35.78,0,0,1-35.78,35.78h-2a34,34,0,0,1-34-34h0a1.74,1.74,0,0,1,1.74-1.74h0Z"
        transform="translate(0 0)"
        fill={getColor("8")}
      />
      <path
        id="9"
        d="M32.76,133.52l73.87-3.24h0l.38,8.66a35.77,35.77,0,0,1-34.18,37.31h0l-4.11.18A34,34,0,0,1,33.26,144l-.46-10.44Z"
        transform="translate(0 0)"
        fill={getColor("9")}
      />
      <path
        id="Corona"
        d="M11.26,78.16C-17.35-15.67,29.84,4.27,71.06,4.27S157.36-2.84,129.85,78c-14.71,43.21-17.61,75.53-58.79,75.53S23.56,118.36,11.26,78.16Z"
        transform="translate(0 0)"
        fill={configuracion.corona.fill}
        stroke={configuracion.corona.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
    </svg>
  );
};

export default IncisivoCentralInf;
