import React from "react";

const MolarSup = (props) => {
  const configuracion = props.config;

  const getColor = (cara) => {
    const defaultColor = "#ffeca2";

    let color = defaultColor;
    if (props.config.caras) {
      let idx = props.config.caras.findIndex((it) => it.cara === cara);
      if (idx > -1) color = props.config.caras[idx].color;
    }
    return color;
  };
  return (
    <svg
      id="Molar_sup"
      data-name="Molar sup"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 250.5 483.7"
    >
      <title>molar_sup</title>
      <g id="Implante">
        <path
          id="Implante-2"
          d="M10.57,339.66l48.23,1.95,109-.57,72.29-.84-3.34-37.31-3.92-44.79s-35.63-7.23-40.71-12.67c-2.57-2.76,0-12.4,0-12.4,10.73-2,18-3.45,19.91-4.09,6.31-2.25,9-6,10.38-13.44,2.22-12.06-3.55-17.34-18.93-17.34-2.15,0-6.14.34-11.49,1V187.6c6.33-1.33,10.56-2.32,11.85-2.79,5.77-2.28,8.29-6,9.48-13.46C215.4,159.29,210,154,196,154c-.95,0-2.37.08-4.06.24V135a21.41,21.41,0,0,0,3.14-1.21c7-3.4,8.47-6.15,8.47-14.59,0-8.83-3.55-13.57-11.61-15V84.46c10.49-4.2,11.55-8.33,11.55-15.68,0-8.85-3.67-13.68-11.62-15V44.89a2.24,2.24,0,0,0-1.26-1.76c-.07-.15-.16-.31-.25-.47-1.22-1.61-2.46-20.27-19.56-29.91C155.22,6.22,123.6,4.3,117.26,4.3s-31-1.2-53.66,10.51C47.68,23,48.19,37.2,47.27,41.63a25.55,25.55,0,0,0-.78,2.58c-1.85,1.07-3,2.54-3,4.16v33a39.28,39.28,0,0,0-4.1,1.21C34.24,84.84,32,88.61,30.9,96c-1.75,10.94,2.22,16.48,12.55,17.24v18.78c-11.49,4.32-13,8.58-13,16,0,9.48,4.11,14.28,13,15.22V180a105.81,105.81,0,0,0-12,3.16c-9.91,4.21-11.14,6-11.14,15.07q0,15.84,21,15.4a11.72,11.72,0,0,0,2.13-.19v9.23c-10.2,2-17.46,3.62-20,4.6-10.82,4.21-12.18,6-12.18,15.08q0,15.85,23,15.39c.87,0,3.82-.34,8.38-1a77.45,77.45,0,0,1-32.07,21.71Z"
          transform="translate(0 -1.8)"
          fill={configuracion.implante.fill}
          stroke={configuracion.implante.strokeColor}
        />
      </g>
      <path
        id="Raiz"
        d="M13.16,339.81l227,1.8s25.2-212.35-14.48-255.77C156.86,10.66,141-37.85,176.4,111.28c7,29.46,6.06,65.26,6.06,120.58,0,13.86-13.71-50.16-21.16-109.92-5.69-44.72-17.61-78.14-19.64-101.39C139.71-2,90.46-.63,88.8,21.75,84.85,75,61.17,217.86,64.3,222,71,21.61,51.69,67.13,51.69,67.13S33,84.64,23.33,109.64c-8.71,22.59-8.37,52.29-10.17,63.3C6.64,212.82,13.16,339.81,13.16,339.81Z"
        transform="translate(0 -1.8)"
        fill={configuracion.raiz.fill}
        stroke={configuracion.raiz.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
      <path
        id="Nucleo"
        d="M32.59,337.75l187,18.71s-7.33-41.43-3.85-94.74c1.58-24.18,10.33-55.66,10.39-79.24.07-34.51,4.45-57.24-6.69-70.29-61.69-72.1-65.32-135.86-26,3.22,12.65,29.23,4.58,106-5.56,157.48-1.31,6.63-26.55,15.76-35.64-3.93-13.36-29.08-19.87-83.7-23.14-111.75C124,114.29,130.86,46.88,129,24.56,127.3,3,103.43,3.26,102,24.67c-2.62,37.67,13.59,194-17.59,251.91-4.25,7.88-23,3.08-24-7.61C53.5,194.2,49.71,118.63,52.61,81.46c.33-4.31-11.58,33.3-20.23,57.27-6.31,25.57-4,66.33-1.51,75.21C43.4,258.42,36,338.36,32.59,337.75Z"
        transform="translate(0 -1.8)"
        fill={configuracion.nucleo.fill}
        stroke={configuracion.nucleo.strokeColor}
      />
      <path
        id="6"
        d="M189.33,213.93h33.26c7.16,0,23,7.72,23,15.47l-8.23,100.27c0,7.75-7.58,11.94-14.74,11.94H171.38c-7.16,0-13-6.29-13-14L187.67,235C187.67,227.25,182.17,213.93,189.33,213.93Z"
        transform="translate(0 -1.8)"
        fill={getColor("6")}
      />
      <path
        id="7"
        d="M30.9,213.93H68.31c7.63,0,13.81,6.7,13.81,14.95h0V341.61H17.31L15.6,229.4c0-8.11,7.78-15.47,15.27-15.47Z"
        transform="translate(0 -1.8)"
        fill={getColor("7")}
      />
      <path
        id="8"
        d="M232.54,341.61H16.5s-2-57.3,0-75.62c5.73-52.2,197.9-45.25,216,0C239.44,283.2,232.54,341.61,232.54,341.61Z"
        transform="translate(0 -1.8)"
        fill={getColor("8")}
      />
      <path
        id="9"
        d="M232.54,341.61H16.5s-2-53.12,0-70.09c5.73-48.39,197.9-41.95,216,0C239.44,287.48,232.54,341.61,232.54,341.61Z"
        transform="translate(0 -1.8)"
        fill={getColor("9")}
      />
      <path
        id="Corona"
        d="M245.56,394.56C245,519,121.17,475.71,121.17,475.71S-4.44,513.34,2.8,398.3C6.14,344.51,20,273,120.28,272.89,224.4,272.89,245.89,326.43,245.56,394.56Z"
        transform="translate(0 -1.8)"
        fill={configuracion.corona.fill}
        stroke={configuracion.corona.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
    </svg>
  );
};
export default MolarSup;
