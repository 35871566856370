export const GET_PRESTATION = "GET_PRESTATION";
export const UPDATE_PRESTATION = "UPDATE_PRESTATION";
export const NEW_PRESTATION = "NEW_PRESTATION";
export const SET_PRESTATION = "SET_PRESTATION";
export const SET_CARAS = "SET_CARAS";
export const PAGAR_PRESTACION = "PAGAR_PRESTACION";
export const REVERTIR_PAGO = "REVERTIR_PAGO";
export const SAVE_PRESTATION_REQUEST = "SAVE_PRESTATION_REQUEST";
export const ADD_PRESTATION_SUCCESS = "ADD_PRESTATION_SUCCESS";
export const UPDATE_PRESTATION_SUCCESS = "UPDATE_PRESTATION_SUCCESS";
export const SAVE_PRESTATION_FAILURE = "SAVE_PRESTATION_FAILURE";
export const INICIAR_PRESTATION_REQUEST = "INICIAR_PRESTATION_REQUEST";
export const INICIAR_PRESTATION_SUCCESS = "INICIAR_PRESTATION_SUCCESS";
export const INICIAR_PRESTATION_FAILURE = "INICIAR_PRESTATION_FAILURE";

export const TERMINAR_PRESTACION_TRX_REQUEST =
  "TERMINAR_PRESTACION_TRX_REQUEST";
export const TERMINAR_PRESTACION_TRX_SUCCESS =
  "TERMINAR_PRESTACION_TRX_SUCCESS";
export const TERMINAR_PRESTACION_TRX_FAILURE =
  "TERMINAR_PRESTACION_TRX_FAILURE";

export const ADD_NOTA_CLINICA = "ADD_NOTA_CLINICA";
export const UPDATE_NOTA_CLINICA = "UPDATE_NOTA_CLINICA";
export const REMOVE_NOTA_CLINICA = "REMOVE_NOTA_CLINICA";
