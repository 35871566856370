import React from "react";

import {
  TextArea,
  ContainerTitle,
  ContainerTitleText,
  TablaBody,
  TablaHeaderTextoItems,
  TablaHeaderTextoValues,
  TablaHeaderTextoButtons,
  Tabla,
  LinkCancelar,
  ButtonPagar,
  TablaButtons,
  ContainerBody,
  FlowContainer,
  AddButton,
  Select,
  ItemContainer,
  StyledInputNumber,
  SelectStyle,
  StyledInput2,
  Dientelab,
  Textarea,
  ContainerBtn,
} from "./styles";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';

export const EditaOrden = ({
  orden,
  onChangeOrden,
  onChangeLabId,
  labs,
  onSelectedItem,
  activas,
  onRemove,
  onSave,
  onCloseModal,
  onAddPrestacion,
  refOrden,
  item,
  onChangeItem,
}) => {
  const getPrecio = () => {
    let precio = 0;
    if (orden.items.length > 0) {
      precio = orden.items
        .map((item) => item.precio)
        .reduce((total, i) => total + i, 0);
    }

    return precio;
  };
  return (
    <form ref={refOrden}>
      <ContainerTitle>
        <ContainerTitleText>{`Editar Orden: ${orden.numCor}`}</ContainerTitleText>
      </ContainerTitle>
      <ContainerBody>
        <Tabla>
          <TablaBody>
            <tr>
              <TablaHeaderTextoItems><strong>Laboratorio</strong></TablaHeaderTextoItems>
              <TablaHeaderTextoValues>
                <SelectStyle
                  id="labId"
                  name="labId"
                  value={orden.labId}
                  onChange={onChangeLabId}
                  onSelected={onChangeLabId}
                  required
                >
                  <option value="" selected disabled hidden>
                    Escoja un laboratorio
                  </option>
                  {labs.map((lab) => (
                    <option value={lab.id}>{lab.razonSocial}</option>
                  ))}
                </SelectStyle>
              </TablaHeaderTextoValues>
            </tr>
            <tr>
              <TablaHeaderTextoItems>
                <strong>Precio Total </strong>
              </TablaHeaderTextoItems>
              <TablaHeaderTextoValues data-label="envio">
                <StyledInputNumber
                  type="text"
                  value={getPrecio()}
                  name="precio"
                  disabled
                ></StyledInputNumber>
              </TablaHeaderTextoValues>
            </tr>
            <tr>
              <TablaHeaderTextoItems>
                <strong>Fec. Envío: </strong>
              </TablaHeaderTextoItems>
              <TablaHeaderTextoValues data-label="envio">
                <StyledInput2
                  type="date"
                  value={orden.fecEnv}
                  name="fecEnv"
                  onChange={onChangeOrden}
                  disabled
                  required
                ></StyledInput2>
              </TablaHeaderTextoValues>
            </tr>
          </TablaBody>
        </Tabla>
        <FlowContainer>
          <SelectStyle onChange={onSelectedItem} value={item.id}>
            <option hidden selected value="">
              Seleccione Prestacion
            </option>
            {activas.map((pr) => (
              <option value={pr.id}>{`${pr.tratamiento} ${pr.tipo ? pr.tipo : ""
                }`}</option>
            ))}
          </SelectStyle>
          <IconButton style={{ color: "#0060b0" }} onClick={onAddPrestacion}> <AddCircleIcon fontSize="large" /> </IconButton>
        </FlowContainer>
        {orden.items.map((item, index) => (
          <ItemContainer>
            <Dientelab>{` ${item.nombre} dientes:[${item.dientes}] - S/ ${item.precio}`}</Dientelab>

            <Textarea
              name="nota"
              rows="3"
              cols="50"
              placeholder="Descripcion de la Nota"
              value={item.nota}
              onChange={(e) => onChangeItem(e, index)}
            ></Textarea>
            <IconButton onClick={() => onRemove(item.id)} color="secondary"><CancelIcon fontSize="medium" /></IconButton>

            <div>
              {item.estados && (
                <ul>
                  {item.estados.map((estado, indey) => (
                    <li>{estado}</li>
                  ))}
                </ul>
              )}
            </div>
          </ItemContainer>
        ))}
      </ContainerBody>
      <ContainerBtn>
        <LinkCancelar onClick={onCloseModal}>Cancelar</LinkCancelar>
        <ButtonPagar onClick={onSave}>Guardar</ButtonPagar>
      </ContainerBtn>
    </form>
  );
};
