import styled from "styled-components";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { dispositivo } from "../../components/Dispositivos";

export const MainContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
width: 100%;
padding-left: 3%;
padding-right: 3%;
background: #f8f8f8;
justify-content: center;
text-align: center;
`;

export const ContainerTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  padding: 2.5%;
`;

export const ContainerTitleText = styled.h2`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  @media ${dispositivo.mobileL} {
    font-size: 16px;
  }
  @media ${dispositivo.tablet} {
    font-size: 20px;
  }
`;

export const ContainerBody = styled.div`
// max-width: 1000px;
display: flex;
align-items: center;
justify-content: center;
background: #fff;
border-radius: 14px;
padding: 4%;
display: block;
box-shadow: 2px 2px #88888877;
`;

export const ContainerButton = styled.div`
  display: flex;
  margin-bottom: 1em;
  justify-content: flex-start;
  align-items: center;  
  .containerRubro{
    display: flex;
    width: 100%;
    gap: 0.5rem;
  }
  @media ${dispositivo.mobileL} {
  .containerRubro{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 60%;
  }
  }
`;
export const ContaineRight = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5em 0;
`;
export const StyledButton = styled.button`
    height: 2.25rem;
    font-size: 0.9rem; 
    border-radius: 0.8rem;      
    text-transform: uppercase;
    cursor: pointer;
    font-width: bold;
    background-color: #0060b0;
    color: #ffffff;
    border: none;
    padding: 0 0.5em;
    width: 40%;
     &:hover{
      color:#000000;
      background-color: #0c8de390;
      font-width: bold;
     }
    @media ${dispositivo.tablet}{
      width: 10rem;
      font-size: 1rem;  
    }  
    @media ${dispositivo.laptop} {      
      width: 12.5rem;
    }
`;


export const ContainerForm = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  border: 2px solid #0c8de3;
  flex-direction: column;
  align-items: center;
`;
export const DivInputs = styled.div`
  display: flex;
  align-items: center;  
  gap: .2rem;
  padding: 0.5em 0;
  @media ${dispositivo.mobileL} {
    gap: 1rem;
  }
`;
export const StyledLabel = styled.label`
  display: flex;
  align-items: center;  
  gap: 0.5rem;
  font-size: .875rem;
  font-weight: bold;
  color: #040404;
  @media ${dispositivo.mobileL} {
    font-size: 1rem;
  }
`;
export const StyledLabelHours = styled(StyledLabel)`
  justify-content: flex-end;
`;
export const StyledSelect = styled.select`
  width: 60%;
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0d0808;
  line-height: 2.5;
  font-size: 1rem;
  text-align: end;
  height: 2em;
  &::placeholder {
    color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus {
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }

  @media ${dispositivo.tablet} {
    font-size: 16px;    
  }
`;
export const StyledInput = styled.input`
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  line-height: 2.5;
  font-size: 0.875rem;
  heigth: 2rem;
  padding: 0 0.5em;
  width: 45%;
  &::placeholder {
  color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.mobileL} {
    font-size: 1rem;
    width: 55%;
  }
`;
export const StyledInputHeader = styled.input`
  border-style: none;
  color: #040404;
  font-weight: bold;
  padding-left: 0.5em;
  width: 30%;
  font-size: .9rem;
  @media ${dispositivo.mobileL} {
    font-size: 1rem;
    width: 20%;
  }
`;
export const StyledInputNumber = styled(StyledInput)`
  text-align: end;
  width: 15%;
`;

export const StyledInputNumbers = styled(StyledInput)`
  text-align: end;
  width: 30px;
  @media ${dispositivo.mobileL} {
    width: 50px;
  }
`;

export const DivTotal = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 2% 3% 0 0;
  font-weight: bold;
  font-size: 1rem;  
  .spanTotal{
    color: #040404;
  }
  .spanTotalPrecio{
    color: #045184;
  }
  @media ${dispositivo.mobileL} {
    font-size: 1.25rem;
  }
`;
