import { db } from "../Config";
//import { useFirebaseApp } from "reactfire";
// función asincrona de fetch ordenes
//const firebase = useFirebaseApp();
export const fetchCentrosRx = () => {
  return new Promise((resolve, reject) => {
    let centrosRxRef = db.collection("centrosRx");
    let centrosRx = [];
    centrosRxRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          centrosRx.push({
            id: doc.id,
            nombre: doc.data().nombre,
            tipo: doc.data().tipo,
            razonSocial: doc.data().razonSocial,
          });
        });
        resolve(centrosRx);
      })
      .catch((err) => reject(err));
  });
};
