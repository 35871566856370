import styled from "styled-components";
import { dispositivo } from "../Dispositivos";
import Container from "react-bootstrap/Container";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";

export const HeaderWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  background: rgb(248, 248, 248);
  background: linear-gradient(
    0deg,
    rgba(248, 248, 248, 1) 50%,
    rgba(12, 141, 227, 1) 50%
  );

  .header {
    display: flex;
    justify-content: flex-start;
    width: 90%;
    align-items: center;
    height: auto;
    margin: 0 auto;

    flex-direction: column;

    &__user-data {
      display: flex;
      justify-content: center;
      align-items: center;

      &__figure {
        height: unset;
        margin-right: 20px;

        &__img {
          height: 60px;
          border-radius: 50%;
        }

        @media ${dispositivo.tablet} {
          height: 100px;
          flex-direction: row;

          &__img {
            height: 100px;
            width: auto;
          }
        }
      }

      &__data {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &__name {
          color: white;
          font-weight: bolder;
          font-size: 15px;
          margin: 0;
          font-size: 17px;
          font-family: Segoe UI;
          position: relative;
          transform: translateY(-10px);
          text-overflow: ellipsis;

          &__span {
            display: inline;
            font-size: 12px;
            font-weight: lighter;
            /* position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 100%; */
            display: flex;
            white-space: nowrap;
            align-items: center;
          }
        }

        &__socials {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(2, 1fr);
          gap: 5px;

          @media ${dispositivo.mobileM} {
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: 100%;
          }

          @media ${dispositivo.tablet} {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: nowrap;
          }

          @media ${dispositivo.tablet} {
            font-size: 12px;

            &__item {
              &__icon {
                height: 25px;
                width: 25px;
              }

              &__label {
                font-size: 15px;
              }
            }
          }
        }

        @media ${dispositivo.tablet} {
          margin-right: 25px;

          &__name,
          h3 {
            font-size: 18px;
            font-size: 18px;
          }

          &__span {
            font-size: 15px;
          }
        }
      }
    }

    &__allergies-container {
      display: flex;
      justify-content: flex-start;
      align-self: flex-start;
      align-items: center;
      margin-top: 30px;
      align-self: center;
      border-radius: 10px;
      padding: 10px;
      background-color: #f44538;
      cursor: pointer;

      &__img {
        height: 20px;
        width: 20px;
        margin-right: 10px;
      }

      &__allergy {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        margin-right: 16px;

        &__name {
          background-color: #f44538;
          color: snow;
          text-decoration: none;
          font-weight: bolder;
          padding: 4px 9px;
          border-radius: 5px;
        }
      }

      @media ${dispositivo.tablet} {
        margin-top: 0;
        align-self: unset;
      }
    }

    &__balances {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 20px;
      font-weight: bold;
      &__balance {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 20px;

        &__title {
          font-size: 14px;
          font-weight: bolder;
          color: black;
        }

        &__debt {
          color: red;
        }

        p {
          margin: 0;
        }
      }

      @media ${dispositivo.tablet} {
        margin-right: 25px;
        margin-top: 0;

        &__title {
          color: white;
        }

        &__balance {
          &__title {
            color: white;
          }
        }
      }
    }

    @media (min-width: 1000px) {
      flex-direction: row;
      height: 120px;
      justify-content: space-around;
    }
  }

  .header-icon {
    cursor: pointer;
    transform: translateX(20px);
  }
`;

export const CabeceraPaciente = styled.div`
  display: block;
  width: 100%;

  @media ${dispositivo.mobileL} {
    display: flex;
  }
`;

export const CabeceraPacienteBack = styled.div`
  padding: 0.5%;
`;

export const CabeceraPacienteDatos = styled(Container)`
  display: block;
  width: 100% !important;
  padding-left: 0%;
  padding-right: 0%;
`;

export const CabeceraPacienteNombre = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  padding-top: 2%;
  text-align: center;
  @media ${dispositivo.mobileM} {
    padding-bottom: 2%;
  }
  @media ${dispositivo.mobileL} {
    padding-bottom: 2%;
  }
  @media ${dispositivo.tablet} {
    padding-bottom: 2%;
  }
  @media ${dispositivo.laptop} {
    padding-bottom: 2%;
  }
`;

export const CabeceraPacienteDocumento = styled.div`
  width: 100%;

  justify-content: center;
  padding-bottom: 2%;
  display: flex;
  text-align: center;
  @media ${dispositivo.mobileM} {
    padding-bottom: 5%;
  }
  @media ${dispositivo.mobileL} {
    padding-bottom: 5%;
  }
  @media ${dispositivo.tablet} {
    padding-bottom: 2%;
  }
  @media ${dispositivo.laptop} {
    padding-bottom: 2%;
  }
`;

export const NombreEdad = styled.div`
  font-size: 16px;

  color: #707070;
  @media ${dispositivo.mobileM} {
    font-size: 18px;
  }
  @media ${dispositivo.mobileL} {
    font-size: 20px;
  }

  @media ${dispositivo.tablet} {
    font-size: 26px;
  }

  @media ${dispositivo.laptop} {
    width: 70%;
    font-size: 34px;
    font-weight: bold;
  }
`;

export const Documento = styled.div`
  width: 30%;
  font-size: 14px;

  font-weight: bold;
  color: #707070;
  @media ${dispositivo.mobileM} {
    font-size: 15px;
  }
  @media ${dispositivo.mobileL} {
    font-size: 17px;
  }
  @media ${dispositivo.tablet} {
    font-size: 23px;
  }
  @media ${dispositivo.laptop} {
    font-size: 30px;
    color: #707070;
  }
`;

export const CabeceraPacienteDatosSecundarios = styled.div`
  display: flex;
  width: 100% !important;
  padding: 1%;
`;

export const Contacto = styled.div`
  display: block;
  width: 40%;
  justify-content: center;
  color: #707070;
`;

export const TelefonoFigura = styled.figure`
  margin: 0%;
  margin-left: 10%;
`;
export const whatsappFigura = styled.figure`
  margin: 0%;
  margin-left: 10%;
`;

export const Telefono = styled.div`
  color: #707070;
  margin-left: 5%;
  margin-top: 0.5%;
  font-size: 12px;
  @media ${dispositivo.mobileM} {
    font-size: 14px;
  }
  @media ${dispositivo.mobileL} {
    font-size: 16px;
    padding-top: 4%;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
  @media ${dispositivo.laptop} {
    font-size: 20px;
    padding-top: 4%;
  }
`;

export const CorreoFigura = styled.figure`
  margin: 0%;
  margin-left: 10%;
  @media ${dispositivo.mobileL} {
    display: none;
  }
  @media ${dispositivo.mobileM} {
    display: none;
  }
`;

export const Correo = styled.div`
  padding-top: 4%;
  font-size: 12px;
  color: #707070;
  margin-left: 5%;
  margin-top: 0.5%;
  @media ${dispositivo.mobileM} {
    font-size: 14px;
  }
  @media ${dispositivo.mobileL} {
    font-size: 16px;
    padding-top: 4%;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }

  @media ${dispositivo.laptop} {
    font-size: 20px;
  }
`;

export const Alergias = styled.div`
  width: 40%;
  display: flex;
  justify-content: right;
`;

export const AlergiasFigura = styled.div`
  width: 33%;
`;

export const AlergiasTexto = styled.div`
  width: 80%;
  font-weight: bold;
  color: #707070;
`;

export const Pago = styled.div`
  display: block;
  width: 20%;
  justify-content: center;
`;

export const Saldo = styled.div`
  display: flex;
`;

export const Deuda = styled.div`
  display: flex;
`;

export const HeaderBar = styled.div`
  display: flex;

  padding-top: 25px;
  background: #0c8de3;
`;

export const H5Styled = styled.h5`
  color: #707070;
  padding-top: 4%;
  font-size: 12px;
  @media ${dispositivo.mobileM} {
    font-size: 14px;
  }
  @media ${dispositivo.mobileL} {
    font-size: 18px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
  @media ${dispositivo.laptop} {
    font-size: 20px;
  }
`;

export const H5StyledAdeudo = styled.h5`
  color: red;
  margin: 3%;
  margin-top: 1%;
  font-size: 12px;
  @media ${dispositivo.mobileM} {
    padding-top: 4%;
    font-size: 14px;
  }
  @media ${dispositivo.mobileL} {
    padding-top: 4%;
    font-size: 16px;
  }
  @media ${dispositivo.tablet} {
    font-size: 20px;
  }
  @media ${dispositivo.laptop} {
    font-size: 18px;
  }
`;

export const Icono = styled.div`
  margin: 0%;
  display: none;

  @media ${dispositivo.tablet} {
    display: block;
  }
`;

export const ContenedorDatos = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  padding-bottom: 2%;
  text-align: left;
`;

export const LinkDatos = styled(Link)`
  outline: none;
  cursor: pointer;
  color: white;
  text-decoration: none;
`;
