import {
  ABONO_CUENTA,
  SET_CUENTA,
  NEW_CUENTA,
  DEVOLUCION_CUENTA,
} from "./cuentaTypes";

const initialState = {
  adeudado: 0,
  id: "",
  pacienteId: "",
  saldo: 0,
  empresaId: "",
};
export const cuentaReducers = (state = initialState, action) => {
  switch (action.type) {
    case NEW_CUENTA:
      return initialState;
    case SET_CUENTA:
      return action.payload;
    case ABONO_CUENTA:
      return {
        ...state,
        saldo: Number(state.saldo) + Number(action.payload),
      };

    case DEVOLUCION_CUENTA:
      return {
        ...state,
        saldo: Number(state.saldo) - Number(action.payload),
      };
    default:
      return state;
  }
};
