import styled from "styled-components";
import { Link } from "react-router-dom";
import { dispositivo } from "../../components/Dispositivos";

export const MainContainer = styled.div`
  width: 100%;
  text-align: center;
  background: #f8f8f8;
`;

export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2%;
`;
export const FlexContainerRight = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
  margin-bottom: 2%;
`;
export const ContainerTitle = styled.div`
  color: #000;
  font-size: 20px;
  font-weight: normal;
  text-align: center;
  //padding: 2.5%;
  display: flex;
  justify-content: space-around;
`;

export const ContainerTitleText = styled.h2`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  @media ${dispositivo.mobileL} {
    font-size: 16px;
  }
  @media ${dispositivo.tablet} {
    font-size: 20px;
  }
`;

export const ContainerBody = styled.div`
  //margin-top: 1.5rem;
  width: 98%;
  height: 100%;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: #fff;
  box-shadow: 2px 2px #88888877;
  border-radius: 14px;
  @media ${dispositivo.mobileL} {
    padding: 3%;
  }
  @media ${dispositivo.tablet} {
    padding: 4%;
  }
`;

export const ContainerLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 1em 0;
`;

export const ContenedorPrincipal = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 800px;
  width: 98%;
`;
export const ContenedorLista = styled.div`
  display: block;
  width: 30%;
  margin-right: 2%;
  margin-bottom: 3%;
  @media ${dispositivo.mobileL} {
    margin-right: 4%;
  }
  @media ${dispositivo.tablet} {
    margin-right: 7%;
  }
`;
export const ContenedorTabla = styled.div`
  display: block;
  width: 70%;
  margin-left: 2%;
  @media ${dispositivo.mobileL} {
    margin-left: 4%;
  }
  @media ${dispositivo.tablet} {
    margin-left: 7%;
  }
`;

export const ContainerIFrame = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  //padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
`;

export const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
`;

export const Liga = styled.div`
  cursor: pointer;
  text-decoration: underline;
  color: #0c8de3;
  font-size: 12px;
  @media ${dispositivo.tablet} {
    font-size: 14px;
  }
`;

export const Tabla = styled.table`
  width: 100%;
`;

export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 5%;
  padding-bottom: 5%;
  border: 2px solid #0c8de3;
`;

export const TablaHeaderTexto = styled.th`
  font-size: 12px;
  font-family: Segoe UI;
  font-weight: normal;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const TablaBody = styled.tbody`
  background: #fff;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  border: 2px solid #ccc;
  padding-bottom: 16px;
`;

export const TablaBodyTexto = styled.th`
  font-size: 12px;
  font-family: Segoe UI;
  font-weight: normal;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const StyledLink = styled(Link)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.25rem;
    font-size: 0.9rem;
    width: 10rem;
    border-radius: 0.8rem;
    text-transform: uppercase;
    cursor: pointer;
    font-width: bold;
    background-color: #0060b0;
    color: #ffffff;
    border: none;
    &:hover {
      color: #000000;
      background-color: #0c8de390;
      font-width: bold;
    }
    @media ${dispositivo.tablet} {
      font-size: 1rem;
    }
    @media ${dispositivo.laptop} {
      width: 12.5rem;
    }
  }
`;

export const ContainerLista = styled.div`
  width: 100%;
  height: 100%;
  padding: 4%;
  font-size: 10px;
  background: #fff;
  box-shadow: 2px 2px #88888877;
  border: 2px solid #0c8de3;
  border-color: #dcf2f9;
  margin-right: 14%;

  @media ${dispositivo.tablet} {
    font-size: 12px;
  }
`;

export const UlLista = styled.ul`
  list-style-type: none;
  color: #fff;
  padding-top: 2%;
`;

export const LiLista = styled.li`
  color: #fff;
`;
