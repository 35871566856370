//@ts-check
import { db } from "../Config";

export const fetchAccesosByEmpresa = async (perfil,empresaId) => {
  let acceso = {};
  await db
    .collection("accesos")
    .where("perfil", "==", perfil)
    .where("empresaId","==", empresaId)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        acceso = doc.data();
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
  return acceso;
};
export const fetchAccesosGeneral = async (perfil) => {
  let acceso = {};
  await db
    .collection("accesos")
    .where("perfil", "==", perfil)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        acceso = doc.data();
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
  return acceso;
};
