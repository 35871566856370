import React from "react";

import MolarSup from "./Dientes/MolarSup";
import MolarInf from "./Dientes/MolarInf";
import PreMolarSup from "./Dientes/PreMolarSup";
import PreMolarInf from "./Dientes/PreMolarInf";
import IncisivoCentralSup from "./Dientes/IncisivoCentralSup";
import IncisivoCentralInf from "./Dientes/IncisivoCentralInf";
import IncisivoLateralInf from "./Dientes/IncisivoLateralInf";
import IncisivoLateralSup from "./Dientes/IncisivoLateralSup";
import Extraccion from "./Dientes/Extraccion";
import CaninoInf from "./Dientes/CaninoInf";
import CaninoSup from "./Dientes/CaninoSup";
import MolarDeciduoSup from "./Dientes/MolarDeciduoSup";
import MolarDeciduoInf from "./Dientes/MolarDeciduoInf";
import {
  Denticion,
  incisivo_central_sup,
  incisivo_central_inf,
  incisivo_lateral_sup,
  incisivo_lateral_inf,
  canino_sup,
  canino_inf,
  premolar_sup,
  premolar_inf,
  molar_sup,
  molar_inf,
  molar_deciduo_sup,
  molar_deciduo_inf,
} from "./ConfigDiente";

const Diente = ({ numero, config }) => {
  if (numero == null) return <div></div>;
  const nombre = Denticion.getNombre(numero);
  const { estado } = config;
  if (estado === "EXODONCIA") return <Extraccion />;
  switch (nombre) {
    case incisivo_central_sup:
      return <IncisivoCentralSup config={config} />;
    case incisivo_central_inf:
      return <IncisivoCentralInf config={config} />;
    case incisivo_lateral_sup:
      return <IncisivoLateralSup config={config} />;
    case incisivo_lateral_inf:
      return <IncisivoLateralInf config={config} />;
    case canino_sup:
      return <CaninoSup config={config} />;
    case canino_inf:
      return <CaninoInf config={config} />;
    case premolar_sup:
      return <PreMolarSup config={config} />;
    case premolar_inf:
      return <PreMolarInf config={config} />;
    case molar_sup:
      return <MolarSup config={config} />;
    case molar_inf:
      return <MolarInf config={config} />;
    case molar_deciduo_sup:
      return <MolarDeciduoSup config={config} />;
    case molar_deciduo_inf:
      return <MolarDeciduoInf config={config} />;
    default:
      return <IncisivoLateralSup config={config} />;
  }
};

export default Diente;
