import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import Chartjs from "chart.js";
import { DivCharts, Item, Contenedor, Contenido, Titulo } from "./styles";
import { fetchStats } from "../../services/firebase/stsMensual/index";
import Spinner from "react-bootstrap/Spinner";

const getConfig = (data, titulo) => {
  return {
    type: "bar",
    data: {
      labels: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ],
      datasets: [
        {
          label: titulo,
          data: data,

          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
        },
      ],
    },
    options: {
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },
  };
};

const Chart = ({ data, titulo }) => {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(
        chartContainer.current,
        getConfig(data, titulo)
      );
      setChartInstance(newChartInstance);
    }
  }, [chartContainer]);

  return (
    <div id="holaaCanva">
      <canvas ref={chartContainer} />
    </div>
  );
};

export const Dashboard = () => {
  const sesion = useSelector((state) => state.sesion);
  const [estado, setEstado] = React.useState({
    loading: false,
    data: [],
    error: "",
  });
  let fecha = new Date();
  // Se setea la fecha a un día anterior, debido a que el proceso de cierre corre al inicio del día siguiente
  fecha.setDate(fecha.getDate() - 1);
  let month = fecha.getMonth();
  const traerEstadisticas = async (empresaId) => {
    setEstado({ ...estado, loading: true });
    let data = await fetchStats(empresaId, fecha.getFullYear());
    data.sort(function (a, b) {
      if (a.month > b.month) {
        return 1;
      }
      if (a.month < b.month) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    setEstado({ ...estado, loading: false, data: data });
  };
  React.useEffect(() => {
    traerEstadisticas(sesion.usuario.empresaId);
  }, []);
  const getSts = (month) => {
    const idx = estado.data.findIndex((item) => item.month === month);
    if (idx > -1) {
      return estado.data[idx];
    }
    return {
      "#abonos": undefined,
      "#pacientes": undefined,
      "#prestaciones": undefined,
      abonos: undefined,
      compras: undefined,
      deuda: undefined,
      empresaId: undefined,
      gastosFijos: undefined,
      gastosVariables: undefined,
      year: undefined,
      month: undefined,
    };
  };
  if (estado.loading)
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden"></span>
      </Spinner>
    );
  return (
    <Contenedor>
      <Item>
        <Titulo>Pacientes</Titulo>
        <Contenido>{getSts(month)["#pacientes"]}</Contenido>
      </Item>
      <Item>
        <Titulo>Por Cobrar</Titulo>
        <Contenido>{getSts(month).deuda}</Contenido>
      </Item>
      <Item>
        <Titulo>Gastos Fijos</Titulo>
        <Contenido>{getSts(month).gastosFijos}</Contenido>
      </Item>
      <Item>
        <Titulo>Ingresos</Titulo>
        <Contenido>{getSts(month).abonos}</Contenido>
        <Chart
          titulo="ingresos"
          data={estado.data.map((item) => item.abonos)}
        />
      </Item>

      <Item>
        <Titulo>Gastos Variables</Titulo>
        <Contenido>{getSts(month).gastosVariables}</Contenido>
        <Chart
          titulo="compras"
          data={estado.data.map((item) => item.gastosVariables)}
        />
      </Item>
      <Item>
        <Titulo>Utilidad</Titulo>
        <Contenido>
          {getSts(month).abonos -
            getSts(month).gastosFijos -
            getSts(month).gastosVariables}
        </Contenido>
        <Chart
          titulo="utilidad"
          data={estado.data.map(
            (item) => item.abonos - item.gastosFijos - item.gastosVariables
          )}
        />
      </Item>
    </Contenedor>
  );
};
