import { SET_GASTOS_FIJOS } from "./gastosFijosTypes";

const initialState = {
  empresaId: "",
  fecha: "",
  total: 0,
  dias: 0,
  horas: 0,
  costoMinuto:0,
  items: [],
};

export const gastosFijosReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_GASTOS_FIJOS:
      return action.payload;
    default:
      return state;
  }
};
