import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateItem } from "../../redux/ordenRx/ordenRxActions";
import {
  Denticion,
  INFANTIL,
  ADULTO,
  superiorDerecha,
  inferiorDerecha,
  superiorIzquierda,
  inferiorIzquierda,
} from "../ConfigDiente";

import {
  Centrado,
  DenticionContainer,
  DenticionBlock,
  DenticionSide,
} from "./styles";

import { DienteSuperior } from "../DienteSuperiorCheck/index";
import { DienteInferior } from "../DienteInferiorCheck/index";

export const DientesCheck = ({ llave }) => {
  const [tipoDenticion, setTipoDenticion] = React.useState(ADULTO);
  const ordenRx = useSelector((state) => state.ordenRx);
  const dispatch = useDispatch();
  let item1 = ordenRx.items.find((item) => item.llave == llave);
  const handleSelectedTooth = (e) => {
    let diente = e.target.id;
    if (e.target.checked) {
      item1.dientes.push(diente);
      dispatch(updateItem(item1));
    } else {
      item1.dientes = item1.dientes.filter((d) => d !== diente);
      dispatch(updateItem(item1));
    }
  };

  const generaDientes = (items) => {
    let dientes = [];
    dientes = items.map((item) => {
      if (item.lado == superiorIzquierda || item.lado == superiorDerecha)
        return (
          <DienteSuperior
            key={item.diente}
            item={item}
            onSelectedTooth={handleSelectedTooth}
            checked={
              item1 ? item1.dientes.some((d) => d == item.diente) : false
            }
          />
        );
      else
        return (
          <DienteInferior
            key={item.diente}
            item={item}
            onSelectedTooth={handleSelectedTooth}
            checked={
              item1 ? item1.dientes.some((d) => d == item.diente) : false
            }
          />
        );
    });

    return dientes;
  };

  return (
    <div>
      <Centrado>
        <DenticionContainer>
          <DenticionBlock>
            <DenticionSide>
              {generaDientes(
                Denticion.getDientes(tipoDenticion, superiorDerecha)
              )}

              {generaDientes(
                Denticion.getDientes(tipoDenticion, superiorIzquierda)
              )}
            </DenticionSide>
          </DenticionBlock>

          <DenticionBlock>
            <DenticionSide>
              {generaDientes(
                Denticion.getDientes(tipoDenticion, inferiorDerecha)
              )}
              {generaDientes(
                Denticion.getDientes(tipoDenticion, inferiorIzquierda)
              )}
            </DenticionSide>
          </DenticionBlock>
        </DenticionContainer>
      </Centrado>
    </div>
  );
};
