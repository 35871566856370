import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { dispositivo } from "../../../../components/Dispositivos";

export const ContainerTitle1 = styled.div`
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;

export const ContainerTitleText1 = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;

export const ContainerBody = styled.div`
  display: flex !important;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 1em;
  background: #fff;
  border-radius: 10px;
  border: 2px solid #0c8de3;
  flex-direction: column;
  .container-dientes-general {
    display: flex;
    justify-content: center;
  }
  .container-dientes {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

  }
`;

export const ContenedorRaiz = styled.div`
  display: flex;
  width: 33%;
  margin: 0 5%;
  max-width: 50px;
  margin-top: 2.6%;
  margin-left: auto;
  margin-right: auto;
`;

export const ContenedorCorona = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-left: 1em;
  @media ${dispositivo.tablet} {
    flex: 1;
  }
  .superiorIzquierda {
    padding: 2px;
    width: 100%;
    max-width: 90px;
    border-left: 2px solid red;
    border-bottom: 2px solid red;
    @media ${dispositivo.mobileM} {
      padding: 5px;
    }
  }
  .superiorDerecha {
    padding: 2px;
    width: 100%;
    max-width: 90px;
    border-right: 2px solid red;
    border-bottom: 2px solid red;
    @media ${dispositivo.mobileM} {
      padding: 5px;
    }
  }
  .inferiorIzquierda {
    padding: 2px;
    width: 100%;
    max-width: 90px;
    border-left: 2px solid red;
    border-top: 2px solid red;
    @media ${dispositivo.mobileM} {
      padding: 5px;
    }
  }
  .inferiorDerecha {
    padding: 2px;
    width: 100%;
    max-width: 90px;
    border-right: 2px solid red;
    border-top: 2px solid red;
    @media ${dispositivo.mobileM} {
      padding: 5px;
    }
  }
  .diente-select {
    width: 100%;
    max-width: 90px;
  }
  .span-font {
    font-size:  0.563rem;
    @media ${dispositivo.mobileM} {
      font-size: 0.7rem;
    }
    @media ${dispositivo.mobileL} {
      font-size: 0.875rem;
    }
    @media ${dispositivo.tablet} {
      font-size: 1rem;
    }
  }
`;

export const DienteSelect = styled.div`
  width: 100%;
  max-width: 90px;
`;

export const TablaHeaderLabel = styled.strong`
  font-size: 12px;
  font-weight: bold;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 18%;
  text-align: left;
  margin-right: 5px;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;
export const TablaHeaderText = styled.span`
  font-size: 12px;
  font-weight: normal;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;
export const ContenedorDiente = styled.div`
  display: flex;
  justify-content: center;
  width: 35px;
  border: 1px solid red;
  padding: 5px;
  height: 50px;

  @media ${dispositivo.mobileL} {
    width: 40px;
    height: 60px;
  }
  @media ${dispositivo.tablet} {
    width: 45px;
    height: 70px;
  }
`;

export const ContenedorDientes = styled.div`

  display: flex;
  justify-content: space-around;
  overflow: auto;
  gap: 10px;
  flex-direction: row;
  padding-bottom: 0.5em;
  // height: 80px;
  
  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 2px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0c8de3;
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }

`;

export const ContainerText = styled.div`
  display: block;
  width: 40%;
  justify-content: left;
  text-align: left;
`;

export const SubontainerText = styled.div`
  display: flex;
`;

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;
  padding: 1em 2em 0;
`;

export const ContainerTitle = styled.div`
  text-align: center;
`;

export const ContainerTitleText = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  padding: 1.5%;
  background-color: #0c8de3;
  color: white;
  border-radius: 10px;
  word-wrap: break-word;
  @media ${dispositivo.mobileL} {
    font-size: 1.25rem;
  }
`;

export const ButtonPagar = styled.button`
  height: 2.25rem;
  font-size: 0.9rem;
  width: 7rem;
  border-radius: 0.8rem;
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #0060b0;
  color: #ffffff;
  border: none;
  &:hover {
    color: #000000;
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.tablet} {
    width: 10rem;
    font-size: 1rem;
  }
  @media ${dispositivo.laptop} {
    width: 12.5rem;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  justify-content: center;
  text-align: center;
`;

export const StyledForm = styled.form`
  width: 90%;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const Tabla = styled.table`
  width: 33%;
  justify-content: center;
  text-align: center;
  width: 90%;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 1rem;
  margin: 1em 0;
`;

export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
  justify-content: center;
  text-align: center;
`;

export const TablaHeaderTextoItems = styled.th`
  font-size: 12px;
  font-weight: normal;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: center;
  text-align: left;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;
export const TablaHeaderTextoValues = styled.th`
  font-size: 12px;
  font-weight: normal;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: center;
  text-align: right;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;


export const StyledButton = styled(Button)`
  && {
    background-color: #0c8de3;
    border: none;
    color: white;
    outline: none;
    text-align: center;
    font-size: 10px;
    font-weight: normal;
    margin-bottom: 2%;
    margin-left: 20%;
    margin-right: 16px;
    padding-bottom: 8px;
    padding-top: 8px;
    border-radius: 4px;
    font-size: 12px;
    transition: 0.3s;
    display: inline-block;
    text-decoration: none;
    :active {
      transform: translateY(4px);
    }

    @media ${dispositivo.tablet} {
      font-size: 18px;
    }
  }
`;

export const TablaHeaderTextoNumber = styled.th`
  font-size: 12px;
  font-weight: normal;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: right;
  text-align: right;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const TablaHeaderTexto = styled.th`
  font-weight: normal;
  color: #707070;
  font-size: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: right;
  text-align: right;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const TablaBody = styled.tbody`
  vertical-align: middle;
`;

export const TablaBodyTexto = styled.th`
  font-size: 12px;
  font-weight: normal;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  vertical-align: middle;
  text-align: left;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const SelectStyle = styled.select`
  width: 50%;
  vertical-align: middle;
`;

export const StyledInput = styled.input`
  width: 45%;
  color: #707070;
  vertical-align: middle;
  text-align: right;
  padding-left: 12px;
  padding-right: 0px;
  &:focus {
    outline: none;
  }
`;

export const LinkCancelar = styled.button`
  height: 2.25rem;
  font-size: 0.9rem;
  width: 7rem;
  border-radius: 0.8rem;
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #ffffff;
  border: 2px solid #0060b0;
  color: #0060b0;
  margin-right: 1rem;
  &:hover {
    color: #000000;
    background-color: #0c8de390;
    font-width: bold;
    border: none;
  }
  @media ${dispositivo.tablet} {
    width: 10rem;
    font-size: 1rem;
  }
  @media ${dispositivo.laptop} {
    width: 12.5rem;
  }
`;
