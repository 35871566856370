import styled from "styled-components";
import { Link } from "react-router-dom";
import { dispositivo } from "../Dispositivos";

export const ContenedorHeader = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  display: flex;
  background: #0c8de3;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 768px) {
    padding: 5px 0px;
  }

`;

export const HeaderBar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const HeaderFigure = styled.figure`
  align-content: center;
  justify-content: flex-start;
  float: left;
`;

export const HeaderBarUl = styled.ul`
  display: flex;
  list-style: none;
  width: 100%;
  justify-content: center;
  margin-bottom: 0;

  position: relative;

  @media ${dispositivo.tablet} {
    position: absolute;
    justify-content: flex-end;
    top: 10px;
    right: 5%;
  }
`;

export const HeaderBarLi = styled.li`
  border-bottom: none;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  @media ${dispositivo.mobileL} {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const HeaderBarLink = styled(Link)`
  color: #fff;
  font-size: 13px;

  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const HeaderBarSpan = styled.span`
  color: #fff;
  font-size: 13px;
  &:hover {
    text-decoration: underline;
  }
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;
