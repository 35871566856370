import styled from "styled-components";
import { Link } from "react-router-dom";
import { dispositivo } from "../../components/Dispositivos";

export const AutocompleteStyle = styled.div`
  width: 95%;
  position: relative;
  margin-top: 2%;
  margin-left: 2%;
  justify-content: center;
  text-align: center;
  align-items: center;
  // @media (max-width: 768px) {
  //   margin-top: 0%;
  // }
`;

export const AutocompleteResultStyle = styled.ul`
  position: relative;
  top: 0px;
  z-index: 100;
  background-color: #ffffff;
  width: 80%;
  color: #0060B0;
  margin-left: 5.5%;
  list-style-type: none;
  border-radius: 4px;
  font-size: 16px;
  border-radius: 6px;
  outline: 0;
  transition: border-color 0.2s;
  box-shadow: 0px 4px 10px rgb(50 151 169 / 30%);
  .liStyle{
    &:hover{
      background: #0c8de350;
      color: #000000;
    }
  }
  @media ${dispositivo.mobileM} {
    font-size: 18px;
    margin-left: 6.5%;
    }
  @media ${dispositivo.mobileL} {
    font-size: 18px;
    margin-left: 7.5%;
    }
  @media ${dispositivo.tablet} {
    font-size: 20px;
    margin-left: 8.5%;
    }
  @media ${dispositivo.laptop} {
    font-size: 20px;
    margin-left: 9.5%;
    }
`;
export const FormField = styled.input`
  border-radius: 12px;
  margin: 4px 4px;
  width: 80%;
  border: 0;
  outline: 0;
  font-size: 18px;
  color: #0060B0;
  padding: 5px;
  background: #ffffff;
  transition: border-color 0.2s;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #0060B0;
  }
`;

export const AutocompleteSearch = styled.div`
  position: absolute;
  width: 100%;
  cursor: pointer;
`;
