import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { auth } from "../services/firebase/Config";
export const PrivateRoutePaciente = ({ component: Component, ...rest }) => {
  const sesion = useSelector((state) => state.sesion);

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page

    <Route
      {...rest}
      render={(props) =>
        sesion.paciente ? (
          <Component {...props} />
        ) : (
          <Redirect to="/paciente/datos" />
        )
      }
    />
  );
};
