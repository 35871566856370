import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { saveTratamiento } from "../../redux/tratamientos/tratamientosActions";
import { useToasts } from "react-toast-notifications";

import {
  ContainerTitle,
  ContainerTitleText,
  ContainerBody,
  StyledInput,
  LabelNombre,
  ContainerSelect,
  StyledButton,
  ContainerLeft,
  MainContainer,
  StyledButtonCancelar,
  StyledForm,
  StyledInputNumber,
  SelectStyle,
} from "./styles";

import { fetchTarifarios } from "../../services/firebase/tarifarios/index";

const useTratamiento = (tratamiento) => {
  if (!tratamiento.color) {
    tratamiento.color = "#000";
    tratamiento.afectaOdonto = false;
    tratamiento.grupo = "NONE";
    tratamiento.codtra = "";
  }

  const [item, setItem] = React.useState(tratamiento);
  return [item, setItem];
};

const ESPECIALIDADES = [
  "anexos",
  "cirugia",
  "dentistica",
  "endodoncia",
  "estetica",
  "exodoncias",
  "higienista",
  "implantologia",
  "odontopediatria",
  "ortodoncia",
  "periodoncia",
  "protesis fija",
  "protesis removible",
  "radiologia",
];

export const ConfigTratamiento = ({ location }) => {
  const sesion = useSelector((state) => state.sesion);
  const subGrupos = useSelector((state) => state.subGrupos);
  const dispatch = useDispatch();
  const [item, setItem] = useTratamiento(location.state.data);
  const [tarifario, setTarifario] = React.useState([]);
  const history = useHistory();
  const { addToast } = useToasts();
  const handleChange = (e) => {
    setItem({ ...item, [e.target.name]: e.target.value });
  };
  const enviaLab = () => {
    if (item.subGrupoId == undefined) return false;
    const i = subGrupos.find((it) => it.id === item.subGrupoId);
    if (i) return i.envLab;
    else return false;
  };
  React.useEffect(() => {
    if (tarifario.length === 0)
      fetchTarifarios().then((tarifario) => {
        setTarifario(tarifario);
      });
  }, []);
  const handleGuardar = (e) => {
    e.preventDefault();
    try {
      dispatch(saveTratamiento(item));
      addToast("Guardado satisfactorio", { appearance: "success" });
      history.push("/admin/mantenimiento/tratamientos");
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };
  if (sesion.loading || sesion.loading === undefined)
    return <div>...procesando</div>;
  if (sesion.error) return <div>{`Hubo un error ${sesion.error}`}</div>;
  return (
    <MainContainer>
      <ContainerTitle>
        <ContainerTitleText>Configura Tratamientos</ContainerTitleText>
      </ContainerTitle>
      <ContainerBody>
        <form className="form form--simple" onSubmit={handleGuardar}>
          <StyledForm>
            <ContainerSelect>
              <LabelNombre htmlFor="subGrupo">Sub. Grupo</LabelNombre>
              <SelectStyle
                name="subGrupoId"
                required
                onChange={handleChange}
                onSelected={handleChange}
                value={item.subGrupoId}
                disabled={item.readOnly}
              >
                <option value="" selected disabled hidden>
                  Seleccione
                </option>
                {subGrupos.map((s, index) => (
                  <option key={index} value={s.id}>{s.nombre}</option>
                ))}
              </SelectStyle>
            </ContainerSelect>

            <ContainerSelect>
              <LabelNombre htmlFor="especialidad">Especialidad</LabelNombre>

              <SelectStyle
                name="especialidad"
                value={item.especialidad}
                onChange={(e) =>
                  setItem({ ...item, [e.target.name]: e.target.value })
                }
              >
                {ESPECIALIDADES.map((it, index) => (
                  <option key={index} value={it}>{it}</option>
                ))}
              </SelectStyle>
            </ContainerSelect>
            {enviaLab() && (
              <ContainerSelect>
                <LabelNombre htmlFor="odonto">Tratamiento Lab</LabelNombre>
                <SelectStyle
                  name="codLab"
                  value={item.codLab}
                  onChange={(e) =>
                    setItem({ ...item, [e.target.name]: e.target.value })
                  }
                >
                  {tarifario.map((it,index) => (
                    <option key={index} value={it.id}>{it.nombre}</option>
                  ))}
                </SelectStyle>
              </ContainerSelect>
            )}
            <ContainerSelect>
              <LabelNombre className="form__labelStyled" htmlFor="tratamiento">
                Tratamiento
              </LabelNombre>
              <StyledInput
                name="tratamiento"
                id="tratamiento"
                autoComplete="off"
                value={item.tratamiento}
                onChange={handleChange}
                required
              //ref={register}
              />
            </ContainerSelect>

            <ContainerSelect>
              <LabelNombre className="form__labelStyled" htmlFor="Tipo">
                Tipo
              </LabelNombre>
              <StyledInput
                name="tipo"
                id="tipo"
                autoComplete="off"
                value={item.tipo}
                onChange={handleChange}
              //ref={register}
              />
            </ContainerSelect>

            <ContainerSelect>
              <LabelNombre htmlFor="precio">Precio</LabelNombre>
              <StyledInputNumber
                type="input"
                name="precio"
                autoComplete="off"
                value={item.precio}
                //ref={register}
                onChange={handleChange}
                required
              />
            </ContainerSelect>
          </StyledForm>
          <ContainerLeft>
            <StyledButtonCancelar
              type="button"
              value="Cancelar"
              onClick={() => history.push("/admin/mantenimiento/tratamientos")}
            >
              Cancelar
            </StyledButtonCancelar>
            <StyledButton type="submit" value="Guardar">
              Guardar
            </StyledButton>
          </ContainerLeft>
        </form>
      </ContainerBody>
    </MainContainer>
  );
};
