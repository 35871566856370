import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { dispositivo } from "../../components/Dispositivos";

export const MainContainer = styled.div`
  width: 100%;
  text-align: center;
  background: #f8f8f8;
`;

export const ContainerTitle = styled.div`
  color: #000;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-top: 2%;
  display: flex;
  justify-content: space-around;
`;

export const ContainerTitleText = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #ffffff;
  background: #0c8de3;
  border: 2px solid #0c8de3;
  width: 100%;
`;

export const ContainerBody = styled.div`
  width: 95%;
  height: 100%;
  padding: 3%;
  margin: 3%;
  background: #fff;
  box-shadow: 2px 2px #88888877;
  border-radius: 14px;
`;

export const ContainerActions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media ${dispositivo.mobileL} {
    flex-direction: row;
  }
`;
export const ContainerLeft = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 1%;
  padding-bottom: 4%;
  @media ${dispositivo.mobileL} {
    text-align: left;
    padding-bottom: 0%;
  }
`;

export const ContainerRight = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-bottom: 1rem;
  @media ${dispositivo.mobileL} {
    text-align: right;
  }
`;

export const ButtonAddBudget = styled(Button)`
  && {
    height: 2.25rem;
    font-size: 0.9rem;
    width: 15rem;
    border-radius: 0.8rem;
    text-transform: uppercase;
    cursor: pointer;
    font-width: bold;
    background-color: #0060b0;
    color: #ffffff;
    border: none;
    &:hover {
      color: #000000;
      background-color: #0c8de390;
      font-width: bold;
    }
  }
`;

export const ContenedorTabla = styled.div`
  @media ${dispositivo.tablet} {
    flex: 1;
    // max-height: 370px;
    // overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 20px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #0c8de3;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #fff;
    }
  }
`;
