import { db } from "../Config";

export const getMessageGroupByPhonesAndStatus = async (from) => {
    return new Promise((resolve, reject) => {
        let unreadCounts = {};
        db.collection("mensajes")
            .where("from", "==", from)
            .orderBy("date", "desc")
            .get()
            .then(async (querySnapshot) => {
                const phoneNumbers = new Set();
                const messagesToCheck = [];
                querySnapshot.forEach((doc) => {
                    const toPhone = doc.data().to;
                    const isRead = doc.data().is_read || false;
                    if (!unreadCounts[toPhone]) {
                        unreadCounts[toPhone] = {
                            not_read: 0,
                            messages: []
                        };
                        phoneNumbers.add(toPhone);
                    }
                    if (!isRead) {
                        unreadCounts[toPhone].not_read++;
                        unreadCounts[toPhone].messages.push({
                            id: doc.id,
                            from: doc.data().from,
                            to: doc.data().to,
                            message: doc.data().message,
                            type: doc.data().type,
                            date: doc.data().date
                        });
                    }
                });
                for (let toPhone of phoneNumbers) {
                    if (unreadCounts[toPhone].not_read === 0) {
                        messagesToCheck.push(toPhone);
                    }
                }
                const lastMessagePromises = messagesToCheck.map(async (toPhone) => {
                    const lastMessageSnapshot = await db.collection("mensajes")
                        .where("from", "==", from)
                        .where("to", "==", toPhone)
                        .orderBy("date", "desc")
                        .limit(1)
                        .get();

                    if (!lastMessageSnapshot.empty) {
                        const lastMessageDoc = lastMessageSnapshot.docs[0];
                        unreadCounts[toPhone].messages.push({
                            id: lastMessageDoc.id,
                            from: lastMessageDoc.data().from,
                            to: lastMessageDoc.data().to,
                            message: lastMessageDoc.data().message,
                            type: lastMessageDoc.data().type,
                            date: lastMessageDoc.data().date
                        });
                    }
                });
                const promises = Array.from(phoneNumbers).map(async (toPhone) => {
                    const userSnapshot = await db.collection("pacientes")
                        .where("telefono", "==", ensurePrefix(toPhone))
                        .limit(1)
                        .get();
                    if (!userSnapshot.empty) {
                        const userDoc = userSnapshot.docs[0];
                        unreadCounts[toPhone].nombres = userDoc.data().nombres;
                        unreadCounts[toPhone].apellidos = userDoc.data().apellidos;
                    } else {
                        unreadCounts[toPhone].nombres = toPhone;
                    }
                });
                await Promise.all([...promises, ...lastMessagePromises]);
                resolve(unreadCounts);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const updateMessageStatusToRead = async (messages) => {
    if (!Array.isArray(messages) || messages.length === 0) {
        throw new Error("Se requieren IDs de mensajes válidos.");
    }
    const updatePromises = messages.map((message) => {
        return db.collection("mensajes").doc(message.id).update({
            is_read: true,
        });
    });
    try {
        await Promise.all(updatePromises);
        console.log("Mensajes actualizados a 'vistos' con éxito.");
    } catch (error) {
        console.error("Error al actualizar los mensajes: ", error);
        throw error;
    }
}

const ensurePrefix = (phoneNumber) => {
    const cleanedNumber = phoneNumber.replace(/^\+/, '').replace(/\D/g, '');
    return cleanedNumber.startsWith('51') ? cleanedNumber.replace('51', '') : cleanedNumber;
};

