import { ADD_ITEM_TO_CART } from "./itemsOrdenCartTypes";

export const itemsOrdenCartReducers = (state = [], action) => {
  switch (action.type) {
    case ADD_ITEM_TO_CART:
      const index = state.findIndex((pr) => pr.id === action.payload.id);
      const newArray = [...state];
      if (index > -1) newArray[index] = action.payload;
      else newArray.push(action.payload);
      return newArray;
    default:
      return state;
  }
};
