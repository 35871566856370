import styled from "styled-components";
import { Link } from "react-router-dom";
import { dispositivo } from "../../../../components/Dispositivos";

export const MainContainer = styled.div`
  height: 100%;
  justify-content: center;
  text-align: center;
`;

export const ContainerTitle = styled.div`
  text-align: center;
`;

export const ContainerTitleText = styled.h2`
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center;
  padding: 1.5%;
  background-color: #0c8de3;
  color: white;
  border-radius: 10px;
  @media ${dispositivo.mobileL} {      
    font-size: 1.25rem;
  }
`;

export const ButtonPagar = styled.button`
  height: 2.25rem;
  font-size: 0.9rem;  
  width: 7rem;
  border-radius: 0.8rem;      
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #0060b0;
  color: #ffffff;
  border: none;
  &:hover{
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.tablet}{
    width: 10rem;
    font-size: 1rem;  
  }  
  @media ${dispositivo.laptop} {      
    width: 12.5rem;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  border: 2px solid #0c8de3;
`;

export const ContainerBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 2%;
  background: #fff;
  display: block;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid #0c8de3;
  @media ${dispositivo.mobileL} {
    display: flex;
  }
`;

export const StyledForm = styled.form`
  width: 90%;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const Tabla = styled.table`
  width: 90%;
  justify-content: center;
  text-align: center;
`;

export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
  justify-content: center;
  text-align: center;
`;

export const TablaHeaderTextoItems = styled.th`
  font-size: 12px;
  color: #040404;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: center;
  text-align: left;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;
export const TablaHeaderTextoValues = styled.th`
  font-size: 12px;
  color: #045184;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: center;
  text-align: right;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;


export const TablaHeaderTextoNumber = styled.th`
  font-size: 12px;
  color: #045184;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: right;
  text-align: right;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const TablaHeaderTexto = styled.th`
  font-size: 12px;
  color: #045184;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: right;
  text-align: right;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const TablaBody = styled.tbody`
  vertical-align: middle;
`;

export const TablaBodyTexto = styled.th`
  font-size: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  vertical-align: middle;
  text-align: left;

  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const SelectStyle = styled.select`
  width: 50%;
  vertical-align: middle;
`;

export const StyledInput = styled.input`
  width: 60%;
  height: 2.5rem;
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #474747;
  margin: 0 0 1em;
  line-height: 2.5;
  font-size: 1rem;
  text-align: end;
  padding-right: 0.75rem;
  &::placeholder {
    color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.tablet}{
    width: 40%;
  }

`;

export const LinkCancelar = styled.button`
  height: 2.25rem;
  font-size: 0.9rem;  
  width: 7rem;
  border-radius: 0.8rem;      
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #ffffff;
  border: 2px solid #0060b0;
  color: #0060b0;
  margin-right: 1rem;
  &:hover {
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
    border: none;
  }
  @media ${dispositivo.tablet}{
    width: 10rem;
    font-size: 1rem;  
  }  
  @media ${dispositivo.laptop} {      
    width: 12.5rem;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 1rem;
  margin: 1em 0;
`;
