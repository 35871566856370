export const FETCH_PRESTACIONES_SESION_REQUEST =
  "FETCH_PRESTACIONES_SESION_REQUEST";
export const FETCH_PRESTACIONES_SESION_SUCCESS =
  "FETCH_PRESTACIONES_SESION_SUCCESS";
export const FETCH_PRESTACIONES_SESION_FAILURE =
  "FETCH_PRESTACIONES_SESION_FAILURE";
export const SET_PRESTACIONES_SESION = "SET_PRESTACIONES_SESION";
export const NEW_PRESTACIONES_SESION = "NEW_PRESTACIONES_SESION";
export const ADD_PRESTATION_SESION = "ADD_PRESTATION_SESION";
export const REMOVE_PRESTACION_SESION = "REMOVE_PRESTACION_SESION";
export const UPDATE_PRESTATION_SESION_IN_ARRAY = "UPDATE_PRESTATION_IN_ARRAY";
export const SAVE_PRESTACIONES_SESION_REQUEST =
  "SAVE_PRESTACIONES_SESION_REQUEST";
export const SAVE_PRESTACIONES_SESION_SUCCESS =
  "SAVE_PRESTACIONES_SESION_SUCCESS";
// export const UPDATE_PRESTATION_SUCCESS = "UPDATE_PRESTATION_SUCCESS";
export const SAVE_PRESTACIONES_SESION_FAILURE =
  "SAVE_PRESTACIONES_SESION_FAILURE";
