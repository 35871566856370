import styled from "styled-components";
import { dispositivo } from "../../components/Dispositivos";

const btn = `
  height: 2.125rem;
  width: 100%;
  border-radius:  0.8rem;      
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  font-size: 0.9rem;
  
  @media ${dispositivo.mobileS}{
    font-size: 0.9rem;
    padding: 0.1em;
  }    
 
`;
export const LoginViewWrapper = styled.div`
  background: #F8F8F8;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
`;
export const ImgLogo = styled.img`
  width: 90%;
  padding: 1em 0;
`;
export const LoginView = styled.div`
  
  background: #F8F8F8;
  display: grid;
  grid-template-columns: 400px auto;
  height: 100vh;
  width: 100vw;
  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr;
  }
  .form__container {
    background-color: #F8F8F8;
    padding:1.5rem;
    padding-bottom: 100px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    color: #292d31;
    z-index: 100;
  }
  .img__container{
    display: flex;
    padding-bottom: 1em;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    color: #292d31;
    font-size: 0.75rem;
  }
  .form__input {
    height: 2.5rem;
    border-radius: 10px;
    background-color: #64bdff30;
    border: none;
    outline: none;
    color: #474747;
    margin: 0 0 1em;
    padding-left: 12px;
    line-height: 2.5;
    font-size: 1rem;
    width: 100%;

    &::placeholder {
      color: #04000060;
      fonst-size: 0.5rem;
    }
    &:focus{
      border: 2px solid #0060b0;
    }
 }

    .form__buttonPrimary{
      ${btn}
      background-color: #0060b0;
      color: #ffffff;
      font-size: 0.9rem;
      margin: 1em 0;
      border: none;
      &:hover{
        color:#000000;
        background-color: #0c8de390;
        font-width: bold;
      }
    }

    .form__linkButton{
      font-width: bold;
    }
    .form__buttonSecondary{
      ${btn}
      background-color: #ffffff;
      border: 2px solid #0060b0;
      color: #0060b0;
      &:hover {
        color:#000000;
        background-color: #0c8de390;
        font-width: bold;
        border: none;
      }
    }
  }
`;
export const SectionForm = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 100vh;
  top: 0;
  // margin: 0 2em;
`;
export const FooterWrapper = styled.footer`
 background: #EBF0FC;
 left: 400px;
 position: fixed;
 @media (max-width: 1024px) {
   display: none;
 }
 .carouselHeigth{
   height: 100%;
 }
`;
