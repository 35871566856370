import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FormField,
  AutocompleteStyle,
  AutocompleteResultStyle,
  AutocompleteSearch,
} from "./styles";
import { Link, useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { Modal, Button, Form } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import './styles.css'
import { db } from "../../services/firebase/Config";
import { ContactsOutlined } from "@material-ui/icons";

export function Autocomplete(props) {
  const inputRef = useRef();
  const { query, setQuery, filteredItems, clearFilteredItems, loading } =
    props.useSearchItems();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const sesion = useSelector((state) => state.sesion);
  const urlLink = "https://pac.sysdent.net"
  // const urlLink = "http://localhost:5173"
  const endpoint = `${process.env.REACT_APP_API_URL}`
  // const endpoint = 'http://127.0.0.1:5001/sysdent-devs/us-central1'
  const { addToast } = useToasts();

  React.useEffect(()=>{
    inputRef.current.focus();
  }, [])
  //const { query, setQuery, filteredItems } = useMyHook(myitems);

  function handleClickItem(item) {
    clearFilteredItems();
    setQuery("");
    props.onSelected(item);
    //setQuery(`${item.nombres} ${item.apellidos}`);
  }

  function handleOpenModal() {
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  function handleSavePhoneNumber() {
    if (phoneNumber.length !== 9 || !/^\d+$/.test(phoneNumber)) {
      alert("El número de teléfono debe tener 9 dígitos");
    }

    const tokenUrl = `${endpoint}/api/v1/pacientes/token?userid=${sesion.usuario.id}&empresaid=${sesion.usuario.empresaId}`;

    const empresaId = sesion.usuario.empresaId;
    const empresasCollection = db.collection('empresas');

    let razonSocial;

    empresasCollection
      .doc(empresaId)
      .get()
      .then(empresaDoc => {
        if (!empresaDoc.exists) {
          throw new Error(`Empresa not found with id: ${empresaId}`);
        }

        const empresaData = empresaDoc.data();
        razonSocial = empresaData.razonSocial;

        return fetch(tokenUrl);
      })
      .then(async response => {
        if (!response.ok) {
          alert(`Error al obtener el token: ${response.status}`);
          throw new Error(`Error al obtener el token: ${response.status}`);
        }

        const result = await response.json();
        console.log('RESULT', result);

        const tokenData = result;
        const token = tokenData.token;
        console.log('TOKEN', token);

        const url = `${urlLink}/invited?&empresaId=${sesion.usuario.empresaId}&token=${token}`;

        const mensaje = `¡Bienvenido a ${razonSocial}! Ingresa tus datos aquí: ${url}`;

        const whatsappLink = `https://wa.me/51${phoneNumber}?text=${encodeURIComponent(mensaje)}`;
        window.open(whatsappLink, '_blank');

        handleCloseModal();
      })
      .catch(error => {
        console.error(error);
      });
  }
  return (
    <>
      <AutocompleteStyle>
        <FormField
          ref={inputRef}
          type="text"
          placeholder="Buscar Paciente"
          onChange={(e) => setQuery(e.target.value)}
          value={query}
        ></FormField>

        <AutocompleteSearch>
          <AutocompleteResultStyle>
            {filteredItems.map((item) => (
              <li className="liStyle" key={item.id} onClick={() => handleClickItem(item)}>
                {props.toString(item)}
              </li>
            ))}
          </AutocompleteResultStyle>
        </AutocompleteSearch>
        <Tooltip
          placement="top"
          title="Agregar Paciente"
        // onClick={() => history.push("datos")}
        >
          {/* <EditIcon style={{ color: "#ffffff" }} /> */}
          <Link to="/paciente/datos">
            <img
              src={require("../../img/azul_nuevo_paciente.png")}
              alt="add new patient icon"
            />
          </Link>
        </Tooltip>
        <Tooltip
          placement="top"
          title="Invitar Paciente"
        // onClick={() => history.push("datos")}
        >
          {/* <EditIcon style={{ color: "#ffffff" }} /> */}

          <img
            src={require("../../img/mail-send.png")}
            alt="add new patient icon"
            onClick={handleOpenModal}
          />

        </Tooltip>
      </AutocompleteStyle>
      <Modal show={isModalOpen} onHide={handleCloseModal} dialogClassName="custom-modal">
        <Modal.Header >
          <Modal.Title>Ingresa el número de teléfono del Paciente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Número de Teléfono</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingresa el número de teléfono"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSavePhoneNumber}>
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
