import React from "react";

const MolarDeciduoSup = (props) => {
  const configuracion = props.config;

  const getColor = (cara) => {
    const defaultColor = "#ffeca2";
    let color = defaultColor;
    if (props.config.caras) {
      let idx = props.config.caras.findIndex((it) => it.cara === cara);
      if (idx > -1) color = props.config.caras[idx].color;
    }
    return color;
  };

  return (
    <svg
      id="Molar_sup"
      data-name="Molar sup"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 345.52 499.15"
    >
      <title>molar_deciduo_sup</title>

      <path
        id="Raiz"
        d="M64.69,339.55C24.76,311.1,13.53,214.18,6,170.65-6.95,96.28,18.3,61,42.84,44.26c15.43-10.43,37.94,1.18,34,17.43a160.06,160.06,0,0,1-6.63,20.88c-15.43,38.89.27,78.19,2.68,88.08,3.86,15.88,30.85,59.27,39.56,72C165.73,320.12,152.73,23.8,188,6c13.77-7,34.57,276.6,49.09,249.84C263,208.14,288.07,84.34,271.91,46,265.08,29.89,277.11,23,289.09,20c10-2.48,20.73,1.58,25.13,9.56,4.21,7.52,12.76,17,18.6,29.46,26.34,56-.9,267.52-53.3,299.12C222.93,392.25,109.81,371.69,64.69,339.55Z"
        transform="translate(0.02 -3.35)"
        fill={configuracion.raiz.fill}
        stroke={configuracion.raiz.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
      <path
        id="Nucleo"
        d="M107.09,348.33C69.22,324,41.44,251.84,33.87,214.11,20.91,149.67,17.75,86.66,40.74,72c14.37-9.2,6.79,29,4.46,45.89a107.35,107.35,0,0,0-2.47,25.24c.77,35.6,15.53,59.37,20.84,67.09C73,224,93.22,261.36,101.63,272.31c16,20.86,40.41,10.6,52.5-12.31C180.78,209.49,175,64.26,186.18,65c14,.94,27.34,237.9,46.28,217.49,34.72-37.34,46-73.07,59.27-108.63,12.79-34.36,3.59-104,4.92-113.14,2.05-14.14,3.32-13,10.43-7.77,18.32,13.42,22.44,40.62,22.67,55.7.75,52.53-38.95,220.64-87.95,248.54C189,387.34,149.92,375.84,107.09,348.33Z"
        transform="translate(0.02 -3.35)"
        fill={configuracion.nucleo.fill}
        stroke={configuracion.nucleo.strokeColor}
      />
      <path
        id="6"
        d="M247.49,252.27h30.27c6.49,0,20.88,6.4,20.88,12.82l-7.47,83.13c0,6.43-6.89,9.9-13.38,9.9H231.2a11.73,11.73,0,0,1-11.81-11.6L246,269.74C246,263.31,241,252.27,247.49,252.27Z"
        transform="translate(0.02 -3.35)"
        fill={getColor("6")}
      />
      <path
        id="7"
        d="M68,252.27h32.43a12.19,12.19,0,0,1,12,12.44h0v93.41H56.25l-1.49-93c0-6.71,6.75-12.82,13.24-12.82Z"
        transform="translate(0.02 -3.35)"
        fill={getColor("7")}
      />
      <path
        id="8"
        d="M295.33,358.12H66.73s-2.15-54,0-71.33c6.06-49.25,209.4-42.68,228.6,0C302.64,303,295.33,358.12,295.33,358.12Z"
        transform="translate(0.02 -3.35)"
        fill={getColor("8")}
      />
      <path
        id="9"
        d="M288.49,358.12H55.68s-2.15-44.41,0-58.61c6.17-40.46,213.17-35.12,232.72,0C296,312.86,288.49,358.12,288.49,358.12Z"
        transform="translate(0.02 -3.35)"
        fill={getColor("9")}
      />
      <path
        id="Corona"
        d="M309.1,417c-.63,116.75-138.84,76.14-138.84,76.14S30,528.46,38.09,420.54C41.79,370.12,57.26,303,169.23,302.88,285.49,302.88,309.47,353.11,309.1,417Z"
        transform="translate(0.02 -3.35)"
        fill={configuracion.corona.fill}
        stroke={configuracion.corona.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
    </svg>
  );
};
export default MolarDeciduoSup;
