import styled from "styled-components";
import { Link } from "react-router-dom";
import { dispositivo } from "../../components/Dispositivos";

export const MainContainer = styled.div`
  width: 100%;
  padding-left: 3%;
  padding-right: 3%;
  margin-bottom: 15%;
  background: #f8f8f8;
  justify-content: center;
  text-align: center;
`;

export const ContainerTabla = styled.div`
  background: #fff;
  border-radius: 14px;
  padding: 2% 4% 4%;
  margin-top: 3%;
  display: block;
  box-shadow: 2px 2px #88888877;
`;

export const ContainerTitle = styled.div`
  text-align: center;
`;

export const ContainerTitleText = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 15px;
`;

export const ButtonPagar = styled.button`
  height: 2.25rem;
  font-size: 0.9rem;  
  width: 7rem;
  border-radius: 0.8rem;      
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #0060b0;
  color: #ffffff;
  border: none;
  &:hover{
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.tablet}{
    width: 10rem;
    font-size: 1rem;  
  }  
  @media ${dispositivo.laptop} {      
    width: 12.5rem;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  border: 2px solid #0c8de3;
  
`;

export const StyledForm = styled.form`
  width: 90%;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
`;

export const Tabla = styled.table`
  width: 100%;
  justify-content: center;
  text-align: center;
  
`;

export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
  justify-content: center;
  text-align: center;
`;

export const TablaHeaderTextoItems = styled.th`
  font-size: 12px;
  color: #040404;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: center;
  text-align: left;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;
export const TablaHeaderTextoValues = styled.th`
  font-size: 12px;
  color: #045184;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: center;
  text-align: right;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const TablaHeaderTextoNumber = styled.th`
  font-size: 12px;
  color: #045184;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: right;
  text-align: right;
  .color-saldo{
    color: #ff0000;
  }
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const TablaHeaderTexto = styled.th`
  font-size: 12px;
  color: #045184;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: right;
  text-align: right;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const TablaBody = styled.tbody`
  vertical-align: middle;
`;


export const SelectStyle = styled.select`
  width: 50%;
  vertical-align: middle;
`;

export const StyledInput = styled.input`
  width: 80%;
  height: 2.5rem;
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  line-height: 2.5;
  font-size: 1rem;
  text-align: end;
  padding-right: 0.75em;
  &::placeholder {
    color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.mobileL}{
    width: 60%;
  }
  @media ${dispositivo.tablet}{
    width: 50%;
  }
`;

export const LinkCancelar = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  font-size: 0.9rem;  
  width: 7rem;
  border-radius: 0.8rem;      
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #ffffff;
  border: 2px solid #0060b0;
  color: #0060b0;
  text-decoration: none;
  padding: 0.6em 0;
  &:hover {
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
    border: none;
    text-decoration: none;
  }
  @media ${dispositivo.tablet}{
    width: 10rem;
    font-size: 1rem;  
  }  
  @media ${dispositivo.laptop} {      
    width: 12.5rem;
  }
`;
export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 1rem;
  margin: 1em 0;
  @media ${dispositivo.tablet} {
    width: 100%;
  }
`;
