import styled from "styled-components";
import { Link } from "react-router-dom";
import { dispositivo } from "../../components/Dispositivos";

export const ContainerTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  margin-top: 10px;
`;

export const ContainerTitleText = styled.h2`
font-size: 20px;
font-weight: bold;
text-align: center;
padding: 1.5%;
background-color: #0c8de3;
color: white;
border-radius: 10px;
`;

export const ButtonPagar = styled.button`
  height: 2.25rem;
  font-size: 0.9rem;  
  width: 7rem;
  border-radius: 0.8rem;      
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #0060b0;
  color: #ffffff;
  border: none;
  &:hover{
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.tablet}{
    width: 10rem;
    font-size: 1rem;  
  }  
  @media ${dispositivo.laptop} {      
    width: 12.5rem;
  }
`;

export const Tabla = styled.table`
  width: 90%;
  justify-content: center;
  text-align: center;
  margin: 1rem 0;
  @media ${dispositivo.tablet} {
    width: 100%;
    margin: 2rem 0;
  }
`;

export const TablaHeaderTextoButtons = styled.th`
  font-size: 12px;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: center;
  text-align: center;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const TablaBody = styled.tbody`
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
  vertical-align: middle;
`;

export const TablaBodyTexto = styled.th`
  font-size: 12px;
  font-family: Segoe UI;
  font-weight: normal;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  vertical-align: middle;
  text-align: left;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const SelectStyle = styled.select`
  width: 50%;
  vertical-align: middle;
`;

export const LinkCancelar = styled(ButtonPagar)`
  background-color: #ffffff;
  border: 2px solid #0060b0;
  color: #0060b0;
  &:hover {
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
    border: none;
  }
`;
