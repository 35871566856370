import React from "react";
import { HomeViewWrapper, PlanCardsWrapper } from "./styles";
import { ListGroup, Button } from "react-bootstrap";
import { savePreference } from "../../services/firebase/planes";
import { useSelector } from "react-redux";
export const Planes = () => {
  const [plan, setPlan] = React.useState();
  const empresa = useSelector((state) => state.empresa);
  const sesion = useSelector((state) => state.sesion);
  const planes = [
    { plan: "BASIC", precio: 60 },
    { plan: "PLUS", precio: 80 },
    { plan: "PREMIUM", precio: 120 },
  ];
  const FORM_ID = "button-checkout";

  const [preferenceId, setPreferenceId] = React.useState();

  const getPrecio = (plan) => {
    return planes.find((item) => item.plan === plan)?.precio;
  };

  function addCheckout() {
    const mp = new window.MercadoPago(
      "TEST-9667d745-cc7c-4ef6-b2b9-9e6ef52911e8",
      {
        locale: "es-PE",
      }
    );

    // Inicializa el checkout
    mp.checkout({
      preference: {
        id: preferenceId,
      },
      render: {
        container: `#${FORM_ID}`, // Indica el nombre de la clase donde se mostrará el botón de pago
        label: "Pagar", // Cambia el texto del botón de pago (opcional)
      },
    });
  }

  React.useEffect(() => {
    if (preferenceId) {
      // con el preferenceId en mano, inyectamos el script de mercadoPago
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://sdk.mercadopago.com/js/v2";
      script.addEventListener("load", addCheckout); // Cuando cargue el script, se ejecutará la función addCheckout
      document.body.appendChild(script);
    }
  }, [preferenceId]);

  const handleClick = async (plan) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    try {
      const orderData = {
        quantity: 1,
        description: `PLAN ${plan}`,
        price: getPrecio(plan),
        fechaCliente: new Date(),
        empresaId: empresa.id,
        usuario: sesion.usuario.email,
        plan: plan,
      };
      let preferenceId = await savePreference(orderData);
      setPlan(plan);
      setPreferenceId(preferenceId);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <HomeViewWrapper>
      {!preferenceId && (
        <section className="home__section home__section--single">
          <div className="container">
            <PlanCardsWrapper>
              <div className="plan-card plan-card--basic">
                <span className="plan-card__title">BASIC</span>
                <div className="plan-card__price">
                  <span className="plan-card__price__ammount">60</span>
                  <span className="plan-card__price__period">MENSUAL</span>
                </div>

                <ListGroup variant="flush" className="text-center">
                  <ListGroup.Item>120 Clientes</ListGroup.Item>
                  <ListGroup.Item>80 Prestaciones Mes</ListGroup.Item>
                  <ListGroup.Item>50 Operaciones</ListGroup.Item>
                </ListGroup>
                <div className="plan-card__button-container">
                  <Button
                    className="button--green"
                    onClick={() => handleClick("BASIC")}
                  >
                    Elegir plan
                  </Button>
                </div>
              </div>

              <div className="plan-card plan-card--plus">
                <span className="plan-card__title">PLUS</span>
                <div className="plan-card__price">
                  <span className="plan-card__price__ammount">80</span>
                  <span className="plan-card__price__period">MENSUAL</span>
                </div>

                <ListGroup variant="flush" className="text-center">
                  <ListGroup.Item>500 Clientes</ListGroup.Item>
                  <ListGroup.Item>300 Prestaciones Mes</ListGroup.Item>
                  <ListGroup.Item>100 Operaciones</ListGroup.Item>
                </ListGroup>
                <div className="plan-card__button-container">
                  <Button
                    className="button--blue"
                    onClick={() => handleClick("PLUS")}
                  >
                    Elegir plan
                  </Button>
                </div>
              </div>

              <div className="plan-card plan-card--titanium">
                <span className="plan-card__title">PREMIUM</span>
                <div className="plan-card__price">
                  <span className="plan-card__price__ammount">120</span>
                  <span className="plan-card__price__period">MENSUAL</span>
                </div>

                <ListGroup variant="flush" className="text-center">
                  <ListGroup.Item>1000 Clientes</ListGroup.Item>
                  <ListGroup.Item>1000 Prestaciones Mes</ListGroup.Item>
                  <ListGroup.Item>500 Operaciones</ListGroup.Item>
                </ListGroup>
                <div className="plan-card__button-container">
                  <Button
                    className="button--orange"
                    onClick={() => handleClick("PREMIUM")}
                  >
                    Elegir plan
                  </Button>
                </div>
              </div>
            </PlanCardsWrapper>
          </div>
        </section>
      )}
      {preferenceId && (
        <section className="payment-form dark">
          <div className="container_payment">
            <div className="block-heading">
              <h2>Verificación del pago</h2>
              <p>Su compra es segura a través de este canal</p>
            </div>
            <div className="form-payment">
              <div className="products">
                <h2 className="title">Resumen</h2>
                <div className="item">
                  <span className="price" id="summary-price">
                    S/ {getPrecio(plan)}
                  </span>
                  <p className="item-name">
                    {plan}
                    <span id="summary-quantity"></span>
                  </p>
                </div>
                <div className="total">
                  Total
                  <span className="price" id="summary-total">
                    S/ {getPrecio(plan)}
                  </span>
                </div>
              </div>
              <div className="payment-details">
                <div className="form-group col-sm-12">
                  <br />
                  <div id="button-checkout"></div>
                  <br />
                  <span onClick={() => setPlan(null)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 10 10"
                      className="chevron-left"
                    >
                      <path
                        fill="#009EE3"
                        fill-rule="nonzero"
                        id="chevron_left"
                        d="M7.05 1.4L6.2.552 1.756 4.997l4.449 4.448.849-.848-3.6-3.6z"
                      ></path>
                    </svg>
                    Go back to Shopping Cart
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </HomeViewWrapper>
  );
};
