import styled from "styled-components";
import { dispositivo } from "../../Dispositivos";

export const Alert = styled.div`
  font-size: 14px;
  color: red;
  font-weight: bolder;
  margin-right: 5px;
  padding-right: 10px;
  @media ${dispositivo.tablet} {
    display: inline-block;
  }
`;
export const HeaderProfileItemWrapper = styled.div`
  .main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
      padding: 5px 8px 5px 0;
  
    &__img {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      margin-right: 5px;
    }

    &__name {
      display: none;
      font-size: 14px;
      color: white;
      font-weight: bolder;
      margin: 0;

      @media ${dispositivo.tablet} {
        display: inline-block;
      }
    }

    &__content {
      position: absolute;
      top: 100%;
      background-color: white;
      font-weight: bolder;
      padding: 0;
      z-index: 100;      
      left: auto;
      right: 10px;
      width: 150px;      
      box-shadow: 0px 4px 10px rgb(50 151 169 / 30%);
      @media ${dispositivo.tablet} {
        right: 0;
      }
      
      &__link{
        text-decoration: none;
        font-size: 14px;
        color: #0060B0;
        &:hover {
          color: #045184;
          text-decoration: none;
        }
      }
      &__list {
        list-style-type: none;
        font-size: 14px;
        color: #0060B0;

        &__item {
          cursor: pointer;
          padding: 5px 7px;
          border-bottom: 1px solid #3297A9;
          

          &:hover {
            color: #045184;
            text-decoration: none;
            border-left: 5px solid #3297A9;
          }
        }
      }
    }
    .hide {
      display: none;
    }

    &:hover .hide {
      display: block;
      transition: 0.3s;
    }
  }
`;
