import styled from "styled-components";
import { dispositivo } from "../../components/Dispositivos";

/**
 * Posicionamiento: fix, relative
 * Modelo de caja - box model
 * Tipografia
 * Visuales
 * Otros
 */
export const ContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  padding: 1.5em 0;
  gap: 1em;
  @media ${dispositivo.mobileL} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;
export const Btn = styled.button`
  height: 2.25rem;
  width: 100%;
  border-radius: 1em;
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  font-size: 0.9rem;
  background-color: #0060b0;
  color: #ffffff;
  border: none;
  &:hover {
    color: #000000;
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.mobileL} {
    font-size: 1rem;
  }
`;

export const BtnSiguiente = styled.button`
  height: 2.25rem;
  width: 100%;
  border-radius: 1em;
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  font-size: 0.9rem;
  background-color: #0060b0;
  color: #ffffff;
  border: none;
  &:hover {
    color: #000000;
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.mobileL} {
    width: 7rem;
    font-size: 1rem;
  }
  @media ${dispositivo.tablet} {
    width: 10rem;
  }
`;
export const StyledButtonCancelar = styled(BtnSiguiente)`  
  background-color: #ffffff;
  border: 2px solid #0060b0;
  color: #0060b0;
  padding: 0.5em;
  }
  &:hover {
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
    border: none;
  }  
  
`;

export const StyledSelect = styled.select`
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0d0808;
  line-height: 2.5;
  font-size: 1rem;
  height: 2.5rem;
  width: 100%;
  text-align: end;
  padding-right: 0.5em;
  &::placeholder {
    color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus {
    border: 2px solid #0060b0;
  }
`;
export const StyledInput = styled.input`
  height: 2.5rem;
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0d0808;
  padding-left: 1em;
  line-height: 2.5;
  font-size: 0.875rem;
  width: 100%;
  margin: 0 0 1em;
  text-align: end;
  padding-right: 0.75rem;
  &::placeholder {
    color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus {
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;

export const LoginViewWrapper = styled.div`
  background: #f8f8f8;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
`;

export const ImgLogo = styled.img`
  width: 90%;
  padding: 1em 0;
`;
export const LoginView = styled.section`
  background: #f8f8f8;
  display: grid;
  grid-template-columns: 400px auto;
  height: 100vh;
  width: 100vw;
  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr;
  }
  .form__container {
    background-color: #f8f8f8;
    padding: 1.5rem;
    display: grid;
    grid-template-rows: 1fr 2fr;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    color: #292d31;
    line-height: 1.2;
  }
  .img__container {
    display: flex;
    padding-bottom: 1em;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    color: #292d31;
    font-size: 0.75rem;
  }
  .form__input {
    height: 3rem;
    border-radius: 10px;
    background-color: #64bdff30;
    border: none;
    outline: none;
    color: #474747;
    margin: 0 0 1em;
    padding-left: 12px;
    line-height: 2.5;
    font-size: 0.875rem;
    width: 100%;
    @media ${dispositivo.tablet} {
      font-size: 1rem;
    }

    &::placeholder {
      color: #04000060;
      fonst-size: 0.5rem;
    }
    .form__container {
      background-color: #f8f8f8;
      padding: 1.5rem;
      display: grid;
      grid-template-rows: 1fr 1fr;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      letter-spacing: 2px;
      color: #292d31;
      z-index: 100;
    }
    .img__container {
      display: flex;
      padding-bottom: 1em;
      align-items: center;
      flex-direction: column;
      justify-content: flex-end;
      color: #292d31;
      font-size: 0.75rem;
    }
    .form__input {
      height: 2.5rem;
      border-radius: 10px;
      background-color: #64bdff30;
      border: none;
      outline: none;
      color: #0d0808;
      margin: 0 0 1em;
      padding: 8px 35px;
      line-height: 2.5;
      font-size: 0.875rem;
      width: 100%;
      @media ${dispositivo.tablet} {
        font-size: 1rem;
      }
      &::placeholder {
        color: #04000060;
        fonst-size: 0.5rem;
      }
      &:focus {
        border: 2px solid #0060b0;
      }
    }

    .form__select_option {
      border-radius: 10px;
      background-color: #64bdff30;
      border: none;
      outline: none;
      color: #0d0808;
      margin: 0 0 0.8em;
      line-height: 2.5;
      font-size: 0.875rem;
      width: 100%;
      text-align: left;
      height: 2rem;
      &::placeholder {
        color: #04000060;
        fonst-size: 0.5rem;
      }
      &:focus {
        border: 2px solid #0060b0;
      }
    }
  }
`;

export const SectionForm = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 100vh;
  top: 0;
`;

export const FooterWrapper = styled.footer`
  background: #ebf0fc;
  left: 400px;
  position: fixed;
  @media (max-width: 1024px) {
    display: none;
  }
  .carouselHeigth {
    height: 100%;
  }
`;

export const SpanContainer = styled.div`
  font-size: 0.75rem;
  margin: 1.5em 0 2em 0;
  text-align: center;
  line-height: 1.6;
  &:focus {
    color: #0c8de3;
    text-decoration: none;
  }
  .changeLink {
    text-decoration: none;
    font-size: 0.75rem;
  }
`;
