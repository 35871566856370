const BASE_URL = "http://localhost:3005";
const META_CLOUD_BUSINESS_ACCOUNT_ID = "310678535464335";
const TOKEN_META_WHATSAPP = "EAAGXQ8gHSS0BO6fJH7wqQ0AmfwgeZCrKQzc7gAtBa4WvlB5TUTW8UHBZB3y1iForZCVOfkqUkv1ZBtzBg5KrSZBsyKm1uidd21ZB3AWlZABb2Em1eHOgHT8ZCdd3hZCkbdCyCHwDsGCvIjpBym1xZCEfVJxMvi5hvl7ZANlJgedePGyfVJmFOLC5bjs5qDA3p3lny5K"
let API_URL = `${process.env.REACT_APP_API_URL}/api`;


let headers = {
  "Content-Type": "application/json;charset=utf-8",
  Accept: "application/json",
};


async function callApi(endpoint, options = {}) {
  options.headers = headers;
  const url = BASE_URL + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();

  return data;
}

const serialize = (object) => {
  var str = [];
  for (var p in object)
    if (object.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(object[p]));
    }
  return str.join("&");
}
const httpPostPromise = (url, payload, authorization = '') => {
  headers.Authorization = `Bearer ${authorization}`
  return new Promise((result, reject) => {
    fetch(url, { method: 'POST', headers, body: JSON.stringify(payload) })
      .then(r => r.json())
      .then(r => result(r))
      .catch(e => reject(e))
  })
}
const httpGetPromise = (url, payload, authorization = '') => {
  headers.Authorization = `Bearer ${authorization}`
  return new Promise((result, reject) => {
    let params = serialize(payload);
    fetch(`${url}?${params}`, { method: 'GET', headers })
      .then(r => r.json())
      .then(r => result(r))
      .catch(e => reject(e))
  })
}

const api = {
  pacientes: {
    getAll() {
      return callApi("/pacientes");
    },
    getPacienteById() { },
    createPaciente() { },
    updatePaciente() { },
  },
  especialistas: {
    getAll() {
      return callApi("/especialistas");
    },
    getPacienteById() { },
    createPaciente() { },
    updatePaciente() { },
  },
  recursos: {
    getAll() {
      return callApi("/recursos");
    },
  },
  prestaciones: {
    getAll() {
      return callApi("/prestaciones");
    },
  },
  WhatsApp: {
    enviarMensaje(payload, token) {
      return httpPostPromise(`${API_URL}/v1/whatsappV2/send-message`, payload, token);
    },
    addConsersation(payload, token) {
      return httpPostPromise(`${API_URL}/v1/message/add-conversation`, payload, token);
    },
    getAllConversation(params, token) {
      return httpGetPromise(`${API_URL}/v1/message/get-message`, params, token);
    }
  },
  metaWhatsAppApi: {
    getAllBusinessRegistered() {
      return httpGetPromise(`${process.env.REACT_APP_META_API_WHATSAPP}/${process.env.REACT_APP_META_CLOUD_BUSINESS_ACCOUNT_ID}/phone_numbers`, {}, TOKEN_META_WHATSAPP)
    }
  }
};
export default api;
