import React from "react";
import { Modal } from "../Modal/index";
import {
  ContainerTitle,
  ContainerTitleText,
  TablaBody,
  TablaHeaderTextoButtons,
  Tabla,
  LinkCancelar,
  ButtonPagar,
} from "./styles";

export const ConfirmacionSiNoModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.handleCloseModal}>
      <ContainerTitle>
        <ContainerTitleText>{props.mensaje}</ContainerTitleText>
      </ContainerTitle>
      <Tabla>
        <TablaBody>
          <tr>
            <TablaHeaderTextoButtons>
              <LinkCancelar className="btn" onClick={props.handleCloseModal}>No</LinkCancelar>
            </TablaHeaderTextoButtons>
            <TablaHeaderTextoButtons>
              <ButtonPagar className="btn" onClick={props.onClickYes}>Sí</ButtonPagar>
            </TablaHeaderTextoButtons>
          </tr>
        </TablaBody>
      </Tabla>
    </Modal>
  );
};
