import { db } from "../../services/firebase/Config";
import firebase from "firebase/app";
import {
  ABONO_CUENTA,
  SET_CUENTA,
  NEW_CUENTA,
  DEVOLUCION_CUENTA,
} from "./cuentaTypes";

import { httpRequest, httpSuccess, httpFailure } from "../sesion/sesionActions";

export const setCuenta = (cuenta) => {
  return {
    type: SET_CUENTA,
    payload: cuenta,
  };
};
export const newCuenta = () => {
  return {
    type: NEW_CUENTA,
  };
};

const abonoCuenta = (monto) => {
  return {
    type: ABONO_CUENTA,
    payload: monto,
  };
};

const devolucionCuenta = (monto) => {
  return {
    type: DEVOLUCION_CUENTA,
    payload: monto,
  };
};

export const abonarCuenta = (cuenta, monto) => {
  return function (dispatch) {
    dispatch(httpRequest());
    db.collection("cuentas")
      .doc(cuenta.id)
      .update({ saldo: monto })
      .then(() => {
        dispatch(httpSuccess());
        cuenta.saldo = monto;
        dispatch(setCuenta(cuenta));
      })
      .catch((error) => {
        dispatch(httpFailure(error));
      });
  };
};

export const crearCuenta = (paciente) => {
  return function (dispatch) {
    dispatch(httpRequest());
    let cuentaRef = db.collection("cuentas").doc();
    let cuenta = {
      adeudado: 0,
      id: cuentaRef.id,
      pacienteId: paciente.id,
      saldo: 0,
      empresaId: paciente.empresaId,
      paciente: {...paciente, patologias:[]}
    };
    cuentaRef
      .set(cuenta)
      .then(() => {
        dispatch(httpSuccess());
        dispatch(setCuenta(cuenta));
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const abonarCuentaTrx = (abono) => {
  return function (dispatch) {
    dispatch(httpRequest());
    let nuevoSaldo = 0;
    let batch = db.batch();
    let documentRef = db.collection("cuentas").doc(abono.cuentaId);
    let abonoRef = db.collection("movimientos").doc();
    batch.update(documentRef, {
      saldo: firebase.firestore.FieldValue.increment(Number(abono.monto)),
    });
    batch.set(abonoRef, abono);
    batch
      .commit()
      .then(() => {
        dispatch(httpSuccess());
        dispatch(abonoCuenta(Number(abono.monto)));
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const devolverCuentaTrx = (devolucion) => {
  return function (dispatch) {
    dispatch(httpRequest());
    let batch = db.batch();
    let documentRef = db.collection("cuentas").doc(devolucion.cuentaId);
    let movimientosRef = db.collection("movimientos").doc();
    batch.update(documentRef, {
      saldo: firebase.firestore.FieldValue.increment(-Number(devolucion.monto)),
    });
    batch.set(movimientosRef, devolucion);
    batch
      .commit()
      .then(() => {
        dispatch(httpSuccess());
        dispatch(devolucionCuenta(Number(devolucion.monto)));
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const getCuenta = (pacienteId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    let cuenta = {};
    db.collection("cuentas")
      .where("pacienteId", "==", pacienteId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          cuenta = doc.data();
          cuenta.id = doc.id;
        });
        dispatch(httpSuccess(cuenta));
        dispatch(setCuenta(cuenta));
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};
