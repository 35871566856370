import React, { useState, useContext, useEffect } from "react";
import { auth, db } from "../../services/firebase/Config";
import firebase from "firebase/app";
import { getUrlFile } from "../../services/firebase/storage";

import {
  guardaEmpresaUsuario,
  setEmpresa,
} from "../../redux/empresa/empresaActions";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import NuevoLogo from "../../img/slider/nuevoLogo.png";
import EjemploSlider from "../../img/slider/ejemploSlider.png";
import EjemploSlider2 from "../../img/slider/ejemploSlider2.png";
import { fetchUser } from "../../services/firebase/usuarios";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import {
  LoginViewWrapper,
  FooterWrapper,
  LoginView,
  SectionForm,
  ImgLogo,
  Btn,
  BtnSiguiente,
  SpanContainer,
  ContainerLeft,
  StyledSelect,
  StyledButtonCancelar,
  StyledInput,
} from "./styles";
import Carousel from "react-bootstrap/Carousel";
import DataEnterprise from "./DataEnterprise";
import SelectAccount from "./SelectAccount";
import SignUp from "./SignUp";
import { useLocalStorage } from "../../hooks/useLocalStorage";

export const Register = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const [, setUsuario] = useLocalStorage('usuario', {})
  const FormTitles = ["Crea tu cuenta gratis", "Datos personales", "Datos de la empresa"]
  const [urlFile, setUrlFile] = useState("")
  const [checked, setChecked] = useState(false)
  const [dataForm, setDataForm] = useState(
    {
      razonSocial: "",
      ruc: "",
      telefono: "",
      ubicacion: "",
      googleCalendar: "",
      nombres: "",
      email: "",
      uid: "",
      especialidad: "",
      plan: ""
    }

  )
  useEffect(() => { 
    const getUrl = async () => {
      const urlFile = await getUrlFile('sysdent/politica_de_privacidad.pdf')    
      console.log('urlFile:', urlFile)
      setUrlFile(urlFile)
    }
    getUrl()

  },[])
  const [step, setStep] = useState(0);
  const verificaUsuario = async (login) => {
    let usuario = await fetchUser(login.user.email);
    if (!usuario.id) {
      setDataForm({
        ...dataForm,
        nombres: login.user.displayName,
        email: login.user.email,
        uid: login.user.uid,
        especialidad: "",
      });
      setStep(1)

      //dispatch(setUsuarioSesion(usuario));
      //history.push("/paciente");
    } else
      addToast("Tu cuenta ya existe, ingresa desde Login", {
        appearance: "error",
      });
  };

  const handleLoginWithGoogle = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();

      const login = await auth.signInWithPopup(provider);
      const accessToken = login.credential.accessToken;

      // Verifica el usuario
      verificaUsuario(login, accessToken);

      console.log('accessToken:', accessToken);

      await auth.getRedirectResult();
    } catch (err) {
      console.error("Error en el manejo de inicio de sesión con Google:", err);
      auth.signOut();
    }
  };





  const onSubmitLastStep = (e) => {
    //dispatch(setEmpresa(empresa));
    try {
      e.preventDefault();
      if (dataForm.especialidad === "") {
        throw new Error("Ingrese especialidad")
      }
      else if (dataForm.razonSocial === "") {
        throw new Error("Ingrese Razon Social")
      }
      else if (dataForm.ruc === "") {
        throw new Error("Ingrese el RUC")
      } else if (dataForm.telefono === "") {
        throw new Error("Ingrese telefono")
      } else if (dataForm.ubicacion === "") {
        throw new Error("Ingrese ubicacion")
      }
      let usuario = {
        nombres: dataForm.nombres,
        email: dataForm.email,
        especialidad: dataForm.especialidad,
        uid: dataForm.uid
      }
      let empresa = {
        razonSocial: dataForm.razonSocial,
        ruc: dataForm.ruc,
        telefono: dataForm.telefono,
        ubicacion: dataForm.ubicacion,
        googleCalendar: dataForm.email,
        tipoMembresia: dataForm.plan,
        owner: usuario.email
      }
      console.log("dataform plan", dataForm.plan)

      dispatch(guardaEmpresaUsuario(empresa, usuario));
      setUsuario(usuario)
      history.push("/admin/dashboard/bienvenida");
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

const handleCheckChange = (e) => {
  setChecked(e.target.checked)
}


  const StepForm = () => {
    switch (step) {
      case 0:
        //<a href={urlFile} target="_blank" rel="noopener noreferrer">Acepto la Política de Privacidad</a>
        //<input type="checkbox">  </input>
        return <div>
         
         <input type="checkbox" id="politica" name="politica" value="politica" onChange={(e) => handleCheckChange(e)}
            checked={checked}/>
         <label for="politica"><a href={urlFile} target="blank">Acepto la Politica de Privacidad de Datos Personales</a></label>
        {checked&&<SelectAccount loginWithGoogle={handleLoginWithGoogle} />}
         </div>;
      case 1:
        return <SignUp dataForm={dataForm} setDataForm={setDataForm} />;
      case 2:
        return <DataEnterprise dataForm={dataForm} setDataForm={setDataForm} />;

      default:
        return null;
    }
  };

  if (isLoading) return <CircularProgress color="secondary" />;
  return (
    <LoginViewWrapper>
      <LoginView>

        <SectionForm>

          <div className="form__container">
            <div className="img__container">
              <ImgLogo src={NuevoLogo} alt="Sysdent logo" />
              <p>{FormTitles[step]}</p>
            </div>
            <form onSubmit={(e) => onSubmitLastStep(e)}>
              {StepForm()}
            </form>
            <ContainerLeft>
              <StyledButtonCancelar
                disabled={step === 0}

                style={{ display: step === 0 ? 'none' : 'block' }}
                onClick={() => setStep((currentData) => currentData - 1)}>
                Atras
              </StyledButtonCancelar>
              <BtnSiguiente

                type={step === 2 ? 'submit' : 'button'}
                style={{ display: step === 0 ? 'none' : 'block' }}
                onClick={(e) => {
                  if (step === 2) {
                    onSubmitLastStep(e)
                  } else {

                    setStep((currentData) => currentData + 1)
                  }
                }

                }>{step === 2 ? "Submit" : "Siguiente"}</BtnSiguiente>
            </ContainerLeft>
          </div>

        </SectionForm>
        <FooterWrapper>
          <Carousel variant="dark" className="carouselHeigth">
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={EjemploSlider}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={EjemploSlider2}
                alt="Second slide"
              />
            </Carousel.Item>
          </Carousel>
        </FooterWrapper>
      </LoginView>
    </LoginViewWrapper>
  );
};
