import React from "react";

import { MainContainer, ContainerIFrame, ContainerBody } from "./styles";
import Spinner from "react-bootstrap/Spinner";
import {
    fetchDeudores
} from "../../../services/firebase/cuentas/index";
import { useSelector } from "react-redux";
import {
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  TablaBodyTexto,
  ContainerTitle,
  ContainerTitleText,
  FlexContainer,
  StyledInput,
  StyledButton,
  ContainerBtn,
} from "./styles";
import { getPacientesByIds } from "../../../services/firebase/pacientes";
const getFecha = (fecha) => {
  const a = fecha.getFullYear();
  const m = (fecha.getMonth() + 1).toString().padStart(2, "0");
  const d = fecha.getDate().toString().padStart(2, "0");
  return `${a}-${m}-${d}`;
};

export const DeudoresRpt = () => {
const empresa = useSelector((state) => state.empresa);
const pacientes = useSelector((state) => state.pacientes);
const [estado, setEstado] = React.useState({
    loading: false,
    data: [],
    err: "",
  });
  const getFecha = (fecha) => {
    const a = fecha.getFullYear();
    const m = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const d = fecha.getDate().toString().padStart(2, "0");
    return `${a}-${m}-${d}`;
  };

  const [fecIni, setFecIni] = React.useState(getFecha(new Date()));
  const [fecFin, setFecFin] = React.useState(getFecha(new Date()));
  /*
   *
   * Se resta menos uno, debido a que el proceso ese ejecuta en la madrugada,
   * con el fin de poder cortar el dia anterior al 01 de cada mes.
   */



  const handleClick = async () => {
    let fecIni1 = new Date(fecIni + "T00:00");
    let fecFin1 = new Date(fecFin + "T00:00");

    fecFin1.setHours(23);
    fecFin1.setMinutes(59);

    try {
      setEstado({ ...estado, loading: true });
      let data = await fetchDeudores(empresa.id);
      setEstado({ ...estado, data: data, loading: false });
      } 
          catch (err) {
      setEstado({ ...estado, err: err.message, loading: false });
    }
  };

  if (estado.loading)
    return (
      <MainContainer>
        <Spinner animation="border" role="status">
          <span className="visually-hidden"></span>
        </Spinner>
      </MainContainer>
    );
  if (estado.err) return <div>{estado.err}</div>;
  return (
    <>
      <MainContainer>
        <ContainerTitle>
          <ContainerTitleText>Deudores</ContainerTitleText>
        </ContainerTitle>
        <ContainerBody>
          <ContainerBtn>
            <StyledButton onClick={handleClick}>Reporte</StyledButton>
          </ContainerBtn>


          <div className="py-2">
            {
              <Tabla>
                <TablaHeader>
                  <tr>
                    <TablaHeaderTexto scope="col">Paciente</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Deuda</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Abono</TablaHeaderTexto>
                    <TablaHeaderTexto scope="col">Telefono</TablaHeaderTexto>
                  </tr>
                </TablaHeader>
                <TablaBody>
                  {estado.data
                    .map((item) => (
                      <tr key={item}>
                        <TablaBodyTexto data-label="Paciente">
                          {`${item.paciente?.nombres} ${item.paciente?.apellidos}`}
                        </TablaBodyTexto>
                        <TablaBodyTexto data-label="Deuda">
                          {item.adeudado}
                        </TablaBodyTexto>
                        <TablaBodyTexto data-label="Abono">
                          {item.abonado}
                        </TablaBodyTexto>
                        <TablaBodyTexto data-label="Telefono">
                          {item.paciente?.telefono}
                        </TablaBodyTexto>
                      </tr>
                    ))}
                  </TablaBody>
              </Tabla>
            }
          </div>
        </ContainerBody>
        {/* <IFrame src={url} frameborder="0" scrolling="no"></IFrame> */}
      </MainContainer>
    </>
  );
};
