import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ContainerBody,
  ContainerTitle,
  ContainerTitleText,
  MainContainer,
  DivForm,
  StyledButton,
  ContainerButton,
  ContaineRight,
  StyledSelect,
  ContainerForm,
  DivInputs,
  StyledInputHeader,
  StyledInput,
  DivTotal,
  StyledLabel,
  StyledLabelHours,
  StyledInputNumber,
  StyledInputNumbers,
} from "./styles";
import Spinner from 'react-bootstrap/Spinner';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { useToasts } from "react-toast-notifications";
import {
  setGastosFijos,
  guardaGastosFijos,
} from "../../redux/gastosFijos/gastosFijosActions";
export const GastosFijos = () => {
  const item = { rubro: "", concepto: "", valor: "" };
  const [rubroId, setRubroId] = React.useState("");
  const rubros = ["SUELDOS", "LOCAL", "FONDO", "APOYO", "OTROS"];
  const sesion = useSelector((state) => state.sesion);
  const empresa = useSelector((state) => state.empresa);
  const gastosFijos = useSelector((state) => state.gastosFijos);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const getCostoMinuto = ()=>{
    const minutosTrabajados = (gastosFijos.horas * 60 * gastosFijos.dias)
    const costoMinuto = minutosTrabajados>0? (Math.round(getTotalGastos() / minutosTrabajados*100)/100):undefined; 
    return costoMinuto;
  }

  function getTotalGastos(){
   const total= gastosFijos.items.length > 0
                ? gastosFijos.items
                  .map((item) => item.valor)
                  .reduce((acc, valor) => Number(acc) + Number(valor), 0)
                : 0
  return total;
              }

  const handleAddItem = (e, rubro) => {
    e.preventDefault();
    if (!rubroId) {
      addToast("Seleccione un rubro", { appearance: "error" });
      return;
    }
    dispatch(
      setGastosFijos({
        ...gastosFijos,
        items: [...gastosFijos.items, { ...item, rubro: rubro }],
      })
    );
  };

  const handleDeleteItem = (index) => {
    let valores = gastosFijos.items;
    valores.splice(index, 1);
    dispatch(setGastosFijos({ ...gastosFijos, items: valores }));
  };

  const handleChangeItems = (index, e) => {
    let valores = gastosFijos.items;
    valores[index][e.target.name] = e.target.value;
    dispatch(setGastosFijos({ ...gastosFijos, items: valores }));
  };

  const handleChangeProperty = (e) => {
    let valores = { ...gastosFijos };
    valores[e.target.name] = e.target.value;
    dispatch(setGastosFijos(valores));
  };

  const handleSave = (e) => {
    e.preventDefault();
   
    gastosFijos.empresaId = empresa.id;
    gastosFijos.fecha = new Date();
    gastosFijos.usuario = sesion.usuario.email;
    gastosFijos.costoMinuto = getCostoMinuto();
    gastosFijos.total =getTotalGastos();
    try {
      dispatch(guardaGastosFijos(gastosFijos));
      addToast("Se guardaron los cambios satisfactoriamente", {
        appearance: "success",
      });
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  if (sesion.loading || sesion.loading === undefined)
    return <MainContainer><Spinner animation="border" role="status">
      <span className="visually-hidden"></span>
    </Spinner></MainContainer>;
  if (sesion.error) return <div>{`ocurrio un error ${sesion.error} `}</div>;
  return (
    <MainContainer>
      <ContainerTitle>
        <ContainerTitleText>Gastos Fijos</ContainerTitleText>
      </ContainerTitle>
      <ContainerBody>
        <form>
          <DivInputs>
            <StyledLabel for="dias">
              Dias trabajados:
              <StyledInputNumbers
                id="dias"
                name="dias"
                placeholder="dias trabajados"
                type="text"
                autoComplete="off"
                value={gastosFijos.dias}
                onChange={handleChangeProperty}
              ></StyledInputNumbers >
            </StyledLabel >
            <StyledLabel for="costoMin">
              Costo Minuto:
              <StyledInputNumbers
                id="costoMin"
                name="costoMin"
                placeholder="costo Minuto"
                type="text"
                autoComplete="off"
                value={getCostoMinuto()}
                readOnly
                onChange={handleChangeProperty}
              ></StyledInputNumbers >
            </StyledLabel >
            <StyledLabelHours for="horas">
              Horas trabajadas:
              <StyledInputNumbers
                id="horas"
                name="horas"
                placeholder="horas trabajadas"
                type="text"
                autoComplete="off"
                value={gastosFijos.horas}
                onChange={handleChangeProperty}
              ></StyledInputNumbers >
            </StyledLabelHours >
            
          </DivInputs>
          <ContainerButton>
            <div className="containerRubro">
              <StyledSelect
                value={rubroId}
                onChange={(e) => setRubroId(e.target.value)}
                required
              >
                <option hidden selected value="">
                  Seleccione Rubro
                </option>
                {rubros.map((rubro) => (
                  <option value={rubro}>{rubro}</option>
                ))}
              </StyledSelect>

                <AddCircleIcon fontSize="large"  onClick={(e) => handleAddItem(e, rubroId)}/>
                         </div>
          </ContainerButton>
          <ContainerForm>
            {gastosFijos.items.map((item, index) => (
              <DivInputs>
                <StyledInputHeader
                  name="rubro"
                  placeholder="rubro"
                  type="text"
                  autoComplete="off"
                  value={item.rubro}
                  readOnly
                ></StyledInputHeader>
                <StyledInput
                  name="concepto"
                  placeholder="concepto"
                  type="text"
                  autoComplete="off"
                  value={item.concepto}
                  onChange={(e) => handleChangeItems(index, e)}
                ></StyledInput>
                <StyledInputNumber
                  name="valor"
                  placeholder="valor"
                  type="text"
                  autoComplete="off"
                  value={item.valor}
                  onChange={(e) => handleChangeItems(index, e)} className="inputNumber"
                ></StyledInputNumber>
                <span onClick={() => handleDeleteItem(index)} style={{ width: "10%" }}><CancelIcon style={{ cursor: "pointer", color: "red" }} /></span>
              </DivInputs>
            ))}
          </ContainerForm>
          <DivTotal>
            <span className="spanTotal">Total <span className="spanTotalPrecio">S/ </span></span>
            <span className="spanTotalPrecio">
              {getTotalGastos()}
            </span>
          </DivTotal>
          <ContaineRight>
            <StyledButton onClick={handleSave}>Guardar</StyledButton>
          </ContaineRight>
        </form>
      </ContainerBody>
    </MainContainer>
  );
};
