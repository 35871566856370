import styled from "styled-components";
import { dispositivo } from "../Dispositivos";
import { NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import Nav from 'react-bootstrap/Nav'
import { NavDropdown } from "react-bootstrap";

export const Divheader = styled.div`
  margin:auto;
  width: 100%;
  
  .fa-hospital-alt:before,  .fa-x-ray:before,.fa-user:before, .fa-calendar-alt:before {
    grid-column: col / span 2;
    grid-row: row;
    display: grid;
    justify-content: end;
  }
  @media ${dispositivo.tablet} {
    display: none;
  }
  li:last-child > ul{ 
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate3d(135px, 83px, 0px);
    @media ${dispositivo.mobileS} {
      transform: translate3d(145px, 83px, 0px);
    }
    @media ${dispositivo.mobileM} {
      transform: translate3d(188px, 83px, 0px);
    }
    @media ${dispositivo.mobileL} {
      transform: translate3d(290px, 83px, 0px);
    }
    @media (min-width:670px) {
      transform: translate3d(425px, 83px, 0px);
    }
  }
 
`;
export const IconTitle = styled.i`
    display: grid;
    grid-gap: 4px;
    grid-template-columns: repeat(1, [col] auto );
    grid-template-rows: repeat(1, [row] auto  );
    i{
      grid-column: col 2 / span 2;
    grid-row: row;
    }
  span{
    font-size: 12px;
    grid-column: col / span 4;
  }
`;
export const UlMenu = styled.ul`
  list-style:none;
  display: flex;
  background: white;
  display: flex;
  margin-top: 0%;
  margin-bottom:0;
  gap: 1rem;
  padding: 5px 0;
  color: #045184;
  z-index:2;
  justify-content: center;
  
}

  li a , li p, li div{
    padding: 0 5px;
    line-height: 2.5;
    width: 100%;
    color: #045184;
    cursor: pointer;
    text-decoration:none;
    display:block;
  }
  
  li ul{    
    display:none;
    position:absolute;
    min-width:140px;
    z-index: 1000;
    float: left;
    min-width: 10rem;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;
  }
  li ul li ol {    
    display:none;
    position:relative;
    min-width:140px;
    z-index: 1000;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    text-align: left;
    list-style: none;
    background-color: #fff;
    a{
      box-shadow: 0 0 1px #3297a9;
      padding-left: 20px;
    }
  }
  li:hover > ul {
    display:block;
  }
  li:hover > ul.show-ul {
    display:block;
    
  }
  li:hover > ul.delete-ul{
    display: none;
  }

  li ul li {
    position:relative;
    
  }
  li:hover > ol {
    display:none
  }
  li:hover > ol{
    display:block
  }
  li ul li ol {
    right:0px;
    top:-9px;
  }

  .span-subtitle{
      padding-left: 5px;
      font-family: "Roboto",sans-serif;
      font-weight: 300;
  }

`;



