export const Prestacion = {
  id: "",
  especialidad: "",
  tratamiento: "",
  tipo: "",
  precio: 0,
  pagado: 0,
  dientes: [],
  caras: [],
  notasClinicas: [],
  fechaInicio: "",
  fechaFin: "",
  estado: "",
};

export class CPrestacion {
  constructor(
    id = "",
    pacienteId = "",
    especialidad = "",
    tratamiento = "",
    tipo = "",
    precio = 0,
    pagado = 0,
    dientes = [],
    caras = [],
    notasClinicas = [],
    fechaInicio = "",
    fechaFin = "",
    estado = Estado.PENDIENTE,
    estfin = EstFin.PENDIENTE,
    config = { afectaOdonto: false, color: "#000", grupo: "NONE" },
    lab = {},
    tratamientoId = ""
  ) {
    this.id = id;
    this.pacienteId = pacienteId;
    this.especialidad = especialidad;
    this.tratamiento = tratamiento;
    this.tipo = tipo;
    this.precio = precio;
    this.pagado = pagado;
    this.dientes = dientes;
    this.caras = caras;
    this.notasClinicas = notasClinicas;
    this.fechaInicio = fechaInicio;
    this.fechaFin = fechaFin;
    this.estado = estado;
    this.estfin = estfin;
    this.config = config;
    this.lab = lab;
    this.tratamientoId = tratamientoId;
  }
}

export const Estado = {
  PENDIENTE: "PENDIENTE",
  ACTIVO: "ACTIVO",
  TERMINADO: "TERMINADO",
};

export const EstFin = {
  PENDIENTE: "PENDIENTE",
  CANCELADO: "CANCELADO",
};

export const EstLab = {
  PENDIENTE: "PENDIENTE",
  ENVIADO: "ENVIADO",
  ENPRUEBAS: "ENPRUEBAS",
  TERMINADO: "TERMINADO",
};
