import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { auth } from "../../services/firebase/Config";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { useToasts } from "react-toast-notifications";
import NuevoLogo from "../../img/slider/nuevoLogo.png";
import ServicioOdontograma from "../../img/slider/servicioOdontograma.png";
import { LoginViewWrapper, FooterWrapper, LoginView, SectionForm, ImgLogo } from "./styles";
import Carousel from 'react-bootstrap/Carousel';

export const ResetPassword = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [{ email }, handleInputChange] = useForm({
    email: "",
  });
  const { addToast } = useToasts();

  const handleForgotPassword = (e) => {
    e.preventDefault();
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        history.push("/login");
      })
      .catch((err) => {
        addToast(err.message, { appearance: "warning" });
      });
  };

  const renderLoginForm = () => {
    return !isLoading ? (
      <div className="form__container">
        <div className="img__container">
          <ImgLogo src={NuevoLogo} alt="Sysdent logo" />
          <p>¿No puedes ingresar a tu cuenta?<br></br>
            Restablece tu contraseña</p>
        </div>
        <form onSubmit={handleForgotPassword}>
          <fieldset>
            <input
              className="form__input"
              placeholder="Ingrese email"
              name="email"
              onChange={handleInputChange}
            />
          </fieldset>

          <button className="form__buttonPrimary" type="submit">
            Restablecer
          </button>

          <Link to="/login" className="linkButton">
            <button className="form__buttonSecondary" type="submit">
              Cancelar
            </button>
          </Link>


        </form>
      </div>
    ) : (
      <>
        <CircularProgress color="secondary" />
      </>
    );
  };
  return (
    <LoginViewWrapper>
      <LoginView>
        <SectionForm>

          {renderLoginForm()}
        </SectionForm>
        <FooterWrapper>
          <Carousel variant="dark" className="carouselHeigth">
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={ServicioOdontograma}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={ServicioOdontograma}
                alt="Second slide"
              />
            </Carousel.Item>
          </Carousel>
        </FooterWrapper>

      </LoginView>
    </LoginViewWrapper>
  );
};
