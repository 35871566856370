import { SET_LISTAS } from "./listasTypes";
import { fetchListas } from "../../services/firebase/listasEmpresa/index";
import { httpRequest, httpSuccess, httpFailure } from "../sesion/sesionActions";

export const setListas = (listas) => {
  return {
    type: SET_LISTAS,
    payload: listas,
  };
};

export const getListas = (empresaId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    fetchListas(empresaId)
      .then((listas) => {
        dispatch(setListas(listas));
        dispatch(httpSuccess());
      })
      .catch((error) => httpFailure(error));
  };
};
