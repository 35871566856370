//@ts-check
import { db } from "../../services/firebase/Config";
import { SET_ACCESOS } from "./accesosTypes";

import {
  httpRequest,
  httpSuccess,
  httpFailure,
} from "../sesion/sesionActions";

import { fetchAccesosGeneral, fetchAccesosByEmpresa } from "../../services/firebase/accesos/index";

export const setAccesos = (accesos) => {
  return {
    type: SET_ACCESOS,
    payload: accesos,
  };
};

export const getAccesosByEmpresa = (perfil, empresaId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    fetchAccesosByEmpresa(perfil,empresaId)
      .then((accesos) => {
        dispatch(httpSuccess());
        dispatch(setAccesos(accesos));
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};

export const getAccesosGeneral = (perfil) => {
  return function (dispatch) {
    dispatch(httpRequest());
    fetchAccesosGeneral(perfil)
      .then((accesos) => {
        dispatch(httpSuccess());
        dispatch(setAccesos(accesos));
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};
