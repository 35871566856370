import styled from "styled-components";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { dispositivo } from "../../components/Dispositivos";

export const ContenedorPrincipal = styled.div`
  display: flex;
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  justify-content: space-between;
`;

export const ContenedorFormulario = styled.div`
  display: block;
  width: 100%;
  margin: 0 auto;
`;

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  background: #f8f8f8;
`;

export const ContainerTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  padding: 2.5%;
`;

export const ContainerTitleText = styled.h2`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  @media ${dispositivo.mobileL} {
    font-size: 16px;
  }
  @media ${dispositivo.tablet} {
    font-size: 20px;
  }
`;

export const DivForm = styled.form`
  margin: 1em;
`;

export const ContainerBody = styled.div`
  width: 98%;
  height: 100%;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: #fff;
  box-shadow: 2px 2px #88888877;
  border-radius: 14px;
  @media ${dispositivo.mobileL} {
    padding: 2%;
  }
  @media ${dispositivo.tablet} {
    padding: 3%;
  }
`;

export const ContainerLeft = styled.div`
  position: relative;
  width: 100%;
  text-align: right;
  margin: 1em 0;
  .buttons {
    display: flex;
    justify-content: center;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2%;
`;

export const StyledButton = styled(Button)`
  && {
    height: 2.25rem;
    font-size: 0.9rem;
    width: 7rem;
    border-radius: 0.8rem;
    text-transform: uppercase;
    cursor: pointer;
    font-width: bold;
    background-color: #0060b0;
    color: #ffffff;
    border: none;
    &:hover {
      color: #000000;
      background-color: #0c8de390;
      font-width: bold;
    }
    @media ${dispositivo.tablet} {
      width: 10rem;
      font-size: 1rem;
    }
    @media ${dispositivo.laptop} {
      width: 12.5rem;
    }
  }
`;

export const StyledLink = styled(Link)`
  && {
    height: 2.25rem;
    font-size: 0.9rem;
    width: 7rem;
    border-radius: 0.8rem;
    text-transform: uppercase;
    cursor: pointer;
    font-width: bold;
    background-color: #ffffff;
    border: 2px solid #0060b0;
    color: #0060b0;
    margin-right: 1rem;
    text-decoration: none;
    padding: 0.6em 1.41em;
    &:hover {
      height: 2.5rem;
      color: #000000;
      background-color: #0c8de390;
      font-width: bold;
      border: none;
      text-decoration: none;
    }
    @media ${dispositivo.tablet} {
      width: 10rem;
      font-size: 1rem;
      padding: 0.6em 3.41em;
    }
    @media ${dispositivo.laptop} {
      width: 12.5rem;
    }
  }
`;

export const StyledInputButton = styled.input`
  && {
    height: 2.25rem;
    font-size: 0.9rem;
    width: 7rem;
    border-radius: 0.8rem;
    text-transform: uppercase;
    cursor: pointer;
    font-width: bold;
    background-color: #0060b0;
    color: #ffffff;
    border: none;
    &:hover {
      color: #000000;
      background-color: #0c8de390;
      font-width: bold;
    }
    @media ${dispositivo.tablet} {
      width: 10rem;
      font-size: 1rem;
    }
    @media ${dispositivo.laptop} {
      width: 12.5rem;
    }
  }
`;

export const StyledInput = styled.input`
  width: 70%;
  height: 1.5rem;
  font-size: 10px;
  color: #040404;
  vertical-align: middle;
  text-align: center;
  margin: 3%;
  margin-left: 2%;
  border: 1px solid #0c8de3;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #6a6969;
  }
  @media ${dispositivo.tablet} {
    margin: 1%;
    width: 15%;
    font-size: 12px;
  }
`;

export const StyledInputIndex = styled(StyledInput)`
  @media ${dispositivo.tablet} {
    width: 5%;
  }
`;

export const ContainerForm = styled.div`
  width: 100%;
  height: 100%;
  padding: 1%;
  margin: 1.5% 0;
  border: 1px solid #0c8de3;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  justify-content: center;
  @media ${dispositivo.mobileL} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media ${dispositivo.tablet} {
    display: flex;
  }
`;
