import React from "react";
// import { useBuscarTratamientos } from "../../hooks/useBuscarTratamientos";
import { useBuscarTratamientos } from "../../hooks/useBuscarTratamientosDebounce";
import { AutocompleteTratamiento } from "../Autocomplete-Tratamiento/index";
import { useSelector, useDispatch } from "react-redux";
import { setPrestation } from "../../redux/prestation/prestationActions";
// import { fetchTratamientos } from "../../redux/tratamientos/tratamientosActions";
import Spinner from 'react-bootstrap/Spinner';

const BuscarTratamientos = (props) => {
  //const tratamientos = useSelector((state) => state.tratamientos);
  const sesion = useSelector((state) => state.sesion);
  const dispatch = useDispatch();
  const handleSelected = (item) => {
    dispatch(setPrestation(item));
    props.onSelected(item);
  };

  // React.useEffect(() => {
  //   if (tratamientos.length === 0)
  //     dispatch(fetchTratamientos(sesion.usuario.empresaId));
  // }, []);
  const toString = (item) => {
    return (
      <>
        <strong> {item.tratamiento}</strong>
        <strong> {item.tipo}</strong>
        <span className="spanPrecio">S/ {getFormatPrecio(item.precio)}</span>
      </>
    );
  };

  const getFormatPrecio = (precio) => {
    if (precio) return Number(precio).toFixed(2);
    else return 0;
  };
  if (sesion.loading || sesion.loading == undefined) return <Spinner animation="border" role="status">
    <span className="visually-hidden"></span>
  </Spinner>;
  else
    return (
      <AutocompleteTratamiento
        //items={tratamientos.filter((tr) => tr.estado)}
        useSearchItems={useBuscarTratamientos}
        onSelected={handleSelected}
        toString={toString}
        // grupo={props.grupo}
      ></AutocompleteTratamiento>
    );
};

export default BuscarTratamientos;
