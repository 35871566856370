import React, { useEffect, useState } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import accessosJSON from "../../data/accesos.json";
import accesosFalseJson from "../../data/accesosFalse.json";
import "./styles.css";
import {
    actualizarPerfilEnFirebase,
    crearPerfil,
    eliminarPerfil,
    obtenerDatosEmpresa,
    obtenerPerfilesCreados,
} from "../../services/firebase/perfiles";
import { useToasts } from "react-toast-notifications";


export const Perfiles = () => {
    const [usuario] = useLocalStorage("usuario");
    const empresaId = usuario.empresaId;
    const [membresia, setMembresia] = useState(null);
    const [nombrePerfil, setNombrePerfil] = useState("");
    const [accesos, setAccesos] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [perfilesCreados, setPerfilesCreados] = useState([]);
    const [modalEdit, setModalEditVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [perfilEditando, setPerfilEditando] = useState({});
    const [loading, setLoading] = useState(false);
    const [numeroPerfilesCreados, setNumeroPerfilesCreados] = useState(0);
    const [editProfileActiveTab, setEditProfileActiveTab] = useState(
        { id: "" }
    );
    const { addToast } = useToasts();


    const accesoFalse = accesosFalseJson[0].lateral;

    useEffect(() => {
        setAccesos(accessosJSON[0].lateral);
        setEditProfileActiveTab(accessosJSON[0].lateral[0]);
        const obtenerDatos = async () => {
            try {
                const empresaData = await obtenerDatosEmpresa(empresaId);

                if (empresaData.membresia === undefined) {
                    addToast("No se encontró la membresía", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    return;
                }

                setMembresia(empresaData.membresia);

                const perfilesData = await obtenerPerfilesCreados(empresaId);
                setNumeroPerfilesCreados(perfilesData.length);

                setPerfilesCreados(perfilesData);
            } catch (error) {
                console.error("Error al obtener datos de Firebase:", error);
            }
        };

        obtenerDatos();
    }, [empresaId]);

    if (perfilesCreados === null) {
        return <p>Cargando Perfiles...</p>;
    }

    if (membresia === null) {
        return <p>Cargando Membresia...</p>;
    }

    function handleEditProfileTabClick(item) {
        setEditProfileActiveTab(item);
    }

    function listaMenu(items) {
        return (
            <>
                <ul className="ul-profile-tabs-container">
                    {items.map((item) => (
                        <li
                            key={item.id}
                            className={`li-profile-tab ${item.id === editProfileActiveTab.id ? "li-profile-tab-active" : ""
                                }`}
                            onClick={() => handleEditProfileTabClick(item)}
                        >
                            {item.nombre}
                        </li>
                    ))}
                </ul>
                <ul className="ul-lista">
                    <li key={editProfileActiveTab.id} className="li-lista">
                        <div className="li-lista-titulo">
                            <p >Todos</p>
                            <label className="checkbox-label">
                                <input
                                    checked={editProfileActiveTab.activo}
                                    type="checkbox"
                                    onChange={(e) => handleItemChecked(e, editProfileActiveTab.id)}
                                />
                            </label>
                        </div>
                        {editProfileActiveTab.items && (
                            <ul className="hijo">
                                {editProfileActiveTab.items.map((item1) => (
                                    <li key={item1.id} className="li-lista">
                                        <p className="li-lista-subtittle">
                                            {item1.nombre}
                                            <label className="checkbox-label">
                                                <input
                                                    checked={item1.activo}
                                                    type="checkbox"
                                                    onChange={(e) => handleItemChecked(e, item1.id)}
                                                />
                                            </label>

                                        </p>

                                        {item1.items && (
                                            <ul className="nieto">
                                                {item1.items.map((item2) => (
                                                    <li key={item2.id} className="li-lista">
                                                        {item2.nombre}
                                                        <label className="checkbox-label">
                                                            <input
                                                                checked={item2.activo}
                                                                type="checkbox"
                                                                onChange={(e) =>
                                                                    handleItemChecked(e, item2.id)
                                                                }
                                                            />
                                                        </label>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>


                </ul>
            </>
        );
    }

    const handleItemChecked = (e, id) => {
        console.log("checked", id);
        const longitud = id?.length;
        const localAccesos = [...accesos];
        console.log("localAccesos", localAccesos);

        console.log("longitud", longitud, "id", id);
        if (longitud === 2) {
            const item = localAccesos.find((it) => it.id == id);
            console.log("item", item);
            item.activo = e.target.checked;
            if (item.items) {
                item.items = item.items.map((it) => {
                    if (it.items) {
                        it.items = it.items.map((ith) => ({
                            ...ith,
                            activo: e.target.checked,
                        }));
                    }
                    return { ...it, activo: e.target.checked };
                });
            }
        } else if (longitud === 4) {
            const idPadre = id.substring(0, 2);
            const idHijo = id.substring(0, 4);
            const itemPadre = localAccesos.find((it) => it.id == idPadre);
            console.log("itemPadre", itemPadre);
            const itemHijo = itemPadre.items.find((it) => it.id == idHijo);
            console.log("itemHijo", itemHijo);
            itemHijo.activo = e.target.checked;
            if (itemHijo.items) {
                itemHijo.items = itemHijo.items.map((it) => ({
                    ...it,
                    activo: e.target.checked,
                }));
                if (e.target.checked) {
                    itemPadre.activo = e.target.checked;
                }
            }
        } else if (longitud === 6) {
            const idPadre = id.substring(0, 2);
            const idHijo = id.substring(0, 4);
            const idNieto = id.substring(0, 6);
            const itemPadre = localAccesos.find((it) => it.id == idPadre);
            const itemHijo = itemPadre.items.find((it) => it.id == idHijo);
            const itemNieto = itemHijo.items.find((it) => it.id == idNieto);
            itemNieto.activo = e.target.checked;
            if (e.target.checked) {
                itemHijo.activo = e.target.checked;
                itemPadre.activo = e.target.checked;
            }
        }
        console.log("localAccesos", localAccesos);
        setAccesos(localAccesos);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            console.log("accesos GUARDAR ", accesos);

            const accesosSeleccionados = accesos.filter((acceso) => acceso.activo);
            console.log("accesosSeleccionados", accesosSeleccionados);

            await crearPerfil(usuario, nombrePerfil, accesosSeleccionados);

            addToast("Perfil creado correctamente", {
                appearance: "success",
                autoDismiss: true,
            });

            const perfilesData = await obtenerPerfilesCreados(empresaId);
            setPerfilesCreados(perfilesData);
            setModalVisible(false);
        } catch (error) {
            console.error("Error al crear el perfil:", error);
        }
    };

    const mostrarModal = () => {
        if (numeroPerfilesCreados < membresia.perfiles) {
        } else {
            console.log("Has alcanzado el límite de perfiles permitidos.");
        }
        setModalVisible(true);
    };

    const deshabilitarCrearPerfil = numeroPerfilesCreados >= membresia.usuarios;

    const cerrarModal = () => {
        setModalVisible(false);
    };

    const handleEditarPerfil = (perfil) => {
        setPerfilEditando(perfil);
        setNombrePerfil(perfil.perfil);
        setAccesos(
            accesoFalse.map((acceso) => {
                const accesoSeleccionado = perfil.lateral.find(
                    (accesoPerfil) => accesoPerfil.id === acceso.id
                );
                console.log("accesoSeleccionado", accesoSeleccionado);

                const subItems = acceso.items.map((item) => {
                    const itemSeleccionado = accesoSeleccionado?.items?.find(
                        (itemPerfil) => itemPerfil.id === item.id
                    );
                    console.log("itemSeleccionado", itemSeleccionado);
                    return {
                        ...item,
                        activo: itemSeleccionado?.activo || false,
                        items:
                            item.items?.map((subItem) => {
                                const subItemSeleccionado = itemSeleccionado?.items?.find(
                                    (subItemPerfil) => subItemPerfil.id === subItem.id
                                );
                                console.log("subItemSeleccionado", subItemSeleccionado);
                                return {
                                    ...subItem,
                                    activo: subItemSeleccionado?.activo || false,
                                };
                            }) || [],
                    };
                });

                return {
                    ...acceso,
                    activo: accesoSeleccionado?.activo || false,
                    items: subItems,
                };
            })
        );
        setEditMode(true);
        setEditProfileActiveTab(accesos[0]);
        setModalEditVisible(true);
    };

    const handleCancelarEdicion = () => {
        setEditMode(false);
        setModalEditVisible(false);
    };

    const handleGuardarEdicion = async (event) => {
        event.preventDefault();

        try {
            setLoading(true);

            const accesosSeleccionados = accesos.filter((acceso) => acceso.activo);

            await actualizarPerfilEnFirebase(
                perfilEditando.id,
                nombrePerfil,
                accesosSeleccionados
            );


            addToast("Perfil actualizado correctamente", {
                appearance: "success",
                autoDismiss: true,
            });

            const perfilesData = await obtenerPerfilesCreados(empresaId);
            setPerfilesCreados(perfilesData);


        } catch (error) {
            console.error("Error al guardar la edición del perfil:", error);
        } finally {
            setLoading(false);
        }

        setEditMode(false);
        setModalEditVisible(false);
    };


    return (
        <>
            <div className="container-perfiles">
                <div className="perfiles-creados-container">
                    <div>
                        <h2>PERFILES CREADOS</h2>
                    </div>
                    <div className="perfiles-map">
                        {perfilesCreados.map((perfil) => (
                            <>
                                <div key={perfil.id} className="perfil-creado">
                                    <p className="nombre-perfil">
                                        <span
                                            style={{
                                                color: "#1a1a1a",
                                                fontSize: "1.2rem",
                                                fontWeight: "bold",
                                                color: "#3498db",
                                            }}
                                        >
                                            {perfil.perfil}
                                        </span>
                                    </p>
                                    <div className="accesos-perfil">
                                        <strong>Accesos:</strong>
                                        <ul>
                                            {perfil.lateral.map((acceso) => (
                                                <li key={acceso.key}>{acceso.nombre}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="opciones-perfil">
                                        <button
                                            className="editar-perfil-button"
                                            onClick={() => handleEditarPerfil(perfil)}
                                        >
                                            Editar
                                        </button>

                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
                {/* ModalEdit*/}
                {modalEdit && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={handleCancelarEdicion}>
                                &times;
                            </span>
                            <span>{editMode ? "Editar Perfil" : "Crear Perfil"}</span>
                            <form
                                className="crear-perfil-form"
                                onSubmit={handleGuardarEdicion}
                            >
                                <input type="hidden" value={perfilEditando.id} />
                                <label className="crear-perfil-label">
                                    Nombre del Perfil:
                                    <input
                                        className="crear-perfil-input"
                                        type="text"
                                        value={nombrePerfil}
                                        readOnly
                                    />
                                </label>

                                <div className="opciones-acceso">{listaMenu(accesos)}</div>

                                <button
                                    className="crear-perfil-button"
                                    type="submit"
                                    disabled={loading}
                                >
                                    {loading ? "Guardando..." : "Guardar Edición"}
                                </button>
                            </form>
                        </div>
                    </div>
                )}
            </div>
            {modalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={cerrarModal}>
                            &times;
                        </span>
                        <span>Crear Perfil</span>
                        <form className="crear-perfil-form" onSubmit={handleSubmit}>
                            <label className="crear-perfil-label">
                                Nombre del Perfil:
                                <input
                                    className="crear-perfil-input"
                                    type="text"
                                    value={nombrePerfil}
                                    onChange={(e) => setNombrePerfil(e.target.value)}
                                    required
                                />
                            </label>

                            <div className="opciones-acceso">{listaMenu(accesos)}</div>
                            <button className="crear-perfil-button" type="submit">
                                Crear Perfil
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};
