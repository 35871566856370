import styled from "styled-components";
import { dispositivo } from "../../components/Dispositivos";
export const DivPuente = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0.875em;
  align-items: center;
  gap: 1rem;
`;

export const ContenedorDientes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.875em;  
  width: 100%;
`;

export const ContenedorDiente = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 30px;
  justify-content: flex-end;
  .label-puente{
    margin-bottom: 0;
  }
  @media ${dispositivo.tablet} {
    width: 35px;
  }
`;



