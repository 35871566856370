import {
  SET_ODONTOGRAMA,
  NEW_ODONTOGRAMA,
  SAVE_ODONTOGRAMA_REQUEST,
  SAVE_ODONTOGRAMA_SUCCESS,
  SAVE_ODONTOGRAMA_FAILURE,
  GET_ODONTOGRAMA_REQUEST,
  GET_ODONTOGRAMA_SUCCESS,
  GET_ODONTOGRAMA_FAILURE,
  UPDATE_DIENTE_EN_ODONTOGRAMA,
  ADD_DIENTE_ODONTOGRAMA,
} from "./odontogramaTypes";

const adulto = {
  id: "",
  pacienteId: "",
  tipo: "INICIAL",
  clase: "",
  detalleId: "",
  items: [],
};

export const odontogramaReducers = (state = adulto, action) => {
  switch (action.type) {
    case NEW_ODONTOGRAMA:
      return adulto;

    case UPDATE_DIENTE_EN_ODONTOGRAMA:
      const idx = state.items.findIndex(
        (item) => item.diente == action.payload.diente
      );
      let items = Object.assign([], state.items);
      if (idx > -1) {
        items[idx] = action.payload;
      } else {
        items.push(action.payload);
      }
      return {
        ...state,
        items: items,
      };
    case ADD_DIENTE_ODONTOGRAMA:
      return {
        ...state,
        items: [state.items, action.payload],
      };
    case SET_ODONTOGRAMA:
      return action.payload;
    case SAVE_ODONTOGRAMA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SAVE_ODONTOGRAMA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_ODONTOGRAMA_SUCCESS:
      return action.payload;

    case SAVE_ODONTOGRAMA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
