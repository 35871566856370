import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchPrestacionesTerminadas } from "../../services/firebase/prestaciones";
import { useSelector } from "react-redux";
import UpIcon from "@material-ui/icons/ArrowUpward";
import DownIcon from "@material-ui/icons/ArrowDownward";
import { TopBarPaciente } from "../../components/TopBarPaciente";
import Loader from "../../components/Loader";
import {
  MainContainer,
  ContainerTitle,
  ContainerTitleText,
  ContainerBody,
  StyledButton,
  Tabla,
  TablaHeader,
  TablaBody,
  Fila,
  TablaHeaderTexto,
  TablaBodyTextoDiente,
} from "./styles";
import Spinner from "react-bootstrap/Spinner";

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);
  const [clickedTratamiento, setClickedTratamiento] = useState(false);
  const [clickedEstado, setClickedEstado] = useState(false);
  const [clickedDiente, setClickedDiente] = useState(false);
  const handleIconClick = (id) => {
    if (id == "tratamiento") {
      setClickedTratamiento(!clickedTratamiento);
    }
    if (id == "diente") {
      setClickedDiente(!clickedDiente);
    }
    if (id == "estado") {
      setClickedEstado(!clickedEstado);
    }

    // change <AddCircleIcon /> to <BlockIcon /> at "id"
  };
  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    handleIconClick(key);
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return {
    items: sortedItems,
    requestSort,
    sortConfig,
    clickedTratamiento,
    clickedEstado,
    clickedDiente,
  };
};

export const HistoriaClinica = () => {
  const [estado, setEstado] = useState({ loading: true, error: "", data: [] });
  const paciente = useSelector((state) => state.paciente);
  const {
    items,
    requestSort,
    sortConfig,
    clickedTratamiento,
    clickedEstado,
    clickedDiente,
  } = useSortableData(estado.data);

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  useEffect(() => {
    traerPrestaciones();
  }, []);
  const traerPrestaciones = async () => {
    setEstado({ ...estado, loading: true });
    const prestaciones = await fetchPrestacionesTerminadas(paciente.id);
    setEstado({ ...estado, loading: false, data: prestaciones });
  };
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const toString = (fecha) => {
    let f = fecha;

    f = new Date(fecha);
    const a = f.getFullYear();
    const m = (f.getMonth() + 1).toString().padStart(2, "0");
    const d = f.getDate().toString().padStart(2, "0");
    return `${a}-${m}-${d}`;
  };
  if (estado.loading || estado.loading == undefined) {
    return (
      <MainContainer>
        <Spinner animation="border" role="status">
          <span className="visually-hidden"></span>
        </Spinner>
      </MainContainer>
    );
  } else if (estado.error)
    return <h1>{`se produjo un error ${estado.error}`}</h1>;
  else
    return (
      <React.Fragment>
        <TopBarPaciente />
        <MainContainer>
          <ContainerBody>
            <Tabla>
              <TablaHeader>
                <tr>
                  <TablaHeaderTexto scope="col" style={{ width: "20%", }}>
                    <StyledButton
                      onClick={() => requestSort("tratamiento")}
                      className={getClassNamesFor("tratamiento")}
                      size="medium"
                      startIcon={clickedTratamiento ? <UpIcon /> : <DownIcon />}
                    >
                      Prestacion
                    </StyledButton>
                  </TablaHeaderTexto>

                  <TablaHeaderTexto scope="col" style={{ width: "10%", }}>
                    <StyledButton
                      onClick={() => requestSort("dientes")}
                      className={getClassNamesFor("dientes")}
                      size="medium"
                      startIcon={clickedDiente ? <UpIcon /> : <DownIcon />}
                    >
                      Diente
                    </StyledButton>
                  </TablaHeaderTexto>
                  <TablaHeaderTexto scope="col" style={{ width: "10%", }}>
                    <StyledButton>Fecha</StyledButton>
                  </TablaHeaderTexto>
                  <TablaHeaderTexto scope="col" style={{ width: "60%", }}>
                    <StyledButton>Nota</StyledButton>
                  </TablaHeaderTexto>
                 
                </tr>
              </TablaHeader>
              <TablaBody>
                {items.map((prestacion, idx) =>
                  prestacion.notasClinicas.map((nota, index) => (

                    < Fila idx={idx} >
                      {index === 0 && (
                        <td rowspan={prestacion.notasClinicas.length} style={{ width: "20%", padding: "0 5px" }}>
                          {`${prestacion.tratamiento} ${prestacion.tipo}`}
                        </td>
                      )}

                      {index === 0 && (
                        <td rowspan={prestacion.notasClinicas.length} style={{ width: "20%", padding: "0 5px" }}>
                          <TablaBodyTextoDiente>
                            {prestacion.dientes.length > 0
                              ? prestacion.dientes.join()
                              : ""}
                          </TablaBodyTextoDiente>

                        </td>
                      )}
                      <td style={{ width: "15%", padding: "0 5px" }}>{`${toString(nota.fecha)} ${nota.especialista}`}</td>
                      <td style={
                        nota.nota.length >= 50 ? { textAlign: "justify", padding: "0 5px" } : { textAlign: "center", padding: "0 5px" }
                      }>{nota.nota}</td>
                      
                    </Fila>
                  ))
                )}
              </TablaBody>
            </Tabla>
          </ContainerBody>
        </MainContainer>
      </React.Fragment >
    );
};
