import {
  SAVE_PRESTATION_REQUEST,
  ADD_PRESTATION_SUCCESS,
  UPDATE_PRESTATION_SUCCESS,
  SAVE_PRESTATION_FAILURE,
  NEW_PRESTATION,
  SET_PRESTATION,
  INICIAR_PRESTATION_REQUEST,
  SET_CARAS,
  PAGAR_PRESTACION,
  REVERTIR_PAGO,
  ADD_NOTA_CLINICA,
  UPDATE_NOTA_CLINICA,
} from "./prestationTypes";
import { Prestacion, CPrestacion } from "../../Entidades/Prestaciones";

export const prestacionReducer = (state = new CPrestacion(), action) => {
  switch (action.type) {
    case NEW_PRESTATION:
      return new CPrestacion();
    case PAGAR_PRESTACION:
      return {
        ...state,
        pagado: Number(state.pagado) + Number(action.payload),
      };
    case REVERTIR_PAGO:
      return {
        ...state,
        pagado: Number(state.pagado) - Number(action.payload),
      };

    case ADD_NOTA_CLINICA:
      return {
        ...state,
        notasClinicas: [...state.notasClinicas, action.payload],
      };

    case SET_CARAS:
      return { ...state, caras: action.payload };
    case SET_PRESTATION:
      return action.payload;
    case UPDATE_PRESTATION_SUCCESS:
      return action.payload;

    case ADD_PRESTATION_SUCCESS:
      return { ...state, id: action.payload };

    default:
      return state;
  }
};
