import React from "react";
import { useHistory, Redirect } from "react-router-dom";
import { ConfirmacionSiNoModal } from "../../../../components/ConfirmacionSiNoModal/index";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "../../../../components/Modal/index";
import {
  revertPrestacionTrx,
  newPrestation,
} from "../../../../redux/prestation/prestationActions";
import {
  MainContainer,
  ContainerTitle,
  ContainerTitleText,
  ButtonAbonar,
  BodyContainer,
  TablaBody,
  TablaHeaderTexto,
  TablaHeaderTextoItems,
  TablaHeaderTextoValues,
  TablaHeaderTextoNumber,
  Tabla,
  StyledForm,
  StyledInput,
  LinkCancelar,
  ButtonPagar,
  Buttons,
} from "./styles";
import { useToasts } from "react-toast-notifications";

export const ReviertePagoModal = (props) => {
  const history = useHistory();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const prestacion = useSelector((state) => state.prestacion);
  const [montoPago, setMontoPago] = React.useState(prestacion.pagado);
  const cuenta = useSelector((state) => state.cuenta);
  const sesion = useSelector((state) => state.sesion);
  const paciente = useSelector((state) => state.paciente);
  const dispatch = useDispatch();
  const handleCloseModal = (e) => {
    setModalIsOpen(false);
  };
  const { addToast } = useToasts();

  const handleOpenModal = (e) => {
    e.preventDefault();
    const myForm = document.getElementById("pagoprestacion");
    // TODO: Insertar la transaccion de movimiento, cambiar el estado a pagado, actualizar el
    try {
      myForm.checkValidity();
      myForm.reportValidity();
      validar();
      if (myForm.checkValidity() && myForm.reportValidity()) {
        setModalIsOpen(true);
        //history.push("/prestaciones");
      }
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const handlePayment = (e) => {
    e.preventDefault();
    try {
      validar();
      const pago = {
        monto: Number(montoPago),
        moneda: "SOLES",
        fecha: new Date(),
        usuario: sesion.usuario.email,
        prestacionId: prestacion.id,
        prestacionNombre: `${prestacion.tratamiento} ${prestacion.tipo ? prestacion.tipo : ""
          }`,
        pacienteId: prestacion.pacienteId,
        pacienteNombres: `${paciente.apellidos} ${paciente.nombres}`,
        tipMov: "REVIERTE_PAGO",
        cuentaId: cuenta.id,
        empresaId: sesion.usuario.empresaId,
      };
      dispatch(revertPrestacionTrx(prestacion, cuenta, pago));
      setModalIsOpen(false);
      addToast("Reversión satisfactoria", { appearance: "success" });
      setMontoPago();
      dispatch(newPrestation());
      props.handleCloseModal();
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };
  const validar = () => {
    let numerosExp = /^\d+([.]\d{1,2})?$/;
    if (!numerosExp.test(montoPago)) {
      throw new Error("Ingrese un monto válido");
    }
    if (Number(montoPago) <= 0) throw new Error("Monto debe ser mayor a cero");
    if (Number(prestacion.pagado) - Number(montoPago) < 0)
      throw new Error("Monto a revertir es mayor a lo pagado");
  };
  const handleChange = (e) => {
    setMontoPago(e.target.value);
  };

  const { tratamiento, especialista, precio, pagado } = prestacion;

  if (sesion.loading || sesion.loading == undefined)
    return <div>...procesando</div>;
  else if (sesion.error) return <div>{sesion.error}</div>;
  else
    return (
      <Modal isOpen={props.isOpen} onClose={props.handleCloseModal}>
        <MainContainer>
          <ContainerTitle>
            <ContainerTitleText>Revierte Pago</ContainerTitleText>
          </ContainerTitle>
          <BodyContainer>
            <StyledForm id="pagoprestacion" onSubmit={handlePayment}>
              <Tabla>
                <TablaBody>
                  <tr>
                    <TablaHeaderTextoItems>
                      <span>Tratamiento: </span>
                    </TablaHeaderTextoItems>
                    <TablaHeaderTextoValues>
                      <span>{tratamiento}</span>
                    </TablaHeaderTextoValues>
                  </tr>

                  <tr>
                    <TablaHeaderTextoItems>
                      <span>Precio: </span>
                    </TablaHeaderTextoItems>
                    <TablaHeaderTextoNumber>
                      <span>{`S/ ${precio}`}</span>
                    </TablaHeaderTextoNumber>
                  </tr>

                  <tr>
                    <TablaHeaderTextoItems>
                      <span>Pagado: </span>
                    </TablaHeaderTextoItems>
                    <TablaHeaderTextoNumber>
                      <span>{`S/ ${pagado}`}</span>
                    </TablaHeaderTextoNumber>
                  </tr>

                  <tr>
                    <TablaHeaderTextoItems>
                      <span>Saldo: </span>
                    </TablaHeaderTextoItems>
                    <TablaHeaderTextoNumber>
                      <span className="color-saldo">{`S/ ${Math.round((precio - pagado) * 100) / 100
                        }`}</span>
                    </TablaHeaderTextoNumber>
                  </tr>
                  <tr>
                    <TablaHeaderTextoItems>
                      <span>Moneda: </span>
                    </TablaHeaderTextoItems>
                    <TablaHeaderTexto>
                      <span>SOLES</span>
                    </TablaHeaderTexto>
                  </tr>

                  <tr>
                    <TablaHeaderTextoItems>
                      <span>Monto a revertir: </span>
                    </TablaHeaderTextoItems>
                    <TablaHeaderTexto>
                      <StyledInput
                        type="text"
                        required
                        value={montoPago}
                        onChange={handleChange}
                      ></StyledInput>
                    </TablaHeaderTexto>
                  </tr>
                </TablaBody>
              </Tabla>
            </StyledForm>
          </BodyContainer>
          <Buttons>
            <LinkCancelar onClick={props.handleCloseModal}>
              Cancelar
            </LinkCancelar>

            <ButtonPagar onClick={handleOpenModal}>Revertir</ButtonPagar>
          </Buttons>
          <ConfirmacionSiNoModal
            mensaje="¿Esta seguro de realizar esta operacion?"
            isOpen={modalIsOpen}
            handleCloseModal={handleCloseModal}
            onClickYes={handlePayment}
          />
        </MainContainer>
      </Modal>
    );
};
