import React from "react"
import  Button  from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

export function TokenPac({token, onGetToken}){
   
    const paciente = useSelector((state) => state.paciente);
    
    function copyToClipboard(){
        navigator.clipboard.writeText(token).then(()=>{
            alert("Copiado")
        },(err)=>{
            console.log('error',err)
        })
    }
    function whatsappButton(){
        return (
            <>
                <a
                  href={`https://wa.me/51${paciente.telefono}?text=${token}`}
                  target="_blank"
                  className="show-on-hover__cta"
                  >
              <WhatsAppIcon
                className="response-button__icon"
                style={{
                  background: "#50CE56",
                  height: "100%"
                }}
              />
                  
                </a>
          </>
        )
    }

    return (<>

    <InputGroup className="mb-3">
        {!token&&<Button variant="outline-secondary" onClick={onGetToken}>Invitar</Button>}
        {token&&<Button variant="outline-secondary" onClick={copyToClipboard}>Copiar</Button>}
        <Form.Control aria-label="token" readOnly value={token}/>
        {token&&whatsappButton()}    
      </InputGroup>

        
      </>
    )
}