import styled from "styled-components";
import { Link } from "react-router-dom";
import { dispositivo } from "../../components/Dispositivos";

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 5%;
  padding-right: 5%;
  background: #f8f8f8;
  justify-content: center;
  text-align: center;
`;

export const ContainerTitle = styled.div`
  font-size: 1rem;
  font-weight: normal;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;


export const ContainerTitleText = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;

export const ContainerBtn = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  justify-content: end;
  .linkP{
    padding-top: 0.8em;
  }
`;

export const ButtonAbonar = styled.button`
  height: 2.25rem;
  border-radius: 1em;      
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  font-size: 0.9rem;
  background-color: #0060b0;
  color: #ffffff;
  font-size: 0.9rem;
  border: none;
  &:hover{
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.mobileS}{
    font-size: 0.9rem;
    padding: 0.1em;
    width: 9rem;
  } 
  @media ${dispositivo.tablet}{
    width: 10rem;
  }  
  @media ${dispositivo.laptop} {      
    width: 12.5rem;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 2%;
  justify-content: center;
  text-align: center;
`;

export const StyledForm = styled.form`
width: 100%;
height: 100%;
padding: 2%;
background: #fff;
display: block;
justify-content: center;
border-radius: 10px;
border: 2px solid #0c8de3;
margin-bottom: 1rem;
`;

export const Tabla = styled.table`
  width: 100%;
  border-radius: 12px;
  justify-content: center;
  text-align: center;
`;

export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 12px;
  justify-content: center;
  text-align: center;
`;

export const TablaHeaderTexto = styled.th`
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;


export const TablaBody = styled.tbody`
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 12px;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
`;

export const TablaBodyTexto = styled.th`
  font-size: 0.688rem;
  color:  #045184;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;

export const TrBody = styled.tr`
  display: flex;
  justify-content: space-between;
`;

export const SelectStyle = styled.select`
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  line-height: 2.5;
  font-size: 0.75rem;
  height: 2.5rem;
  width: 6rem;
  text-align: end;
  &::placeholder {
  color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.mobileL} {
    width: 8rem;
  }
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }

  @media ${dispositivo.tablet} {
    font-size: 1rem;
    width: 12.5rem;
  }
`;

export const StyledInput = styled.input`
  height: 2.5rem;
  border-radius: 10px;
  background-color: #64bdff30;
  border: none;
  outline: none;
  color: #0D0808;
  padding-left: 1em;
  line-height: 2.5;
  font-size: 0.75rem;
  width: 6rem;
  &::placeholder {
    color: #04000060;
    fonst-size: 0.5rem;
  }
  &:focus{
    border: 2px solid #0060b0;
  }
  @media ${dispositivo.mobileL} {
    width: 8rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
    width: 12.5rem;
  }
`;

export const LinkCancelar = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 100%;
  border-radius: 1em;      
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  font-size: 0.9rem;
  background-color: #ffffff;
  border: 2px solid #0060b0;
  color: #0060b0;
  margin-right: 1rem;
}
  &:hover {
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
    border: none;
  }  

  @media ${dispositivo.mobileS}{
    font-size: 0.9rem;
    width: 9rem;
  } 
  @media ${dispositivo.tablet}{
    width: 10rem;
  }  
  @media ${dispositivo.laptop} {      
    width: 12.5rem;
  }
`;

export const StyledLabel = styled.label`
  vertical-align: middle;
  padding: 0px;
  margin: 0px;
  font-weight: bold;
  text-align: left;
  color: #040404;
`;

export const StyledOption = styled.option`
  color: #707070;
  font-size: 12px;
`;

export const StyledOptgroup = styled.optgroup`
  color: #707070;
  font-weight: bold;
  font-size: 12px;
`;

export const ContenedorTabla = styled.div`
  flex: 1;
  max-height: 370px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
`;
