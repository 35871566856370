import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setPrestation } from "../../redux/prestation/prestationActions";
import { getLabs } from "../../redux/labs/labsActions";
import { Link } from "react-router-dom";
import { getOrdenes } from "../../redux/ordenes/ordenesActions";
import { getDeuda } from "../../services/firebase/ordenes";
import Spinner from 'react-bootstrap/Spinner';
import {
  MainContainer,
  ContainerTitle,
  ContainerTitleText,
  ContainerBody,
  ContainerLeft,
  ButtonAddPay,
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  ContenedorTabla,
  TablaBodyTexto,
} from "./styles";

const Fila = ({ item, empresaId }) => {
  const [estado, setEstado] = React.useState({
    loading: true,
    data: 0,
    error: "",
  });
  React.useEffect(() => {
    getDeuda(item.id, empresaId).then((deuda) =>
      setEstado({ ...estado, loading: false, data: deuda })
    );
  }, []);
  if (estado.loading) return <div>...calculando</div>;
  if (estado.error) return <div>...error</div>;
  return (
    <tr key={item.id}>
      <TablaBodyTexto data-label="Laboratorio">{item.razonSocial}</TablaBodyTexto>
      <TablaBodyTexto data-label="Deuda">{estado.data}</TablaBodyTexto>
      <TablaBodyTexto data-label="Pagar">
        <Link
          className="pagarLink"
          to={{
            pathname: "pagar",
            state: {
              data: {
                id: item.id,
                razonSocial: item.razonSocial,
                deuda: estado.data,
              },
            },
          }}
        >
          Pagar
        </Link>
      </TablaBodyTexto>
    </tr>
  );
};

export const LaboratoriosTable = (props) => {
  return (
    <div>
      <Tabla>
        <TablaHeader>
          <tr>
            <TablaHeaderTexto scope="col">Nombre</TablaHeaderTexto>
            <TablaHeaderTexto scope="col">Deuda</TablaHeaderTexto>
            <TablaHeaderTexto scope="col">Pagar</TablaHeaderTexto>
          </tr>
        </TablaHeader>
        <TablaBody>
          {props.items.map((item) => {
            return (
              <Fila key={item.id} item={item} empresaId={props.empresaId} />
            );
          })}
        </TablaBody>
      </Tabla>
    </div>
  );
};

export const DeudasLabs = () => {
  const labs = useSelector((state) => state.labs);
  const sesion = useSelector((state) => state.sesion);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getLabs());
  }, []);

  if (sesion.loading) return <MainContainer><Spinner animation="border" role="status">
    <span className="visually-hidden"></span>
  </Spinner></MainContainer>;
  if (sesion.error) return <div>{`Error en la carga ${sesion.error}`}</div>;
  return (
    <MainContainer>
      <ContainerTitle>
        <ContainerTitleText>Deudas Laboratorios:</ContainerTitleText>
      </ContainerTitle>

      <ContainerBody>
        <ContenedorTabla>
          <LaboratoriosTable
            items={labs}
            empresaId={sesion.usuario.empresaId}
          />
        </ContenedorTabla>
      </ContainerBody>
    </MainContainer>
  );
};
