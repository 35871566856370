import { db, firestorage } from "../Config";

export const getEmpresaById = (empresaId) => {
  let empresaRef = db.collection("empresas").doc(empresaId);
  return new Promise((resolve, reject) => {
    let empresa = {};
    empresaRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          empresa = doc.data();
          resolve(empresa);
        } else {
          reject("no encontro la empresa");
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const getEmpresas = () => {
  return new Promise((resolve, reject) => {
    let empresas = [];

    let empresasRef = db.collection("empresas");
    empresasRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          empresas.push(doc.data());
        });
        resolve(empresas);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const saveEmpresa = (emp) => {
  return new Promise((resolve, reject) => {
    const empRef = db.collection("empresas").doc(emp.id);

    empRef
      .set(emp)
      .then((docRef) => {
        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addEmpresa = (emp) => {
  return new Promise((resolve, reject) => {
    const empRef = db.collection("empresas").doc();
    emp.id = empRef.id;
    empRef
      .set(emp)
      .then(() => {
        resolve(emp);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const uploadImage = async (file) => {
  const storageRef = firestorage.ref();
  let pathFile = `logos/${file.name}`;
  const fileRef = storageRef.child(pathFile);
  await fileRef.put(file);
  const fileUrl = await fileRef.getDownloadURL();
  return fileUrl;
};
