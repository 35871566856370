import React from "react";
import { IncisivoNoSelect } from "../../../../components/Dientes/IncisivoNoSelect";
import Diente from "../../../../components/Diente";
import {
  Denticion,
} from "../../../../components/ConfigDiente";
import { Modal } from "../../../../components/Modal/index";
import { useToasts } from "react-toast-notifications";
import { Puente } from "../../../../components/Puente";

import { desactivarPrestacionTrx } from "../../../../redux/prestation/prestationActions";
import {
  aplicarCaras,
  aplicarPrestacion,
  DefaultConfig,
} from "../../../../components/ConfigDiente";

import { useDispatch, useSelector } from "react-redux";
import {
  MainContainer,
  ContainerBody,
  ContainerTitle,
  ContainerTitleText,
  ContenedorCorona,
  Buttons,
  LinkCancelar,
  ButtonPagar,
  ContenedorDiente,
  ContenedorDientes,
} from "./styles";

const Caras = ({ itemsOdontograma, config, faces }) => {
  const itemOdonto = aplicarCaras(itemsOdontograma[0], config, faces);
  const clase = Denticion.dientes.find(
    (item) => item.diente === itemOdonto.diente
  )?.lado;
  return (
    <>
      <ContenedorDientes>
        <ContenedorDiente>
          <Diente numero={itemOdonto.diente} config={itemOdonto} />
        </ContenedorDiente>
      </ContenedorDientes>
      <ContenedorCorona>
        <div>
          <span className="span-font">Selección de caras:</span>
        </div>
        <div className={clase}>
          <IncisivoNoSelect
            width="20rm"
            height="20rm"
            faces={faces}
            color={config.color}
          />
        </div>
      </ContenedorCorona>
    </>
  );
};

const General = ({ itemsOdontograma, config, faces }) => {
  let itemsOdonto;
  const { addToast } = useToasts();
  try {
    itemsOdonto = itemsOdontograma.map((item) =>
      aplicarPrestacion({
        itemOdonto: item,
        config,
        caras: faces,
        esPilar: true,
      })
    );
  } catch (err) {
    addToast(err.message, { appearance: "error" });
  }

  return (
    <>
      <ContenedorDientes>
        {itemsOdonto.map((it) => (
          <ContenedorDiente>
            <Diente numero={it.diente} config={it} />
          </ContenedorDiente>
        ))}
      </ContenedorDientes>
    </>
  );
};

export const DesactivarPrestacionModal = (props) => {
  const prestacion = useSelector((state) => state.prestacion);
  const { grupo, coronaTipo } = prestacion.config;
  const paciente = useSelector((state) => state.paciente);
  const sesion = useSelector((state) => state.sesion);
  const [faces, setFaces] = React.useState(prestacion.caras);
  const [pilares, setPilares] = React.useState([]);
  const dispatch = useDispatch();
  const cuenta = useSelector((state) => state.cuenta);

  let itemsOdontograma = new DefaultConfig();
  if (prestacion.dientes)
    itemsOdontograma = prestacion.dientes.map((d) => new DefaultConfig(d));
  const { addToast } = useToasts();

  const handleCloseModal = (e) => {
    setFaces([]);
    setPilares([]);
    props.handleCloseModal();
  };

  const esPilar = (id) => {
    return pilares.some((d) => d === id);
  };

  const handleSave = () => {
    try {
      validaTerminar(prestacion);
      prestacion.fechaFin = new Date();
      prestacion.notasClinicas = [];
      prestacion.estado = "PENDIENTE";
      dispatch(desactivarPrestacionTrx(prestacion, cuenta));
      addToast("Operación Satisfactoria", { appearance: "success" });
      props.handleCloseModal();
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const validaTerminar = (prestacion) => {
    if (prestacion.pagado > 0)
      throw new Error("No puedes desactivar una prestación con monto pagado");
  };

  if (sesion.loading) {
    return <div>Procesando...</div>;
  } else if (sesion.error) {
    return <div>error </div>;
  } else
    return (
      <Modal isOpen={props.isOpen} onClose={handleCloseModal}>
        <React.Fragment>
          <ContainerTitle>
            <ContainerTitleText>
              {`Desactivar Prestacion: ${prestacion.tratamiento} [${
                prestacion?.dientes?.length > 0
                  ? prestacion?.dientes?.join()
                  : ""
              }]`}
            </ContainerTitleText>
            <span>
              Desactivar prestacion, quiere decir que esta prestación regresará
              a estado PENDIENTE y no se aplicará a la Historia Clínica del
              Paciente. La prestación no debe tener monto pagado.
            </span>
          </ContainerTitle>

          <MainContainer>
            <ContainerBody>
              <div className="container-dientes-general">
                {coronaTipo !== "PUENTE" && grupo !== "CARAS" && (
                  <General
                    itemsOdontograma={itemsOdontograma}
                    config={prestacion.config}
                    faces={faces}
                  />
                )}
              </div>
              <div className="container-dientes">
                {grupo === "CARAS" && (
                  <Caras
                    itemsOdontograma={itemsOdontograma}
                    config={prestacion.config}
                    faces={faces}
                  ></Caras>
                )}

                {coronaTipo === "PUENTE" && (
                  <Puente
                    itemsOdontograma={itemsOdontograma}
                    pilares={pilares}
                    config={prestacion.config}
                    getValue={esPilar}
                  />
                )}
              </div>
            </ContainerBody>
            <Buttons>
              <LinkCancelar className="btn" onClick={handleCloseModal}>
                Cancelar
              </LinkCancelar>
              <ButtonPagar className="btn" onClick={handleSave}>
                Guardar
              </ButtonPagar>
            </Buttons>
          </MainContainer>
        </React.Fragment>
      </Modal>
    );
};