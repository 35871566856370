export function calcularEdad(fecha) {
  if (fecha) {
    var hoy = new Date();
    var fechaNacimiento = fecha;
    if (typeof fecha === "string") {
      fechaNacimiento = new Date(fecha);
    }

    var edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    var m = hoy.getMonth() - fechaNacimiento.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < fechaNacimiento.getDate())) {
      edad--;
    }
    return edad;
  } else {
    return 0;
  }
}
