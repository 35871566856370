import React from "react";

import {
  ContainerTitle,
  ContainerTitleText,
  TablaBody,
  TablaHeaderTextoItems,
  TablaHeaderTextoValues,
  LinkCancelar,
  ContainerBody,
  SelectStyle,
  StyledInput2,
  ContainerBtn,
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBodyTexto,
  StyledInputTabla,
  TextareaTabla,
  TablaBody2
} from "./styles";

export const VerOrden = ({ orden, labs, onCloseModal, refOrden }) => {
  return (
    <form ref={refOrden}>
      <ContainerTitle>
        <ContainerTitleText>Ver Orden</ContainerTitleText>
      </ContainerTitle>
      <ContainerBody>
        <Tabla>
          <TablaBody>
            <tr>
              <TablaHeaderTextoItems><strong>Laboratorio</strong></TablaHeaderTextoItems>
              <TablaHeaderTextoValues>
                <SelectStyle id="labId" name="labId" value={orden.labId} disabled>
                  {labs.map((lab) => (
                    <option value={lab.id}>{lab.razonSocial}</option>
                  ))}
                </SelectStyle>
              </TablaHeaderTextoValues>
            </tr>
            <tr>
              <TablaHeaderTextoItems>
                <strong>Fec. Envío: </strong>
              </TablaHeaderTextoItems>
              <TablaHeaderTextoValues data-label="envio">
                <StyledInput2
                  type="date"
                  value={orden.fecEnv}
                  name="fecEnv"
                  required
                  disabled
                ></StyledInput2>
              </TablaHeaderTextoValues>
            </tr>
            <tr>
              <TablaHeaderTextoItems>
                <strong>Fec. Recep: </strong>
              </TablaHeaderTextoItems>
              <TablaHeaderTextoValues data-label="recep">
                <StyledInput2
                  type="date"
                  value={orden.fecRec}
                  name="fecRec"
                  required
                  disabled
                ></StyledInput2>
              </TablaHeaderTextoValues>
            </tr>
          </TablaBody>
        </Tabla>


        <Tabla>
          <TablaHeader>
            <tr>
              <TablaHeaderTexto>Item</TablaHeaderTexto>
              <TablaHeaderTexto>Precio</TablaHeaderTexto>
              <TablaHeaderTexto>Nota</TablaHeaderTexto>
            </tr>
          </TablaHeader>

          <TablaBody2>
            {orden.items.map((item, index) => (
              <tr>
                <TablaBodyTexto>{item.nombre}</TablaBodyTexto>
                <TablaBodyTexto>
                  <StyledInputTabla
                    name="precio"
                    id="precio"
                    type="text"
                    value={item.precio}
                    disabled
                  ></StyledInputTabla>
                </TablaBodyTexto>
                <TablaBodyTexto>
                  <TextareaTabla
                    name="nota"
                    rows="3"
                    cols="50"
                    placeholder="Descripcion de la Nota"
                    value={item.nota}
                    disabled
                  ></TextareaTabla>
                </TablaBodyTexto>
              </tr>
            ))}
          </TablaBody2>
        </Tabla>
      </ContainerBody>
      <ContainerBtn>
        <LinkCancelar onClick={onCloseModal}>Cerrar</LinkCancelar>
      </ContainerBtn>
    </form>
  );
};
