import React, { useState } from "react";
import { CheckboxText } from "../../components/CheckboxText/index";
import {
  MainContainer,
  ContainerCheckBoxes,
  TituloCheckBoxes,
} from "../../components/Styles/formularios.css";
import { DientesCheck } from "../../components/DientesCheck";
import { useToasts } from "react-toast-notifications";
import {
  AccordionContainer,
  ContainerTitle,
  ContainerTitleText,
  Container,
  StyledSelect,
  Button,
  ContainerButton,
  FlowContainer,
  ContainerSelect,
  ScrollDiv,
  StyledInput,
} from "./styles";

import {
  addItem,
  removeItem,
  updateItem,
  nuevaOrdenRx,
  actualizaOrdenRx,
  setOrden,
} from "../../redux/ordenRx/ordenRxActions";
import { useDispatch, useSelector } from "react-redux";
const INTRAORAL = "INTRAORAL";
const PERIAPICAL = "PERIAPICAL";
const BITEWING = "BITEWING";
const OCLUSAL = "OCLUSAL";
const EXTRAORALES = "EXTRAORALES";
const RXLATERAL = "RXLATERAL";
const RXFRONTAL = "RXFRONTAL";
const FOTOS = "FOTOS";
const TOMOGRAFIA = "TOMOGRAFIA";

const useTipos = (items) => {
  const tiposRx = [
    {
      llave: "periapical",
      descripcion: "Pieza Nro",
      checked: false,
      dientes: [],
      grupo: PERIAPICAL,
    },
    // {
    //   llave: "Serie Radiografica",
    //   checked: false,
    //   valor: [],
    //   grupo: INTRAORAL,
    // },
    // {
    //   llave: "estudio",
    //   descripcion: "Estudio de localización de pieza",
    //   checked: false,
    //   dientes: [],
    //   tipo: INTRAORAL,
    // },

    {
      llave: "molarDerecha",
      descripcion: "Molar Derecha",

      checked: false,
      dientes: [],
      grupo: BITEWING,
    },
    {
      llave: "molarIzquierda",
      descripcion: "Molar izquierda",
      checked: false,
      dientes: [],
      grupo: BITEWING,
    },
    {
      llave: "premolarDerecha",
      descripcion: "Premolar derecha",
      checked: false,
      dientes: [],
      grupo: BITEWING,
    },
    {
      llave: "premolarIzquierda",
      descripcion: "Premolar izquierda",
      checked: false,
      dientes: [],
      grupo: BITEWING,
    },
    {
      llave: "inferior",
      descripcion: "Inferior",
      checked: false,
      dientes: [],
      grupo: OCLUSAL,
    },
    {
      llave: "superior",
      descripcion: "Superior",
      checked: false,
      dientes: [],
      grupo: OCLUSAL,
    },

    {
      llave: "panoramica",
      descripcion: "Panoramica",
      checked: false,
      dientes: [],
      grupo: EXTRAORALES,
    },
    {
      llave: "atm",
      descripcion: "ATM",
      checked: false,
      dientes: [],
      grupo: EXTRAORALES,
    },
    {
      llave: "posteriorAnterior",
      descripcion: "Posterior/Anterior",
      checked: false,
      dientes: [],
      grupo: EXTRAORALES,
    },
    {
      llave: "lateralCefalometrica",
      descripcion: "Lateral Cefalometrica",
      checked: false,
      dientes: [],
      grupo: EXTRAORALES,
    },
    {
      llave: "senosMaxilares",
      descripcion: "Senos maxilares",
      checked: false,
      dientes: [],
      grupo: EXTRAORALES,
    },
    {
      llave: "malares",
      descripcion: "Malares",
      checked: false,
      dientes: [],
      grupo: EXTRAORALES,
    },
    {
      llave: "carpal",
      descripcion: "Carpal",
      checked: false,
      dientes: [],
      grupo: EXTRAORALES,
    },

    {
      llave: "rickets",
      descripcion: "Rickets",
      checked: false,
      dientes: [],
      grupo: RXLATERAL,
    },
    {
      llave: "steiner",
      descripcion: "Steiner",
      checked: false,
      dientes: [],
      grupo: RXLATERAL,
    },
    {
      llave: "mcNamara",
      descripcion: "Mc Namara",
      checked: false,
      dientes: [],
      grupo: RXLATERAL,
    },
    {
      llave: "sassouni",
      descripcion: "Sassouni",
      checked: false,
      dientes: [],
      grupo: RXLATERAL,
    },
    {
      llave: "shwarts",
      descripcion: "Shwarts",
      checked: false,
      dientes: [],
      grupo: RXLATERAL,
    },
    {
      llave: "trevisi",
      descripcion: "Trevisi",
      checked: false,
      dientes: [],
      grupo: RXLATERAL,
    },
    {
      llave: "Tweed",
      descripcion: "Tweed",
      checked: false,
      dientes: [],
      grupo: RXLATERAL,
    },
    {
      llave: "usp",
      descripcion: "USP",
      checked: false,
      dientes: [],
      grupo: RXLATERAL,
    },
    {
      llave: "alexander",
      descripcion: "Alexander",
      checked: false,
      dientes: [],
      grupo: RXLATERAL,
    },
    {
      llave: "bjorkJarabak",
      descripcion: "Bjork-Jarabak",
      checked: false,
      dientes: [],
      grupo: RXLATERAL,
    },
    {
      llave: "delmanto",
      descripcion: "Delmanto",
      checked: false,
      dientes: [],
      grupo: RXLATERAL,
    },
    {
      llave: "ricketsFrontal",
      descripcion: "RicketsFrontal",
      checked: false,
      dientes: [],
      grupo: RXFRONTAL,
    },

    {
      llave: "fotosExtraorales ",
      descripcion: "Fotos Extraorales ",

      checked: false,
      valor: " ",
      grupo: FOTOS,
    },
    {
      llave: "fotosIntraorales ",
      descripcion: "Fotos Intraorales",
      checked: false,
      valor: " ",
      grupo: FOTOS,
    },
    {
      llave: "periodoncia",
      descripcion: "Periodoncia",
      checked: false,
      valor: " ",
      grupo: TOMOGRAFIA,
    },
    {
      llave: "implantes",
      descripcion: "Implantes",
      checked: false,
      valor: " ",
      grupo: TOMOGRAFIA,
    },
    {
      llave: "endodoncia",
      descripcion: "Endodoncia",
      checked: false,
      valor: " ",
      grupo: TOMOGRAFIA,
    },
    {
      llave: "tercerosMolares",
      descripcion: "Terceros Molares",
      checked: false,
      valor: " ",
      grupo: TOMOGRAFIA,
    },
    {
      llave: "piezasRetenidas",
      descripcion: "Piezas Retenidas",
      checked: false,
      valor: " ",
      grupo: TOMOGRAFIA,
    },
    {
      llave: "ortodoncia",
      descripcion: "Ortodoncia",
      checked: false,
      valor: " ",
      grupo: TOMOGRAFIA,
    },
    {
      llave: "senosMaxilares",
      descripcion: "Senos Maxilares",
      checked: false,
      valor: " ",
      grupo: TOMOGRAFIA,
    },
    {
      llave: "fracturasFaciales",
      descripcion: "Fracturas Faciales",
      checked: false,
      valor: " ",
      grupo: TOMOGRAFIA,
    },
    {
      llave: "localizacionDePatologia",
      descripcion: "Localización de Patología",
      checked: false,
      valor: " ",
      grupo: TOMOGRAFIA,
    },
    {
      llave: "atm",
      descripcion: "ATM",
      checked: false,
      valor: " ",
      grupo: TOMOGRAFIA,
    },
    {
      llave: "maxsup",
      descripcion: "Max. Sup.",
      checked: false,
      valor: " ",
      grupo: TOMOGRAFIA,
    },
    {
      llave: "maxinf",
      descripcion: "Max. Inf.",
      checked: false,
      valor: " ",
      grupo: TOMOGRAFIA,
    },
  ];
  const [tipos, setTiposRx] = React.useState(tiposRx);
  const tiposFiltrados = tipos.map((item) => {
    let ele = items.find(
      (it) => it.llave === item.llave && it.grupo === item.grupo
    );
    return ele ? ele : item;
  });
  return [tiposFiltrados, setTiposRx];
};

export const OrdenRx = (props) => {
  const ordenRx = useSelector((state) => state.ordenRx);
  const [tiposRx, setTiposRx] = useTipos(ordenRx.items);
  const dispatch = useDispatch();
  const sesion = useSelector((state) => state.sesion);
  const paciente = useSelector((state) => state.paciente);
  const { addToast } = useToasts();
  const centrosRx = useSelector((state) => state.centrosRx);
  const empresa = useSelector((state) => state.empresa);
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (i == selected) return setSelected(null);
    setSelected(i);
  };
  const getTipos = (tipo) =>
    tiposRx
      .filter((item) => item.grupo == tipo)
      .map((item) => (
        <div className="element">
          <label className="labelCheck">{item.descripcion}</label>
          <input
            className="checkBox"
            name={item.llave}
            type="checkbox"
            checked={item.checked}
            onChange={(e) => handleChange(e, item)}
            value={item.llave}
          />
        </div>
      ));

  const handleChange = (e, item) => {
    if (e.target.checked)
      dispatch(addItem({ ...item, checked: e.target.checked }));
    else dispatch(removeItem(item));
  };

  const handleChangeLabId = (e) => {
    dispatch(setOrden({ ...ordenRx, [e.target.name]: e.target.value }));
  };

  const handleChangeOrder = (e) => {};
  const completaDatos = () => {
    ordenRx.pacienteId = paciente.id;
    ordenRx.paciente = {
      pacienteId: paciente.id,
      apellidos: paciente.apellidos,
      nombres: paciente.nombres,
      numDoc: paciente.numDoc,
      tipDoc: paciente.tipDoc,
      correo: paciente.correo,
      telefono: paciente.telefono,
      fecNac: paciente.fecNac,
    };
    ordenRx.empresa = empresa.razonSocial;
    ordenRx.empresaId = empresa.id;
    ordenRx.centroRxNombre = centrosRx.find(
      (centroRx) => centroRx.id === ordenRx.centroRxId
    ).razonSocial;
  };

  const handleSave = () => {
    if (ordenRx.centroRxId == "")
      return addToast(`Seleccione un centro Rx`, { appearance: "error" });
    if (ordenRx.items.length === 0)
      return addToast("Seleccione items", { appearance: "error" });
    try {
      completaDatos();
      if (props.accion === "NUEVA") dispatch(nuevaOrdenRx(ordenRx));
      if (props.accion === "EDITAR") dispatch(actualizaOrdenRx(ordenRx));
      props.onSave();
      addToast("Grabación satisfactoria", { appearance: "success" });
    } catch (e) {
      addToast(`Ocurrió un error ${e.message}`, { appearance: "error" });
    }
  };

  return (
    <Container>
      <ContainerTitle>
        <ContainerTitleText>Orden Rx</ContainerTitleText>
      </ContainerTitle>
      <ContainerSelect>
        <StyledSelect
          id="centroRxId"
          name="centroRxId"
          value={ordenRx.centroRxId}
          onChange={handleChangeLabId}
          onSelected={handleChangeLabId}
          required
        >
          <option value="" selected disabled hidden>
            Escoja un Centro Rx
          </option>
          {centrosRx.map((lab) => (
            <option value={lab.id}>{lab.razonSocial}</option>
          ))}
        </StyledSelect>
      </ContainerSelect>
      <div>
        <labelStyled htmlFor="">Comentarios:</labelStyled>
        <StyledInput
          type="text"
          name="comentarios"
          value={ordenRx.comentarios}
          onChange={(e) => {
            dispatch(setOrden({ ...ordenRx, [e.target.name]: e.target.value }));
          }}
        />
      </div>
      <ScrollDiv>
        <AccordionContainer>
          <div className="accordion" eventKey="0">
            <div className="title" onClick={() => toggle(1)}>
              <span className="iconSpan">RADIOGRAFIAS INTRAORALES</span>
              <span className="iconSpan">{selected == 1 ? "-" : "+"}</span>
            </div>
            <div className={selected === 1 ? "content show" : "content"}>
              <span className="subtitle">Periapical</span>
              <FlowContainer>{getTipos(PERIAPICAL)}</FlowContainer>

              {ordenRx.items.some(
                (item) => item.llave === "periapical" && item.checked
              ) ? (
                <DientesCheck llave="periapical" />
              ) : null}
              <span className="subtitle">Bitewing</span>
              <FlowContainer>{getTipos(BITEWING)}</FlowContainer>
              <span className="subtitle">Oclusal</span>
              <FlowContainer>{getTipos(OCLUSAL)}</FlowContainer>
            </div>
          </div>

          <div className="accordion">
            <div className="title" onClick={() => toggle(2)}>
              <span className="iconSpan">RADIOGRAFÍAS EXTRAORALES</span>
              <span className="iconSpan">{selected == 2 ? "-" : "+"}</span>
            </div>
            <div className={selected == 2 ? "content show" : "content"}>
              <FlowContainer>{getTipos(EXTRAORALES)}</FlowContainer>
            </div>
          </div>

          <div className="accordion">
            <div className="title" onClick={() => toggle(3)}>
              <span className="iconSpan">ANÁLISIS COMPUTARIZADO</span>
              <span className="iconSpan">{selected == 3 ? "-" : "+"}</span>
            </div>
            <div className={selected == 3 ? "content show" : "content"}>
              <span className="subtitle">Rx Lateral</span>
              <FlowContainer>{getTipos(RXLATERAL)}</FlowContainer>
              <span className="subtitle">Rx Frontal</span>
              <FlowContainer>{getTipos(RXFRONTAL)}</FlowContainer>
            </div>
          </div>

          <div className="accordion">
            <div className="title" onClick={() => toggle(4)}>
              <span className="iconSpan">FOTOS</span>
              <span className="iconSpan">{selected == 4 ? "-" : "+"}</span>
            </div>
            <div className={selected == 4 ? "content show" : "content"}>
              <FlowContainer>{getTipos(FOTOS)}</FlowContainer>
            </div>
          </div>

          <div className="accordion">
            <div className="title" onClick={() => toggle(5)}>
              <span className="iconSpan">TOMOGRAFIA</span>
              <span className="iconSpan">{selected == 5 ? "-" : "+"}</span>
            </div>
            <div className={selected == 5 ? "content show" : "content"}>
              <FlowContainer>{getTipos(TOMOGRAFIA)}</FlowContainer>
            </div>
          </div>
        </AccordionContainer>
      </ScrollDiv>

      <ContainerButton>
        {props.accion !== "VER" ? (
          <Button onClick={handleSave} variant="contained" size="large">
            Guardar
          </Button>
        ) : null}
      </ContainerButton>
    </Container>
  );
};
