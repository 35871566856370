import styled from "styled-components";
import { dispositivo } from "../Dispositivos";

export const MainContainer = styled.div`
  width: 100%;
  text-align: center;
  background: #f8f8f8;
`;
export const ContainerBody = styled.div`
  width: 95%;
  height: 100%;
  padding: 3%;
  margin: 3%;
  background: #fff;
  box-shadow: 2px 2px #88888877;
  border-radius: 14px;
`;
export const Tabla = styled.table`
  width: 100%;
`;

export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const TablaHeaderTexto = styled.th`
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
`;

export const TablaBody = styled.tbody`
  background: #fff;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  border: 2px solid #ccc;
  padding-bottom: 16px;
`;

export const TablaBodyTexto = styled.th`
  font-size: 0.688rem;
  color:  #045184;
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
  .button-icon{
    padding: 5px 0;
    @media ${dispositivo.tablet} {
      padding: 0 5px;
    }
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  color:  #474747;
  vertical-align: middle;
  text-align: center;
  border-color: #dcf2f9;
  &:focus {
    outline: none;
  }
  @media ${dispositivo.mobileM}{
    width: 80%;
  }
  @media ${dispositivo.mobileL}{
    width: 60%;
  }  
  @media ${dispositivo.tablet} {      
    width: 70%;
  }
  @media ${dispositivo.laptop} {      
    width: 40%;
  }
`;

export const StyledInputNota = styled(StyledInput)`
  width: 90%;
 
`;

export const TablaBodyTextoDiente = styled.div`
  font-size: 0.6rem;
  color:  #045184;
  padding: 0.313em 0  0.313em 0.5em;
  display: flex;
  justify-content: space-between;
  max-width: 80px;
  width: 95%;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 2px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0c8de3;
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
  @media ${dispositivo.mobileL} {
    font-size: 0.875rem;
  }
  @media ${dispositivo.tablet} {
    font-size: 1rem;
  }
  @media ${dispositivo.laptopL} {
    max-width: 200px;
  }
`;
