import styled from "styled-components";
import { dispositivo } from "../../components/Dispositivos";

export const HomeViewWrapper = styled.div`
  .container {
    max-width: 1000px;
    margin: 0 auto;
    border-radius: 14px;
    background: #ffffff;
    padding: 4% 1%;
    box-shadow: 2px 2px #88888877;
    * {
      font-family: "Clarika geo", "Trebuchet MS", sans-serif;
    }

    * {
      font-family: "Clarika geo", "Trebuchet MS", sans-serif;
    }
  }

  .home__section{
    padding: 4%;
  }

  /* MAIN HEADER */
  &__header {
    background-color: #0a87c2;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__logo {
      height: 38px;
      margin: 10px 0;
      cursor: pointer;
    }

    &__buttons {
      display: flex;
      justify-content: center;
      align-items: center;

      &__button {
        border-radius: 9px;
        font-size: 12px;
        padding: 5px 7px;
        font-weight: bolder;
        margin-left: 7px;
        border: 2px solid #0a87c2;
        white-space: nowrap;

        &:hover {
          text-decoration: none;
        }

        &:first-child {
          border: 2px solid white;
          color: white;

          &:hover {
            background-color: white;
            color: #0a87c2;
            transition: 0.3s ease;
          }
        }

        &:last-child {
          background-color: white;
          color: #0a87c2;

          &:hover {
            border: 2px solid white;
            color: white;
            background: transparent;
            transition: 0.3s ease;
          }
        }
      }
    }
    @media ${dispositivo.tablet} {
      &__logo {
        height: 45px;
      }

      &buttons____button {
        padding: 10px;
        font-size: 14px;
      }
    }
  }

  /* WELCOME SECTION */

  &__section {
    min-height: 70vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .container {
      display: grid;
      z-index: 100;
      grid-template-columns: 100%;
      grid-template-rows: auto auto;
      padding-top: 20px;
      padding-bottom: 100px;

      @media ${dispositivo.tablet} {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 100%;
      }
    }

    &--single {
      display: flex;
      justify-content: center;
      align-items: center;
      .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 100px 0;
      }
    }

    &__image {
      min-height: 250px;
      width: auto;
      padding: 20px;
      z-index: 100;
      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__data {
      padding: 20px;
      z-index: 100;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      &__title {
        font-weight: bolder;
        color: #0a87c2;
        font-size: 32px;
      }

      &__text {
        font-size: 19px;

        font-size: 17px;

        line-height: 1.5;
        color: #0a87c2;
        display: inline-block;
        align-items: center;
      }

      &__button {
        border-radius: 9px;
        font-size: 14px;
        padding: 10px 19px;
        font-weight: bolder;
        margin-top: 10px;
        display: inline-block;
        border: 2px solid #0a87c2;
        color: #0a87c2;

        &:hover {
          background-color: #0a87c2;
          color: white;
          transition: 0.3s ease;
          text-decoration: none;
        }

        &--white {
          background-color: white;
          border: 1px solid white;
        }
      }

      @media ${dispositivo.tablet} {
        text-align: left;
        display: unset;

        &__title {
          font-size: 38px;
          text-align: left;
        }
      }
    }

    &--welcome {
      background-image: url("https://previews.123rf.com/images/nadiinko/nadiinko1805/nadiinko180500137/102208014-dentist-orthodontics-blue-seamless-pattern-with-line-icons-dental-care-medical-equipment-braces-toot.jpg");
      background-size: 150px 150px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 10;
      }
    }

    &--info {
      background-color: #0a87c2;
      color: white;
      padding: 100px 0;

      .home__section__data {
        order: 2;
      }

      .home__secton__image {
        order: 1;
      }

      .home__section__data__title,
      .home__section__data__text {
        color: white;
      }

      .home__header__buttons__button {
        padding: 10px;
        display: inline-block;
        margin-top: 10px;
        margin-left: 0;
      }

      @media ${dispositivo.tablet} {
        .home__section__data {
          order: unset;
        }

        .home__secton__image {
          order: unset;
        }
      }
    }

    .section--transition {
      background-color: #0a87c2;
      width: 150%;
      height: 300px;
      position: absolute;
      bottom: -200px;
      left: 0;
      z-index: 200;
      transform: rotate(-2deg);
    }
  }

  &__footer {
    color: white;
    position: relative;

    background-color: #f8f8f8;
    /* background-image: url("https://previews.123rf.com/images/nadiinko/nadiinko1805/nadiinko180500137/102208014-dentist-orthodontics-blue-seamless-pattern-with-line-icons-dental-care-medical-equipment-braces-toot.jpg"); */

    background-image: url("https://previews.123rf.com/images/nadiinko/nadiinko1805/nadiinko180500137/102208014-dentist-orthodontics-blue-seamless-pattern-with-line-icons-dental-care-medical-equipment-braces-toot.jpg");

    background-size: 150px 150px;
    padding: 25px 0;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      /* background-color: rgba(255, 255, 255, 0.8); */

      background-color: rgba(255, 255, 255, 0.8);

      z-index: 10;
    }

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__list {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 300;

      &__button {
        text-decoration: none;
        color: white;
        margin: 0 10px;
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        z-index: 250;

        * {
          z-index: 300;
          border-radius: 50%;
          height: 60px;
          width: 60px;
        }

        :hover {
          transform: translateY(-6px);
          transition: 0.2s;

          :hover {
            transform: translateY(-6px);
            transition: 0.2s;
          }
          @media ${dispositivo.tablet} {
            font-size: 12px;
          }
          @media ${dispositivo.mobileL} {
            font-size: 10px;
          }
        }
      }
    }
  }

  /* Shopping Cart Section - Start */
  .shopping-cart {
    padding-bottom: 10px;
    overflow: hidden;
    transition: max-height 5s ease-in-out;
  }

  .shopping-cart.hide {
    max-height: 0;
    pointer-events: none;
  }

  .shopping-cart .content {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
    background-color: white;
  }

  .shopping-cart .block-heading {
    padding-top: 40px;
    margin-bottom: 30px;
    text-align: center;
  }

  .shopping-cart .block-heading p {
    text-align: center;
    max-width: 600px;
    margin: auto;
    color: RGBA(0, 0, 0, 0.45);
  }

  .shopping-cart .block-heading h1,
  .shopping-cart .block-heading h2,
  .shopping-cart .block-heading h3 {
    margin-bottom: 1.2rem;
    color: #009ee3;
  }

  .shopping-cart .items {
    margin: auto;
  }

  .shopping-cart .items .product {
    margin-bottom: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .shopping-cart .items .product .info {
    padding-top: 0px;
    text-align: left;
  }

  .shopping-cart .items .product .info .product-details .product-detail {
    padding-top: 40px;
    padding-left: 40px;
  }

  .shopping-cart .items .product .info .product-details h5 {
    color: #009ee3;
    font-size: 19px;
  }

  .shopping-cart .items .product .info .product-details .product-info {
    font-size: 15px;
    margin-top: 15px;
  }

  .shopping-cart .items .product .info .product-details label {
    width: 50px;
    color: #009ee3;
    font-size: 19px;
  }

  .shopping-cart .items .product .info .product-details input {
    width: 80px;
  }

  .shopping-cart .items .product .info .price {
    margin-top: 15px;
    font-weight: bold;
    font-size: 22px;
  }

  .shopping-cart .summary {
    border-top: 2px solid #c6e9fa;
    background-color: #f7fbff;
    height: 100%;
    padding: 30px;
  }

  .shopping-cart .summary h3 {
    text-align: center;
    font-size: 1.3em;
    font-weight: 400;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .shopping-cart .summary .summary-item:not(:last-of-type) {
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .shopping-cart .summary .text {
    font-size: 1em;
    font-weight: 400;
  }

  .shopping-cart .summary .price {
    font-size: 1em;
    float: right;
  }

  .shopping-cart .summary button {
    margin-top: 20px;
    background-color: #009ee3;
  }

  @media (min-width: 768px) {
    .shopping-cart .items .product .info .product-details .product-detail {
      padding-top: 40px;
      padding-left: 40px;
    }

    .shopping-cart .items .product .info .price {
      font-weight: 500;
      font-size: 22px;
      top: 17px;
    }

    .shopping-cart .items .product .info .quantity {
      text-align: center;
    }

    .shopping-cart .items .product .info .quantity .quantity-input {
      padding: 4px 10px;
      text-align: center;
    }
  }

  .payment-form {
    padding-bottom: 10px;
    margin-right: 15px;
    margin-left: 15px;
    font-family: "Helvetica Neue", Helvetica, sans-serif;
  }

  .payment-form.dark {
    background-color: #f6f6f6;
  }

  .payment-form .content {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
    background-color: white;
  }

  .payment-form .block-heading {
    padding-top: 40px;
    margin-bottom: 30px;
    text-align: center;
  }

  .payment-form .block-heading p {
    text-align: center;
    max-width: 420px;
    margin: auto;
    color: RGBA(0, 0, 0, 0.45);
  }

  .payment-form .block-heading h1,
  .payment-form .block-heading h2,
  .payment-form .block-heading h3 {
    margin-bottom: 1.2rem;    
    color: #045184;
    font-weight: bold;
  }

  .payment-form .form-payment {
    border-top: 2px solid #c6e9fa;
    box-shadow: 2px 2px #88888877;
    border-radius: 14px;
    background-color: #ffffff;
    padding: 0;
    max-width: 600px;
    margin: auto;
  }

  .payment-form .title {
    font-size: 1em;
    border-bottom: 2px solid rgba(0, 96, 176, 1);
    margin-bottom: 0.8em;
    font-weight: bold;
    padding-bottom: 8px;
    color: #040404;
  }

  .payment-form .products {
    background-color: #f7fbff;
    padding: 25px;
    border-radius: 14px;
  }

  .payment-form .products .item {
    margin-bottom: 1em;
  }

  .payment-form .products .item-name {
    font-weight: bold;
    font-size: 0.9em;
  }

  .payment-form .products .item p {
    margin-bottom: 0.2em;
    color: #040404;
  }

  .payment-form .products .price {
    float: right;
    font-weight: bold;
    font-size: 0.9em;
    color: #045184;
  }

  .payment-form .products .total {
    border-top: 1px solid rgba(0,96,176,1);
    margin-top: 10px;
    padding-top: 19px;
    font-weight: bold;
    color: #040404;
    line-height: 1;
  }

  .payment-form .payment-details {
    padding: 25px 25px 15px;
    height: 100%;
  }

  .payment-form .payment-details label {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #8c8c8c;
    text-transform: uppercase;
  }
  .payment-form .payment-details span {
    display: flex;
    align-items: center;
    color:#040404;
    cursor: pointer;
  }

  .payment-form button {
    height: 2.5rem;
    font-size: 0.9rem; 
    border-radius: 0.8rem;      
    text-transform: uppercase;
    cursor: pointer;
    font-width: bold;
    background-color: #0060b0;
    color: #ffffff;
    border: none;
    padding: 0 0.5em;
    // width: 40%;
     &:hover{
      color:#000000;
      background-color: #0c8de390;
      font-width: bold;
     }
    @media ${dispositivo.tablet}{
      // width: 10rem;
      font-size: 1rem;  
    }  
    @media ${dispositivo.laptop} {      
      // width: 12.5rem;
    }
  }

  .payment-form .mercadopago-button {
    width: 100%;
    // padding: 8px 0;
  }

  .payment-form a,
  .payment-form a:not([href]) {
    margin: 0;
    padding: 0;
    font-size: 13px;
    color: #009ee3;
    cursor: pointer;
  }

  .payment-form a:not([href]):hover {
    color: #3483fa;
    cursor: pointer;
  }

  .input-background {
    background-position: 98% 50%;
    background-repeat: no-repeat;
    background-color: #fff;
  }

  footer {
    padding: 2% 10% 6% 10%;
    margin: 0 auto;
    position: relative;
  }

  #horizontal_logo {
    width: 150px;
    margin: 0;
  }

  footer p a {
    color: #009ee3;
    text-decoration: none;
  }

  footer p a:hover {
    color: #3483fa;
    text-decoration: none;
  }

  @media (min-width: 576px) {
    .payment-form .title {
      font-size: 1.2em;
    }

    .payment-form .products {
      padding: 40px 40px 0;
    }

    .payment-form .products .item-name {
      font-size: 1em;
    }

    .payment-form .products .price {
      font-size: 1em;
    }

    .payment-form .payment-details {
      padding: 20px 40px;
    }

    .payment-form .payment-details button {
      margin-top: 1em;
      margin-bottom: 15px;
    }

    .footer_logo {
      margin: 0 0 0 0;
      width: 20%;
      text-align: left;
      position: absolute;
    }

    .footer_text {
      margin: 0 0 0 65%;
      width: 200px;
      text-align: left;
      position: absolute;
    }

    footer p {
      padding: 1px;
      font-size: 13px;
      color: RGBA(0, 0, 0, 0.45);
      margin-bottom: 0;
    }
  }

  @media (max-width: 576px) {
    footer {
      padding: 5% 1% 15% 1%;
      height: 55px;
    }

    footer p {
      padding: 1px;
      font-size: 11px;
      margin-bottom: 0;
    }
    .footer_text {
      margin: 0 0 0 45%;
      width: 180px;
      position: absolute;
    }

    .footer_logo {
      margin: 0 0 0 0;
      position: absolute;
    }
  }
`;

export const PlanCardsWrapper = styled.div`  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media ${dispositivo.tablet} {
    flex-direction: row;
  }

  .plan-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 10px;
    box-shadow: 0 0 5px 0px grey;
    padding: 20px 0;
    width: 80%;
    background: white;

    @media ${dispositivo.tablet} {
      margin: 0 6px;
      width: auto;

      &--plus {
        transform: scaleY(1.1);
      }
    }

    &__title {
      letter-spacing: 1.5px;
      line-height: 2;
      font-weight: bolder;
    }

    &__price {
      font-weight: bolder;
      color: #525f70;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__ammount {
        font-size: 52px;
        line-height: 1.1;
        position: relative;

        &::after {
          content: "S./";
          position: absolute;
          right: 100%;
          top: 0;
          font-size: 20px;
        }
      }

      &__period {
        font-size: 20px;
      }
    }

    &__button-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .btn {
        border: none;
        margin-bottom: 10px;
        padding: 5px 25px;
      }
    }

    &--basic {
      border: 1px solid #98b8fa;
      .plan-card__title {
        color: #98b8fa;
      }
      .plan-card__button-container {
        .btn {
          background-color: #98b8fa;
          :hover{
            background-color: #6688c7;
          }
        }
      }
    }

    &--plus {
      border: 1px solid #2cc789;
      .plan-card__title {
        color: #2cc789;
      }
      .plan-card__button-container {
        .btn {
          background-color: #2cc789;
          &:hover{
            background-color: #00955c;
          }
        }
      }
    }

    &--titanium {
      border: 1px solid #fd8b9a;
      .plan-card__title {
        color: #fd8b9a;
      }
      .plan-card__button-container {
        .btn {
          background-color: #fd8b9a;
          &:hover{
            background-color: #fa6478;
          }
        }
       
      }
    }

    /* Shopping Cart Section - Start */
    .shopping-cart {
      padding-bottom: 10px;
      overflow: hidden;
      transition: max-height 5s ease-in-out;
    }

    .shopping-cart.hide {
      max-height: 0;
      pointer-events: none;
    }

    .shopping-cart .content {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
      background-color: white;
    }

    .shopping-cart .block-heading {
      padding-top: 40px;
      margin-bottom: 30px;
      text-align: center;
    }

    .shopping-cart .block-heading p {
      text-align: center;
      max-width: 600px;
      margin: auto;
      color: RGBA(0, 0, 0, 0.45);
    }

    .shopping-cart .block-heading h1,
    .shopping-cart .block-heading h2,
    .shopping-cart .block-heading h3 {
      margin-bottom: 1.2rem;
      color: #009ee3;
    }

    .shopping-cart .items {
      margin: auto;
    }

    .shopping-cart .items .product {
      margin-bottom: 0px;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .shopping-cart .items .product .info {
      padding-top: 0px;
      text-align: left;
    }

    .shopping-cart .items .product .info .product-details .product-detail {
      padding-top: 40px;
      padding-left: 40px;
    }

    .shopping-cart .items .product .info .product-details h5 {
      color: #009ee3;
      font-size: 19px;
    }

    .shopping-cart .items .product .info .product-details .product-info {
      font-size: 15px;
      margin-top: 15px;
    }

    .shopping-cart .items .product .info .product-details label {
      width: 50px;
      color: #009ee3;
      font-size: 19px;
    }

    .shopping-cart .items .product .info .product-details input {
      width: 80px;
    }

    .shopping-cart .items .product .info .price {
      margin-top: 15px;
      font-weight: bold;
      font-size: 22px;
    }

    .shopping-cart .summary {
      border-top: 2px solid #c6e9fa;
      background-color: #f7fbff;
      height: 100%;
      padding: 30px;
    }

    .shopping-cart .summary h3 {
      text-align: center;
      font-size: 1.3em;
      font-weight: 400;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .shopping-cart .summary .summary-item:not(:last-of-type) {
      padding-bottom: 10px;
      padding-top: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .shopping-cart .summary .text {
      font-size: 1em;
      font-weight: 400;
    }

    .shopping-cart .summary .price {
      font-size: 1em;
      float: right;
    }

    .shopping-cart .summary button {
      margin-top: 20px;
      background-color: #009ee3;
    }

    @media (min-width: 768px) {
      .shopping-cart .items .product .info .product-details .product-detail {
        padding-top: 40px;
        padding-left: 40px;
      }

      .shopping-cart .items .product .info .price {
        font-weight: 500;
        font-size: 22px;
        top: 17px;
      }

      .shopping-cart .items .product .info .quantity {
        text-align: center;
      }

      .shopping-cart .items .product .info .quantity .quantity-input {
        padding: 4px 10px;
        text-align: center;
      }
    }
  }
`;
