import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addTratamiento,
} from "../../redux/tratamientos/tratamientosActions";
import { useToasts } from "react-toast-notifications";
import { fetchTarifarios } from "../../services/firebase/tarifarios/index";

import {
  ContainerTitle,
  ContainerTitleText,
  ContainerBody,
  StyledInput,
  StyledSelect,
  StyledButton,
  ContainerLeft,
  MainContainer,
  LinkCancelar,
  StyledForm,
  StyledLabel,
  DivInput,
  StyledInputNumber,
} from "./styles";
const useTratamiento = (tratamiento) => {
  if (!tratamiento.color) {
    tratamiento.color = "#000";
    tratamiento.afectaOdonto = false;
    tratamiento.grupo = "NONE";
    tratamiento.codtra = "";
  }

  const [item, setItem] = React.useState(tratamiento);
  return [item, setItem];
};

const ESPECIALIDADES = [
  "anexos",
  "cirugia",
  "dentistica",
  "endodoncia",
  "estetica",
  "exodoncias",
  "higienista",
  "implantologia",
  "odontopediatria",
  "ortodoncia",
  "periodoncia",
  "protesis fija",
  "protesis removible",
  "radiologia",
];

export const NuevoTratamiento = () => {
  const sesion = useSelector((state) => state.sesion);
  const subGrupos = useSelector((state) => state.subGrupos);
  const empresa = useSelector((state) => state.empresa);
  const dispatch = useDispatch();
  const [tarifario, setTarifario] = React.useState([]);
  const tratamiento = {
    id: "",
    codtra: "",
    especialidad: "",
    tratamiento: "",
    tipo: "",
    precio: 0,
    costo: 0,
    tiempo: 30,
    empresaId: "",
    codLab: "",
    estado: "",
    subGrupoId: "",
    readOnly: false
  };
  const [item, setItem] = useTratamiento(tratamiento);
  const history = useHistory();
  const { addToast } = useToasts();

  React.useEffect(() => {
    if (tarifario.length === 0)
      fetchTarifarios().then((tarifario) => {
        setTarifario(tarifario);
      });
  }, []);

  const handleChange = (e) => {
    if (e.target.name == "precio")
      setItem({ ...item, [e.target.name]: Number(e.target.value) });
    else setItem({ ...item, [e.target.name]: e.target.value });
  };
  const enviaLab = () => {
    if (item.subGrupoId == undefined) return false;
    const i = subGrupos.find((it) => it.id === item.subGrupoId);
    if (i) return i.envLab;
    else return false;
  };
  const handleGuardar = (e) => {
    e.preventDefault();
    try {
      item.estado = "ACTIVO";
      item.empresaId = empresa.id;
      dispatch(addTratamiento(item));
      addToast("Guardado satisfactorio", { appearance: "success" });
      history.push("/admin/mantenimiento/tratamientos");
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };
  if (sesion.loading || sesion.loading === undefined)
    return <div>...procesando</div>;
  if (sesion.error) return <div>{`Hubo un error ${sesion.error}`}</div>;
  return (
    <MainContainer>
      <ContainerTitle>
        <ContainerTitleText>Nuevo Tratamiento</ContainerTitleText>
      </ContainerTitle>
      <ContainerBody>
        <form className="form form--simple" onSubmit={handleGuardar}>
          <StyledForm>
            <DivInput>
              <StyledLabel htmlFor="subGrupo">Sub. Grupo</StyledLabel>
              <StyledSelect
                name="subGrupoId"
                required
                onChange={handleChange}
                onSelected={handleChange}
                value={item.subGrupoId}
              >
                <option value="" selected disabled hidden>
                  Seleccione
                </option>
                {subGrupos.map((s) => (
                  <option value={s.id}>{s.nombre}</option>
                ))}
              </StyledSelect>
            </DivInput>

            <DivInput>
              <StyledLabel htmlFor="especialidad">Especialidad</StyledLabel>
              <StyledSelect
                name="especialidad"
                value={item.especialidad}
                onChange={(e) =>
                  setItem({ ...item, [e.target.name]: e.target.value })
                }
              >
                {ESPECIALIDADES.map((it) => (
                  <option value={it}>{it}</option>
                ))}
              </StyledSelect>
            </DivInput>
            {enviaLab() && (
              <DivInput>
                <StyledLabel htmlFor="odonto">Tratamiento Lab</StyledLabel>

                <StyledSelect
                  name="codLab"
                  value={item.codLab}
                  onChange={(e) =>
                    setItem({ ...item, [e.target.name]: e.target.value })
                  }
                >
                  {tarifario.sort((a, b) => a.nombre.localeCompare(b.nombre)).map((it) => (
                    <option value={it.id}>{it.nombre}</option>
                  ))}
                </StyledSelect>
              </DivInput>
            )}
            <DivInput>
              <StyledLabel className="form__labelStyled" htmlFor="tratamiento">
                Tratamiento
              </StyledLabel>
              <StyledInput
                name="tratamiento"
                id="tratamiento"
                autoComplete="off"
                value={item.tratamiento}
                onChange={handleChange}
                required
                //ref={register}
              />
            </DivInput>

            <DivInput>
              <StyledLabel className="form__labelStyled" htmlFor="Tipo">
                Tipo
              </StyledLabel>
              <StyledInput
                name="tipo"
                id="tipo"
                autoComplete="off"
                value={item.tipo}
                onChange={handleChange}
                //ref={register}
              />
            </DivInput>

            <DivInput>
              <StyledLabel htmlFor="precio">Precio</StyledLabel>
              <StyledInputNumber
                type="number"
                name="precio"
                autoComplete="off"
                value={item.precio}
                //ref={register}
                onChange={handleChange}
                required
              />
            </DivInput>
            <DivInput>
              <StyledLabel htmlFor="costo">Costo</StyledLabel>
              <StyledInputNumber
                type="number"
                name="costo"
                autoComplete="off"
                value={item.costo}
                //ref={register}
                onChange={handleChange}
                required
              />
            </DivInput>
            <DivInput>
              <StyledLabel htmlFor="tiempo">Tiempo</StyledLabel>
              <StyledInputNumber
                type="number"
                name="tiempo"
                autoComplete="off"
                value={item.tiempo}
                //ref={register}
                onChange={handleChange}
                required
              />
            </DivInput>
          </StyledForm>
          <ContainerLeft>
            <LinkCancelar
              type="button"
              value="Cancelar"
              onClick={() => history.goBack()}
            >
              Cancelar
            </LinkCancelar>

            <StyledButton type="submit" value="Guardar">
              Guardar
            </StyledButton>
          </ContainerLeft>
        </form>
      </ContainerBody>
    </MainContainer>
  );
};
