import {
  ADD_PRESTATION_SESION,
  REMOVE_PRESTACION_SESION,
  UPDATE_PRESTATION_SESION_IN_ARRAY,
  SET_PRESTACIONES_SESION,
  SAVE_PRESTACIONES_SESION_REQUEST,
  SAVE_PRESTACIONES_SESION_SUCCESS,
  SAVE_PRESTACIONES_SESION_FAILURE,
  FETCH_PRESTACIONES_SESION_REQUEST,
  FETCH_PRESTACIONES_SESION_SUCCESS,
  FETCH_PRESTACIONES_SESION_FAILURE,
  NEW_PRESTACIONES_SESION,
} from "./prestacionesSesionTypes";

const initialState = {
  id: "",
  fecha: "",
  pacienteId: "",
  items: [],
};

export const prestacionesSesionReducers = (state = initialState, action) => {
  switch (action.type) {
    case NEW_PRESTACIONES_SESION:
      return initialState;
    case FETCH_PRESTACIONES_SESION_SUCCESS:
      return action.payload;

    case ADD_PRESTATION_SESION:
      return action.payload;
    case REMOVE_PRESTACION_SESION:
      return {
        ...state,
        items: state.items.filter(
          (prestation) => prestation.id !== action.payload.id
        ),
      };
    case SET_PRESTACIONES_SESION:
      return action.payload;
    case SAVE_PRESTACIONES_SESION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SAVE_PRESTACIONES_SESION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SAVE_PRESTACIONES_SESION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_PRESTATION_SESION_IN_ARRAY:
      const index = state.items.findIndex(
        (prestation) => prestation.id === action.payload.id
      );
      const newArray = [...state.items];
      newArray[index] = action.payload;
      return {
        ...state,
        items: newArray,
      };
    default:
      return state;
  }
};
