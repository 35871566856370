import React from "react";
import Extraccion from "./Extraccion";
import { Grafico } from "./Grafico";
import { Icon } from "./Icon";
import "./style.css";
const IncisivoCentralSup = (props) => {
  const constrastColor = "blue";
  const defaultColor = "white";
  const configuracion = props.config;
  const getColor = (cara) => {
    const defaultColor = "#ffeca2";

    let color = defaultColor;
    if (props.config.caras) {
      let idx = props.config.caras.findIndex((it) => it.cara == cara);
      if (idx > -1) color = props.config.caras[idx].color;
    }
    return color;
  };
  return (
    <svg
      id="Incisor_lateral"
      data-name="Incisor lateral"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 156.71 377.52"
    >
      <title>incisivo_central555_sup</title>
      <g id="Implante">
        <path
          d="M28.54,234.65,50.34,236l49.25-.39,32.62-.59-1.48-25.81,1.48-30s-19.36-6.07-21.66-9.85c-1.16-1.92,0-8.58,0-8.58a88.5,88.5,0,0,0,9-2.82c2.85-1.57,4.08-4.15,4.69-9.31,1-8.31-1.63-12-8.56-12a37.77,37.77,0,0,0-5.19.66v-8a48.51,48.51,0,0,0,5.38-1.93c2.61-1.57,3.73-4.15,4.29-9.31.93-8.3-1.49-12-7.83-12a14.14,14.14,0,0,0-1.84.17V92.87a8.73,8.73,0,0,0,1.42-.82c3.16-2.35,3.83-4.26,3.83-10.1,0-6.11-1.6-9.39-5.25-10.35V58c4.75-2.85,5.25-5.7,5.25-10.79,0-6.13-1.66-9.48-5.25-10.39V30.64a1.66,1.66,0,0,0-.56-1.21l-.12-.33c-.57-1.2-1.13-14-8.85-20.7C94,3.88,79.63,2.53,76.77,2.53s-14-.87-24.23,7.26c-7.2,5.72-7,15.52-7.38,18.59-.12.51-.25,1.11-.37,1.78A3.86,3.86,0,0,0,43.42,33V55.89a12.24,12.24,0,0,0-1.85.82c-2.33,1.57-3.33,4.15-3.83,9.31-.78,7.59,1,11.42,5.68,11.94V91c-5.18,3-5.86,5.93-5.86,11.06,0,6.55,1.85,9.87,5.86,10.53v11.59a35.36,35.36,0,0,0-5.42,2.2c-4.47,2.92-5,4.15-5,10.43,0,7.3,3.17,10.89,9.5,10.66a6.69,6.69,0,0,0,1-.14v6.43a60.45,60.45,0,0,0-9.06,3.18c-4.9,2.91-5.51,4.15-5.51,10.43,0,7.29,3.47,10.88,10.4,10.66a32.55,32.55,0,0,0,3.78-.68c-6.75,11.53-14.49,15-14.49,15Z"
          transform="translate(0 -0.01)"
          fill={configuracion.implante.fill}
          stroke={configuracion.implante.strokeColor}
        />
      </g>
      <path
        id="Raiz"
        d="M20.65,262.5l115.1-1.2S128,212.93,123.5,160c-3.89-45.45-3.92-94.27-12-118.5-3-8.93-19.15-32.4-19.15-32.4-2.3-8.7-13.6-8.8-16-.2,0,0-10.85,9.1-13.34,38.6C58,107,20.65,262.5,20.65,262.5Z"
        transform="translate(0 -0.01)"
        fill={configuracion.raiz.fill}
        stroke={configuracion.raiz.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
      <path
        id="Nucleo"
        d="M83.35,216.4h0c-4.4,0-21.3-3.05-20.3-7.53L76.35,20.51a6.62,6.62,0,0,1,5.3-7.82,10.79,10.79,0,0,1,1.7-.19h0a8,8,0,0,1,8,8h0l6.4,185.88C97.75,210.78,87.75,216.4,83.35,216.4Z"
        transform="translate(0 -0.01)"
        fill={configuracion.nucleo.fill}
        stroke={configuracion.nucleo.strokeColor}
      />
      <path
        id="6"
        d="M101.6,204.81c4.65,1.87,17.46,8.7,27.57,28.63,2.31,4.56-7.5-62.9-7.5-62.9s-23-13.15-23-8.16C98.69,174.1,96.35,202.7,101.6,204.81Z"
        transform="translate(0 -0.01)"
        fill={getColor("6")}
      />
      <path
        id="7"
        d="M60.17,203.6c-4.28,3.53-18.73,5.64-28.84,25.57-2.31,4.55,13-59.84,13-59.84S63,157.29,63,162.28C63,174,64.54,200,60.17,203.6Z"
        transform="translate(0 -0.01)"
        fill={getColor("7")}
      />
      <path
        id="8"
        d="M123.44,224.29c-18.77-31.62-62.27-30.53-85.17-5-6.65,7.41,6-39.7,6-39.7,1.51-10.07,8.19-17.32,16-17.32H96.91c10.82,0,20.06,10.34,21.82,24.43C118.73,186.71,128.2,232.31,123.44,224.29Z"
        transform="translate(0 -0.01)"
        fill={getColor("8")}
      />
      <path
        id="9"
        d="M128,232.42C113.5,190,44.83,190,33.89,228c-2.48,8.59,6.57-35.16,6.57-35.16,1.67-8.92,9.06-15.34,17.64-15.34H98.66c12,0,22.15,9.16,24.1,21.64C122.76,199.14,130.67,240.39,128,232.42Z"
        transform="translate(0 -0.01)"
        fill={getColor("9")}
      />
      <path
        id="Corona"
        d="M143.75,289.2c32.5,106.5-16.9,83.8-63.7,83.8s-99.4,8-68.1-83.8c16.7-49,21.3-85.6,68.1-85.6S129.75,243.5,143.75,289.2Z"
        transform="translate(0 -0.01)"
        fill={configuracion.corona.fill}
        stroke={configuracion.corona.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
    </svg>
  );
};

export default IncisivoCentralSup;
