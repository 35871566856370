import React, { useState } from "react";
import { Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import datos from "../../img/azul_datos_32.png";
import presupuesto from "../../img/azul_presupuesto_32.png";
import pago from "../../img/azul_pagos_32.png";
import prestacion from "../../img/azul_prestacion_32.png";
import diente from "../../img/azul_odontograma_32.png";
import reporte from "../../img/azul_reportes_32.png";
import tu_logo_aqui from "../../img/logos/tu_logo_aqui.png";
import { NavDropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { fetchUsuario } from "../../services/firebase/usuarios";
import {
  MenuLateralWrapper,
  Navbar,
  SubItemWrapper,
  DivItemMenu,
  DivTitle,
  SpanTitleMenu,
  DivSubItem,
  DivSubTitleMenu,
  DivSubtitlte,
  SpanSubTitleMenu,
  DivSubTitleMenuItem,
  SpanSubTitleMenuItem,
} from "./styles";
import { auth } from "../../services/firebase/Config";
import { useDispatch } from "react-redux";
import { setUsuarioSesion } from "../../redux/sesion/sesionActions";
import SysDent from "../../img/sysdent-logo.png";
// import { Menus, mappedMenus, getMenus } from "../Accesos/index";

export const MenuLateral = ({ url, urlBase }) => {
  const [showLinks, setShowLinks] = useState(false);
  const menu = { admin: false, paciente: false, calendario: false, lab: false };
  const [menuState, setMenuState] = useState(menu);
  const accesos = useSelector((state) => state.accesos);
  const sesion = useSelector((state) => state.sesion);
  const empresa = useSelector((state) => state.empresa);
  const getLogoRoute = () => {
    return empresa?.logoUrl;
  };

  const getItemClassName = (_nombre) => {
    return menuState[_nombre] ? "shown" : "not-shown";
  };

  const generaMenu = (perfil, tipo) => {
    //const options = mappedMenus()[0];
    // const options = getMenus(perfil, tipo);
    return accesos.lateral.map((_op) => {
      // let imgPath1 = require(`../../img/${_op.iconFile}`);
      let iconPath1 = `${_op.iconFile}`;
      return (
        <MenuLateralWrapper key={_op.key}>
          <DivItemMenu>
            <DivTitle>
              <SpanTitleMenu
                onClick={() => {
                  const _menuState = { ...menuState };
                  for (let x in _menuState) {
                    if (x === _op.key)
                      _menuState[_op.key] = !_menuState[_op.key];
                    else _menuState[x] = false;
                  }
                  return setMenuState(_menuState);
                }}
              >
                <i className={iconPath1}></i>
                {_op.nombre}
              </SpanTitleMenu>
            </DivTitle>

            {_op.items && (
              <DivSubItem className={getItemClassName(_op.key)}>
                {_op?.items?.map((it, index) => {
                  let iconPath = `${it.iconFile}`;
                  return (
                    <DivSubTitleMenu key={index}>
                      <DivSubtitlte>
                        <NavLink
                          className="subtitle-menu"
                          to={`${_op.url}${it.url}`}
                          onClick={(e) =>
                            it.items ? e.preventDefault() : null
                          }
                        >

                          <SpanSubTitleMenu>
                            <i className={iconPath}></i>
                            {it.nombre}
                          </SpanSubTitleMenu>
                        </NavLink>
                      </DivSubtitlte>

                      <DivSubTitleMenuItem>
                        {it.items?.length > 0 &&
                          it.items.map((_subitem, index) => {
                            return (
                              <NavLink
                                className="subtitle-menu-item"
                                to={`${_op.url}${_subitem.url}`}
                                key={index}
                              >
                                <SubItemWrapper>
                                  <SpanSubTitleMenuItem>
                                    {_subitem.nombre}
                                  </SpanSubTitleMenuItem>
                                </SubItemWrapper>
                              </NavLink>
                            );
                          })}
                      </DivSubTitleMenuItem>
                    </DivSubTitleMenu>
                  );
                })}
              </DivSubItem>
            )}
          </DivItemMenu>
        </MenuLateralWrapper>
      );
    });
  };
  return (
    <div>
      <Navbar>
        {empresa?.logoUrl ? (
          <img
            src={empresa.logoUrl}
            alt="clinic logo"
            style={{ width: "248px", objectFit: "cover", padding: "10px" }}
          />
        ) : (
          <div
            style={{
              width: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "10px",
            }}
          >
            <img src={tu_logo_aqui} alt="tu_logo_aqui"></img>
          </div>
        )}


        {generaMenu(sesion.usuario.perfil, "LATERAL")}
      </Navbar>
    </div>
  );
};
