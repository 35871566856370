import styled from "styled-components";
import { dispositivo } from "../../components/Dispositivos";
export const LogoWaterMarkWrapper = styled.div`
  filter: grayscale(1);
  opacity: 0.1;
  margin: 10px 0;
  text-align: center;
  position: absolute;
  width: 100%;
  @media ${dispositivo.tablet} {
    width: calc(100% - 250px);
  }
`