import { db } from "../../services/firebase/Config";
import { SET_GASTOS_FIJOS } from "./gastosFijosTypes";

import {
  httpRequest,
  httpSuccess,
  httpFailure,
  setUsuarioSesion,
} from "../sesion/sesionActions";
import {
  fetchGastosFijos,
  saveGastosFijos,
} from "../../services/firebase/gastosFijos/index";

export const setGastosFijos = (gastos) => {
  return {
    type: SET_GASTOS_FIJOS,
    payload: gastos,
  };
};

export const getGastosFijos = (empresaId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    fetchGastosFijos(empresaId)
      .then((gastos) => {
        dispatch(httpSuccess());
        dispatch(setGastosFijos(gastos));
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};

export const guardaGastosFijos = (gastos) => {
  return function (dispatch) {
    dispatch(httpRequest());
    saveGastosFijos(gastos)
      .then(() => {
        dispatch(setGastosFijos(gastos));
        dispatch(httpSuccess());
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};
