import styled from "styled-components";
import { dispositivo } from "../Dispositivos";

export const DenticionContainer = styled.div`
  display: block;
`;

export const DenticionBlock = styled.div`
  display: flex;
  justify-content: center;
  margin:  10px 0;
  // max-width: 90%;
`;

export const DenticionSide = styled.div`
  display: flex;
  //justify-content: space-between;
  width: 100%;
  justify-content: center;
`;

export const Centrado = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 1em;
`;
