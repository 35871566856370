import { SET_LABS, UPDATE_LAB_IN_ARRAY } from "./labsTypes";
import { fetchLabs } from "../../services/firebase/labs";
import { pagar } from "../../services/firebase/pagosLabs";
import { httpRequest, httpSuccess, httpFailure } from "../sesion/sesionActions";

export const setLabs = (labs) => {
  return {
    type: SET_LABS,
    payload: labs,
  };
};
export const updateLabsInArray = (lab) => {
  return {
    type: UPDATE_LAB_IN_ARRAY,
    payload: lab,
  };
};
export const getLabs = () => {
  return function (dispatch) {
    dispatch(httpRequest());
    fetchLabs()
      .then((labs) => {
        dispatch(httpSuccess());
        dispatch(setLabs(labs));
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};

export const pagarLab = (pago, ordenes) => {
  return function (dispatch) {
    dispatch(httpRequest());
    pagar(pago, ordenes)
      .then(() => {
        dispatch(httpSuccess());
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};
