import React from "react";
import {
  DienteContenedor,
  ContenedorAuxiliar,
  DienteContenedorNumero,
  DienteContenedorCorona,
  DienteContenedorRaiz,
  Img,
} from "../Styles/dientes.css";

export const DienteSuperior = ({ item, onSelectedTooth, checked }) => {
  const { diente, nombre } = item;
  const imgDiente = require(`../../assets/svg/${nombre}.svg`);
  const imgCorona = require(`../../assets/svg/incisivo.svg`);

  return (
    <DienteContenedor>
      <DienteContenedorRaiz>
        <Img src={imgDiente} />
      </DienteContenedorRaiz>
      <ContenedorAuxiliar>
        <DienteContenedorCorona>{diente}</DienteContenedorCorona>

        <DienteContenedorNumero>
          <input
            type="checkbox"
            id={diente}
            name="dientes"
            onChange={(e) => onSelectedTooth(e)}
            checked={checked}
          />
        </DienteContenedorNumero>
      </ContenedorAuxiliar>
    </DienteContenedor>
  );
};
