import { SET_RECETAS } from "./recetasTypes";

const initialState = {
  items: [],
};
export const recetasReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_RECETAS:
      return { items: action.payload };
    default:
      return state;
  }
};
