import { SET_RECETA } from "./recetaTypes";

const initialState = {
  id: "",
  fecha: "",
  pacienteId: "",
  pacienteNombres: "",
  especialistaNombres: "",
  especialistaCop: "",
  items: [
    {
      medicina: "",
      concentracion: "",
      cantidad: "",
      dosis: "",
      duracion: "",
      frecuencia: "",
      formato: "",
    },
  ],
};
export const recetaReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_RECETA:
      return action.payload;
    default:
      return state;
  }
};
