import React, { useState } from "react";
// import { useBuscarPacientes } from "../../hooks/useBuscarPacientes";
import { useBuscarPacientes } from "../../hooks/useBuscarPacientesDebounce";
import { Autocomplete } from "../Autocomplete-hooks/index";
import { fetchPacientes } from "../../redux/pacientes/pacientesActions";
import { fetchPrestations } from "../../redux/prestations/prestationsActions";
import { fetchPrestacionesSesion } from "../../redux/prestacionesSesion/prestacionesSesionActions";
import { getOdontograma } from "../../redux/odontograma/odontogramaActions";
import { useSelector, useDispatch } from "react-redux";
import { getCuenta } from "../../redux/cuenta/cuentaActions";
import { setPaciente } from "../../redux/paciente/pacienteActions";
import { setPacienteSesion } from "../../redux/sesion/sesionActions";
import { useHistory } from "react-router-dom";
import { fetchTratamientos } from "../../redux/tratamientos/tratamientosActions";
import Spinner from 'react-bootstrap/Spinner'
import styles from "./styles.module.css";
import {
  httpRequest,
  httpSuccess,
  httpFailure,
} from "../../redux/sesion/sesionActions";

export const BuscarPacientes = () => {
  //const pacientes = useSelector((state) => state.pacientes);
  const cuenta = useSelector((state) => state.cuenta);
  const sesion = useSelector((state) => state.sesion);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSelected = (item) => {
    dispatch(setPaciente(item));
    dispatch(setPacienteSesion(true));
    dispatch(getCuenta(item.id));
    dispatch(fetchPrestations(item.id));
    dispatch(fetchPrestacionesSesion(item.id, sesion.fecha));
    dispatch(getOdontograma(item.id));

    history.push("/paciente/odontograma");
  };

  const toString = (item) => {
    return `${item.nombres} ${item.apellidos} ${item.numDoc}`;
  };
  // React.useEffect(() => {
  //   //todo: implementar la llamada a la base de datos desde redux
  //   dispatch(httpRequest());
  //   //dispatch(fetchPacientes(1));
  //   dispatch(httpSuccess());
  // }, []);

  if (sesion.loading || sesion.loading == undefined)
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden"></span>
      </Spinner>
    );
  if (cuenta.error) {
    return <div>Error</div>;
  } else
    return (
      <Autocomplete
        useSearchItems={useBuscarPacientes}
        onSelected={handleSelected}
        toString={toString}
      ></Autocomplete>
    );
};
