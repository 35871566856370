import { db } from "../../services/firebase/Config";
import { SET_EMPRESA } from "./empresaTypes";
import {
  setUser,
  httpUserRequest,
  httpUserRequestSuccess,
} from "../usuario/usuarioActions";
import {
  httpRequest,
  httpSuccess,
  httpFailure,
  setUsuarioSesion,
} from "../sesion/sesionActions";
import {
  getEmpresaById,
  saveEmpresa,
  addEmpresa,
} from "../../services/firebase/empresas/index";

let tratamientos = require("../../data/tratamientos.json");
let accesos = require("../../data/accesos.json");

const membresias = [
  {
    tipoMembresia: 'FREE',
    detalles: {
      plan: 'FREE',
      usuarios: 1,
      perfiles: ['ODONTOLOGO_ADM'],
      pacientes: 3,
      prestaciones: 5,
    },
  },
  {
    tipoMembresia: 'BASIC',
    detalles: {
      plan: 'BASIC',
      usuarios: 1,
      perfiles: ['ODONTOLOGO_ADM'],
      pacientes: 150,
      prestaciones: 300,
    },
  },
  {
    tipoMembresia: 'SILVER',
    detalles: {
      plan: 'SILVER',
      usuarios: 2,
      perfiles: ['ODONTOLOGO_ADM', 'ASISTENTE'],
      pacientes: 500,
      prestaciones: 1000,
    },
  },
  {
    tipoMembresia: 'GOLD',
    detalles: {
      plan: 'GOLD',
      usuarios: 4,
      perfiles: ['ODONTOLOGO', 'ODONTOLOGO', 'ASISTENTE', 'ADMINISTRADOR'],
      pacientes: 5000,
      prestaciones: 10000,
    },
  },
];

export const setEmpresa = (empresa) => {
  return {
    type: SET_EMPRESA,
    payload: empresa,
  };
};

export const getEmpresa = (empresaId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    getEmpresaById(empresaId)
      .then((empresa) => {
        dispatch(setEmpresa(empresa));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const guardaEmpresa = (empresa) => {
  return function (dispatch) {
    dispatch(httpRequest());
    saveEmpresa(empresa)
      .then((emp) => {
        dispatch(setEmpresa(empresa));
        dispatch(httpSuccess());
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};

export const agregaEmpresa = (empresa) => {
  return function (dispatch) {
    dispatch(httpRequest());
    addEmpresa(empresa)
      .then((emp) => {
        dispatch(setEmpresa(emp));
        dispatch(httpSuccess());
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};

export const guardaEmpresaUsuario = (empresa, usuario) => {
  return async function (dispatch) {
    dispatch(httpUserRequest());
    var batch = db.batch();

    const empresasRef = db.collection("empresas").doc();
    const usuariosRef = db.collection("usuarios").doc();
    const secuenciasRef = db.collection("secuencias").doc();
    const fecha = new Date();
    let newSequence = {
      year: fecha.getFullYear(),
      empresaId: empresasRef.id,
      nombre: "orden",
      valor: 0,
    };
    let fecVen = fecha.setDate(fecha.getDate() + 15);

    dispatch(httpRequest());
    usuario.empresaId = empresasRef.id;
    usuario.cargo = "GERENTE";
    usuario.perfil = "ADMINISTRADOR";
    usuario.id = usuariosRef.id;
    usuario.fecha = fecha;
    
    empresa.id = empresasRef.id;
    empresa.fecIni = fecha;
    empresa.fecUltPago = fecha;
    empresa.fecVen = fecVen;
    empresa.tipoMembresia = empresa.tipoMembresia;

    empresa.membresia = getMembresiaDetails(empresa.tipoMembresia);
    console.log('empresa.membresia:', empresa.tipoMembresia);


    batch.set(empresasRef, empresa);
    batch.set(usuariosRef, usuario);
    batch.set(secuenciasRef, newSequence);

    tratamientos.forEach((p) => {
      var tratamientosRef = db.collection("tratamientos").doc();
      let tratamiento = {
        id: tratamientosRef.id,
        especialidad: p.especialidad,
        tratamiento: p.tratamiento,
        tipo: p.tipo ? p.tipo : "",
        precio: p.precio,
        codLab: p.codLab ? p.codLab : "",
        empresaId: empresa.id,
        estado: p.estado ? p.estado : "VIGENTE",
        subGrupoId: p.subGrupoId ? p.subGrupoId : "",
        readOnly: p.readOnly,
      };
      batch.set(tratamientosRef, tratamiento);
    });

    const accesosQuery = await db.collection("accesos").where("empresaId", "==", '0').get();

    console.log('accesosQuery:', accesosQuery);

    if (accesosQuery.empty) {
      console.log('No se encontraron accesos en la colección "accesos".');
      return null;
    }

    accesosQuery.docs.forEach((doc) => {
      var accesosRef = db.collection("accesos").doc();
      let acceso = doc.data();
      acceso.empresaId = empresa.id;
      batch.set(accesosRef, acceso);
    });

    batch
      .commit()
      .then(() => {
        dispatch(setEmpresa(empresa));
        dispatch(setUsuarioSesion(usuario));
        dispatch(httpUserRequestSuccess(usuario));
        //dispatch(setUser(usuario));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

function getMembresiaDetails(tipoMembresia) {
  const membresiaEncontrada = membresias.find(m => m.tipoMembresia === tipoMembresia);
  console.log('membresiaEncontrada:', membresiaEncontrada);
  return membresiaEncontrada ? membresiaEncontrada.detalles : {};
}