import { SELECT_TOOTH, NEW_TOOTH_SELECTION } from "./denticionTypes";

const initialState = [];
const denticionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_TOOTH:
      //creamos un nuevo objeto a partir del estado "state" y copiamos el objeto enviado desde Prestacion Table
      return { ...state, ...action.payload };
    case NEW_TOOTH_SELECTION:
      return initialState;

    default:
      return state;
  }
};

export default denticionReducer;
