// firebaseFunctions.js
import { db } from '../../firebase/Config';
import accessosJSON from '../../../data/accesos.json';


export const obtenerDatosEmpresa = async (empresaId) => {
    try {
        const empresaSnapshot = await db.collection('empresas').doc(empresaId).get();

        if (empresaSnapshot.exists) {
            return empresaSnapshot.data();
        } else {
            console.log('No se encontraron datos en la colección empresas.');
            return null;
        }
    } catch (error) {
        console.error('Error al obtener datos de la empresa desde Firebase:', error);
        throw error;
    }
};

export const obtenerPerfilesCreados = async (empresaId) => {
    try {
        const perfilesSnapshot = await db.collection('accesos').where('empresaId', '==', empresaId).get();

        return perfilesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
        console.error('Error al obtener perfiles creados:', error);
        throw error;
    }
};

export const crearPerfil = async (usuario, nombrePerfil, accesos) => {
    try {
        console.log('Usuario:', usuario);
        console.log('Nombre del perfil:', nombrePerfil);
        console.log('Accesos:', accesos);
        console.log('Accesos JSON:', accessosJSON);

        const nuevoJSON = {
            empresaId: usuario.empresaId,
            perfil: nombrePerfil,
            lateral: [...accesos],


        };

        await db.collection('accesos').add(nuevoJSON);
        console.log('Perfil creado y guardado en la colección "accesos":', nuevoJSON);

    } catch (error) {
        console.error('Error al guardar el perfil en la colección "accesos":', error);
        throw error;
    }
};




export const actualizarPerfilEnFirebase = async (perfilId, nombrePerfil, accesosSeleccionados) => {
    try {


        await db.collection('accesos').doc(perfilId).update({
            perfil: nombrePerfil,
            lateral: accesosSeleccionados,
        });

        console.log('Perfil actualizado en la base de datos:', {
            perfil: nombrePerfil,
            lateral: accesosSeleccionados,
        });
    } catch (error) {
        console.error('Error al actualizar el perfil en la base de datos:', error);
        throw error;
    }
};

export const eliminarPerfil = async (perfilId) => {
    try {
        await db.collection('accesos').doc(perfilId.id).delete();
        console.log('Perfil eliminado de la base de datos:', perfilId);
    } catch (error) {
        console.error('Error al eliminar el perfil de la base de datos:', error);
        throw error;
    }
};
