import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { dispositivo } from "../../../../components/Dispositivos";

export const ContainerTitle1 = styled.div`
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;

export const ContainerTitleText1 = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;

export const ContainerBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 2%;
  background: #fff;
  justify-content: center;
  border-radius: 10px;
  display: block;
  border: 2px solid #0c8de3;
  @media ${dispositivo.tablet} {
    display: flex;
  }
`;

export const ContenedorRaiz = styled.div`
  display: block;
  width: 33%;
  margin: 0 5%;
  max-width: 50px;
  margin-top: 2.6%;
`;

export const ContenedorCorona = styled.div`
  display: block;
  width: 33%;
  justify-content: center;
  margin: 0 5%;
  max-width: 100px;
`;

export const TablaHeaderLabel = styled.strong`
  font-size: 12px;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 18%;
  text-align: left;
  margin-right: 5px;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;
export const TablaHeaderText = styled.span`
  font-size: 12px;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const ContainerText = styled.div`
  display: block;
  width: 40%;
  justify-content: left;
  text-align: left;
`;

export const SubontainerText = styled.div`
  display: flex;
`;

//////////////

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;
`;

export const ContainerTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  text-align: center;
`;

export const ContainerTitleText = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  background-color: #0c8de3;
  color: white;
  border-radius: 10px;
`;


export const ButtonPagar = styled.button`
  height: 2.25rem;
  font-size: 0.9rem;  
  width: 7rem;
  border-radius: 0.8rem;      
  text-transform: uppercase;
  cursor: pointer;
  font-width: bold;
  background-color: #0060b0;
  color: #ffffff;
  border: none;
  &:hover{
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
  }
  @media ${dispositivo.tablet}{
    width: 10rem;
    font-size: 1rem;  
  }  
  @media ${dispositivo.laptop} {      
    width: 12.5rem;
  }
`;
export const ButtonCancelar = styled(ButtonPagar)`
  background-color: #ffffff;
  border: 2px solid #0060b0;
  color: #0060b0;
  &:hover {
    color:#000000;
    background-color: #0c8de390;
    font-width: bold;
    border: none;
  }
`;


export const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  justify-content: center;
  text-align: center;
`;

export const StyledForm = styled.form`
  width: 90%;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const Tabla = styled.table`
  width: 33%;
  justify-content: center;
  text-align: center;
  width: 90%;
  @media ${dispositivo.tablet} {
    width: 100%;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin: 1em 0;
`;

export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
  justify-content: center;
  text-align: center;
`;

export const TablaHeaderTextoItems = styled.th`
  font-size: 12px;
  color: #040404;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: center;
  text-align: left;
  font-width: bold;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;
export const TablaHeaderTextoValues = styled.th`
  font-size: 12px;
  color: #045184;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: center;
  text-align: right;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const TablaHeaderTextoNumber = styled.th`
  font-size: 12px;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: right;
  text-align: right;
  @media ${dispositivo.tablet} {
    font-size: 18px;
    font-size: 14px;
  }
`;

export const TablaHeaderTexto = styled.th`
  font-size: 12px;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: right;
  text-align: right;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const TablaBody = styled.tbody`
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
  vertical-align: middle;
`;

export const TablaBodyTexto = styled.th`
  font-size: 12px;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  vertical-align: middle;
  text-align: left;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const SelectStyle = styled.select`
  width: 50%;
  vertical-align: middle;
`;

export const StyledInput = styled.input`
  width: 45%;
  color: #707070;
  vertical-align: middle;
  text-align: right;
  padding-left: 12px;
  padding-right: 0px;
  &:focus {
    outline: none;
  }
`;

export const LinkCancelar = styled(Link)`
  background-color: #0c8de3;
  border: none;
  color: white;
  text-align: center;
  font-size: 16px;
  margin: 4px 22px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  :hover {
    background-color: #707070;
    color: white;
  }
`;
