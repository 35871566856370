import React from "react";
import { AllergiesWrapper } from "./styles";

const Allergies = (allergiesProps) => {
  const { allergies = [] } = allergiesProps;

  return (
    <AllergiesWrapper>
      <div className="flex-container">
        <img
          className="allergies__img"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA7AAAAOwBeShxvQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANiSURBVFiF7ZdNaFxVFMd/5755mTSJ0yYjMkFisSgFP1BJSV2oybS4sLpwoRhbUQtFOsNQq7FWtJBS3BhKqUg+cBVw0YLgTlHBzqQp0kJptLXRoCI2NH5gcJDYTOZ9HFcT82be2BkziIv8d++ec8//d++79777hH/QyI+pdscwZPu8mt44+nt5vHjqrrw9PR2TjG/KYzsnU+0qDEWd6IHx5PF8NY+KjiUdu5LZsmRkSpE9jiUDYTliidAcDe2vyAAqe5bs4tTOyVR3NZ9IqPls5m6DfgJ0gLzvO/ZRAL3cl8BYu1V4HLgHiDqeUsyKimUtSCTyuWX8A3L/hYvrpPDWIs0bUZ5Rkc/6J/YmT/aOTVUMorzh7bkXblEvcg5IgBzZ3zU8qNm+iJ+IHBLRV4DWlfnu3C/BAkbURKM5q9C2Q5K5wtOT6TeBN4Cf1Gfryd6R2UB6xdR5kVEgIcjw/q7hQb34QLt2mo9FdLDcPFS+ir9YSLp2/qqe67n1xIMjh0QZAzoxOlKeHgA4fiX9FLAD5WspmAE9321rU/QDkO3XNS4fSNHtcIvOF3p2a6zJu/YSMCPIY/2n009UBUA4CGBEU/tuf2fJb9twFOir13wZwnFijudNjCfHC/h+GkCE10IBjs3u7QHuU/TLfV2jE/pNcrNA6t+aL2upcK97vufRE71jpwQuAN39ZzJbSuHlXRARe07VOyhqZgB8MS+LYq8aANCiOwR8iMoR0M2WKz+XYhW7AED1sNGZyatA4nrFK3ZBmCzxbT8elWTOLQ+FH0TTuU21mNcsT43b+mdvWCgcwNg3N8y8VFLljtoBRDc0GsBX7awHIHRtrEomfL1V/Rj9V1oDWANYA/i/Akis0UYCHTUBqB42qrq70QBadJ/UbF/FJbhyBmbOvM4qbkHVAZx2r3nho/L2wPms3z4SVa/4K1DzK6jpPlCSEbU1HpNkbmG5KYjpbKrHvG75Kl7b4kMBpmCGhP/mNFIqN6x8DC4K/9r3YtY9XK2vl//jeXXcXYEC8/PB+i2tP3BTx1i1Gkac0yuf6/ruF7J33mZUpuHvy6p96asV1QQ3Ht9m7/otW2vNug6i5uTl70AygB8W99evf68e87oBAJq2XXpXhO0KnwILWEa1pWWeG+MvWs/ln6233l8ZfTHW9rjqJAAAAABJRU5ErkJggg=="
          alt="allergies icon"
        />
        <span className="allergies__title">Paciente Alérgico!</span>
      </div>
      <div className="allergies__data">
        <ul style={{paddingLeft: "14px"}}>
          {allergies.map((al, index) => (
            <li key={index} className="allergies__data__allergy">{al.valor} - <span style={{fontStyle: "italic", fontSize: "12px"}}>{al.llave}</span></li>
          ))}
        </ul>
      </div>
    </AllergiesWrapper>
  );
};

export default Allergies;
