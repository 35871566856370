import React from "react";

const MolarDeciduoInf = (props) => {
  const configuracion = props.config;

  const getColor = (cara) => {
    const defaultColor = "#ffeca2";
    let color = defaultColor;
    if (props.config.caras) {
      let idx = props.config.caras.findIndex((it) => it.cara === cara);
      if (idx > -1) color = props.config.caras[idx].color;
    }
    return color;
  };

  return (
    <svg
      id="Molar_inf"
      data-name="Molar inf"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 340.5 492"
    >
      <title>molar_deciduo_inf</title>
      <path
        id="Raiz"
        d="M288.58,141.33l-251.86-1.5c-15.28-.11-25,14.24-18,26.13,13.66,22.8,4.76,40.26-8.11,91.35a15,15,0,0,0-.44,3.12c-2.32,81.83-6.2,115.85-.66,151,0,19.46,10.5,64.5,18.94,73.18A11.47,11.47,0,0,1,30,486.41c22,35.51,28.54-83,36.14-107.1,6.93-22,21.23-69.07,67.21-99.06,58.6-38.2,69.2-47.22,101.91,11.65a14.46,14.46,0,0,1,.78,1.58c23.23,54.51,43.29,60.17,15.31,171-26.83,94.47,123-45.38,56.81-301.58C308.49,151.61,301.32,141.44,288.58,141.33Z"
        transform="translate(0 -4.63)"
        fill={configuracion.raiz.fill}
        stroke={configuracion.raiz.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
      <path
        id="Nucleo"
        d="M282.23,119.05,56.92,117.59c-13.68-.11.6,14.42,6.89,26.28,5.1,48.37-33.32,108-34.93,160.78-2.33,76.43-.6,141.64,9.86,155,.46.59,6.95-84.61,13.74-108.62,6.15-21.92,70-108.92,125-117.06,69.8-10.31,117.79,93.92,96.72,205,0,0,67.21-61.34,9.47-295.17C284,132.55,293.61,119.18,282.23,119.05Z"
        transform="translate(0 -4.63)"
        fill={configuracion.nucleo.fill}
        stroke={configuracion.nucleo.strokeColor}
      />
      <g id="6">
        <path
          d="M284.79,257.21,238,258c-8.71.17-16-4.38-16.23-10.16h0V197.63s55.18,7.28,83.45-26.57c-2.7,11.38,10.42,61.05,10.42,61.05,2.13,12.43-11.31,23.67-30,25.08Z"
          transform="translate(0 -4.63)"
          fill={getColor("6")}
        />
      </g>
      <g id="7">
        <path
          d="M111.66,270.73s-63.08,12.09-71.69,0c-14.49-20.46-11.16-90.8-7.94-103,19.19,27.65,83.85,39.18,100,38a2.54,2.54,0,0,1,2.84,1.8v.21L133,254.93C132.34,263.86,123,270.8,111.66,270.73Z"
          transform="translate(0 -4.63)"
          fill={getColor("7")}
        />
      </g>
      <g id="8">
        <path
          d="M222.3,242.14H109.87c-42.08,0-76.07-19-76.07-42.57V159.45H298.35v40.12C298.35,223.11,264.4,242.14,222.3,242.14Z"
          transform="translate(0 -4.63)"
          fill={getColor("8")}
        />
      </g>
      <g id="9">
        <path
          d="M199.1,240.4l-74.54,7c-52.24,0-94.33-24.11-94.33-53.64V159.44H306.84l-6.35,38C293.76,242.14,251.32,240.4,199.1,240.4Z"
          transform="translate(0 -4.63)"
          fill={getColor("9")}
        />
      </g>
      <path
        id="Corona"
        d="M2.5,100.38C2.77,48,5.48,5,40.78,11.49c50.43,24.07,62.4,11.22,84.27,0,40.17-4.93,58.18,16.29,75,15.64,23.54-1.07,14.32-7.18,25.81-11.68C255.57,3.67,328.21-9.93,337.27,84.86c3.92,40.16-2.3,131.3-165.56,129.7C45.11,213,2.09,166.57,2.5,100.38Z"
        transform="translate(0 -4.63)"
        fill={configuracion.corona.fill}
        stroke={configuracion.corona.strokeColor}
        stroke-miterlimit="10"
        stroke-width="5"
      />
    </svg>
  );
};
export default MolarDeciduoInf;
