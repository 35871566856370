import React from "react";

import IncisivoOdontograma from "../../components/Dientes/IncisivoOdontograma";
import MolarSup from "../../components/Dientes/MolarSup";
import MolarInf from "../../components/Dientes/MolarInf";
import MolarDeciduoSup from "../../components/Dientes/MolarDeciduoSup";
import MolarDeciduoInf from "../../components/Dientes/MolarDeciduoInf";
import { TopBarPaciente } from "../../components/TopBarPaciente";
import PreMolarSup from "../../components/Dientes/PreMolarSup";
import PreMolarInf from "../../components/Dientes/PreMolarInf";
import IncisivoCentralSup from "../../components/Dientes/IncisivoCentralSup";
import IncisivoCentralInf from "../../components/Dientes/IncisivoCentralInf";
import IncisivoLateralInf from "../../components/Dientes/IncisivoLateralInf";
import IncisivoLateralSup from "../../components/Dientes/IncisivoLateralSup";
import Extraccion from "../../components/Dientes/Extraccion";
import CaninoInf from "../../components/Dientes/CaninoInf";
import CaninoSup from "../../components/Dientes/CaninoSup";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { OdontoInicialModal } from "../../components/OdontoInicialModal/index";
import {
  Denticion,
  ADULTO,
  INFANTIL,
  superiorDerecha,
  inferiorDerecha,
  superiorIzquierda,
  inferiorIzquierda,
  incisivo_central_sup,
  incisivo_central_inf,
  incisivo_lateral_sup,
  incisivo_lateral_inf,
  canino_sup,
  canino_inf,
  premolar_sup,
  premolar_inf,
  molar_sup,
  molar_inf,
  molar_deciduo_sup,
  molar_deciduo_inf,
  DefaultConfig,
} from "../../components/ConfigDiente";

import { getOdontograma } from "../../redux/odontograma/odontogramaActions";

import {
  DienteContenedorRaiz,
  DienteContenedorRaizInferior,
  DienteContenedorCorona,
  DienteContenedor,
  ContenedorAuxiliar,
  DienteContenedorNumero,
  DenticionSide,
  DenticionContainer,
  DenticionBlock,
  ContainerTitle,
  ContainerTitleText,
  MainContainer,
  ContenedorAuxiliarSuperior,
  ContaineRight,
  StyledSelect,
  StyledButton,
  ContainerButton,
  LinkCancelar,
} from "./styles";

export const Odontograma = (props) => {
  const [isShown, setIsShown] = React.useState(true);
  const dispatch = useDispatch();
  const paciente = useSelector((state) => state.paciente);
  const odontograma = useSelector((state) => state.odontograma);
  const [tipoDenticion, setTipoDenticion] = React.useState(ADULTO);
  const [seleccionados, setSeleccionados] = React.useState(Denticion.dientes);
  const [grupo, setGrupo] = React.useState();
  const { addToast } = useToasts();

  React.useEffect(() => {
    if (odontograma.items.length == 0) dispatch(getOdontograma(paciente.id));
  }, []);

  const getDiente = (nombre, config) => {
    const { estado } = config;
    if (estado === "EXODONCIA") return <Extraccion />;
    switch (nombre) {
      case incisivo_central_sup:
        return <IncisivoCentralSup config={config} />;
      case incisivo_central_inf:
        return <IncisivoCentralInf config={config} />;
      case incisivo_lateral_sup:
        return <IncisivoLateralSup config={config} />;
      case incisivo_lateral_inf:
        return <IncisivoLateralInf config={config} />;
      case canino_sup:
        return <CaninoSup config={config} />;
      case canino_inf:
        return <CaninoInf config={config} />;
      case premolar_sup:
        return <PreMolarSup config={config} />;
      case premolar_inf:
        return <PreMolarInf config={config} />;
      case molar_sup:
        return <MolarSup config={config} />;
      case molar_inf:
        return <MolarInf config={config} />;
      case molar_deciduo_sup:
        return <MolarDeciduoSup config={config} />;
      case molar_deciduo_inf:
        return <MolarDeciduoInf config={config} />;
      default:
        return <IncisivoLateralSup config={config} />;
    }
  };

  const DienteSuperior = ({ item, config }) => {
    const { diente, nombre, selected, grupo } = item;
    //const imgDiente = require(`./svg/${tipo}.svg`);
    const imgCorona = require(`../../assets/svg/incisivo.svg`);
    return (
      <DienteContenedor>
        <DienteContenedorRaiz>{getDiente(nombre, config)}</DienteContenedorRaiz>
        <ContenedorAuxiliarSuperior>
          <DienteContenedorCorona>
            <IncisivoOdontograma config={config} />
          </DienteContenedorCorona>

          <DienteContenedorNumero>{diente}</DienteContenedorNumero>
        </ContenedorAuxiliarSuperior>
      </DienteContenedor>
    );
  };

  const DienteInferior = ({ item, config }) => {
    const { diente, nombre, selected, grupo } = item;
    //const imgCorona = require(`./svg/incisivo.svg`);

    return (
      <DienteContenedor>
        <ContenedorAuxiliar>
          <DienteContenedorNumero>{diente}</DienteContenedorNumero>

          <DienteContenedorCorona>
            <IncisivoOdontograma config={config} />
          </DienteContenedorCorona>
        </ContenedorAuxiliar>

        <DienteContenedorRaizInferior>
          {getDiente(nombre, config)}
        </DienteContenedorRaizInferior>
      </DienteContenedor>
    );
  };

  const generaDientes = (items) => {
    let dientes = [];
    let idx = -1;
    items.forEach((item) => {
      if (odontograma.items) {
        idx = odontograma.items.findIndex((it) => it.diente == item.diente);
      }
      let config = { caras: [] };
      if (idx > -1) {
        config = odontograma.items[idx];
      } else {
        config = new DefaultConfig(item.diente);
      }
      if (item.lado == superiorIzquierda || item.lado == superiorDerecha) {
        dientes.push(
          <DienteSuperior key={item.diente} item={item} config={config} />
        );
      } else {
        dientes.push(
          <DienteInferior key={item.diente} item={item} config={config} />
        );
      }
    });

    return dientes;
  };
  const handleChangeTipoDenticion = (e) => {
    let tipo = e.target.value;
    setTipoDenticion(tipo);
    if (tipo == "ADULTO") {
      setIsShown(true);
    } else {
      setIsShown(false);
    }
  };

  return (
    <React.Fragment>
      <TopBarPaciente />
      <MainContainer>
        <DenticionContainer id="dientesContenedor">
          <ContainerButton>
            <ContaineRight>
              <label className="label-styled" for="tipo">
                Tipo de dentición:
              </label>
              <StyledSelect id="tipo" onChange={handleChangeTipoDenticion}>
                <option value={ADULTO}>Adulto</option>
                <option value={INFANTIL}>Infantil</option>
              </StyledSelect>
            </ContaineRight>
            {odontograma.tipo === "INICIAL" && (
              <LinkCancelar to="/paciente/odontogramainicial">
                Odontograma Inicial
              </LinkCancelar>
            )}{" "}
          </ContainerButton>

          <DenticionBlock>
            <DenticionSide isShown={isShown}>
              {generaDientes(
                seleccionados.filter(
                  (item) =>
                    item.lado === superiorDerecha && item.tipo === tipoDenticion
                )
              )}
              {generaDientes(
                seleccionados.filter(
                  (item) =>
                    item.lado === superiorIzquierda &&
                    item.tipo == tipoDenticion
                )
              )}
            </DenticionSide>
          </DenticionBlock>

          <DenticionBlock>
            <DenticionSide isShown={isShown}>
              {generaDientes(
                seleccionados.filter(
                  (item) =>
                    item.lado === inferiorDerecha && item.tipo == tipoDenticion
                )
              )}
              {generaDientes(
                seleccionados.filter(
                  (item) =>
                    item.lado === inferiorIzquierda &&
                    item.tipo == tipoDenticion
                )
              )}
            </DenticionSide>
          </DenticionBlock>
        </DenticionContainer>
      </MainContainer>
    </React.Fragment>
  );
};

export default Odontograma;
