import React from "react";

import {
  ContainerTitle,
  ContainerTitleText,
  TablaBody,
  TablaHeaderTextoItems,
  TablaHeaderTextoValues,
  Tabla,
  LinkCancelar,
  ContainerBody,
  Select,
  ButtonPagar,
  ItemContainer,
  ButtonInsideContainer,
  InputButton,
  ButtonInside,
  FlowContainer,
  Textarea2,
  StyledInput2,
  SelectStyle,
  Dientelab,
  ContainerBtn,
  ItemContainerEnviar,
} from "./styles";

export const EnviaOrden = ({
  orden,
  labs,
  onCloseModal,
  refOrden,
  onEnviar,
  onChangeOrden,
  onChangeItem,
}) => {
  return (
    <form ref={refOrden}>
      <ContainerTitle>
        <ContainerTitleText>Envia Orden</ContainerTitleText>
      </ContainerTitle>
      <ContainerBody>
        <Tabla>
          <TablaBody>
            <tr>
              <TablaHeaderTextoItems><strong>Laboratorio</strong></TablaHeaderTextoItems>
              <TablaHeaderTextoValues>
                <SelectStyle id="labId" name="labId" value={orden.labId} disabled>
                  {labs.map((lab) => (
                    <option value={lab.id}>{lab.razonSocial}</option>
                  ))}
                </SelectStyle>
              </TablaHeaderTextoValues>
            </tr>
            <tr>
              <TablaHeaderTextoItems>
                <strong>Fec. Envío: </strong>
              </TablaHeaderTextoItems>
              <TablaHeaderTextoValues data-label="envio">
                <StyledInput2
                  type="date"
                  value={orden.fecEnv}
                  name="fecEnv"
                  required
                  disabled
                ></StyledInput2>
              </TablaHeaderTextoValues>
            </tr>
            <tr>
              <TablaHeaderTextoItems>
                <strong>Fecha Prueba: </strong>
              </TablaHeaderTextoItems>
              <TablaHeaderTextoValues data-label="recep">
                <StyledInput2
                  type="date"
                  value={orden.fecRec}
                  name="fecRec"
                  onChange={onChangeOrden}
                  required
                ></StyledInput2>
              </TablaHeaderTextoValues>
            </tr>
          </TablaBody>
        </Tabla>

        {orden.items.map((item, index) => (
          <ItemContainerEnviar>
            <Dientelab>{` ${item.nombre} dientes:[${item.dientes}] S/ ${item.precio}`}</Dientelab>

            <Textarea2
              name="nota"
              rows="3"
              cols="50"
              placeholder="Descripcion de la Nota"
              value={item.nota}
              disabled
            ></Textarea2>

            <div className="divEnviar">
              <span>Terminado</span>
              <input
                name="terminado"
                type="checkbox"
                checked={item.terminado}
                disabled
              ></input>
            </div>
            <div>
              {item.estados && (
                <ul>
                  {item.estados.map((estado, indey) => (
                    <li>{estado}</li>
                  ))}
                </ul>
              )}
            </div>
          </ItemContainerEnviar>
        ))}
      </ContainerBody>
      <ContainerBtn>
        <LinkCancelar onClick={onCloseModal}>Cancelar</LinkCancelar>
        <ButtonPagar onClick={onEnviar}>Enviar</ButtonPagar>
      </ContainerBtn>
    </form>
  );
};
