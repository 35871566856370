export class COrdenRx {
  constructor(
    id = "",
    estado = "PENDIENTE",
    fecEnv = new Date(),
    fecTer = "",
    centroRxId = "",
    centroRxNombre = "",
    paciente = {},
    empresaId = "",
    items = [],
    empresa = {},
    comentarios = ""
  ) {
    this.id = id;
    this.estado = estado;
    this.fecEnv = fecEnv;
    this.fecTer = fecTer;
    this.centroRxId = centroRxId;
    this.centroRxNombre = centroRxNombre;
    this.paciente = paciente;
    this.empresaId = empresaId;
    this.items = items;
    this.empresa = empresa;
    this.comentarios = comentarios;
  }
  getFecha() {
    const fecha = new Date();
    const a = fecha.getFullYear();
    const m = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const d = fecha.getDate().toString().padStart(2, "0");
    return `${a}-${m}-${d}`;
  }
}

export const ESTADOS = {
  SOLICITADO: "SOLICITADO",
  PENDIENTE: "PENDIENTE",
  PROCESO: "PROCESO",
  TRANSITO: "TRANSITO",
  PRUEBAS: "PRUEBAS",
  TERMINADO: "TERMINADO",
};
