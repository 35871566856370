import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import Chartjs from "chart.js";
import {
  DivCharts,
  Item,
  Items,
  Contenedor,
  Contenido,
  Titulo,
  ContenedorBienvenida,
} from "./styles";
import Spinner from 'react-bootstrap/Spinner';
import { fetchStats } from "../../services/firebase/stsMensual/index";
import { getTotalAbonos } from "../../services/firebase/movimientos/index";
import { fetchPrestacionesTerminadasEmpresa } from "../../services/firebase/prestaciones/index";
const getConfig = (data, titulo) => {
  return {
    type: "bar",
    data: {
      labels: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ],
      datasets: [
        {
          label: titulo,
          data: data,

          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
        },
      ],
    },
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },
  };
};

const Chart = ({ data, titulo }) => {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(
        chartContainer.current,
        getConfig(data, titulo)
      );
      setChartInstance(newChartInstance);
    }
  }, [chartContainer]);

  return (
    <div>
      <canvas ref={chartContainer} />
    </div>
  );
};

export const Inicio = () => {
  const sesion = useSelector((state) => state.sesion);
  const pacientes = useSelector((state) => state.pacientes);
  const gastosFijos = useSelector((state) => state.gastosFijos);
  const [estado, setEstado] = React.useState({
    loading: false,
    abonos: 0,
    prestaciones: 0,
    error: "",
  });

  const getDatos = async (empresaId) => {
    let now = new Date();
    let hoy = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    setEstado({ ...estado, loading: true });

    let abonos = await getTotalAbonos(empresaId, hoy);
    let prestaciones = await fetchPrestacionesTerminadasEmpresa(empresaId, hoy);

    setEstado({
      ...estado,
      loading: false,
      abonos: abonos,
      prestaciones: prestaciones,
    });
  };

  React.useEffect(() => {
    console.log('sesion-usuario',sesion.usuario)
    if(sesion.usuario.empresaId)
    getDatos(sesion.usuario.empresaId);
  }, []);
  if (estado.loading) return <Spinner animation="border" role="status">
    <span className="visually-hidden"></span>
  </Spinner>;

  const frases = [
    { frase: "Hoy es un excelente día, ¡Animo!" },
    { frase: "¡Que bueno es crecer!...vamos por esos ..." },
    { frase: "¡Estoy para ayudarte!, vamos a superar los ..." },
    { frase: "Si planificaste bien, hoy superamos los ..." },
    { frase: "¿Llamaste clientes?, perfecto…, logremos más de  ..." }

  ];
  const Q = frases.length;
  const numAleatorio = Math.round(Math.random() * (Q - 1));
  const fraseAleatorio = frases[numAleatorio].frase;

  return (
    <>
      <ContenedorBienvenida>
        <h1 className="titulo-bienvenida">Bienvenido a Sysdent</h1>
        <h2 className="subtitulo-bienvenida">
          {fraseAleatorio}
        </h2>
        <Items>
          <Titulo>Costo Dia: {gastosFijos.dias > 0 ? gastosFijos.total / gastosFijos.dias : 0}</Titulo>

        </Items>
        <h2 className="subtitulo-reporte ">
          Este es el reporte de hoy
        </h2>
      </ContenedorBienvenida>
      <Contenedor>
        <Item>
          <Titulo>Ingresos</Titulo>
          <Contenido>{estado.abonos}</Contenido>
        </Item>
        <Item>
          <Titulo>Prestaciones</Titulo>
          <Contenido>{estado.prestaciones}</Contenido>
        </Item>
      </Contenedor>
    </>
  );
};
