import { db } from "../../services/firebase/Config";

import {
  FETCH_PACIENTES_REQUEST,
  FETCH_PACIENTES_SUCCESS,
  FETCH_PACIENTES_FAILURE,
  UPDATE_PACIENTE_IN_ARRAY,
  ADD_PACIENTE_IN_ARRAY,
} from "./pacientesTypes";

import { httpRequest, httpSuccess, httpFailure } from "../sesion/sesionActions";

import { setEstadoCompletado } from "../sesion/sesionActions";

const fetchPacientesRequest = () => {
  return {
    type: FETCH_PACIENTES_REQUEST,
  };
};

const fetchPacientesSuccess = (payload) => {
  return {
    type: FETCH_PACIENTES_SUCCESS,
    payload,
  };
};

const fetchPacientesFailure = (payload) => {
  return {
    type: FETCH_PACIENTES_FAILURE,
    payload,
  };
};

export const addPacienteInArray = (payload) => {
  return {
    type: ADD_PACIENTE_IN_ARRAY,
    payload,
  };
};

export const updatePacienteInArray = (payload) => {
  return {
    type: UPDATE_PACIENTE_IN_ARRAY,
    payload,
  };
};
const fechaACadena = (fecha) => {
  let f;
  if (fecha === null || fecha === undefined || fecha === "" || isNaN(fecha))
    f = new Date();
  else f = fecha.toDate();
  const a = f.getFullYear();
  const m = (f.getMonth() + 1).toString().padStart(2, "0");
  const d = f.getDate().toString().padStart(2, "0");
  return `${a}-${m}-${d}`;
};
export const fetchPacientes = (empresaId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    // Reference to the Firestore collection
    const pacientesCollection = db.collection('pacientes');
        // Set up a real-time listener
        const unsubscribe = pacientesCollection
        .where('empresaId', '==', empresaId)
        .onSnapshot((querySnapshot) => {
          let pacientes = [];
  
          querySnapshot.forEach((doc) => {
            pacientes.push({
              id: doc.id,
              nombres: doc.data().nombres,
              apellidos: doc.data().apellidos,
              estado: doc.data().estado,
              fecNac: fechaACadena(doc.data().fecNac),
              telefono: doc.data().telefono,
              patologias: doc.data().patologias,
              tipDoc: doc.data().tipDoc,
              direccion: doc.data().direccion,
              numDoc: doc.data().numDoc,
              correo: doc.data().correo,
              empresaId: doc.data().empresaId,
              token: doc.data().token
            });
      
          });
  
          // Dispatch only when there are changes
          dispatch(fetchPacientesSuccess(pacientes));
          dispatch(httpSuccess());
        }, (error) => {
          // Handle errors
          dispatch(httpFailure(error));
        });

    
  };
};
