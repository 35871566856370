import React, { useCallback, useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { auth, db } from "../../services/firebase/Config";
import { Link } from "react-router-dom";
import DatePicker from 'react-datepicker';
import "./styles.css";
import esLocale from '@fullcalendar/core/locales/es';
import { useDispatch, useSelector } from 'react-redux';
import { setPacienteSesion } from "../../redux/sesion/sesionActions";
import { setPaciente } from "../../redux/paciente/pacienteActions";
import { getCuenta } from "../../redux/cuenta/cuentaActions";
import { fetchPrestations } from "../../redux/prestations/prestationsActions";
import { fetchPrestacionesSesion } from "../../redux/prestacionesSesion/prestacionesSesionActions";
import { getOdontograma } from "../../redux/odontograma/odontogramaActions";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import Spinner from "react-bootstrap/Spinner";

const CitaItem = () => {
    const [citasGoogleCalendar, setCitasGoogleCalendar] = useState([]);
    const [eventosCalendario, setEventosCalendario] = useState([]);
    const [usuario] = useLocalStorage("usuario");
    const [showModal, setShowModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
    const [editMode, setEditMode] = useState(false);
    const [editedEvent, setEditedEvent] = useState(null);
    const [editedNombrePaciente, setEditedNombrePaciente] = useState("");
    const [editedSummary, setEditedSummary] = useState("");
    const [editedInicio, setEditedInicio] = useState("");
    const [editedFin, setEditedFin] = useState("");
    const [pacientesEncontrados, setPacientesEncontrados] = useState([]);
    const [pacienteNoEncontrado, setPacienteNoEncontrado] = useState(null);
    const [nombrePaciente, setNombrePaciente] = useState('');
    const [apellidoPaciente, setApellidoPaciente] = useState('');
    const [eventCorreo, setEventCorreo] = useState('');
    const [eventTelefono, setEventTelefono] = useState('');
    const [accessToken, setAccessToken] = useState(null);
    const [events, setEvents] = useState([]);
    const [error, setError] = useState(null);
    const { addToast } = useToasts();


    const sesion = useSelector((state) => state.sesion);
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const api = `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`
    console.log('api:', api);

    let url = `${process.env.REACT_APP_API_URL}/api/v1/calendar/`;
    console.log('url:', url);

    useEffect(() => {

        const getAccessToken = async () => {
            try {
                const empresaId = usuario?.empresaId;

                const response = await fetch(
                    `${url}/get-token?empresaId=${empresaId}`)

                const data = await response.json();
                console.log('data:', data);
                if (response.ok) {
                    setAccessToken(data.accessToken);
                } else {
                    setError(data.message);
                }
            } catch (error) {
                setError('Error en la solicitud. Verifica la conexión a Internet y vuelve a intentarlo.');
            }
        };
        const getEvents = async () => {
            try {
                const empresaId = usuario?.empresaId;

                const response = await fetch(`${url}/get-events?empresaId=${empresaId}`);
                const data = await response.json();
                setCitasGoogleCalendar(data.todayEvents)

                if (response.ok) {
                    setEvents(data.events);
                    addToast("Se obtuvo correctamente las citas", {
                        appearance: "success",
                        autoDismiss: true,
                    });
                } else {
                    setError(data.message);
                    addToast("Error al obtener las citas", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            } catch (error) {
                setError('Error en la solicitud. Verifica la conexión a Internet y vuelve a intentarlo.');
            }
        };
        const verificarPacientes = async () => {
            try {
                const pacientesRef = db.collection("pacientes");
                const pacientesSnapshot = await pacientesRef.get();

                const pacientesNumerosDNI = [];
                pacientesSnapshot.forEach((doc) => {
                    const numeroDNI = doc.data().numDoc;
                    pacientesNumerosDNI.push(numeroDNI);
                });

                setPacientesEncontrados(pacientesNumerosDNI);
            } catch (error) {
                console.error("Error al obtener los pacientes", error);
            }
        };
        getEvents();
        getAccessToken();
        setLoading(true)
        verificarPacientes();
        setLoading(false)
    }, [accessToken, addToast, url, usuario.empresaId]);


    useEffect(() => {
        if (citasGoogleCalendar.length > 0) {
            setEventosCalendario(
                citasGoogleCalendar.map((evento) => ({
                    id: evento.id,
                    title: evento.summary,
                    description: evento.description,
                    start: evento.start.dateTime,
                    end: evento.end.dateTime,
                    display: "block",
                }))
            );
        }
    }, [citasGoogleCalendar]);

    const verificarPaciente = (nombrePaciente) => {
        return pacientesEncontrados.includes(nombrePaciente);
    };

    const handleDateClick = (arg) => {

    };

    const handleEventClick = (arg) => {
        setSelectedEvent(arg.event);
        console.log("arg.event:", arg.event);

        const eventApellido = obtenerApellidoPaciente(arg.event.extendedProps.description);
        const nombrePaciente = obtenerNombrePaciente(arg.event.title);
        setNombrePaciente(nombrePaciente);

        const apellidoPaciente = obtenerApellidoPaciente(arg.event.title);
        setApellidoPaciente(eventApellido);

        const eventCorreo = obtenerCorreoDesdeDescripcion(arg.event.extendedProps.description);
        setEventCorreo(eventCorreo);


        const eventTelefono = obtenerTelefonoDesdeDescripcion(arg.event.extendedProps.description);
        setEventTelefono(eventTelefono);

        const pacienteEncontrado = verificarPaciente(eventApellido);

        setPacienteNoEncontrado(!pacienteEncontrado);

        const eventElement = arg.el;
        const rect = eventElement.getBoundingClientRect();

        const modalTop = window.scrollY + rect.top + rect.height;
        const modalLeft = window.scrollX + rect.left;

        setModalPosition({ top: modalTop, left: modalLeft });
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const obtenerNombrePaciente = (summary) => {
        const regex = /\(([^)]+)\)/;
        const match = regex.exec(summary);
        return match ? match[1] : "";
    };

    const obtenerApellidoPaciente = (summary) => {
        const regex = /<b>DNI<\/b>\s*([\d]+)/;
        const match = regex.exec(summary);

        return match ? match[1] : "";
    };

    const obtenerCorreoDesdeDescripcion = (description) => {
        const regex = /(?:[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/;
        const match = regex.exec(description);
        return match ? match[0] : '';
    };

    const obtenerTelefonoDesdeDescripcion = (description) => {
        const regex = /[\w.-]+@[\w.-]+\.[a-zA-Z]{2,6}\n(\d+)/;
        const match = regex.exec(description);
        return match ? match[1] : '';
    };

    const handleEdit = (event) => {
        setEditedEvent(event);
        setEditedSummary(event.title);
        setEditedInicio(new Date(event.start));
        setEditedFin(new Date(event.end));
        setEditMode(true);
    };

    const handleSaveEdit = async () => {
        try {
            const eventId = editedEvent.id;
            const updatedEvent = {
                summary: editedSummary,
                start: { dateTime: editedInicio.toISOString() },
                end: { dateTime: editedFin.toISOString() }
            };

            const response = await fetch(
                `https://www.googleapis.com/calendar/v3/calendars/primary/events/${eventId}`,
                {
                    method: "PATCH",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(updatedEvent),
                }
            );

            if (response.ok) {
                console.log("Evento actualizado correctamente");
                setEditMode(false);
                setEditedEvent(null);
                window.location.reload();
            } else {
                throw new Error("Error al actualizar el evento en Google Calendar");
            }
        } catch (error) {
            console.error("Error al actualizar el evento:", error);
        }
    };

    const handleCancelEdit = () => {
        setEditMode(false);
        setEditedEvent(null);
    };

    const handleDeleteClick = async (event) => {
        try {
            console.log("Evento a eliminar:", event);

            const eventId = event.id;

            console.log("ID del evento a eliminar:", eventId);

            const response = await fetch(
                `https://www.googleapis.com/calendar/v3/calendars/primary/events/${eventId}`,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (response.ok) {
                console.log("Evento eliminado correctamente");
                window.location.reload();
            } else {
                throw new Error("Error al eliminar el evento en Google Calendar");
            }
        } catch (error) {
            console.error("Error al eliminar el evento:", error);
        }

    }

    const handleActivateSessionClick = async () => {
        try {
            const pacienteRef = db.collection('pacientes').where('numDoc', '==', apellidoPaciente);
            const pacienteSnapshot = await pacienteRef.get();

            if (!pacienteSnapshot.empty) {
                const paciente = pacienteSnapshot.docs[0].data();

                const fechaNacimiento = paciente.fecNac.toDate();
                paciente.fecNac = fechaNacimiento;

                dispatch(setPaciente(paciente));
                dispatch(setPacienteSesion(true));
                dispatch(getCuenta(paciente.id));
                dispatch(fetchPrestations(paciente.id));
                dispatch(fetchPrestacionesSesion(paciente.id, sesion.fecha));
                dispatch(getOdontograma(paciente.id));

                history.push("/paciente/odontograma");

                setShowModal(false);
            } else {
                console.error('No se encontró al paciente con el apellido especificado');
            }
        } catch (error) {
            console.error('Error al obtener detalles del paciente desde la base de datos:', error);
        }
    };

    const headerToolbar = window.innerWidth < 768
        ? { start: "prev,next", center: "title", end: "" }
        : { start: "today prev,next", center: "title", end: "timeGridWeek,timeGridDay" };

    if (loading)
        return (

            <Spinner animation="border" role="status">
                <span className="visually-hidden"></span>
            </Spinner>

        );

    return (
        <>
            <div className="cita-item-container">
                <div className="calendar">

                    <FullCalendar
                        plugins={[timeGridPlugin, interactionPlugin]}
                        initialView="timeGridDay"
                        headerToolbar={headerToolbar}
                        locale={esLocale}
                        dateClick={handleDateClick}
                        editable={true}
                        eventClick={handleEventClick}
                        events={eventosCalendario}
                        slotDuration="01:00:00"
                        allDaySlot={false}
                        themeSystem="Litera"
                        eventDisplay="block"
                        slotLabelFormat={{
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true
                        }}
                        height={window.innerWidth < 768 ? "auto" : "auto"}
                        contentHeight={window.innerWidth < 768 ? "auto" : "auto"}
                    />
                </div>
                {showModal && selectedEvent && (
                    <div
                        className="modal"
                        style={{

                        }}
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2>Detalles del evento</h2>
                                <div className="modal-icons" style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}>
                                    <button className="icon-button" onClick={() => handleEdit(selectedEvent)} style={{
                                        background: "#c9f1ff",
                                        border: "none",
                                        cursor: "pointer"
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                            <path fill="#0284c7" d="m19.3 8.925l-4.25-4.2l1.4-1.4q.575-.575 1.413-.575t1.412.575l1.4 1.4q.575.575.6 1.388t-.55 1.387L19.3 8.925ZM17.85 10.4L7.25 21H3v-4.25l10.6-10.6l4.25 4.25Z" />
                                        </svg>
                                    </button>
                                    <button className="icon-button" onClick={() => handleDeleteClick(selectedEvent)} style={{
                                        background: "#c9f1ff",
                                        border: "none",
                                        cursor: "pointer"
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#0284c7" d="M9 3v1H4v2h1v13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6h1V4h-5V3H9m0 5h2v9H9V8m4 0h2v9h-2V8Z" /></svg>
                                    </button>
                                </div>
                            </div>
                            <div>
                                <p>
                                    <strong>Título: {selectedEvent.title} {pacienteNoEncontrado ?
                                        <Link
                                            to={`/paciente/datos?nombre=${nombrePaciente}&apellido=${apellidoPaciente}&correo=${eventCorreo}&telefono=${eventTelefono}`}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="30"
                                                height="30"
                                                viewBox="0 0 24 24"
                                            >
                                                <path
                                                    fill="#0284c7"
                                                    d="M20 22H6a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1Zm-1-2v-2H6a1 1 0 1 0 0 2h13Zm-7-10a2 2 0 1 0 0-4a2 2 0 0 0 0 4Zm-3 4h6a3 3 0 1 0-6 0Z"
                                                />
                                            </svg>
                                        </Link> :

                                        <button
                                            id="activarSesionBtn"
                                            className="icon-button"
                                            onClick={() => handleActivateSessionClick()}
                                            style={{
                                                background: "#c9f1ff",
                                                border: "none",
                                                cursor: "pointer"
                                            }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                                <path fill="#0284c7" d="M4 19v-.375V19V8v11Zm0 2q-.825 0-1.413-.588T2 19V8q0-.825.588-1.413T4 6h4V4q0-.825.588-1.413T10 2h4q.825 0 1.413.588T16 4v2h4q.825 0 1.413.588T22 8v4.275q-.45-.325-.95-.563T20 11.3V8H4v11h7.075q.075.525.225 1.025t.375.975H4Zm6-15h4V4h-4v2Zm8 17q-2.075 0-3.538-1.463T13 18q0-2.075 1.463-3.538T18 13q2.075 0 3.538 1.463T23 18q0 2.075-1.463 3.538T18 23Zm0-2q.2 0 .35-.15t.15-.35q0-.2-.15-.35T18 20q-.2 0-.35.15t-.15.35q0 .2.15.35T18 21Zm0-2q.2 0 .35-.15t.15-.35v-3q0-.2-.15-.35T18 15q-.2 0-.35.15t-.15.35v3q0 .2.15.35T18 19Z" />
                                            </svg>
                                        </button>
                                    }</strong>
                                </p>
                                <p><strong>Inicio:</strong> {selectedEvent.start.toLocaleString()}</p>
                                <p><strong>Fin:</strong> {selectedEvent.end.toLocaleString()}</p>
                            </div>
                            <button className="close-btn" onClick={closeModal} style={{ background: "#c9f1ff" }}>Cerrar</button>
                        </div>
                    </div>
                )}

            </div>
            {editMode && editedEvent && (
                <div
                    className="modal"
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        background: 'transparent',
                        border: '0px solid #ccc',
                        zIndex: 1000,
                        width: '80%',
                    }}
                >
                    <div
                        className="modal-content"
                        style={{
                            background: "white",
                            padding: "20px",
                            borderRadius: "8px",
                        }}
                    >
                        {" "}
                        <h2>Editar Evento</h2>
                        <p>Resumen:</p>
                        <p>Hora de Inicio:</p>
                        <DatePicker
                            selected={editedInicio}
                            onChange={(date) => {
                                const newDate = new Date(editedInicio);
                                newDate.setHours(date.getHours(), date.getMinutes());
                                setEditedInicio(newDate);
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="Hora"
                            dateFormat="h:mm aa"
                            className="time-picker"
                        />
                        <p>Hora de fin:</p>
                        <DatePicker
                            selected={editedFin}
                            onChange={(date) => {
                                const newDate = new Date(editedFin);
                                newDate.setHours(date.getHours(), date.getMinutes());
                                setEditedFin(newDate);
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Hora"
                            dateFormat="h:mm aa"
                            className="time-picker"
                        />
                        <button onClick={handleSaveEdit}>Actualizar</button>
                        <button onClick={handleCancelEdit}>Cancelar</button>


                    </div>

                </div>
            )}

        </>
    );
};

export default CitaItem;